import _defineProperty from "/app/store-locator/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _TEMPLATE_SEARCH_IMAG;

import { Template } from 'core/interfaces/template.interface';
import { getTemplateData } from 'core/templates';
import { config } from './config';
export var template = getTemplateData(config.template);
export var theme = template.theme;
export var components = template.components;
export function getThemeColor(color) {
  return theme === null || theme === void 0 ? void 0 : theme.colors[color];
}
export var TEMPLATE_SEARCH_IMAGE_BACKGROUND_MAP = (_TEMPLATE_SEARCH_IMAG = {}, _defineProperty(_TEMPLATE_SEARCH_IMAG, Template.Pokawa, null), _defineProperty(_TEMPLATE_SEARCH_IMAG, Template.FivePizza, '/image/fivepizza-search-background.jpeg'), _TEMPLATE_SEARCH_IMAG);