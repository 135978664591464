export const frKioskTranslations = {
  welcome: {
    cta: 'Toucher pour commander',
  },

  setupKiosk: {
    title: 'Installer',
    subtitle: 'Insérez le jeton du tableau de bord',

    token: { placeholder: 'Insérez le jeton ...' },

    unlockPad: {
      incorrectCode: 'Le code est incorrect',
    },

    home: {
      title: 'Paramètres de la borne',
      subtitle: 'Gérer les paramètres de la borne de commande',

      general: {
        title: 'Général',
        restaurant: 'Restaurant',
        deviceId: 'ID device',
        terminal: 'Terminal de paiement',
        refresh: 'Rafraîchir',
      },

      printer: {
        title: 'Imprimante',
        usb: 'Port USB',
        type: "Type d'imprimante",
      },

      tests: {
        title: 'Tests',
        printer: "Tester l'imprimante",
        crash: 'Tester un crash',
      },

      kiosk: {
        title: 'Kiosque',
        isAdmin: 'Mode administrateur',
        enable: 'Activer le mode kiosque',
        disable: 'Désactiver le mode kiosque',
        makeAdmin: 'Activer mode administrateur',

        removeAdmin: 'Désactiver mode administrateur',
      },

      version: {
        title: 'Version',
        downloadLatest: 'Télécharger la dernière version',
      },
    },

    error: {
      title: 'Erreur',
      subtitle: 'Un problème est survenu. Veuillez réessayer ou contacter le support client.',
    },
  },

  setup: {
    accessibility: 'Changer L’affichage',

    connect: {
      title: 'Connexion',
      description: 'Profitez des avantages avec votre compte client',
      retryTip:
        "Appuyez sur ce bouton si le kiosque était connecté avant et qu'il y avait un problème de réseau.",

      yes: {
        title: 'Se connecter',
        description: 'Se connecter avec son compte fidélité client',
      },
      no: {
        title: 'Commander',
        description: 'Continuer en tant qu’invité',
      },
    },

    guest: {
      title: 'Votre prénom',
      description: 'Pour identifier votre commande',
      placeholder: 'Entrez votre prénom ici ...',
    },

    login: {
      title: 'Se connecter',
      description: 'Saisissez votre identifiant',
      placeholder: 'ABC123',
    },

    order: {
      title: 'Commande',
      description: 'Choisissez votre mode de commande',
    },

    eatIn: {
      title: 'Service à table',
      description: 'Saisissez le numéro du chevalet ci-dessous',
      placeholder: '',
      pass: 'Récupérer ma commande au comptoir',
      confirm: 'Valider',
    },
  },

  catalog: {
    title: 'Bienvenue {{name}},',
    home: 'Accueil',

    cancel: {
      title: 'Votre panier sera vidé. Souhaitez-vous continuer ?',
    },
    inactivity: {
      title: 'Êtes-vous toujours là ?',
      description: 'Souhaitez-vous poursuivre votre commande ?',
      cta: 'Continuer',
    },

    header: {
      allergens: 'Liste d’allergène',
      fidelity: 'Ma fidélité',
      banners: 'en ce moment',
      categories: 'Nos catégories',
    },

    footer: {
      title: 'VOTRE COMMANDE',
      total: 'TOTAL {{total}}',
      empty: 'Votre panier est vide',

      buttons: {
        cancel: 'Abandonner la commande',
        validate: 'Valider',
      },
    },

    baseItems: {
      title: 'Souhaitez-vous retirer un ingrédient?',
    },
    upsell: {
      title: 'Faîtes un choix',
    },
    crossSell: {
      title: 'Un petit extra pour la route?',
    },

    cartProductDetails: {
      quantity: 'Quantité',
      personalize: 'Personnaliser',
    },
  },

  catalogProduct: {
    selection: 'Votre composition :',
    noThanks: 'Non merci',
    addProduct: 'Ajouter au panier',

    emptyItem: 'Sélection',

    extras: {
      minMax: '{{ min }} min. / {{ max }} max.',
      max: '{{ current }}/{{ max }} sélectionnés',
      multiMin: '{{ current }}/{{ min }} min.',
      optional: '{{ current }}/{{ max }} max.',

      skip: 'Non merci',
    },
  },

  checkout: {
    title: 'votre commande est-elle exacte ?',
    total: 'Total à payer',

    promotion: {
      cta: 'Code promo',
      title: 'Code Promo',
      subtitle: 'Saisissez votre code promo',
      placeholder: 'Code promo',
    },

    footerButtons: {
      cancel: 'Continuer la commande',
      validate: 'Passer au paiement',
    },

    payment: {
      home: {
        title: 'Paiement',
        subtitle: 'Choisissez votre mode de paiement',

        creditCard: 'Cartes bancaires',
        trd: 'Titres et cartes Restaurant',
        cash: 'Payer au comptoir',
      },

      loading: {
        pos: {
          title: 'Suivez les instruction de paiement.',
          subtitle:
            'Veuillez suivre les instructions de paiement affichées sur le terminal de paiement.',
        },
        trd: {
          title: 'Traitement de votre commande en cours...',
          subtitle: 'Nous envoyons la commande à la caisse, veuillez patienter.',
        },
        cash: {
          title: 'Traitement de votre commande en cours...',
          subtitle: 'Nous envoyons la commande à la caisse, veuillez patienter.',
        },
      },

      finished: {
        title: 'Nous préparons votre commande',
        title_cash: 'Dirigez-vous au comptoir pour régler',
        title_eatIn: 'Installez-vous, vous serez servi à table',

        pos: {
          subtitle: 'Votre paiement a été accepté',
        },
        trd: {
          subtitle: 'Dirigez-vous vers la caisse pour régler votre commande.',
        },
        cash: {
          subtitle: 'Dirigez-vous vers la caisse pour régler votre commande.',
        },

        ticketType: {
          title: 'Récupérez votre reçu de commande',
          print: 'Imprimer le ticket',
          email: 'Par email',
          emailTip: 'Un geste pour la planète !',
        },

        details: {
          title: 'N° de commande',
          subtitle: 'Présentez votre ticket pour récupérer votre commande',
          subtitle_cash: 'Présentez votre ticket au comptoir pour régler votre commande',
          subtitle_eathIn: 'Présentez votre ticket pour récupérer votre commande',
        },

        sendEmail: {
          title: 'Reçu',
          subtitle: 'Saisissez votre adresse email',
          input: 'Entrez votre e-mail ici ...',
        },
      },
    },

    footer: 'VOTRE COMMANDE : {{price}}',
  },

  update: {
    title: 'Vérification des mises à jour...',
  },
}
