import {
  Template,
  TemplateData,
  TemplateLinks,
  Theme,
  ThemeColor,
  ThemeComponents,
  ThemeTypography,
} from '../interfaces/template.interface'

const theme: Theme = {
  colors: {
    // Theme
    [ThemeColor.Primary]: '#104434',
    [ThemeColor.LightPrimary]: '#F0F5F9',
    [ThemeColor.Secondary]: '#EBB826',
    [ThemeColor.Tertiary]: '#ED9780',
    // System
    [ThemeColor.Black]: '#333333',
    [ThemeColor.White]: '#FFFFFF',
    [ThemeColor.LightGrey]: '#F8FAFA',
    [ThemeColor.MediumGrey]: '#F3F5F5',
    [ThemeColor.Grey]: '#707070',
    [ThemeColor.DarkGrey]: '#B9BDC0',

    [ThemeColor.New]: '#2D9CDB',
    [ThemeColor.Prices]: '#FF9E34',
    [ThemeColor.Discounts]: '#FF9E34',
    [ThemeColor.OutOfStock]: '#BDBDBD',
    [ThemeColor.Success]: '#008305',
    [ThemeColor.Alert]: '#EB5757',
    // Form
    [ThemeColor.InputContrast]: '#EBB826',
    [ThemeColor.ContrastThreshold]: '#E8E8EB',
    [ThemeColor.SwitchChecked]: '#2CD889',
    [ThemeColor.SwitchUnchecked]: '#E0E0E0',
    // Typography:
    [ThemeColor.Text]: '#104434',
    [ThemeColor.DarkText]: '#333333',
    [ThemeColor.LightText]: '#FFF',
    [ThemeColor.TextContrast]: '#FFFFFF',
    [ThemeColor.Icon]: '#333333',
  },

  typography: {
    [ThemeTypography.H1]: {
      fontFamily: 'Livvic',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '35px',
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
    },
    [ThemeTypography.H2]: {
      fontFamily: 'Livvic',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '37px',
      letterSpacing: '0.01em',
    },
    [ThemeTypography.H3]: {
      fontFamily: 'Livvic',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.BodyBold]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.Body]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.Link]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
      textDecorationLine: ' underline',
    },
    [ThemeTypography.Button]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.02em',
    },
    [ThemeTypography.CategoryWithPicture]: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0em',
    },
    [ThemeTypography.CategoryWithoutPicture]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.Label]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    [ThemeTypography.Placeholder]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: 300,
      lineHeight: '18px',
    },
    [ThemeTypography.Input]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '18px',
    },
    [ThemeTypography.ProductTag]: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    [ThemeTypography.PriceTag]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.PriceTagDiscount]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textDecorationLine: ' line-through',
    },
    [ThemeTypography.Alert]: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: '0em',
    },
    [ThemeTypography.AlertBold]: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    [ThemeTypography.Copyright]: {
      fontFamily: 'Poppins',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
  },

  mobileBreakpoint: 750,
}

const components: ThemeComponents = {
  navBar: {
    backgroundColor: theme.colors.primary,
    dividerColor: theme.colors.white,
    iconColor: theme.colors.white,
    textColor: theme.colors.white,
  },

  orderSettings: {
    backgroundColor: theme.colors.lightPrimary,
    dividerColor: theme.colors.primary,
    arrowColor: theme.colors.primary,
    labelColor: theme.colors.primary,
    valueColor: theme.colors.primary,
  },

  products: {
    cardWidth: 165,
    embeddedCardWidth: 286,

    labelColors: {
      discount: '#F2994A',
      new: '#2D9CDB',
      outOfOrder: theme.colors.white,
    },
  },
}

const links: TemplateLinks = {
  usefulLinks: {
    customerService: 'https://jour.fr/service-client/',
    accessToWebsite: 'https://jour.fr/',
    seeOurRestaurants: 'https://restaurants.jour.fr/',
  },
  legal: {
    cgv: 'https://jour.fr/conditions-generales/',
    cgu: 'https://jour.fr/conditions-generales-dutilisation/',
    cookies: 'https://jour.fr/politique-de-cookies-ue/',
    data: 'https://jour.fr/politique-de-donnees/',
    mentions: 'https://jour.fr/mentions-legales/',
  },
  applications: {
    appStore: 'https://apps.apple.com/fr/app/jour-healthy-by-nature/id1458103845',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.healthygroup.jour',
  },
}

export const jourTemplateData: TemplateData = {
  name: Template.Jour,
  storeLink: 'https://stores.jour.fr',
  webappLink: 'https://order.jour.fr',
  fontLinks: [
    'https://fonts.googleapis.com/css2?family=Livvic:wght@300;400;500;600;700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300;1,700&display=swap=',
  ],
  links,
  theme,
  components,
}
