import { UserResponse } from '../client'
import { LoggerReporter } from '../interfaces/logger-reporter.types'

let reporter: LoggerReporter

/**
 * Sets the logger reporter
 * @param newReporter
 */
export function setLoggerReporter(newReporter: LoggerReporter) {
  reporter = newReporter
}

export class Logger {
  /**
   * Constructor.
   * @param context
   */
  constructor(readonly context: string) {}

  /**
   * Logs a message
   * @param message
   * @param data
   */
  info(message: string, ...data: unknown[]) {
    console.log(this.context, message, ...data)
  }

  /**
   * Logs an error
   * @param error
   */
  error(error: unknown) {
    try {
      const extra = this.extractExtraFromError(error)
      console.error(this.message(), error, extra)

      reporter?.onError(this.context, error)
    } catch (error) {
      //
    }
  }

  /**
   * Registers a the current user
   * @param user
   * @param message
   */
  registerUser(user: UserResponse, message: string) {
    reporter?.registerUser(this.context, message, user)
  }

  removeUser(message: string) {
    reporter?.removeUser(this.context, message)
  }

  protected message(text?: string): string {
    return `[${this.context}] ${text ?? ''}`
  }

  /**
   * Extracts extra information from the error
   * @param error
   * @returns extra information
   */
  protected extractExtraFromError(error: unknown): Record<string, any> {
    // Response data
    if (typeof error === 'object' && (error as any).response?.data) {
      return { response: (error as any).response.data }
    }

    return {}
  }
}
