import { frLandingPageTranslations } from '../fr/landingPage'

export const ptLandingPageTranslations: typeof frLandingPageTranslations = {
  header: {
    menu: 'MENU',
  },
  content: {
    quickOrder: {
      delivery: 'Entrega',
      clickAndCollect: 'Clique e colete',
      address: {
        placeholder: 'Escolha seu endereço ...',
        placeholder_delivery: 'Escolha um endereço de entrega ...',
      },
    },
  },
}
