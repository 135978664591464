import { createContext, useContext } from 'react'

import { TemplateResponse } from '../../client'
import { Config } from '../../interfaces/config.interface'
import { StorageRepository } from '../../interfaces/storage-repository.interface'
import { DEFAULT_TEMPLATE } from '../../templates/default.template'
import { useStoredBoolean, useStoredObject, useStoredString } from '../../utils/stored-field'

const JWT_KEY = 'auth.jwt'
const TUTORIAL_KEY = 'auth.tutorial'
const TEMPLATE_KEY = 'template'

export function useSettingsStore(config: Config, storage: StorageRepository) {
  const jwtField = useStoredString(JWT_KEY, undefined, storage)
  const hasFinishedTutorialField = useStoredBoolean(TUTORIAL_KEY, false, storage)
  const templateField = useStoredObject<TemplateResponse>(TEMPLATE_KEY, DEFAULT_TEMPLATE, storage)

  // Getters

  const isInitialized = jwtField.loaded && hasFinishedTutorialField.loaded && templateField.loaded
  const isLoading =
    jwtField.isLoading || hasFinishedTutorialField.isLoading || templateField.isLoading

  return {
    config,
    isInitialized,

    jwt: jwtField.value,
    hasFinishedTutorial: hasFinishedTutorialField.value,
    template: templateField.value
      ? { ...DEFAULT_TEMPLATE, ...templateField.value }
      : DEFAULT_TEMPLATE,
    isLoading,

    saveJwt: jwtField.save,
    removeJwt: jwtField.remove,
    saveHasFinishedTutorial: hasFinishedTutorialField.save,
    saveTemplate: templateField.save,
  }
}

export type SettingsStore = ReturnType<typeof useSettingsStore>

export const SettingsStoreContext = createContext<SettingsStore>(null as any)

export function getSettingsStore() {
  return useContext(SettingsStoreContext)
}

export function getTemplate(): TemplateResponse {
  return getSettingsStore()?.template ?? DEFAULT_TEMPLATE
}
