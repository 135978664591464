import { TemplateFontStyle, TemplateResponse } from '../client'

export const DEFAULT_TEMPLATE: TemplateResponse = {
  colors: {
    system: {
      black: '#000000',
      white: '#FFFFFF',
      lightGrey: '#F9F9F9',
      mediumGrey: '#F4F4F4',
      darkGrey: '#757575',
      green: '#2B9255',
      red: '#DE3838',
      orange: '#FF9E34',
    },
    couples: {
      c1: {
        background: '#333333',
        primary: '#FFFFFF',
        secondary: '#FDE354',
      },
      c2: {
        background: '#FFFFFF',
        primary: '#333333',
        secondary: '#333333',
      },
      c3: {
        background: '#FFFFFF',
        primary: '#333333',
        secondary: '#333333',
      },
      c4: {
        background: '#FDE354',
        primary: '#333333',
        secondary: '#FFFFFF',
      },
      c5: {
        background: '#444444',
        primary: '#FFFFFF',
        secondary: '#FDE354',
      },
      footer: {
        background: '#444444',
        primary: '#FFFFFF',
        secondary: '#FDE354',
      },
    },
  },

  typography: {
    h1: {
      fontFamily: 'Poppins',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '35px',
      letterSpacing: '0.03em',
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '37px',
      letterSpacing: '0.01em',
    },
    h3: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    bodyBold: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    body: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    link: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
      textDecorationLine: ' underline',
    },
    button: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '0.02em',
    },
    categoryWithPicture: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0em',
    },
    categoryWithoutPicture: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    label: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    placeholder: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: '300',
      lineHeight: '18px',
    },
    input: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '18px',
    },
    productTag: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    priceTag: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    priceTagDiscount: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0em',
      textDecorationLine: ' line-through',
    },
    alert: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0em',
    },
    alertBold: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    copyright: {
      fontFamily: 'Poppins',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
  },

  fonts: {
    [TemplateFontStyle.Hero]: 'Livvic',
    [TemplateFontStyle.Title]: 'Poppins',
    [TemplateFontStyle.Body]: 'Poppins',
  },
}
