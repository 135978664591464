import { frKioskTranslations } from '../fr/kiosk'

export const nlKioskTranslations: typeof frKioskTranslations = {
  welcome: {
    cta: 'Tik om te bestellen',
  },
  setupKiosk: {
    title: 'Installeren',
    subtitle: 'Dashboard-token invoegen',
    token: { placeholder: 'Plaats de token...' },
    unlockPad: {
      incorrectCode: 'Ongeldige code',
    },
    home: {
      title: 'Terminal-parameters',
      subtitle: 'Instellingen bestelterminal beheren',
      general: {
        title: 'Algemeen',
        restaurant: 'Restaurant',
        deviceId: 'ID-apparaat',
        terminal: 'Betaalterminal',
        refresh: 'Vernieuwen',
      },
      printer: {
        title: 'Printer',
        usb: 'USB Port',
        type: 'Type of printer',
      },
      tests: {
        title: 'Testen',
        printer: 'Printertest',
        crash: 'Crashtest',
      },
      kiosk: {
        title: 'Kiosk',
        isAdmin: 'Beheermodus',
        enable: 'Kioskmodus inschakelen',
        disable: 'Kioskmodus uitschakelen',
        makeAdmin: 'Kioskmodus activeren',
        removeAdmin: 'Beheerdersmodus uitschakelen',
      },
      version: {
        title: 'Versie',
        downloadLatest: 'Download de nieuwste versie',
      },
    },
    error: {
      title: 'Fout',
      subtitle:
        'Er is een probleem opgetreden. Probeer het eens opnieuw of neem contact op met de klantenservice.',
    },
  },
  setup: {
    accessibility: 'Change the display',

    connect: {
      title: 'Aanmelden',
      description: 'Geniet van alle voordelen met jouw klantenrekening',
      retryTip:
        'Druk op deze knop als de kiosk eerder verbonden was en er een netwerkprobleem was.',
      yes: {
        title: 'Aanmelden',
        description: 'Aanmelden met jouw getrouwheidsaccount',
      },
      no: {
        title: 'Bestellen',
        description: 'Ga door als gast',
      },
    },
    guest: {
      title: 'Jouw voornaam',
      description: 'Om jouw bestelling te identificeren',
      placeholder: 'Vul hier je voornaam in...',
    },
    login: {
      title: 'Aanmelden',
      description: 'Typ je gebruikersnaam',
      placeholder: 'ABC123',
    },
    order: {
      title: 'Bestellen',
      description: 'Kies hoe je besteld',
    },
    eatIn: {
      title: 'Bediening aan tafel',
      description: 'Voer jouw tafelnummer in',
      placeholder: '',
      pass: 'Mijn bestelling aan de toog ophalen',
      confirm: 'Bevestigen',
    },
  },
  catalog: {
    title: 'Welkom {{name}},',
    home: 'Start',
    cancel: {
      title: 'Jouw winkelmandje wordt geledigd. Wil je verder gaan ?',
    },
    inactivity: {
      title: 'Ben je er nog ?',
      description: 'Wil je doorgaan met je bestelling ?',
      cta: 'Doorgaan',
    },
    header: {
      allergens: 'allergenen lijst',
      fidelity: 'mijn getrouwheid',
      banners: 'Onze huidige aanbiedingen',
      categories: 'Onze kaart',
    },
    footer: {
      title: 'JOUW BESTELLING',
      total: 'TOTAAL {{total}}',
      empty: 'Jouw winkelwagen is leeg',
      buttons: {
        cancel: 'De bestelling annuleren',
        validate: 'Bevestigen',
      },
    },
    baseItems: {
      title: 'Do you want to remove an ingredient?',
    },
    upsell: {
      title: 'Make a choice',
    },
    crossSell: {
      title: 'A little extra for the road?',
    },
    cartProductDetails: {
      quantity: 'Hoeveelheid',
      personalize: 'Personaliseren',
    },
  },
  catalogProduct: {
    selection: 'Jouw keuze :',
    noThanks: 'Nee, dank u wel',
    addProduct: 'Voeg toe aan mijn bestelling',
    emptyItem: 'Jouw keuze',
    extras: {
      minMax: '{{ min }} min. / {{ max }} max.',
      max: '{{ current }}/{{ max }} geselecteerd',
      multiMin: '{{ current }}/{{ min }} min.',
      optional: '{{ current }}/{{ max }} max.',
      skip: 'Nee, dank u wel',
    },
  },
  checkout: {
    title: 'Klopt je bestelling ?',
    total: 'Te betalen',
    promotion: {
      cta: 'Promotiecode',
      title: 'Promotiecode',
      subtitle: 'Voer uw promotiecode in',
      placeholder: 'Promotiecode',
    },
    footerButtons: {
      cancel: 'Doorgaan met bestellen',
      validate: 'Ga door naar de betaling',
    },
    payment: {
      home: {
        title: 'Betaling',
        subtitle: 'Kies je betaalmethode',
        creditCard: 'Kredietkaart',
        trd: 'Maaltijdcheques',
        cash: 'Cash',
      },
      loading: {
        pos: {
          title: 'Volg de betalingsinstructie.',
          subtitle: 'Volg de betalingsinstructies op de betaalterminal.',
        },
        trd: {
          title: 'Bestelling in uitvoering...',
          subtitle: 'We sturen jouw bestelling naar de kassa, even geduld a.u.b.',
        },
        cash: {
          title: 'Bestelling in uitvoering...',
          subtitle: 'We sturen jouw bestelling naar de kassa, even geduld a.u.b.',
        },
      },
      finished: {
        title: 'We gaan uw bestelling voorbereiden',
        title_cash: 'Ga naar de kassier om de betaling te doen',
        title_eatIn: 'Ga naar je tafel, je wordt daar geserveerd',

        pos: {
          subtitle: 'Jouw betaling is aanvaard',
        },
        trd: {
          subtitle: 'Ga naar de kassa om de te betalen.',
        },
        cash: {
          subtitle: 'Ga naar de kassa om de te betalen.',
        },
        ticketType: {
          title: 'Neem je kassabon',
          print: ' Jouw kassabon afdrukken',
          email: 'Jouw kassabon per email doorsturen',
          emailTip: 'Een mooi gebaar voor onze planeet !',
        },
        details: {
          title: 'Herstel uw bestelontvangst ',
          subtitle: 'Presenteer uw ticket om uw bestelling te verzamelen ',
          subtitle_cash: 'Presenteer uw ticket aan de balie om uw bestelling te betalen ',
          subtitle_eathIn: 'Presenteer uw ticket om uw bestelling te verzamelen ',
        },
        sendEmail: {
          title: 'Ontvangen',
          subtitle: 'Vul je e-mailadres in',
          input: 'Vul je email hier in...',
        },
      },
    },
    footer: 'JOUW BESTELLING : {{price}}',
  },
  update: {
    title: 'Controleren op updates...',
  },
}

export default nlKioskTranslations
