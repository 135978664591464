import { OrderType } from '../../client'

export const frMobileTranslations = {
  headers: {
    loading: '',
    tutorial: '',
    enter: '',
    'enter.welcome': '',
    'enter.loginOrRegister': 'Inscription / Connexion',
    'enter.login': 'Se connecter',
    'enter.register': 'Créer un compte',
    'enter.register.email': 'Créer un compte',
    'enter.register.facebook': 'Créer un compte',
    'enter.register.apple': 'Créer un compte',
    'enter.verifyPhone': 'Vérification de votre numéro',
    'enter.recoverPassword': 'Récupérez votre mot de passe',
    'enter.resetPassword': 'Nouveau mot de passe',
    main: '',
    home: '',
    'home.stack': '',
    'home.home': '',
    'home.user.profile': 'Mon compte',
    'home.user.orders': 'Historique',
    'home.user.orders.item': 'Détail commande',
    'home.reOrder': '',
    'home.fidelity': '',
    'home.account': '',
    'setup.delivery': 'Entrez votre adresse',
    'setup.pickUp': 'Selectionnez un restaurant',
    restaurantInfo: 'Informations',
    catalog: '{{restaurant.name}}',
    'catalog.fidelityProgram': 'Ma fidélité',
    'catalog.item': '',
    'catalog.item.item': '',
    'catalog.upsell': '',
    checkout: 'Récapitualitf de la commande',
    'checkout.home': '',
    'checkout.promotion': '',
    'checkout.info': 'Vos informations',
    'checkout.addPayment': '',
    'checkout.payment': '',
    'checkout.order': 'Détail commande',
    orders: '',
    'orders.list': '',
    'orders.item': '',
    'orders.item.details': 'Détail commande',
    'order.item.sendInvoice': '',
    'order.item.help': 'COMMANDE N°{{order.posOrderNumber}}',
  },

  confirmation: {
    heading: 'Important',
  },

  welcome: {
    title: 'Payez moins,\nkiffez plus!',
    register: 'Créer un compte',
    login: 'Se connecter',
    guest: 'Continuer en tant qu’invité',
  },

  fidelityProgram: {
    title: 'Programme de fidélité',
    guest: {
      title: 'Programme de fidélité',
      subtitle: 'Se connecter pour profiter des avantages',
      cta: 'Se connecter',
    },
    user: {
      title: 'Vos avantages',
      subtitle: 'Vous avez {{ points }} points.',
    },

    step: {
      title: '{{ points }} points',
      connect: 'Connectez-vous pour en profiter',
      morePoints: 'Plus que {{ points }} points',
    },

    details: {
      title: 'Mes avantages',
      subtitle: '{{ points }} Points',
      cta: 'Utiliser mes points',
    },

    itemNotAvailable: 'Aucun produit disponible',
  },

  enter: {
    guest: 'Continuer en tant qu’invité',
    login: 'Se connecter',
    register: 'Créer un compte',
    apple: 'Continuer avec Apple',
    facebook: 'Continuer avec Facebook',
    google: 'Se connecter avec Google (à venir)',
  },

  enterLogin: {
    submit: 'Se connecter',
    forgotPassword: 'Mot de passe oublié ?',
  },

  enterRegister: {
    submitEmail: 'S’inscrire par e-mail',
  },

  enterVerifyPhone: {
    message: 'Veuillez saisir le code qui a été envoyé au numéro de téléphone {{phone}}.',
    submit: 'Valider le code',
    resend: 'Recevoir un nouveau code',
    error: {
      resend: 'Veuillez patienter 30 secondes avant de demander un nouveau code',
    },
  },

  home: {
    pickup: 'Commander à emporter',
    insta: 'INSTA\nFIVE PIZZA',
    website: 'SITE WEB\nFIVE PIZZA',
    orderTypeSelect: {
      title: 'Mode de commande',
    },
  },

  homeUser: {
    loginOrRegister: 'S’inscrire / Se connecter',
    logout: 'Déconnexion',

    orders: 'Mes commandes',
    profile: 'Mon compte',
    addresses: 'Mes adresses',
    paymentMethods: 'Moyens de paiement',
  },

  homeUserProfile: {
    archive: {
      cta: 'Supprimer mon compte',
      message: 'Cette action est irréversible.',
      confirm: 'Supprimer',
    },
    submit: 'Update',
  },

  homeUserOrders: {
    title: 'Toutes mes commandes',

    sectionActive: 'Commandes en cours ({{ quantity }})',
    sectionFinished: 'Commandes passées  ({{ quantity }})',
  },

  tab: {
    home: 'Accueil',
    order: 'Commander',
    userOrders: 'Historique',
    user: 'Compte',
  },

  permissions: {
    location: {
      title: 'Géolocalisation',
      message:
        "Nous l'utiliserons uniquement pour vous montrer les restaurants à proximité et oú récupérer vos commandes à emporter",
    },
  },

  form: {
    addressInput: {
      title: 'Rechercher un restaurant',
      placeholder: 'Rechercher un restaurant',
    },
  },

  setup: {
    geolocate: 'Me gélocaliser',
  },

  setupDelivery: {
    geolocate: 'Me gélocaliser',
    submit: 'Utiliser cette adresse',
  },

  order: {
    properties: {
      updateModal: {
        title: 'Modifier ma commande',
        orderType: 'Mode de commande',
        dueDate: "Changer l'heure",
      },
    },
    details: {
      title: 'COMMANDE N°{{number}}',
      footer: {
        help: 'Aide',
        sendInvoice: 'Obtenir un reçu',
      },
    },
    sendInvoice: {
      title: 'Obtenir un reçu',
      form: {
        email: {
          label: 'E-mail',
          placeholder: 'Entrez votre email…',
        },
        send: 'Envoyer',
      },
    },
  },
  checkout: {
    summary: {
      title: 'Votre commande ({{count}} article)',
      title_plural: 'Votre commande ({{count}} articles)',

      totals: {
        subtotal: 'Sous-total',
        discount: 'Réduction',
        productDiscount: 'Réduction produits',
        wallet: 'Dépense cagnotte',
        serviceFee: 'Frais de service',
        total: 'TOTAL (TTC)',
        ttc: '(TTC)',
        orderTypeFees: {
          [OrderType.Delivery]: 'Frais de service',
          [OrderType.PickUp]: 'Frais de service',
          [OrderType.EatIn]: 'Frais de service',
          [OrderType.Table]: 'Frais de service',
        },
      },

      feesDetails: {
        title: 'Quels sont les frais appliqués ?',
        delivery: {
          title: 'Frais de livraison',
          text: 'Plus le restaurant est proche de chez vous, plus les frais de livraison sont bas',
        },
        service: {
          title: 'Frais de service',
          text: 'Ces frais nous permettent d’optimiser votre expérience, notamment via de nouvelles fonctionnalités et un service client optimal.',
        },
        additional: {
          title: 'Frais additionnels',
          text: 'Si le montant de votre commande est inférieur au montant minimum fixé par le restaurant lors de la livraison, ces frais s’ajouteront à votre panier.',
        },
      },

      feesTitle: 'Quels sont les frais appliqués ?',
      points: '+{{count}}pt',
      points_plural: '+{{count}}pts',
    },
    promotion: {
      add: {
        title: 'Un code promo ?',
        label: 'Ajouter votre code promo',
        placeholder: 'Code promo',
        submit: 'Ajouter',
        error: 'Le code promotionnel utilisé n’est pas valide.',
      },
    },
  },
}

export default frMobileTranslations
