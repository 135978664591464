var _ref;

import { OrderPlatform } from 'core/client';
import { Template } from 'core/interfaces/template.interface';
export var config = {
  // For now the template is always belorder, this should come from .env later
  template: (_ref = process.env.NEXT_PUBLIC_TEMPLATE) !== null && _ref !== void 0 ? _ref : Template.Belorder,
  platformData: {
    platform: OrderPlatform.Web
  },
  api: {
    baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
    companyToken: process.env.NEXT_PUBLIC_COMPANY_TOKEN
  },
  payments: {
    environment: process.env.NEXT_PUBLIC_PAYMENTS_ENV === 'LIVE' ? 'LIVE' : 'TEST',
    clientKey: process.env.NEXT_PUBLIC_PAYMENTS_CLIENT_KEY,
    returnUrl: function returnUrl(orderType, restaurantId) {
      return "".concat(window.location.origin, "/order/").concat(orderType, "/").concat(restaurantId, "/action-result");
    },
    googlePay: {
      gatewayMerchantId: process.env.NEXT_PUBLIC_PAYMENTS_GOOGLE_GATEWAY_MERCHANT_ID,
      merchantId: process.env.NEXT_PUBLIC_PAYMENTS_GOOGLE_MERCHANT_ID
    },
    applePay: {
      merchantId: process.env.NEXT_PUBLIC_PAYMENTS_APPLE_MERCHANT_ID
    }
  },
  googleApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  mapId: process.env.NEXT_PUBLIC_MAP_ID,
  googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
};

if (!config.api.baseUrl) {
  throw new Error('Invalid configuration, "api.baseUrl" is not set. Ensure the env variable "NEXT_PUBLIC_API_BASE_URL" is set.');
}