import { DiscountTarget, Language, OrderStatus, OrderType, UserErrorCode } from '../../client'
import { PurchaseStatus } from '../../modules/cart/cart.types'
import frTranslations from '../fr'
// Modules
import kiosk from './kiosk'
import { caLandingPageTranslations } from './landingPage'

const errors: Record<UserErrorCode | string, string> = {
  // Order
  // - Cart details
  [UserErrorCode.OrderRestaurantOffline]:
    'The restaurant is offline, please try again at another time.',
  [UserErrorCode.OrderRestaurantBusy]:
    'The restaurant is offline, please try again at another time.',
  [UserErrorCode.OrderDateInPast]:
    'The order date is in the past, please refresh the web and ensure the selected time slot is in the future.',
  [UserErrorCode.OrderRestaurantClosed]:
    'The restaurant is offline, please try again at another time.',
  // - Items
  [UserErrorCode.OrderItemsDontExist]:
    'Your order contains products that are no longer available. Please refresh your page so you can re-order',
  [UserErrorCode.OrderModifiersDontExist]:
    'Your order contains products that are no longer available. Please refresh your page so you can re-order',
  [UserErrorCode.OrderInvalidItems]:
    'Your order contains products that are no longer available. Please refresh your page so you can re-order',
  [UserErrorCode.OrderInvalidModifiers]:
    'Your order contains products that are no longer available. Please refresh your page so you can re-order',
  [UserErrorCode.OrderItemsDisabled]:
    'Your order contains products that are no longer available. Please refresh your page so you can re-order',
  // - Payment
  [UserErrorCode.OrderPaymentRejected]: 'The payment was rejected, please try again later.',
  [UserErrorCode.OrderPaymentError]:
    'There was a problem processing your payment, please try again later.',

  403: 'You do not have access to this section',
  404: 'The section you are looking for is not available',
  500: 'Oh no, an unexpected error has occurred',
}

// TODO - remove & any and ensure the types are correct
export const caTranslations: typeof frTranslations & any = {
  unknownError: 'Something went wrong, please try again later',
  geolocationUnavailable: 'Geolocation is not available',

  add: 'Add',
  create: 'Create',
  modify: 'Modify',
  delete: 'Delete',
  remove: 'Remove',

  showMore: 'Show more',
  select: 'Select',
  change: 'Change',
  previous: 'Previous',
  next: 'Next',
  goBack: 'Back',
  reload: 'Reload',
  continue: 'Continue',
  confirm: 'Confirm',
  retry: 'Try again',
  close: 'Close',
  cancel: 'Cancel',
  quit: 'Quit',
  abandon: 'Abandon',
  pass: 'Pass',
  yes: 'Yes',
  no: 'No',
  or: 'Or',
  without: 'Without',

  help: 'Help',
  free: 'Free',

  languageCode: {
    [Language.Fr]: 'fr',
  },
  language: {
    [Language.Fr]: 'Français',
    [Language.En]: 'English',
    [Language.Es]: 'Espagnol',
    [Language.Pt]: 'Portugais',
    [Language.Ca]: 'Catalan',
    [Language.Nl]: 'Holandès',
  },

  form: {
    region: 'País',
    language: 'Llenguatge',
    firstName: {
      label: 'First name',
      placeholder: 'First name...',
      error: 'First name is required',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name...',
      error: 'Last name is required',
    },

    email: {
      label: 'E-mail adress',
      error: 'This email is invalid',
    },

    phone: {
      label: 'Phone number',
      error: 'Please enter a valid number.',
    },

    instructions: {
      label: 'Instruction to delivery man',
      placeholder: 'Gate code, specific indications...',
    },
  },

  // Pages

  tutorial: {
    button: 'wording cta',
    slides: [
      {
        title: 'order in click & collect and delivery',
        subtitle:
          'Be rewarded from the first order with our exclusive loyalty program designed for you.',
      },
      {
        title: 'Benefit of the cheapest prices',
        subtitle:
          'Be rewarded from the first order with our exclusive loyalty program designed for you.',
      },
      {
        title: 'Enjoy our exclusive offers',
        subtitle:
          'Be rewarded from the first order with our exclusive loyalty program designed for you.',
      },
      {
        title: 'Your loyalty is rewarded!',
        subtitle:
          'Be rewarded from the first order with our exclusive loyalty program designed for you.',
      },
    ],
  },

  enter: {
    home: {
      title: 'Order your favorite dish',
      loginButton: 'login / register',
      guestButton: 'continue as guest',
    },
  },

  user: {
    guest: 'Guest mode',
    myOrders: 'All my orders',

    orders: {
      title: 'All my orders',

      activeTitle: 'Current orders ({{quantity}})',
    },

    links: {
      myOrders: 'My orders',
      help: 'Need help ?',
      cguv: 'CGUV',
      terms: 'CGUV',
      confidentiality: 'Privacy Policy',
      legal: 'Legal Notice',
    },
  },

  setup: {
    title: 'Hello <1>{{name}},</1>',

    address: {
      title: 'Select an address',
      title_delivery: 'Select a delivery address',
      subtitle: 'or geolocate yourself',
      placeholder: 'Add your address',
      error: {
        title: 'Atenció',
        description: 'No s’ha pogut trobar la vostra adreça, torneu-la a afegir',
      },
    },

    info: {
      instructions: {
        label: 'Instruction to delivery man',
        placeholder: 'Example: Apartment number, floor...',
      },
      phone: {
        label: 'Phone number',
        placeholder: '6 00 00 00 00',
        error: {
          invalid: 'Please enter a valid number.',
        },
        tip: 'This information will be used to identify your order and contact you if necessary.',
      },

      submit: 'Use this address',

      error: {
        title: 'Important',
        description: 'We do not deliver to this address yet.',
      },
    },

    error: {
      title: 'Important',
      description: 'The selected point of sale is not available.',
    },
  },

  restaurant: {
    openUntil: 'Open until {{hours}}h{{minutes}}',
    openUntilTime: 'Open until {{time}}',
    closed: 'Closed',
    findRestaurant: 'Find your restaurant',
    searchRestaurant: 'Search your restaurant',
    upcoming: 'Opening soon',
    availableSoon: 'Available soon',
    orderHere: 'Order here',
    moreInfo: 'More information',
    openHours: 'Opening time',
    clickAndCollect: 'Click & collect',
    atRestaurant: 'At {{restaurantName}}',
    theRestaurants: 'The restaurants',
    findAll: 'Find all our restaurants {{companyName}} !',
    allRestaurants: 'All the restaurants',
    informations: 'Informations',
  },

  order: {
    type: {
      [OrderType.Delivery]: 'Delivery',
      [OrderType.PickUp]: 'Pickup',
      [OrderType.EatIn]: 'Eat in',
    },

    status: {
      [OrderStatus.Waiting]: 'Waiting',
      [OrderStatus.Preorder]: 'Pre-order',
      [OrderStatus.Unpaid]: 'Unpaid',
      [OrderStatus.New]: 'New',
      [OrderStatus.InProgress]: 'In progress',
      [OrderStatus.Prepared]: 'Ready',
      [OrderStatus.Finished]: 'Finished',
      [OrderStatus.Refunded]: 'Refunded',
    },

    banners: 'Current offers',

    summary: {
      title: 'Your cart',
    },

    change: {
      title: 'Important',
      description: 'By changing the order method, your cart will be emptied.',
    },

    settings: {
      type: {
        [OrderType.Delivery]: 'Delivery',
        [OrderType.PickUp]: 'Pick up',
        [OrderType.EatIn]: 'Eat in',
        [OrderType.Table]: 'Table service',
      },

      slots: {
        now: 'Now ({{ time }})',
        asap: 'As soon as possible',
        asapTable: 'As soon as possible',
        expectedFor: 'Expected for',
      },
    },

    card: {
      number: 'Order\nN°{{ number }}',
      quantity: '{{ quantity }} products',
    },

    details: {
      header: {
        title: 'Order\nN°{{ number }}',
        date: 'The {{- date}} at {{time}}',

        restaurant: 'Order at',

        mode: {
          [OrderType.Delivery]: 'Delivery at',
          [OrderType.PickUp]: 'Pick up at',
          [OrderType.Table]: 'Table service',
        },

        payment: 'Paid by',
      },

      products: {
        title: 'Products ({{ quantity }})',
      },

      sendEmail: {
        button: 'Download receipt',
        title: 'Get a receipt',

        email: 'E-mail',
        quantity: 'Number of cutlery',
        submit: 'SUbmit',
      },
    },

    upsell: {
      title: 'A little extra for the road',
      dismiss: 'No thanks !',
      finish: 'Finish',
    },

    actionBar: {
      activeOrder: {
        title: 'Order in progress',

        time: {
          [OrderType.Delivery]: 'Delivery at',
          [OrderType.PickUp]: 'Pick up at',
          [OrderType.EatIn]: 'Order at',
          [OrderType.Table]: 'Order at',
        },
      },
    },
  },

  checkout: {
    basket: 'Cart',
    title: 'Check your order at {{ restaurantName }}',
    button: 'See my order',
    addProducts: 'Add product',
    restaurantAddress: 'Restaurant address',
    finish: 'Finish',
    discount: {
      notDelivery: {
        pending: 'Add {{pending}} to enjoy a <1>{{reward}} discount</1>',
        completed: 'Congratulations ! You benefit from a <1>{{reward}} discount</1>',
      },
      delivery: {
        pending: 'Add {{pending}} to enjoy <1>free delivery</1>',
        completed: 'Congratulations ! You benefit from free delivery',
      },
    },

    products: {
      empty: {
        title: 'Your cart is empty',
        subtitle: 'Add products to your cart',
      },
      mandatory: 'Mandatory',
    },

    summary: {
      title: 'Your <1>order ({{quantity}})</1>',

      total: {
        products: 'Products',
        deliveryFee: {
          title: 'Delivery fee',
          description: 'The closer the restaurant is to you, the lower the delivery costs.',
        },
        discount: 'Discount',
        service: {
          title: 'Service fee',
          description:
            'These fees allow us to optimize your experience, in particular through new features and optimal customer service.',
        },
        additionalFees: {
          title: 'Additional fees',
          description:
            'If the amount of your order is less than the minimum amount set by the restaurant upon delivery, these costs will be added to your cart.',
        },
        total: 'Total',
        withTaxes: 'TTC',
      },
    },

    priceInfo: {
      title: 'What are the fees applied?',
    },

    promotions: {
      title: 'Promotions',
      error: 'The promotional code used is not valid',
      add: {
        heading: 'A promotional code ?',
        title: 'Add a promotional code',
        label: 'Add your promotional code',
        placeholder: 'promotional code',
        cta: 'Apply',
        error: 'The promotional code used is not valid.',
      },

      details: {
        [DiscountTarget.Total]: '-{{ discount }} on this order',
        [DiscountTarget.Delivery]: {
          discount: '-{{ discount }} on your delivery',
          free: 'Free delivery !',
        },
      },
    },

    payments: {
      title: 'Payment method',
      card: 'Bank card',
      paywithgoogle: 'Google Pay',
      applepay: 'Apple Pay',
      paypal: 'PayPal',
    },

    settings: {
      title: 'Order information',

      timeSlot: {
        title: {
          [OrderType.Delivery]: 'Delivery time',
          [OrderType.PickUp]: 'Pick up time',
          [OrderType.EatIn]: 'Eat in at',
          [OrderType.Table]: 'Served at',
        },
        asap: '{{ time }}',
        asapTable: 'As soon as possible',
        expectedFor: 'Expected for {{ time }}',
      },

      telephone: {
        title: 'Phone number',
        info: 'This information will be used to identify your order and contact you if necessary.',
      },

      guestInfo: {
        info: 'This information will only be used to track your order.',
      },

      cutlery: {
        title: 'Cutlery',
        info: 'Ask for cutlery only if you need it.',
        infoEnabled: 'We will add cutlery to your order within the limits of availability.',
      },

      comments: {
        title: 'Commentary',
        placeholder: 'Do you have a specific request on your order ?',
      },
      tos: 'By continuing, I accept the <1>Terms and conditions</1> of {{ client }}.',
      info: 'By continuing, I accept the <1>CGUV</1> and the <2>privacy policy</2> of {{ client }}. The payment will appear on your statement under the reference Belorder.',
      cta: 'Pay ({{ total }})',
    },

    purchaseStatusModal: {
      title: {
        [PurchaseStatus.Inactive]: 'Order being accepted...',
        [PurchaseStatus.PrePush]: 'Order being accepted...',
        [PurchaseStatus.Ready]: 'Order being accepted...',
        [PurchaseStatus.Purchasing]: 'Order being accepted...',
        [PurchaseStatus.CheckoutActionResult]: 'Order being accepted...',
        [PurchaseStatus.Failed]: 'A problem has occurred...',
        [PurchaseStatus.Finished]: 'The order was created successfully...',
      },
      cancel: 'Cancel my order',
      retry: 'Retry',
    },
  },

  confirmation: {
    title: 'Order\nN°{{ number }}',
    failed: 'Your payment has failed...',
    goBack: 'Order again',

    status: {
      [OrderType.Delivery]: {
        inProgress: 'Expected arrival at {{ time }}',
        finish: 'Order delivered',
      },
      [OrderType.PickUp]: {
        inProgress: 'Expected withdrawal at {{ time }}',
        finish: 'Order retrieved',
      },
      [OrderType.EatIn]: {
        inProgress: 'In preparation...',
        finish: 'Order served',
      },
      [OrderType.Table]: {
        inProgress: 'In preparation...',
        finish: 'Order served',
      },
    },

    details: {
      products: 'Products',
      cta: 'See details',
      total: 'Total',

      checkItinerary: 'See the itinerary',
      trackDelivery: 'Track the delivery',
      help: 'A problem with the order?',
    },
  },

  product: {
    composed: {
      modifiers: {
        title: 'Personalize your product',
        option: {
          placeholder: 'Select...',
        },
      },
    },

    menu: {
      selectProduct: 'Select a product',
    },

    labels: {
      discount: '{{discount}} discount',
      new: 'New',
      outOfOrder: 'Out of order',
    },
    required: 'Required',
  },
  footer: {
    usefulLinks: {
      title: 'Useful links',
      customerService: 'Customer service',
      accessToWebsite: 'Access to the website',
      seeOurRestaurants: 'See our restaurants',
      winAPrize: 'Win a prize',
    },
    legal: {
      title: 'Légal',
      cgv: 'Terms of Sales',
      cgu: 'Terms of Service',
      cookies: 'Cookies policy',
      data: 'Privacy Policy',
      mentions: 'Legal Notice',
    },
    bestOffers: {
      title: 'Take advantage of the best offers',
      download: 'Download our mobile app !',
    },
  },
  print: {
    note: 'COMMENT / NOTE',
    total: 'Total',
    section: {
      user: {
        title: 'CLIENT',
        firstName: 'first name',
        lastName: 'last name',
        phone: 'phone number',
      },
      note: {
        title: 'COMMENT / NOTE',
      },
    },
    type: {
      delivery: 'Delivery',
      pickUp: 'C&C',
      eatIn: 'Eat in',
      table: 'On the spot',
    },
    error: {
      notFound: 'The printer was not found',
      init: 'There seems to be a problem with your Bluetooth. Please make sure Bluetooth is on.',
      bluetooth: {
        title: 'Attention',
        message: 'Bluetooth is not activated. No printer is currently connected.',
      },
    },
  },

  credits: {
    poweredBy: 'powered by',
    name: 'Belorder v2.0',
  },

  errors,

  kiosk,
  landingPage: caLandingPageTranslations,
}

export default caTranslations
