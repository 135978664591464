/* tslint:disable */
/* eslint-disable */
/**
 * Belorder API
 * API docs for belorder
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AcceptedPaymentMethod = {
    Cash: 'cash',
    Card: 'Card',
    GooglePay: 'GooglePay',
    ApplePay: 'ApplePay',
    PayPal: 'PayPal',
    Terminal: 'Terminal',
    Sodexo: 'Sodexo',
    Natixis: 'Natixis',
    Groupeup: 'Groupeup',
    Edenred: 'Edenred'
} as const;

export type AcceptedPaymentMethod = typeof AcceptedPaymentMethod[keyof typeof AcceptedPaymentMethod];


/**
 * 
 * @export
 * @interface ActiveHoursRequest
 */
export interface ActiveHoursRequest {
    /**
     * Seconds from 00:00 when it starts to be active.
     * @type {number}
     * @memberof ActiveHoursRequest
     */
    'startAt': number;
    /**
     * Seconds from 00:00 when it stops to be active. 
     * @type {number}
     * @memberof ActiveHoursRequest
     */
    'endAt': number;
}
/**
 * 
 * @export
 * @interface ActiveHoursResponse
 */
export interface ActiveHoursResponse {
    /**
     * Seconds from 00:00 when it starts to be active.
     * @type {number}
     * @memberof ActiveHoursResponse
     */
    'startAt': number;
    /**
     * Seconds from 00:00 when it stops to be active. 
     * @type {number}
     * @memberof ActiveHoursResponse
     */
    'endAt': number;
}
/**
 * 
 * @export
 * @interface AddressRequest
 */
export interface AddressRequest {
    /**
     * Country Code
     * @type {string}
     * @memberof AddressRequest
     */
    'countryCode': string;
    /**
     * City.
     * @type {string}
     * @memberof AddressRequest
     */
    'city': string;
    /**
     * Street name.
     * @type {string}
     * @memberof AddressRequest
     */
    'street': string;
    /**
     * Apartment number.
     * @type {string}
     * @memberof AddressRequest
     */
    'apartment'?: string;
    /**
     * Zip code.
     * @type {string}
     * @memberof AddressRequest
     */
    'zipCode': string;
    /**
     * Instructions to access to this address
     * @type {string}
     * @memberof AddressRequest
     */
    'instructions'?: string;
    /**
     * Lat, Lng coordinates
     * @type {Array<number>}
     * @memberof AddressRequest
     */
    'coordinates': Array<number>;
}
/**
 * 
 * @export
 * @interface AddressResponse
 */
export interface AddressResponse {
    /**
     * country
     * @type {string}
     * @memberof AddressResponse
     */
    'countryCode': string;
    /**
     * City.
     * @type {string}
     * @memberof AddressResponse
     */
    'city': string;
    /**
     * Street name.
     * @type {string}
     * @memberof AddressResponse
     */
    'street': string;
    /**
     * Apartment number.
     * @type {string}
     * @memberof AddressResponse
     */
    'apartment'?: string;
    /**
     * Zip code.
     * @type {string}
     * @memberof AddressResponse
     */
    'zipCode': string;
    /**
     * Access instructions
     * @type {string}
     * @memberof AddressResponse
     */
    'instructions'?: string;
    /**
     * Lat, Lng coordinates
     * @type {Array<number>}
     * @memberof AddressResponse
     */
    'coordinates': Array<number>;
}
/**
 * 
 * @export
 * @interface AppleConnectRequest
 */
export interface AppleConnectRequest {
    /**
     * Apple authorization code needed for OAuth
     * @type {string}
     * @memberof AppleConnectRequest
     */
    'authorizationCode': string;
}
/**
 * 
 * @export
 * @interface AppleProviderOutputDataResponse
 */
export interface AppleProviderOutputDataResponse {
    /**
     * Token used to get the user information from apple
     * @type {string}
     * @memberof AppleProviderOutputDataResponse
     */
    'refreshToken'?: string;
    /**
     * First name of the user
     * @type {string}
     * @memberof AppleProviderOutputDataResponse
     */
    'firstName'?: string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof AppleProviderOutputDataResponse
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface AppleProviderResponse
 */
export interface AppleProviderResponse {
    /**
     * Whether the user exists in our system
     * @type {boolean}
     * @memberof AppleProviderResponse
     */
    'userExists'?: boolean;
    /**
     * Data to login if the user exists
     * @type {UserLoginResponse}
     * @memberof AppleProviderResponse
     */
    'loginOutput'?: UserLoginResponse;
    /**
     * 
     * @type {AppleProviderOutputDataResponse}
     * @memberof AppleProviderResponse
     */
    'data'?: AppleProviderOutputDataResponse;
}
/**
 * 
 * @export
 * @interface AppleRegisterRequest
 */
export interface AppleRegisterRequest {
    /**
     * First name of the user
     * @type {string}
     * @memberof AppleRegisterRequest
     */
    'firstName': string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof AppleRegisterRequest
     */
    'lastName': string;
    /**
     * 
     * @type {CreateUserPhoneRequest}
     * @memberof AppleRegisterRequest
     */
    'phone': CreateUserPhoneRequest;
    /**
     * Whether the user wants to receive offers via email
     * @type {boolean}
     * @memberof AppleRegisterRequest
     */
    'offersViaEmail': boolean;
    /**
     * Whether the user wants to receive offers via SMS
     * @type {boolean}
     * @memberof AppleRegisterRequest
     */
    'offersViaSMS': boolean;
    /**
     * Refresh token to get the user from apple
     * @type {string}
     * @memberof AppleRegisterRequest
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface AuthProviderOutputResponse
 */
export interface AuthProviderOutputResponse {
    /**
     * Whether the user exists in our system
     * @type {boolean}
     * @memberof AuthProviderOutputResponse
     */
    'userExists': boolean;
    /**
     * Data to login if the user exists 
     * @type {UserLoginResponse}
     * @memberof AuthProviderOutputResponse
     */
    'loginOutput': UserLoginResponse;
    /**
     * Data for the provider
     * @type {object}
     * @memberof AuthProviderOutputResponse
     */
    'data': object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BannerActionType = {
    Nothing: 'nothing',
    Item: 'item',
    Link: 'link'
} as const;

export type BannerActionType = typeof BannerActionType[keyof typeof BannerActionType];


/**
 * 
 * @export
 * @interface BannerResponse
 */
export interface BannerResponse {
    /**
     * ID of the banner
     * @type {string}
     * @memberof BannerResponse
     */
    'id': string;
    /**
     * ID of the company where the banner is created
     * @type {string}
     * @memberof BannerResponse
     */
    'companyId': string;
    /**
     * Title of the banner.
     * @type {LocalizedFieldResponse}
     * @memberof BannerResponse
     */
    'title': LocalizedFieldResponse;
    /**
     * Subtitle of the banner
     * @type {LocalizedFieldResponse}
     * @memberof BannerResponse
     */
    'subtitle'?: LocalizedFieldResponse;
    /**
     * 
     * @type {BannerActionType}
     * @memberof BannerResponse
     */
    'action': BannerActionType;
    /**
     * ID of the item that is in banner
     * @type {string}
     * @memberof BannerResponse
     */
    'itemId'?: string;
    /**
     * URL to redirect when clicked
     * @type {string}
     * @memberof BannerResponse
     */
    'url'?: string;
    /**
     * Image of the banner
     * @type {string}
     * @memberof BannerResponse
     * @deprecated
     */
    'image'?: string;
    /**
     * Image of the banner
     * @type {LocalizedFieldResponse}
     * @memberof BannerResponse
     */
    'localizedImage'?: LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface BusinessDetailsResponse
 */
export interface BusinessDetailsResponse {
    /**
     * Name of the holding.
     * @type {string}
     * @memberof BusinessDetailsResponse
     */
    'name': string;
    /**
     * Business web address
     * @type {string}
     * @memberof BusinessDetailsResponse
     */
    'webAddress': string;
    /**
     * Phone number
     * @type {string}
     * @memberof BusinessDetailsResponse
     */
    'phone': string;
    /**
     * Business legal email
     * @type {string}
     * @memberof BusinessDetailsResponse
     */
    'email': string;
    /**
     * Business company identifier
     * @type {string}
     * @memberof BusinessDetailsResponse
     */
    'identifier': string;
    /**
     * Business representative
     * @type {string}
     * @memberof BusinessDetailsResponse
     */
    'representative': string;
    /**
     * Business VAT number
     * @type {string}
     * @memberof BusinessDetailsResponse
     */
    'VATNumber': string;
}
/**
 * 
 * @export
 * @interface CalculateCartTotalsRequest
 */
export interface CalculateCartTotalsRequest {
    /**
     * 
     * @type {OrderType}
     * @memberof CalculateCartTotalsRequest
     */
    'orderType': OrderType;
    /**
     * Items that compose the request
     * @type {Array<CartItemRequest>}
     * @memberof CalculateCartTotalsRequest
     */
    'items': Array<CartItemRequest>;
    /**
     * Date when the order needs to be prepared
     * @type {string}
     * @memberof CalculateCartTotalsRequest
     */
    'dueDate'?: string;
    /**
     * Discount code
     * @type {string}
     * @memberof CalculateCartTotalsRequest
     */
    'discountCode'?: string;
    /**
     * Platform information used to make the order
     * @type {object}
     * @memberof CalculateCartTotalsRequest
     */
    'platformData'?: object;
    /**
     * Delivery address
     * @type {AddressRequest}
     * @memberof CalculateCartTotalsRequest
     */
    'deliveryAddress'?: AddressRequest;
    /**
     * The delivery quote request
     * @type {DeliveryQuoteRequest}
     * @memberof CalculateCartTotalsRequest
     */
    'deliveryQuote'?: DeliveryQuoteRequest;
}
/**
 * 
 * @export
 * @interface CartCalculatedTotalsResponse
 */
export interface CartCalculatedTotalsResponse {
    /**
     * Total prices for the cart
     * @type {CartTotalsResponse}
     * @memberof CartCalculatedTotalsResponse
     */
    'totals': CartTotalsResponse;
    /**
     * IDs of the discounts used
     * @type {Array<string>}
     * @memberof CartCalculatedTotalsResponse
     */
    'discountIdsUsed': Array<string>;
    /**
     * Discounts applied for each cart item
     * @type {Array<CartItemDiscountResponse>}
     * @memberof CartCalculatedTotalsResponse
     */
    'cartItems': Array<CartItemDiscountResponse>;
}
/**
 * 
 * @export
 * @interface CartItemDiscountResponse
 */
export interface CartItemDiscountResponse {
    /**
     * ID of the cart product
     * @type {string}
     * @memberof CartItemDiscountResponse
     */
    'id': string;
    /**
     * ID of the item
     * @type {string}
     * @memberof CartItemDiscountResponse
     */
    'itemId': string;
    /**
     * IDs of base items that are selected
     * @type {Array<string>}
     * @memberof CartItemDiscountResponse
     */
    'baseItemIds'?: Array<string>;
    /**
     * Selected items from the modifiers
     * @type {Array<CartItemModifierResponse>}
     * @memberof CartItemDiscountResponse
     */
    'modifiers': Array<CartItemModifierResponse>;
    /**
     * Number of products of this type added to the cart
     * @type {number}
     * @memberof CartItemDiscountResponse
     */
    'quantity': number;
    /**
     * Individual applied price of the product in cents.
     * @type {number}
     * @memberof CartItemDiscountResponse
     */
    'price': number;
    /**
     * Original price of item with modifiers before reductions
     * @type {number}
     * @memberof CartItemDiscountResponse
     */
    'originalPrice': number;
    /**
     * Original price of item without modifier before reductions
     * @type {number}
     * @memberof CartItemDiscountResponse
     */
    'originalBasePrice': number;
    /**
     * Original price of item without modifier after reductions
     * @type {number}
     * @memberof CartItemDiscountResponse
     */
    'basePrice': number;
    /**
     * If the item is purchased with fidelity points
     * @type {boolean}
     * @memberof CartItemDiscountResponse
     */
    'isFidelityProgramItem': boolean;
    /**
     * Individual item fidelity points value
     * @type {number}
     * @memberof CartItemDiscountResponse
     */
    'fidelityPointsNeeded': number;
    /**
     * Localized name of the product.
     * @type {LocalizedFieldResponse}
     * @memberof CartItemDiscountResponse
     */
    'name': LocalizedFieldResponse;
    /**
     * Localized description of the product.
     * @type {LocalizedFieldResponse}
     * @memberof CartItemDiscountResponse
     */
    'description': LocalizedFieldResponse;
    /**
     * Discounts applied on item
     * @type {Array<string>}
     * @memberof CartItemDiscountResponse
     */
    'discountIdsUsed': Array<string>;
}
/**
 * 
 * @export
 * @interface CartItemModifierRequest
 */
export interface CartItemModifierRequest {
    /**
     * ID of the modifier
     * @type {string}
     * @memberof CartItemModifierRequest
     */
    'modifierId': string;
    /**
     * Cart items for that modifier
     * @type {Array<CartItemRequest>}
     * @memberof CartItemModifierRequest
     */
    'items': Array<CartItemRequest>;
}
/**
 * 
 * @export
 * @interface CartItemModifierResponse
 */
export interface CartItemModifierResponse {
    /**
     * ID of the modifier
     * @type {string}
     * @memberof CartItemModifierResponse
     */
    'modifierId': string;
    /**
     * Cart items for that modifier
     * @type {Array<CartItemResponse>}
     * @memberof CartItemModifierResponse
     */
    'items': Array<CartItemResponse>;
    /**
     * Localized name of the product.
     * @type {LocalizedFieldResponse}
     * @memberof CartItemModifierResponse
     */
    'name': LocalizedFieldResponse;
    /**
     * Localized description of the product.
     * @type {LocalizedFieldResponse}
     * @memberof CartItemModifierResponse
     */
    'description': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface CartItemRequest
 */
export interface CartItemRequest {
    /**
     * ID of the item
     * @type {string}
     * @memberof CartItemRequest
     */
    'itemId': string;
    /**
     * IDs of base items that are selected
     * @type {Array<string>}
     * @memberof CartItemRequest
     */
    'baseItemIds'?: Array<string>;
    /**
     * Selected items from the modifiers
     * @type {Array<CartItemModifierRequest>}
     * @memberof CartItemRequest
     */
    'modifiers': Array<CartItemModifierRequest>;
    /**
     * Number of products of this type added to the cart
     * @type {number}
     * @memberof CartItemRequest
     */
    'quantity': number;
    /**
     * Individual price of the product in cents.
     * @type {number}
     * @memberof CartItemRequest
     */
    'price': number;
    /**
     * If the item is buy in the fidelity program context
     * @type {boolean}
     * @memberof CartItemRequest
     */
    'isFidelityProgramItem'?: boolean;
    /**
     * Fidelity points needed to get this item from the fidelity program
     * @type {number}
     * @memberof CartItemRequest
     */
    'fidelityPointsNeeded'?: number;
}
/**
 * 
 * @export
 * @interface CartItemResponse
 */
export interface CartItemResponse {
    /**
     * ID of the cart product
     * @type {string}
     * @memberof CartItemResponse
     */
    'id': string;
    /**
     * ID of the item
     * @type {string}
     * @memberof CartItemResponse
     */
    'itemId': string;
    /**
     * IDs of base items that are selected
     * @type {Array<string>}
     * @memberof CartItemResponse
     */
    'baseItemIds'?: Array<string>;
    /**
     * Selected items from the modifiers
     * @type {Array<CartItemModifierResponse>}
     * @memberof CartItemResponse
     */
    'modifiers': Array<CartItemModifierResponse>;
    /**
     * Number of products of this type added to the cart
     * @type {number}
     * @memberof CartItemResponse
     */
    'quantity': number;
    /**
     * Individual applied price of the product in cents.
     * @type {number}
     * @memberof CartItemResponse
     */
    'price': number;
    /**
     * Individual price of the product in cents.
     * @type {number}
     * @memberof CartItemResponse
     */
    'originalPrice': number;
    /**
     * Original price of the product without modifier before reductions
     * @type {number}
     * @memberof CartItemResponse
     */
    'originalBasePrice': number;
    /**
     * Original price of the product without modifier after reductions
     * @type {number}
     * @memberof CartItemResponse
     */
    'basePrice': number;
    /**
     * If the item is purchased with fidelity points
     * @type {boolean}
     * @memberof CartItemResponse
     */
    'isFidelityProgramItem': boolean;
    /**
     * Individual item fidelity points value
     * @type {number}
     * @memberof CartItemResponse
     */
    'fidelityPointsNeeded': number;
    /**
     * Localized name of the product.
     * @type {LocalizedFieldResponse}
     * @memberof CartItemResponse
     */
    'name': LocalizedFieldResponse;
    /**
     * Localized description of the product.
     * @type {LocalizedFieldResponse}
     * @memberof CartItemResponse
     */
    'description': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface CartTotalsResponse
 */
export interface CartTotalsResponse {
    /**
     * Total price of all the products
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'productsTotal': number;
    /**
     * Total price without applying individual product discount
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'productsTotalWithoutDiscount': number;
    /**
     * Taxes from the products
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'productsTaxes': number;
    /**
     * Fee from the order type (delivery fee, for example)
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'orderTypeFeeWithoutDiscount': number;
    /**
     * Fee from the service
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'serviceFee': number;
    /**
     * Reduction from the restaurant discount
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'restaurantDiscountReduction': number;
    /**
     * Reduction from the applied promotion
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'promotionReduction': number;
    /**
     * Reduction applied to the price
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'priceReduction': number;
    /**
     * Reduction applied to the order type fee (delivery fee)
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'orderTypeFeeReduction': number;
    /**
     * Order type fee (delivery fee) with the reduction applied.
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'orderTypeFee': number;
    /**
     * Price of the products with the reduction applied
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'subtotal': number;
    /**
     * Total price with all reductions and fees
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'total': number;
    /**
     * Total price with all reductions and fees, without taxes
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'totalWithoutTaxes': number;
    /**
     * Total of the price which has been refunded
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'totalRefunded'?: number;
    /**
     * Total amount that cannot be payed using a meal payment
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'totalNotSuitableMealPayment': number;
    /**
     * Number of fidelity points used to get items from the order
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'fidelityPointsUsed': number;
    /**
     * Number of fidelity points to be accumulated with the order
     * @type {number}
     * @memberof CartTotalsResponse
     */
    'fidelityPointsAccumulated': number;
}
/**
 * 
 * @export
 * @interface CatalogCategoryResponse
 */
export interface CatalogCategoryResponse {
    /**
     * ID of the category
     * @type {string}
     * @memberof CatalogCategoryResponse
     */
    'id': string;
    /**
     * Populated category
     * @type {CategoryResponse}
     * @memberof CatalogCategoryResponse
     */
    'category'?: CategoryResponse;
    /**
     * ID of the items within the category
     * @type {Array<string>}
     * @memberof CatalogCategoryResponse
     */
    'itemIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CatalogResponse
 */
export interface CatalogResponse {
    /**
     * ID of the catalog
     * @type {string}
     * @memberof CatalogResponse
     */
    'id': string;
    /**
     * Company to which it belongs
     * @type {string}
     * @memberof CatalogResponse
     */
    'companyId': string;
    /**
     * ID of the region to which belongs
     * @type {string}
     * @memberof CatalogResponse
     */
    'regionId'?: string;
    /**
     * Name of the catalog
     * @type {string}
     * @memberof CatalogResponse
     */
    'name': string;
    /**
     * Order types for which the catalog is available
     * @type {Array<OrderType>}
     * @memberof CatalogResponse
     */
    'orderTypes': Array<OrderType>;
    /**
     * Categories within the catalog
     * @type {Array<CatalogCategoryResponse>}
     * @memberof CatalogResponse
     */
    'categories': Array<CatalogCategoryResponse>;
    /**
     * IDs of all the items
     * @type {Array<string>}
     * @memberof CatalogResponse
     */
    'itemIds': Array<string>;
    /**
     * Populated items
     * @type {Array<ItemResponse>}
     * @memberof CatalogResponse
     */
    'items'?: Array<ItemResponse>;
    /**
     * IDs of all the modifiers
     * @type {Array<string>}
     * @memberof CatalogResponse
     */
    'modifierIds': Array<string>;
    /**
     * Populated modifiers
     * @type {Array<ItemModifierResponse>}
     * @memberof CatalogResponse
     */
    'modifiers'?: Array<ItemModifierResponse>;
    /**
     * Date when it was created
     * @type {string}
     * @memberof CatalogResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof CatalogResponse
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface CategoryResponse
 */
export interface CategoryResponse {
    /**
     * ID of the category
     * @type {string}
     * @memberof CategoryResponse
     */
    'id': string;
    /**
     * ID of the company to which it belongs
     * @type {string}
     * @memberof CategoryResponse
     */
    'companyId': string;
    /**
     * ID of the region to which belongs
     * @type {string}
     * @memberof CategoryResponse
     */
    'regionId'?: string;
    /**
     * Source of the category
     * @type {EntitySourceResponse}
     * @memberof CategoryResponse
     */
    'source'?: EntitySourceResponse;
    /**
     * Stock keeping unit
     * @type {string}
     * @memberof CategoryResponse
     */
    'sku'?: string;
    /**
     * Name of the category
     * @type {LocalizedFieldResponse}
     * @memberof CategoryResponse
     */
    'name': LocalizedFieldResponse;
    /**
     * Description of the category
     * @type {LocalizedFieldResponse}
     * @memberof CategoryResponse
     */
    'description': LocalizedFieldResponse;
    /**
     * Whether the items in this category should be grouped
     * @type {boolean}
     * @memberof CategoryResponse
     */
    'groupItems': boolean;
    /**
     * Whether the items in this category should be printed
     * @type {boolean}
     * @memberof CategoryResponse
     */
    'printItemTicket': boolean;
    /**
     * Order position of the category
     * @type {number}
     * @memberof CategoryResponse
     */
    'order': number;
    /**
     * Whether the category is disabled
     * @type {boolean}
     * @memberof CategoryResponse
     */
    'isDisabled': boolean;
    /**
     * Image URL of the category
     * @type {string}
     * @memberof CategoryResponse
     */
    'image'?: string;
    /**
     * Promotion image URL of the category
     * @type {string}
     * @memberof CategoryResponse
     */
    'promotion'?: string;
    /**
     * IDs of the upsell items. Upsell items are offered when a customer wants to purchase an item of this category. e.g. Menu Poke when purchasing a Poke (Menu Poke ID would be in the list)
     * @type {Array<string>}
     * @memberof CategoryResponse
     */
    'upsellItemIds': Array<string>;
    /**
     * IDs of the cross-sell items. Cross-sell items are offered after purchasing an item of this category. e.g list of desserts after a Menu Poke + Dessert (dessert item IDs would be in the list)
     * @type {Array<string>}
     * @memberof CategoryResponse
     */
    'crossSellItemIds': Array<string>;
    /**
     * Date when it was created
     * @type {string}
     * @memberof CategoryResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof CategoryResponse
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface CheckPaymentLinkOrderRequest
 */
export interface CheckPaymentLinkOrderRequest {
    /**
     * Order trace id
     * @type {string}
     * @memberof CheckPaymentLinkOrderRequest
     */
    'traceId': string;
}
/**
 * 
 * @export
 * @interface CheckUserEmailRequest
 */
export interface CheckUserEmailRequest {
    /**
     * Email of the user
     * @type {string}
     * @memberof CheckUserEmailRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CompanyPublicResponse
 */
export interface CompanyPublicResponse {
    /**
     * Id of the company
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'id': string;
    /**
     * Name of the company
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'name': string;
    /**
     * Description of the company
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'description'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof CompanyPublicResponse
     */
    'currency': Currency;
    /**
     * Languages for the company
     * @type {Array<Language>}
     * @memberof CompanyPublicResponse
     */
    'languages': Array<Language>;
    /**
     * 
     * @type {Language}
     * @memberof CompanyPublicResponse
     */
    'mainLanguage': Language;
    /**
     * 
     * @type {BusinessDetailsResponse}
     * @memberof CompanyPublicResponse
     */
    'businessDetails': BusinessDetailsResponse;
    /**
     * URL of the favicon of the company
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'favicon': string;
    /**
     * URL of the logo of the company
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'logo': string;
    /**
     * Email to ask for help
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'helpEmail'?: string;
    /**
     * Link to terms and conditions 
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'termsLink'?: string;
    /**
     * Link to privacy information
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'privacyLink'?: string;
    /**
     * Link with legal notice
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'legalNoticeLink'?: string;
    /**
     * Web app address
     * @type {string}
     * @memberof CompanyPublicResponse
     */
    'webappAddress'?: string;
    /**
     * Template for the company
     * @type {TemplateResponse}
     * @memberof CompanyPublicResponse
     */
    'template'?: TemplateResponse;
    /**
     * 
     * @type {DeliveryExternalLinksResponse}
     * @memberof CompanyPublicResponse
     */
    'deliveryExternalLinks': DeliveryExternalLinksResponse;
    /**
     * Regions in the company
     * @type {Array<CompanyRegionPublicResponse>}
     * @memberof CompanyPublicResponse
     */
    'regions': Array<CompanyRegionPublicResponse>;
}
/**
 * 
 * @export
 * @interface CompanyRegionInstagramPostResponse
 */
export interface CompanyRegionInstagramPostResponse {
    /**
     * Instagram feed post image
     * @type {string}
     * @memberof CompanyRegionInstagramPostResponse
     */
    'mediaUrl': string;
    /**
     * Url of the instagram feed post
     * @type {string}
     * @memberof CompanyRegionInstagramPostResponse
     */
    'permalink': string;
}
/**
 * 
 * @export
 * @interface CompanyRegionInstagramRegionConfigResponse
 */
export interface CompanyRegionInstagramRegionConfigResponse {
    /**
     * Username instagram feed post
     * @type {string}
     * @memberof CompanyRegionInstagramRegionConfigResponse
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface CompanyRegionPublicResponse
 */
export interface CompanyRegionPublicResponse {
    /**
     * ID of the company region
     * @type {string}
     * @memberof CompanyRegionPublicResponse
     */
    'id': string;
    /**
     * ID of the company to which the region belongs
     * @type {string}
     * @memberof CompanyRegionPublicResponse
     */
    'companyId': string;
    /**
     * Name of the company region
     * @type {string}
     * @memberof CompanyRegionPublicResponse
     */
    'name': string;
    /**
     * Description of the company region
     * @type {string}
     * @memberof CompanyRegionPublicResponse
     */
    'description': string;
    /**
     * 
     * @type {Array<BannerResponse>}
     * @memberof CompanyRegionPublicResponse
     */
    'banners'?: Array<BannerResponse>;
    /**
     * Whether should override the currency of the company
     * @type {boolean}
     * @memberof CompanyRegionPublicResponse
     */
    'overrideCurrency': boolean;
    /**
     * 
     * @type {Currency}
     * @memberof CompanyRegionPublicResponse
     */
    'currency'?: Currency;
    /**
     * Whether should override the languages from the company
     * @type {boolean}
     * @memberof CompanyRegionPublicResponse
     */
    'overrideLanguages': boolean;
    /**
     * Languages for the company
     * @type {Array<Language>}
     * @memberof CompanyRegionPublicResponse
     */
    'languages'?: Array<Language>;
    /**
     * Whether should override the main language from the company
     * @type {boolean}
     * @memberof CompanyRegionPublicResponse
     */
    'overrideMainLanguage': boolean;
    /**
     * 
     * @type {Language}
     * @memberof CompanyRegionPublicResponse
     */
    'mainLanguage'?: Language;
    /**
     * Whether should override the fidelity program from the company
     * @type {boolean}
     * @memberof CompanyRegionPublicResponse
     */
    'overrideFidelityProgram': boolean;
    /**
     * Company region\'s fidelity program
     * @type {FidelityProgramResponse}
     * @memberof CompanyRegionPublicResponse
     */
    'fidelityProgram': FidelityProgramResponse;
    /**
     * Regions in the company
     * @type {Array<CompanyRegionResponse>}
     * @memberof CompanyRegionPublicResponse
     */
    'regions': Array<CompanyRegionResponse>;
}
/**
 * 
 * @export
 * @interface CompanyRegionResponse
 */
export interface CompanyRegionResponse {
    /**
     * ID of the company region
     * @type {string}
     * @memberof CompanyRegionResponse
     */
    'id': string;
    /**
     * ID of the company to which the region belongs
     * @type {string}
     * @memberof CompanyRegionResponse
     */
    'companyId': string;
    /**
     * POS data
     * @type {POSDataResponse}
     * @memberof CompanyRegionResponse
     */
    'posData': POSDataResponse;
    /**
     * Name of the company region
     * @type {string}
     * @memberof CompanyRegionResponse
     */
    'name': string;
    /**
     * Description of the company region
     * @type {string}
     * @memberof CompanyRegionResponse
     */
    'description': string;
    /**
     * IDs of the banners that are published in the region
     * @type {Array<string>}
     * @memberof CompanyRegionResponse
     */
    'bannerIds': Array<string>;
    /**
     * 
     * @type {Array<BannerResponse>}
     * @memberof CompanyRegionResponse
     */
    'banners'?: Array<BannerResponse>;
    /**
     * Whether should override the currency of the company
     * @type {boolean}
     * @memberof CompanyRegionResponse
     */
    'overrideCurrency': boolean;
    /**
     * 
     * @type {Currency}
     * @memberof CompanyRegionResponse
     */
    'currency'?: Currency;
    /**
     * Whether should override the languages from the company
     * @type {boolean}
     * @memberof CompanyRegionResponse
     */
    'overrideLanguages': boolean;
    /**
     * Languages for the company
     * @type {Array<Language>}
     * @memberof CompanyRegionResponse
     */
    'languages'?: Array<Language>;
    /**
     * Whether should override the main language from the company
     * @type {boolean}
     * @memberof CompanyRegionResponse
     */
    'overrideMainLanguage': boolean;
    /**
     * 
     * @type {Language}
     * @memberof CompanyRegionResponse
     */
    'mainLanguage'?: Language;
    /**
     * Whether should override the main language from the company
     * @type {boolean}
     * @memberof CompanyRegionResponse
     */
    'overrideTaxRates': boolean;
    /**
     * List of available taxes for the company
     * @type {Array<number>}
     * @memberof CompanyRegionResponse
     */
    'taxRates'?: Array<number>;
    /**
     * 
     * @type {DeliveryDataResponse}
     * @memberof CompanyRegionResponse
     */
    'deliveryData': DeliveryDataResponse;
    /**
     * 
     * @type {Array<DeliveryQuoteDistanceConditionalFeesResponse>}
     * @memberof CompanyRegionResponse
     */
    'deliveryFees'?: Array<DeliveryQuoteDistanceConditionalFeesResponse>;
    /**
     * The real cost of a delivery
     * @type {number}
     * @memberof CompanyRegionResponse
     */
    'defaultDeliveryFee'?: number;
    /**
     * Whether should override the fidelity program from the company
     * @type {boolean}
     * @memberof CompanyRegionResponse
     */
    'overrideFidelityProgram': boolean;
    /**
     * Company region\'s fidelity program
     * @type {FidelityProgramResponse}
     * @memberof CompanyRegionResponse
     */
    'fidelityProgram': FidelityProgramResponse;
    /**
     * Whether should override the invoicing config from the company
     * @type {boolean}
     * @memberof CompanyRegionResponse
     */
    'overrideInvoicingConfig': boolean;
    /**
     * Company region\'s configuration for invoicing
     * @type {InvoicingConfigResponse}
     * @memberof CompanyRegionResponse
     */
    'invoicingConfig'?: InvoicingConfigResponse;
    /**
     * Date when it was created
     * @type {string}
     * @memberof CompanyRegionResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof CompanyRegionResponse
     */
    'updated': string;
    /**
     * Instagram username
     * @type {CompanyRegionInstagramRegionConfigResponse}
     * @memberof CompanyRegionResponse
     */
    'instagramConfig': CompanyRegionInstagramRegionConfigResponse;
}
/**
 * 
 * @export
 * @interface CompanyThemeImagesKioskResponse
 */
export interface CompanyThemeImagesKioskResponse {
    /**
     * Image to render in the welcome screen
     * @type {string}
     * @memberof CompanyThemeImagesKioskResponse
     */
    'welcome'?: string;
    /**
     * Image to render in the welcome screen by language
     * @type {LocalizedFieldResponse}
     * @memberof CompanyThemeImagesKioskResponse
     */
    'welcomeByLanguage'?: LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface CompanyThemeImagesResponse
 */
export interface CompanyThemeImagesResponse {
    /**
     * Images for the kiosk
     * @type {CompanyThemeImagesKioskResponse}
     * @memberof CompanyThemeImagesResponse
     */
    'kiosk'?: CompanyThemeImagesKioskResponse;
}
/**
 * 
 * @export
 * @interface CompanyThemeResponse
 */
export interface CompanyThemeResponse {
    /**
     * Theme colors
     * @type {CompanyThemeResponseColors}
     * @memberof CompanyThemeResponse
     */
    'colors'?: CompanyThemeResponseColors;
    /**
     * Images for different apps
     * @type {CompanyThemeImagesResponse}
     * @memberof CompanyThemeResponse
     */
    'images'?: CompanyThemeImagesResponse;
    /**
     * Image in the welcome screen of the kiosk. Use images instead.
     * @type {string}
     * @memberof CompanyThemeResponse
     * @deprecated
     */
    'kioskWelcomeImage'?: string;
}
/**
 * 
 * @export
 * @interface CompanyThemeResponseColors
 */
export interface CompanyThemeResponseColors {
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'primary': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'lightPrimary': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'secondary': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'tertiary': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'black': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'white': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'lightGrey': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'grey': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'mediumGrey': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'darkGrey': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'new': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'prices': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'discounts': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'outOfStock': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'success': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'alert': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'inputContrast': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'switchChecked': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'switchUnchecked': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyThemeResponseColors
     */
    'textContrast': string;
}
/**
 * 
 * @export
 * @interface CreateItemDisabledForOrderTypeRequest
 */
export interface CreateItemDisabledForOrderTypeRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemDisabledForOrderTypeRequest
     */
    'pickUp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemDisabledForOrderTypeRequest
     */
    'delivery'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemDisabledForOrderTypeRequest
     */
    'eatIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemDisabledForOrderTypeRequest
     */
    'table'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateOrderUsingCashRequest
 */
export interface CreateOrderUsingCashRequest {
    /**
     * 
     * @type {OrderType}
     * @memberof CreateOrderUsingCashRequest
     */
    'orderType': OrderType;
    /**
     * ID of the table, in case of eat in
     * @type {string}
     * @memberof CreateOrderUsingCashRequest
     */
    'tableId'?: string;
    /**
     * Items that compose the request
     * @type {Array<CartItemRequest>}
     * @memberof CreateOrderUsingCashRequest
     */
    'items': Array<CartItemRequest>;
    /**
     * Date when the order needs to be prepared
     * @type {string}
     * @memberof CreateOrderUsingCashRequest
     */
    'dueDate'?: string;
    /**
     * Note from the user
     * @type {string}
     * @memberof CreateOrderUsingCashRequest
     */
    'note': string;
    /**
     * Whether cutlery is needed 
     * @type {boolean}
     * @memberof CreateOrderUsingCashRequest
     */
    'cutlery': boolean;
    /**
     * Discount code
     * @type {string}
     * @memberof CreateOrderUsingCashRequest
     */
    'discountCode'?: string;
    /**
     * 
     * @type {OrderPlatform}
     * @memberof CreateOrderUsingCashRequest
     */
    'platform': OrderPlatform;
    /**
     * Platform information used to make the order
     * @type {object}
     * @memberof CreateOrderUsingCashRequest
     */
    'platformData'?: object;
    /**
     * Delivery address
     * @type {AddressRequest}
     * @memberof CreateOrderUsingCashRequest
     */
    'deliveryAddress'?: AddressRequest;
    /**
     * The delivery quote request
     * @type {DeliveryQuoteRequest}
     * @memberof CreateOrderUsingCashRequest
     */
    'deliveryQuote'?: DeliveryQuoteRequest;
    /**
     * Guest user information
     * @type {GuestRequest}
     * @memberof CreateOrderUsingCashRequest
     */
    'guest'?: GuestRequest;
    /**
     * Payment using a meal payment method
     * @type {PaymentRequest}
     * @memberof CreateOrderUsingCashRequest
     */
    'mealPayment'?: PaymentRequest;
    /**
     * Balance for the meal payment method
     * @type {MealPaymentBalanceRequest}
     * @memberof CreateOrderUsingCashRequest
     */
    'mealPaymentBalance'?: MealPaymentBalanceRequest;
}
/**
 * 
 * @export
 * @interface CreateOrderUsingPayment
 */
export interface CreateOrderUsingPayment {
    /**
     * 
     * @type {OrderType}
     * @memberof CreateOrderUsingPayment
     */
    'orderType': OrderType;
    /**
     * ID of the table, in case of eat in
     * @type {string}
     * @memberof CreateOrderUsingPayment
     */
    'tableId'?: string;
    /**
     * Items that compose the request
     * @type {Array<CartItemRequest>}
     * @memberof CreateOrderUsingPayment
     */
    'items': Array<CartItemRequest>;
    /**
     * Date when the order needs to be prepared
     * @type {string}
     * @memberof CreateOrderUsingPayment
     */
    'dueDate'?: string;
    /**
     * Note from the user
     * @type {string}
     * @memberof CreateOrderUsingPayment
     */
    'note': string;
    /**
     * Whether cutlery is needed 
     * @type {boolean}
     * @memberof CreateOrderUsingPayment
     */
    'cutlery': boolean;
    /**
     * Discount code
     * @type {string}
     * @memberof CreateOrderUsingPayment
     */
    'discountCode'?: string;
    /**
     * 
     * @type {OrderPlatform}
     * @memberof CreateOrderUsingPayment
     */
    'platform': OrderPlatform;
    /**
     * Platform information used to make the order
     * @type {object}
     * @memberof CreateOrderUsingPayment
     */
    'platformData'?: object;
    /**
     * Delivery address
     * @type {AddressRequest}
     * @memberof CreateOrderUsingPayment
     */
    'deliveryAddress'?: AddressRequest;
    /**
     * The delivery quote request
     * @type {DeliveryQuoteRequest}
     * @memberof CreateOrderUsingPayment
     */
    'deliveryQuote'?: DeliveryQuoteRequest;
    /**
     * Guest user information
     * @type {GuestRequest}
     * @memberof CreateOrderUsingPayment
     */
    'guest'?: GuestRequest;
    /**
     * Payment using a meal payment method
     * @type {PaymentRequest}
     * @memberof CreateOrderUsingPayment
     */
    'mealPayment'?: PaymentRequest;
    /**
     * Balance for the meal payment method
     * @type {MealPaymentBalanceRequest}
     * @memberof CreateOrderUsingPayment
     */
    'mealPaymentBalance'?: MealPaymentBalanceRequest;
    /**
     * Payment information
     * @type {PaymentRequest}
     * @memberof CreateOrderUsingPayment
     */
    'payment': PaymentRequest;
}
/**
 * 
 * @export
 * @interface CreateOrderViaLinkRequest
 */
export interface CreateOrderViaLinkRequest {
    /**
     * 
     * @type {OrderType}
     * @memberof CreateOrderViaLinkRequest
     */
    'orderType': OrderType;
    /**
     * ID of the table, in case of eat in
     * @type {string}
     * @memberof CreateOrderViaLinkRequest
     */
    'tableId'?: string;
    /**
     * Items that compose the request
     * @type {Array<CartItemRequest>}
     * @memberof CreateOrderViaLinkRequest
     */
    'items': Array<CartItemRequest>;
    /**
     * Date when the order needs to be prepared
     * @type {string}
     * @memberof CreateOrderViaLinkRequest
     */
    'dueDate'?: string;
    /**
     * Note from the user
     * @type {string}
     * @memberof CreateOrderViaLinkRequest
     */
    'note': string;
    /**
     * Whether cutlery is needed 
     * @type {boolean}
     * @memberof CreateOrderViaLinkRequest
     */
    'cutlery': boolean;
    /**
     * Discount code
     * @type {string}
     * @memberof CreateOrderViaLinkRequest
     */
    'discountCode'?: string;
    /**
     * 
     * @type {OrderPlatform}
     * @memberof CreateOrderViaLinkRequest
     */
    'platform': OrderPlatform;
    /**
     * Platform information used to make the order
     * @type {object}
     * @memberof CreateOrderViaLinkRequest
     */
    'platformData'?: object;
    /**
     * Delivery address
     * @type {AddressRequest}
     * @memberof CreateOrderViaLinkRequest
     */
    'deliveryAddress'?: AddressRequest;
    /**
     * The delivery quote request
     * @type {DeliveryQuoteRequest}
     * @memberof CreateOrderViaLinkRequest
     */
    'deliveryQuote'?: DeliveryQuoteRequest;
    /**
     * Guest user information
     * @type {GuestRequest}
     * @memberof CreateOrderViaLinkRequest
     */
    'guest'?: GuestRequest;
    /**
     * Payment using a meal payment method
     * @type {PaymentRequest}
     * @memberof CreateOrderViaLinkRequest
     */
    'mealPayment'?: PaymentRequest;
    /**
     * Balance for the meal payment method
     * @type {MealPaymentBalanceRequest}
     * @memberof CreateOrderViaLinkRequest
     */
    'mealPaymentBalance'?: MealPaymentBalanceRequest;
}
/**
 * 
 * @export
 * @interface CreateOrderViaURLRequest
 */
export interface CreateOrderViaURLRequest {
    /**
     * 
     * @type {OrderType}
     * @memberof CreateOrderViaURLRequest
     */
    'orderType': OrderType;
    /**
     * ID of the table, in case of eat in
     * @type {string}
     * @memberof CreateOrderViaURLRequest
     */
    'tableId'?: string;
    /**
     * Items that compose the request
     * @type {Array<CartItemRequest>}
     * @memberof CreateOrderViaURLRequest
     */
    'items': Array<CartItemRequest>;
    /**
     * Date when the order needs to be prepared
     * @type {string}
     * @memberof CreateOrderViaURLRequest
     */
    'dueDate'?: string;
    /**
     * Note from the user
     * @type {string}
     * @memberof CreateOrderViaURLRequest
     */
    'note': string;
    /**
     * Whether cutlery is needed 
     * @type {boolean}
     * @memberof CreateOrderViaURLRequest
     */
    'cutlery': boolean;
    /**
     * Discount code
     * @type {string}
     * @memberof CreateOrderViaURLRequest
     */
    'discountCode'?: string;
    /**
     * 
     * @type {OrderPlatform}
     * @memberof CreateOrderViaURLRequest
     */
    'platform': OrderPlatform;
    /**
     * Platform information used to make the order
     * @type {object}
     * @memberof CreateOrderViaURLRequest
     */
    'platformData'?: object;
    /**
     * Delivery address
     * @type {AddressRequest}
     * @memberof CreateOrderViaURLRequest
     */
    'deliveryAddress'?: AddressRequest;
    /**
     * The delivery quote request
     * @type {DeliveryQuoteRequest}
     * @memberof CreateOrderViaURLRequest
     */
    'deliveryQuote'?: DeliveryQuoteRequest;
    /**
     * Guest user information
     * @type {GuestRequest}
     * @memberof CreateOrderViaURLRequest
     */
    'guest'?: GuestRequest;
    /**
     * Payment using a meal payment method
     * @type {PaymentRequest}
     * @memberof CreateOrderViaURLRequest
     */
    'mealPayment'?: PaymentRequest;
    /**
     * Balance for the meal payment method
     * @type {MealPaymentBalanceRequest}
     * @memberof CreateOrderViaURLRequest
     */
    'mealPaymentBalance'?: MealPaymentBalanceRequest;
}
/**
 * 
 * @export
 * @interface CreateUserPhoneRequest
 */
export interface CreateUserPhoneRequest {
    /**
     * Country code
     * @type {string}
     * @memberof CreateUserPhoneRequest
     */
    'countryCode': string;
    /**
     * Phone number
     * @type {string}
     * @memberof CreateUserPhoneRequest
     */
    'number': string;
    /**
     * Full phone number
     * @type {string}
     * @memberof CreateUserPhoneRequest
     */
    'fullNumber': string;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * First name of the user
     * @type {string}
     * @memberof CreateUserRequest
     */
    'firstName': string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof CreateUserRequest
     */
    'lastName': string;
    /**
     * Email of the user
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email': string;
    /**
     * 
     * @type {CreateUserPhoneRequest}
     * @memberof CreateUserRequest
     */
    'phone': CreateUserPhoneRequest;
    /**
     * User password
     * @type {string}
     * @memberof CreateUserRequest
     */
    'password': string;
    /**
     * Whether the user wants to receive offers via email
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'offersViaEmail': boolean;
    /**
     * Whether the user wants to receive offers via SMS
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'offersViaSMS': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Currency = {
    Eur: 'EUR',
    Chf: 'CHF',
    Gbp: 'GBP',
    Usd: 'USD'
} as const;

export type Currency = typeof Currency[keyof typeof Currency];


/**
 * 
 * @export
 * @interface DailyActiveHoursRequest
 */
export interface DailyActiveHoursRequest {
    /**
     * 
     * @type {DayActiveHoursRequest}
     * @memberof DailyActiveHoursRequest
     */
    'monday': DayActiveHoursRequest;
    /**
     * 
     * @type {DayActiveHoursRequest}
     * @memberof DailyActiveHoursRequest
     */
    'tuesday': DayActiveHoursRequest;
    /**
     * 
     * @type {DayActiveHoursRequest}
     * @memberof DailyActiveHoursRequest
     */
    'wednesday': DayActiveHoursRequest;
    /**
     * 
     * @type {DayActiveHoursRequest}
     * @memberof DailyActiveHoursRequest
     */
    'thursday': DayActiveHoursRequest;
    /**
     * 
     * @type {DayActiveHoursRequest}
     * @memberof DailyActiveHoursRequest
     */
    'friday': DayActiveHoursRequest;
    /**
     * 
     * @type {DayActiveHoursRequest}
     * @memberof DailyActiveHoursRequest
     */
    'saturday': DayActiveHoursRequest;
    /**
     * 
     * @type {DayActiveHoursRequest}
     * @memberof DailyActiveHoursRequest
     */
    'sunday': DayActiveHoursRequest;
}
/**
 * 
 * @export
 * @interface DailyActiveHoursResponse
 */
export interface DailyActiveHoursResponse {
    /**
     * 
     * @type {DayActiveHoursResponse}
     * @memberof DailyActiveHoursResponse
     */
    'monday': DayActiveHoursResponse;
    /**
     * 
     * @type {DayActiveHoursResponse}
     * @memberof DailyActiveHoursResponse
     */
    'tuesday': DayActiveHoursResponse;
    /**
     * 
     * @type {DayActiveHoursResponse}
     * @memberof DailyActiveHoursResponse
     */
    'wednesday': DayActiveHoursResponse;
    /**
     * 
     * @type {DayActiveHoursResponse}
     * @memberof DailyActiveHoursResponse
     */
    'thursday': DayActiveHoursResponse;
    /**
     * 
     * @type {DayActiveHoursResponse}
     * @memberof DailyActiveHoursResponse
     */
    'friday': DayActiveHoursResponse;
    /**
     * 
     * @type {DayActiveHoursResponse}
     * @memberof DailyActiveHoursResponse
     */
    'saturday': DayActiveHoursResponse;
    /**
     * 
     * @type {DayActiveHoursResponse}
     * @memberof DailyActiveHoursResponse
     */
    'sunday': DayActiveHoursResponse;
}
/**
 * 
 * @export
 * @interface DayActiveHoursRequest
 */
export interface DayActiveHoursRequest {
    /**
     * Whether it is active that day.
     * @type {boolean}
     * @memberof DayActiveHoursRequest
     */
    'isActive': boolean;
    /**
     * Hours at which it\'s active
     * @type {Array<ActiveHoursRequest>}
     * @memberof DayActiveHoursRequest
     */
    'hours': Array<ActiveHoursRequest>;
}
/**
 * 
 * @export
 * @interface DayActiveHoursResponse
 */
export interface DayActiveHoursResponse {
    /**
     * Whether it is active that day.
     * @type {boolean}
     * @memberof DayActiveHoursResponse
     */
    'isActive': boolean;
    /**
     * Hours at which it\'s active
     * @type {Array<ActiveHoursResponse>}
     * @memberof DayActiveHoursResponse
     */
    'hours': Array<ActiveHoursResponse>;
}
/**
 * 
 * @export
 * @interface DeliverectDataResponse
 */
export interface DeliverectDataResponse {
    /**
     * Channel name in Deliverect
     * @type {string}
     * @memberof DeliverectDataResponse
     */
    'channelLinkName': string;
    /**
     * Channel ID in Deliverect
     * @type {string}
     * @memberof DeliverectDataResponse
     */
    'channelLinkId': string;
}
/**
 * 
 * @export
 * @interface DeliverooDataResponse
 */
export interface DeliverooDataResponse {
    /**
     * Deliveroo username for FulFillment API
     * @type {string}
     * @memberof DeliverooDataResponse
     */
    'fulfillmentUsernameToken': string;
    /**
     * Deliveroo password for FulFillment API
     * @type {string}
     * @memberof DeliverooDataResponse
     */
    'fulfillmentPasswordToken': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeliveryCompany = {
    Personal: 'personal',
    Deliveroo: 'deliveroo',
    Uber: 'uber',
    Stuart: 'stuart',
    Ziticity: 'ziticity'
} as const;

export type DeliveryCompany = typeof DeliveryCompany[keyof typeof DeliveryCompany];


/**
 * 
 * @export
 * @interface DeliveryDataResponse
 */
export interface DeliveryDataResponse {
    /**
     * 
     * @type {DeliverooDataResponse}
     * @memberof DeliveryDataResponse
     */
    'deliveroo'?: DeliverooDataResponse;
    /**
     * 
     * @type {UberDirectDataResponse}
     * @memberof DeliveryDataResponse
     */
    'uber'?: UberDirectDataResponse;
}
/**
 * 
 * @export
 * @interface DeliveryExternalLinkResponse
 */
export interface DeliveryExternalLinkResponse {
    /**
     * 
     * @type {DeliveryCompany}
     * @memberof DeliveryExternalLinkResponse
     */
    'company': DeliveryCompany;
    /**
     * External link
     * @type {string}
     * @memberof DeliveryExternalLinkResponse
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface DeliveryExternalLinksResponse
 */
export interface DeliveryExternalLinksResponse {
    /**
     * Whether external links are enabled
     * @type {boolean}
     * @memberof DeliveryExternalLinksResponse
     */
    'enabled': boolean;
    /**
     * Links for each company
     * @type {Array<DeliveryExternalLinkResponse>}
     * @memberof DeliveryExternalLinksResponse
     */
    'links': Array<DeliveryExternalLinkResponse>;
}
/**
 * 
 * @export
 * @interface DeliveryOptionsResponse
 */
export interface DeliveryOptionsResponse {
    /**
     * Whether the mode is enabled or not.
     * @type {boolean}
     * @memberof DeliveryOptionsResponse
     */
    'isEnabled': boolean;
    /**
     * Fee in cents for using the mode
     * @type {number}
     * @memberof DeliveryOptionsResponse
     */
    'fee': number;
    /**
     * 
     * @type {DailyActiveHoursResponse}
     * @memberof DeliveryOptionsResponse
     */
    'hours': DailyActiveHoursResponse;
    /**
     * 
     * @type {DeliveryCompany}
     * @memberof DeliveryOptionsResponse
     */
    'company': DeliveryCompany;
}
/**
 * 
 * @export
 * @interface DeliveryOrderDataResponse
 */
export interface DeliveryOrderDataResponse {
    /**
     * 
     * @type {DeliveryCompany}
     * @memberof DeliveryOrderDataResponse
     */
    'deliveryCompany': DeliveryCompany;
    /**
     * External ID for the delivery order
     * @type {string}
     * @memberof DeliveryOrderDataResponse
     */
    'externalId': string;
    /**
     * Human readable ID for the delivery order
     * @type {string}
     * @memberof DeliveryOrderDataResponse
     */
    'displayId'?: string;
    /**
     * 
     * @type {DeliveryOrderStatus}
     * @memberof DeliveryOrderDataResponse
     */
    'status': DeliveryOrderStatus;
    /**
     * URL of the map to track the delivery
     * @type {string}
     * @memberof DeliveryOrderDataResponse
     */
    'mapUrl'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeliveryOrderStatus = {
    Placed: 'placed',
    Accepted: 'accepted',
    RiderAssigned: 'rider_assigned',
    RiderArrived: 'rider_arrived',
    RiderInTransit: 'rider_in_transit',
    RiderNearby: 'rider_nearby',
    Delivered: 'delivered',
    Canceled: 'canceled',
    Rejected: 'rejected',
    Undeliverable: 'undeliverable'
} as const;

export type DeliveryOrderStatus = typeof DeliveryOrderStatus[keyof typeof DeliveryOrderStatus];


/**
 * 
 * @export
 * @interface DeliveryQuoteConditionalFeeRequest
 */
export interface DeliveryQuoteConditionalFeeRequest {
    /**
     * Min price to use the fee
     * @type {number}
     * @memberof DeliveryQuoteConditionalFeeRequest
     */
    'minPrice': number;
    /**
     * Fee when the condition is met
     * @type {number}
     * @memberof DeliveryQuoteConditionalFeeRequest
     */
    'fee': number;
}
/**
 * 
 * @export
 * @interface DeliveryQuoteConditionalFeeResponse
 */
export interface DeliveryQuoteConditionalFeeResponse {
    /**
     * Min price to use the fee
     * @type {number}
     * @memberof DeliveryQuoteConditionalFeeResponse
     */
    'minPrice': number;
    /**
     * Fee when the condition is met
     * @type {number}
     * @memberof DeliveryQuoteConditionalFeeResponse
     */
    'fee': number;
}
/**
 * 
 * @export
 * @interface DeliveryQuoteDistanceConditionalFeesResponse
 */
export interface DeliveryQuoteDistanceConditionalFeesResponse {
    /**
     * Min distance to use the fees
     * @type {number}
     * @memberof DeliveryQuoteDistanceConditionalFeesResponse
     */
    'minDistance': number;
    /**
     * Fees depending on the total amount
     * @type {Array<DeliveryQuoteConditionalFeeResponse>}
     * @memberof DeliveryQuoteDistanceConditionalFeesResponse
     */
    'fees': Array<DeliveryQuoteConditionalFeeResponse>;
}
/**
 * 
 * @export
 * @interface DeliveryQuoteDurationRequest
 */
export interface DeliveryQuoteDurationRequest {
    /**
     * Min duration in seconds
     * @type {number}
     * @memberof DeliveryQuoteDurationRequest
     */
    'preparationMin': number;
    /**
     * Max duration in seconds
     * @type {number}
     * @memberof DeliveryQuoteDurationRequest
     */
    'preparationMax': number;
    /**
     * Time that will take the rider to arrive in seconds
     * @type {number}
     * @memberof DeliveryQuoteDurationRequest
     */
    'rider': number;
}
/**
 * 
 * @export
 * @interface DeliveryQuoteDurationResponse
 */
export interface DeliveryQuoteDurationResponse {
    /**
     * Min duration in seconds
     * @type {number}
     * @memberof DeliveryQuoteDurationResponse
     */
    'preparationMin': number;
    /**
     * Max duration in seconds
     * @type {number}
     * @memberof DeliveryQuoteDurationResponse
     */
    'preparationMax': number;
    /**
     * Time that will take the rider to arrive in seconds
     * @type {number}
     * @memberof DeliveryQuoteDurationResponse
     */
    'rider': number;
}
/**
 * 
 * @export
 * @interface DeliveryQuoteFeesRequest
 */
export interface DeliveryQuoteFeesRequest {
    /**
     * Delivery quote fee value in cents
     * @type {number}
     * @memberof DeliveryQuoteFeesRequest
     */
    'default': number;
    /**
     * Delivery quote fees
     * @type {DeliveryQuoteConditionalFeeRequest}
     * @memberof DeliveryQuoteFeesRequest
     */
    'conditionalFees': DeliveryQuoteConditionalFeeRequest;
}
/**
 * 
 * @export
 * @interface DeliveryQuoteFeesResponse
 */
export interface DeliveryQuoteFeesResponse {
    /**
     * Default fee
     * @type {number}
     * @memberof DeliveryQuoteFeesResponse
     */
    'default': number;
    /**
     * Fees depending on the total amount
     * @type {Array<DeliveryQuoteConditionalFeeResponse>}
     * @memberof DeliveryQuoteFeesResponse
     */
    'conditionalFees': Array<DeliveryQuoteConditionalFeeResponse>;
}
/**
 * 
 * @export
 * @interface DeliveryQuoteRequest
 */
export interface DeliveryQuoteRequest {
    /**
     * Delivery quote id
     * @type {string}
     * @memberof DeliveryQuoteRequest
     */
    'id': string;
    /**
     * Delivery quote fees
     * @type {DeliveryQuoteFeesRequest}
     * @memberof DeliveryQuoteRequest
     */
    'fees': DeliveryQuoteFeesRequest;
    /**
     * Delivery quote duration
     * @type {DeliveryQuoteDurationRequest}
     * @memberof DeliveryQuoteRequest
     */
    'duration': DeliveryQuoteDurationRequest;
    /**
     * Delivery quote expiration date
     * @type {string}
     * @memberof DeliveryQuoteRequest
     */
    'expiresAt': string;
}
/**
 * 
 * @export
 * @interface DeliveryQuoteResponse
 */
export interface DeliveryQuoteResponse {
    /**
     * ID of the quote
     * @type {string}
     * @memberof DeliveryQuoteResponse
     */
    'id': string;
    /**
     * Delivery fees
     * @type {DeliveryQuoteFeesResponse}
     * @memberof DeliveryQuoteResponse
     */
    'fees': DeliveryQuoteFeesResponse;
    /**
     * Time that will take to make the order
     * @type {DeliveryQuoteDurationResponse}
     * @memberof DeliveryQuoteResponse
     */
    'duration': DeliveryQuoteDurationResponse;
    /**
     * Time when the order expires
     * @type {string}
     * @memberof DeliveryQuoteResponse
     */
    'expiresAt': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DiscountItemsCampaign = {
    FlashOffer: 'FlashOffer',
    FreeProduct: 'FreeProduct',
    Custom: 'Custom',
    Buy1Get1: 'Buy1Get1',
    GoodDeals: 'GoodDeals'
} as const;

export type DiscountItemsCampaign = typeof DiscountItemsCampaign[keyof typeof DiscountItemsCampaign];


/**
 * 
 * @export
 * @interface DiscountResponse
 */
export interface DiscountResponse {
    /**
     * ID of the discount
     * @type {string}
     * @memberof DiscountResponse
     */
    'id': string;
    /**
     * ID of the company where it\'s created
     * @type {string}
     * @memberof DiscountResponse
     */
    'companyId': string;
    /**
     * ID of the region to which belongs
     * @type {string}
     * @memberof DiscountResponse
     */
    'regionId'?: string;
    /**
     * IDs of the restaurants that can use publish discount
     * @type {Array<string>}
     * @memberof DiscountResponse
     */
    'restaurantIds'?: Array<string>;
    /**
     * Title of the discount
     * @type {string}
     * @memberof DiscountResponse
     */
    'title': string;
    /**
     * Whether the discount is automatic, applied without code
     * @type {boolean}
     * @memberof DiscountResponse
     */
    'automatic': boolean;
    /**
     * Code of the discount
     * @type {string}
     * @memberof DiscountResponse
     */
    'code'?: string;
    /**
     * 
     * @type {DiscountType}
     * @memberof DiscountResponse
     */
    'type': DiscountType;
    /**
     * 
     * @type {DiscountTarget}
     * @memberof DiscountResponse
     */
    'target': DiscountTarget;
    /**
     * Whether the discount is enabled
     * @type {boolean}
     * @memberof DiscountResponse
     */
    'enabled': boolean;
    /**
     * Value in cents of the discount (quantity to be discounted)
     * @type {number}
     * @memberof DiscountResponse
     */
    'value': number;
    /**
     * Whether the discount is a support discount
     * @type {boolean}
     * @memberof DiscountResponse
     */
    'isSupport': boolean;
    /**
     * ID of the order if the discount is a support one
     * @type {string}
     * @memberof DiscountResponse
     */
    'order'?: string;
    /**
     * Order types where this discount can be applied
     * @type {Array<OrderType>}
     * @memberof DiscountResponse
     */
    'orderTypes'?: Array<OrderType>;
    /**
     * Platforms where this discount can be applied
     * @type {Array<OrderType>}
     * @memberof DiscountResponse
     */
    'platforms'?: Array<OrderType>;
    /**
     * Min price in cents needed to apply the discount
     * @type {number}
     * @memberof DiscountResponse
     */
    'minPrice'?: number;
    /**
     * Max discount to apply to the final price
     * @type {number}
     * @memberof DiscountResponse
     */
    'maxDiscount'?: number;
    /**
     * Whether the discount is restricted to some hours
     * @type {boolean}
     * @memberof DiscountResponse
     */
    'restrictHours': boolean;
    /**
     * Daily hours when the discount can be applied.
     * @type {DailyActiveHoursRequest}
     * @memberof DiscountResponse
     */
    'hours'?: DailyActiveHoursRequest;
    /**
     * Max times the discount can be used
     * @type {number}
     * @memberof DiscountResponse
     */
    'maxUses'?: number;
    /**
     * Total max number of uses in any restaurant
     * @type {number}
     * @memberof DiscountResponse
     */
    'maxUsesTotal': number;
    /**
     * Max times the discount can be used by the same customer
     * @type {number}
     * @memberof DiscountResponse
     */
    'maxUsesPerCustomer'?: number;
    /**
     * Max times the discount can be used by the same order
     * @type {number}
     * @memberof DiscountResponse
     */
    'maxUsesPerOrder'?: number;
    /**
     * Total number of uses in all the restaurants
     * @type {number}
     * @memberof DiscountResponse
     */
    'uses': number;
    /**
     * Whether the discount is usable in all the restaurants of the company
     * @type {boolean}
     * @memberof DiscountResponse
     */
    'usableInAllRestaurants'?: boolean;
    /**
     * IDs of the restaurants where the discount is published
     * @type {Array<string>}
     * @memberof DiscountResponse
     */
    'publishedRestaurantIds': Array<string>;
    /**
     * Discount information for the restaurants in the company
     * @type {{ [key: string]: DiscountRestaurantDataResponse; }}
     * @memberof DiscountResponse
     */
    'restaurantsData': { [key: string]: DiscountRestaurantDataResponse; };
    /**
     * 
     * @type {DiscountItemsCampaign}
     * @memberof DiscountResponse
     */
    'itemsCampaign'?: DiscountItemsCampaign;
    /**
     * Items that will be discounted if the conditions are met
     * @type {Array<string>}
     * @memberof DiscountResponse
     */
    'itemIds'?: Array<string>;
    /**
     * Items that trigger (condition) the discount
     * @type {Array<string>}
     * @memberof DiscountResponse
     */
    'triggerItemIds'?: Array<string>;
    /**
     * Number of trigger items to get in order to get the discount for the targets
     * @type {number}
     * @memberof DiscountResponse
     */
    'triggerQuantity'?: number;
    /**
     * Date when it was created
     * @type {string}
     * @memberof DiscountResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof DiscountResponse
     */
    'updated': string;
    /**
     * Date when the discount was archived
     * @type {string}
     * @memberof DiscountResponse
     */
    'archivedAt': string;
}
/**
 * 
 * @export
 * @interface DiscountRestaurantDataResponse
 */
export interface DiscountRestaurantDataResponse {
    /**
     * Date when it was created
     * @type {string}
     * @memberof DiscountRestaurantDataResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof DiscountRestaurantDataResponse
     */
    'updated': string;
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof DiscountRestaurantDataResponse
     */
    'restaurantId': string;
    /**
     * Date when the discount starts
     * @type {string}
     * @memberof DiscountRestaurantDataResponse
     */
    'startsAt': string;
    /**
     * Date when the discount expires
     * @type {string}
     * @memberof DiscountRestaurantDataResponse
     */
    'expiresAt': string;
    /**
     * Number of uses in the restaurant
     * @type {number}
     * @memberof DiscountRestaurantDataResponse
     */
    'uses': number;
    /**
     * Number of uses in the restaurant
     * @type {number}
     * @memberof DiscountRestaurantDataResponse
     */
    'totalUses': number;
    /**
     * Date when the discount was archived for the restaurant
     * @type {string}
     * @memberof DiscountRestaurantDataResponse
     */
    'archivedAt': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DiscountTarget = {
    Total: 'total',
    Items: 'items',
    Item: 'item',
    Delivery: 'delivery'
} as const;

export type DiscountTarget = typeof DiscountTarget[keyof typeof DiscountTarget];


/**
 * 
 * @export
 * @enum {string}
 */

export const DiscountType = {
    Percent: 'percent',
    Amount: 'amount',
    Credit: 'credit'
} as const;

export type DiscountType = typeof DiscountType[keyof typeof DiscountType];


/**
 * 
 * @export
 * @interface EatInOptionsResponse
 */
export interface EatInOptionsResponse {
    /**
     * Whether the mode is enabled or not.
     * @type {boolean}
     * @memberof EatInOptionsResponse
     */
    'isEnabled': boolean;
    /**
     * Fee in cents for using the mode
     * @type {number}
     * @memberof EatInOptionsResponse
     */
    'fee': number;
    /**
     * 
     * @type {DailyActiveHoursResponse}
     * @memberof EatInOptionsResponse
     */
    'hours': DailyActiveHoursResponse;
    /**
     * Whether the table id must be selected when ordering
     * @type {boolean}
     * @memberof EatInOptionsResponse
     */
    'selectTableId': boolean;
}
/**
 * 
 * @export
 * @interface EntitySourceResponse
 */
export interface EntitySourceResponse {
    /**
     * 
     * @type {EntitySourceType}
     * @memberof EntitySourceResponse
     */
    'from': EntitySourceType;
    /**
     * ID of the entity in the source
     * @type {string}
     * @memberof EntitySourceResponse
     */
    'id': string;
    /**
     * Additional data in the source
     * @type {object}
     * @memberof EntitySourceResponse
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EntitySourceType = {
    Zelty: 'zelty',
    Deliverect: 'deliverect'
} as const;

export type EntitySourceType = typeof EntitySourceType[keyof typeof EntitySourceType];


/**
 * 
 * @export
 * @interface FacebookRegisterRequest
 */
export interface FacebookRegisterRequest {
    /**
     * 
     * @type {CreateUserPhoneRequest}
     * @memberof FacebookRegisterRequest
     */
    'phone': CreateUserPhoneRequest;
    /**
     * Whether the user wants to receive offers via email
     * @type {boolean}
     * @memberof FacebookRegisterRequest
     */
    'offersViaEmail': boolean;
    /**
     * Whether the user wants to receive offers via SMS
     * @type {boolean}
     * @memberof FacebookRegisterRequest
     */
    'offersViaSMS': boolean;
    /**
     * Access token to get the user from facebook
     * @type {string}
     * @memberof FacebookRegisterRequest
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface FidelityProgramResponse
 */
export interface FidelityProgramResponse {
    /**
     * Whether the fidelity program is active
     * @type {boolean}
     * @memberof FidelityProgramResponse
     */
    'enabled': boolean;
    /**
     * Amount in cents to gain one point
     * @type {number}
     * @memberof FidelityProgramResponse
     */
    'pointValue': number;
    /**
     * Steps of available products by category
     * @type {Array<FidelityProgramStepResponse>}
     * @memberof FidelityProgramResponse
     */
    'steps': Array<FidelityProgramStepResponse>;
    /**
     * Minimum order price to collect fidelity points
     * @type {number}
     * @memberof FidelityProgramResponse
     */
    'minOrderPrice'?: number;
    /**
     * Maximum points collectable with an order
     * @type {number}
     * @memberof FidelityProgramResponse
     */
    'maxOrderPoints'?: number;
    /**
     * Maximum points to be accumulated by a user
     * @type {number}
     * @memberof FidelityProgramResponse
     */
    'maxAccumulatedPoints'?: number;
}
/**
 * 
 * @export
 * @interface FidelityProgramStepCategoryResponse
 */
export interface FidelityProgramStepCategoryResponse {
    /**
     * Id of the category
     * @type {string}
     * @memberof FidelityProgramStepCategoryResponse
     */
    'categoryId'?: string;
    /**
     * Category
     * @type {CategoryResponse}
     * @memberof FidelityProgramStepCategoryResponse
     */
    'category': CategoryResponse;
    /**
     * Ids of items available in this category
     * @type {Array<string>}
     * @memberof FidelityProgramStepCategoryResponse
     */
    'itemIds': Array<string>;
    /**
     * Minimum number of points to buy items of this step
     * @type {Array<ItemResponse>}
     * @memberof FidelityProgramStepCategoryResponse
     */
    'items'?: Array<ItemResponse>;
}
/**
 * 
 * @export
 * @interface FidelityProgramStepResponse
 */
export interface FidelityProgramStepResponse {
    /**
     * Title of the step
     * @type {LocalizedFieldResponse}
     * @memberof FidelityProgramStepResponse
     */
    'title': LocalizedFieldResponse;
    /**
     * Minimum number of points to buy products of this step
     * @type {number}
     * @memberof FidelityProgramStepResponse
     */
    'minPoints': number;
    /**
     * Category and products available in this step
     * @type {Array<FidelityProgramStepCategoryResponse>}
     * @memberof FidelityProgramStepResponse
     */
    'categories': Array<FidelityProgramStepCategoryResponse>;
}
/**
 * 
 * @export
 * @interface GuestRequest
 */
export interface GuestRequest {
    /**
     * First name of the guest user
     * @type {string}
     * @memberof GuestRequest
     */
    'firstName': string;
    /**
     * Last name of the guest user
     * @type {string}
     * @memberof GuestRequest
     */
    'lastName'?: string;
    /**
     * Email of the guest user
     * @type {string}
     * @memberof GuestRequest
     */
    'email'?: string;
    /**
     * Phone number the guest user
     * @type {string}
     * @memberof GuestRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {Language}
     * @memberof GuestRequest
     */
    'language'?: Language;
}
/**
 * 
 * @export
 * @interface GuestResponse
 */
export interface GuestResponse {
    /**
     * ID of the user
     * @type {string}
     * @memberof GuestResponse
     */
    'id': string;
    /**
     * First name of the user
     * @type {string}
     * @memberof GuestResponse
     */
    'firstName': string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof GuestResponse
     */
    'lastName': string;
    /**
     * Email of the user
     * @type {string}
     * @memberof GuestResponse
     */
    'email': string;
    /**
     * Phone number the user
     * @type {string}
     * @memberof GuestResponse
     */
    'phone': string;
    /**
     * Language of the guest user
     * @type {string}
     * @memberof GuestResponse
     */
    'language': string;
    /**
     * Date when it was created
     * @type {string}
     * @memberof GuestResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof GuestResponse
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    'info'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    'error'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    'details'?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    'info'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    'error'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    'details'?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface InvoicingConfigResponse
 */
export interface InvoicingConfigResponse {
    /**
     * Fee applied on payout in cents (tax excl.)
     * @type {number}
     * @memberof InvoicingConfigResponse
     */
    'payoutFee': number;
    /**
     * Tax rates applied in invoice
     * @type {number}
     * @memberof InvoicingConfigResponse
     */
    'taxRate': number;
    /**
     * Whether the entity is in European Union for VAT
     * @type {boolean}
     * @memberof InvoicingConfigResponse
     */
    'europeanUnion': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ItemDetail = {
    Alcohol: 'alcohol',
    Vegetarian: 'vegetarian',
    Vegan: 'vegan',
    Spicy: 'spicy',
    Halal: 'halal',
    GlutenFree: 'gluten_free',
    Gluten: 'gluten',
    DoNotFreeze: 'do_not_freeze',
    Sulfite: 'sulfite',
    Milk: 'milk',
    Egg: 'egg',
    Sesame: 'sesame',
    Shellfish: 'shellfish',
    Nuts: 'nuts',
    Peanuts: 'peanuts',
    Fish: 'fish',
    Soybean: 'soybean'
} as const;

export type ItemDetail = typeof ItemDetail[keyof typeof ItemDetail];


/**
 * 
 * @export
 * @interface ItemModifierResponse
 */
export interface ItemModifierResponse {
    /**
     * ID of the item modifier
     * @type {string}
     * @memberof ItemModifierResponse
     */
    'id': string;
    /**
     * Company to which it belongs
     * @type {string}
     * @memberof ItemModifierResponse
     */
    'companyId': string;
    /**
     * ID of the region to which belongs
     * @type {string}
     * @memberof ItemModifierResponse
     */
    'regionId'?: string;
    /**
     * Source of the item modifier
     * @type {EntitySourceResponse}
     * @memberof ItemModifierResponse
     */
    'source'?: EntitySourceResponse;
    /**
     * Name of the modifier
     * @type {LocalizedFieldResponse}
     * @memberof ItemModifierResponse
     */
    'name': LocalizedFieldResponse;
    /**
     * Description of the modifier
     * @type {LocalizedFieldResponse}
     * @memberof ItemModifierResponse
     */
    'description': LocalizedFieldResponse;
    /**
     * Stock keeping unit
     * @type {string}
     * @memberof ItemModifierResponse
     */
    'sku'?: string;
    /**
     * Internal name used to differentiate a modifier from another
     * @type {string}
     * @memberof ItemModifierResponse
     */
    'label'?: string;
    /**
     * Min number of items that need to be selected
     * @type {number}
     * @memberof ItemModifierResponse
     */
    'minSelection': number;
    /**
     * Max number of items that can be selected
     * @type {number}
     * @memberof ItemModifierResponse
     */
    'maxSelection': number;
    /**
     * Whether the items can be repeated
     * @type {boolean}
     * @memberof ItemModifierResponse
     */
    'repeatable': boolean;
    /**
     * Items that are selectable in in the modifier
     * @type {Array<string>}
     * @memberof ItemModifierResponse
     */
    'itemIds': Array<string>;
    /**
     * Overrides for the prices of the products; <itemId> => <price>
     * @type {{ [key: string]: number; }}
     * @memberof ItemModifierResponse
     */
    'priceOverrides': { [key: string]: number; };
    /**
     * Overrides for the conditional prices of the products; <itemId> => <conditional prices>
     * @type {{ [key: string]: Array<OrderConditionalNumberValueResponse>; }}
     * @memberof ItemModifierResponse
     */
    'pricesOverrides': { [key: string]: Array<OrderConditionalNumberValueResponse>; };
    /**
     * Date when it was created
     * @type {string}
     * @memberof ItemModifierResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof ItemModifierResponse
     */
    'updated': string;
}
/**
 * 
 * @export
 * @interface ItemResponse
 */
export interface ItemResponse {
    /**
     * ID of the item
     * @type {string}
     * @memberof ItemResponse
     */
    'id': string;
    /**
     * Company to which it belongs
     * @type {string}
     * @memberof ItemResponse
     */
    'companyId': string;
    /**
     * ID of the region to which belongs
     * @type {string}
     * @memberof ItemResponse
     */
    'regionId'?: string;
    /**
     * Source of the item
     * @type {EntitySourceResponse}
     * @memberof ItemResponse
     */
    'source'?: EntitySourceResponse;
    /**
     * Name of the item
     * @type {LocalizedFieldResponse}
     * @memberof ItemResponse
     */
    'name': LocalizedFieldResponse;
    /**
     * Description of the item
     * @type {LocalizedFieldResponse}
     * @memberof ItemResponse
     */
    'description'?: LocalizedFieldResponse;
    /**
     * Whether the item is disabled
     * @type {boolean}
     * @memberof ItemResponse
     */
    'disabled': boolean;
    /**
     * Whether the item is disabled for a given order type
     * @type {CreateItemDisabledForOrderTypeRequest}
     * @memberof ItemResponse
     */
    'disabledForOrderType': CreateItemDisabledForOrderTypeRequest;
    /**
     * Stock keeping unit
     * @type {string}
     * @memberof ItemResponse
     */
    'sku'?: string;
    /**
     * Internal name used to differentiate an item from another
     * @type {string}
     * @memberof ItemResponse
     */
    'label'?: string;
    /**
     * Time needed to prepare the item
     * @type {number}
     * @memberof ItemResponse
     */
    'preparationDuration'?: number;
    /**
     * Color to be used in KDS
     * @type {string}
     * @memberof ItemResponse
     */
    'color'?: string;
    /**
     * Price of the item in cents
     * @type {number}
     * @memberof ItemResponse
     */
    'price': number;
    /**
     * Item prices in different circumstances
     * @type {Array<OrderConditionalNumberValueResponse>}
     * @memberof ItemResponse
     */
    'prices': Array<OrderConditionalNumberValueResponse>;
    /**
     * Taxes rate in percent applied to the product
     * @type {number}
     * @memberof ItemResponse
     */
    'taxRate': number;
    /**
     * Item tax rates in different circumstances
     * @type {Array<OrderConditionalNumberValueResponse>}
     * @memberof ItemResponse
     */
    'taxRates': Array<OrderConditionalNumberValueResponse>;
    /**
     * Tags describing the item
     * @type {Array<ItemTag>}
     * @memberof ItemResponse
     */
    'tags': Array<ItemTag>;
    /**
     * Extra information on the item
     * @type {Array<ItemDetail>}
     * @memberof ItemResponse
     */
    'details': Array<ItemDetail>;
    /**
     * IDs of the categories to which it belongs
     * @type {Array<string>}
     * @memberof ItemResponse
     */
    'categoryIds': Array<string>;
    /**
     * IDs of the modifiers of the product
     * @type {Array<string>}
     * @memberof ItemResponse
     */
    'modifierIds': Array<string>;
    /**
     * IDs of the base items that compose the item
     * @type {Array<string>}
     * @memberof ItemResponse
     */
    'baseItemIds': Array<string>;
    /**
     * Whether the upsell items should be overridden from category
     * @type {boolean}
     * @memberof ItemResponse
     */
    'overrideUpsellItems': boolean;
    /**
     * IDs of the upsell items. Upsell items are offered when a customer wants to purchase a product. e.g. Menu Poke when purchasing a Poke (Menu Poke ID would be in the list)
     * @type {Array<string>}
     * @memberof ItemResponse
     */
    'upsellItemIds': Array<string>;
    /**
     * Whether the cross sell items should be overridden from category 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'overrideCrossSellItems': boolean;
    /**
     * IDs of the cross-sell items. Cross-sell items are offered after purchasing an item. e.g list of desserts after a Menu Poke + Dessert (dessert item IDs would be in the list)
     * @type {Array<string>}
     * @memberof ItemResponse
     */
    'crossSellItemIds': Array<string>;
    /**
     * URL of the image
     * @type {string}
     * @memberof ItemResponse
     */
    'image': string;
    /**
     * Date when it was created
     * @type {string}
     * @memberof ItemResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof ItemResponse
     */
    'updated': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ItemTag = {
    New: 'new',
    Popular: 'popular',
    SpecialEdition: 'special_edition'
} as const;

export type ItemTag = typeof ItemTag[keyof typeof ItemTag];


/**
 * 
 * @export
 * @interface KioskDeviceInfoResponse
 */
export interface KioskDeviceInfoResponse {
    /**
     * Brand of the device
     * @type {string}
     * @memberof KioskDeviceInfoResponse
     */
    'brand': string;
    /**
     * Manufacturer of the device
     * @type {string}
     * @memberof KioskDeviceInfoResponse
     */
    'manufacturer': string;
    /**
     * Device model
     * @type {string}
     * @memberof KioskDeviceInfoResponse
     */
    'model': string;
    /**
     * Product
     * @type {string}
     * @memberof KioskDeviceInfoResponse
     */
    'product': string;
    /**
     * OS version
     * @type {string}
     * @memberof KioskDeviceInfoResponse
     */
    'os': string;
    /**
     * App version name
     * @type {string}
     * @memberof KioskDeviceInfoResponse
     */
    'versionName': string;
    /**
     * App version code
     * @type {string}
     * @memberof KioskDeviceInfoResponse
     */
    'versionCode': string;
}
/**
 * 
 * @export
 * @interface KioskForDeviceResponse
 */
export interface KioskForDeviceResponse {
    /**
     * ID of the kiosk
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'id': string;
    /**
     * Company to which the kiosk belongs
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'companyId': string;
    /**
     * Restaurant to which the kiosk belongs
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'restaurantId': string;
    /**
     * ID of the device
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'deviceId': string;
    /**
     * Token used to connect the device
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'token': string;
    /**
     * Number used to unlock the kiosk
     * @type {number}
     * @memberof KioskForDeviceResponse
     */
    'unlockCode': number;
    /**
     * Name of the kiosk
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'name': string;
    /**
     * Description of the kiosk
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'description': string;
    /**
     * Whether the kiosk accepts ticket restaurant digital
     * @type {boolean}
     * @memberof KioskForDeviceResponse
     */
    'acceptTRD'?: boolean;
    /**
     * TRD cards that must be paid in the cashier
     * @type {Array<KioskTRDBrand>}
     * @memberof KioskForDeviceResponse
     */
    'sendTRDToCashier': Array<KioskTRDBrand>;
    /**
     * Timeout for the connections with the server
     * @type {number}
     * @memberof KioskForDeviceResponse
     */
    'timeout': number;
    /**
     * Whether the kiosk should use animations
     * @type {boolean}
     * @memberof KioskForDeviceResponse
     */
    'useAnimations'?: boolean;
    /**
     * Whether the images should be removed from the ticket
     * @type {boolean}
     * @memberof KioskForDeviceResponse
     */
    'noImagesInTicket'?: boolean;
    /**
     * 
     * @type {KioskDeviceInfoResponse}
     * @memberof KioskForDeviceResponse
     */
    'deviceInfo'?: KioskDeviceInfoResponse;
    /**
     * ID of the associated payment terminal
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'terminalId'?: string;
    /**
     * Date when it was created
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'updated': string;
    /**
     * Token from the company
     * @type {string}
     * @memberof KioskForDeviceResponse
     */
    'companyToken': string;
    /**
     * Languages in the company or region
     * @type {Array<Language>}
     * @memberof KioskForDeviceResponse
     */
    'languages': Array<Language>;
    /**
     * 
     * @type {Language}
     * @memberof KioskForDeviceResponse
     */
    'mainLanguage': Language;
    /**
     * Theme for the company
     * @type {CompanyThemeResponse}
     * @memberof KioskForDeviceResponse
     * @deprecated
     */
    'theme'?: CompanyThemeResponse;
    /**
     * Template for the company
     * @type {TemplateResponse}
     * @memberof KioskForDeviceResponse
     */
    'template'?: TemplateResponse;
    /**
     * 
     * @type {POS}
     * @memberof KioskForDeviceResponse
     */
    'pos'?: POS;
    /**
     * POS data
     * @type {POSDataResponse}
     * @memberof KioskForDeviceResponse
     */
    'posData'?: POSDataResponse;
}
/**
 * 
 * @export
 * @interface KioskLatestVersionResponse
 */
export interface KioskLatestVersionResponse {
    /**
     * Name of the version
     * @type {string}
     * @memberof KioskLatestVersionResponse
     */
    'version': string;
    /**
     * URL of the version
     * @type {string}
     * @memberof KioskLatestVersionResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface KioskResponse
 */
export interface KioskResponse {
    /**
     * ID of the kiosk
     * @type {string}
     * @memberof KioskResponse
     */
    'id': string;
    /**
     * Company to which the kiosk belongs
     * @type {string}
     * @memberof KioskResponse
     */
    'companyId': string;
    /**
     * Restaurant to which the kiosk belongs
     * @type {string}
     * @memberof KioskResponse
     */
    'restaurantId': string;
    /**
     * ID of the device
     * @type {string}
     * @memberof KioskResponse
     */
    'deviceId': string;
    /**
     * Token used to connect the device
     * @type {string}
     * @memberof KioskResponse
     */
    'token': string;
    /**
     * Number used to unlock the kiosk
     * @type {number}
     * @memberof KioskResponse
     */
    'unlockCode': number;
    /**
     * Name of the kiosk
     * @type {string}
     * @memberof KioskResponse
     */
    'name': string;
    /**
     * Description of the kiosk
     * @type {string}
     * @memberof KioskResponse
     */
    'description': string;
    /**
     * Whether the kiosk accepts ticket restaurant digital
     * @type {boolean}
     * @memberof KioskResponse
     */
    'acceptTRD'?: boolean;
    /**
     * TRD cards that must be paid in the cashier
     * @type {Array<KioskTRDBrand>}
     * @memberof KioskResponse
     */
    'sendTRDToCashier': Array<KioskTRDBrand>;
    /**
     * Timeout for the connections with the server
     * @type {number}
     * @memberof KioskResponse
     */
    'timeout': number;
    /**
     * Whether the kiosk should use animations
     * @type {boolean}
     * @memberof KioskResponse
     */
    'useAnimations'?: boolean;
    /**
     * Whether the images should be removed from the ticket
     * @type {boolean}
     * @memberof KioskResponse
     */
    'noImagesInTicket'?: boolean;
    /**
     * 
     * @type {KioskDeviceInfoResponse}
     * @memberof KioskResponse
     */
    'deviceInfo'?: KioskDeviceInfoResponse;
    /**
     * ID of the associated payment terminal
     * @type {string}
     * @memberof KioskResponse
     */
    'terminalId'?: string;
    /**
     * Date when it was created
     * @type {string}
     * @memberof KioskResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof KioskResponse
     */
    'updated': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const KioskTRDBrand = {
    Sodexo: 'sodexo',
    Updejeuner: 'updejeuner',
    Bimpli: 'bimpli',
    Edenred: 'edenred',
    Swile: 'swile'
} as const;

export type KioskTRDBrand = typeof KioskTRDBrand[keyof typeof KioskTRDBrand];


/**
 * 
 * @export
 * @interface LandingPageBackgroundConfigColorResponse
 */
export interface LandingPageBackgroundConfigColorResponse {
    /**
     * 
     * @type {LandingPageBackgroundOption}
     * @memberof LandingPageBackgroundConfigColorResponse
     */
    'option': LandingPageBackgroundOption;
    /**
     * Color of the background
     * @type {string}
     * @memberof LandingPageBackgroundConfigColorResponse
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface LandingPageBackgroundConfigImageResponse
 */
export interface LandingPageBackgroundConfigImageResponse {
    /**
     * 
     * @type {LandingPageBackgroundOption}
     * @memberof LandingPageBackgroundConfigImageResponse
     */
    'option': LandingPageBackgroundOption;
    /**
     * Desktop image
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageBackgroundConfigImageResponse
     */
    'desktop': LocalizedFieldResponse;
    /**
     * Mobile image
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageBackgroundConfigImageResponse
     */
    'mobile': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LandingPageBackgroundOption = {
    Color: 'color',
    Image: 'image'
} as const;

export type LandingPageBackgroundOption = typeof LandingPageBackgroundOption[keyof typeof LandingPageBackgroundOption];


/**
 * 
 * @export
 * @interface LandingPageColorWithOverrideResponse
 */
export interface LandingPageColorWithOverrideResponse {
    /**
     * Whether the color of the text is overridden 
     * @type {boolean}
     * @memberof LandingPageColorWithOverrideResponse
     */
    'overrideColor': boolean;
    /**
     * Overridden color of the text 
     * @type {string}
     * @memberof LandingPageColorWithOverrideResponse
     */
    'color'?: string;
    /**
     * Whether the background color of the text is overridden 
     * @type {boolean}
     * @memberof LandingPageColorWithOverrideResponse
     */
    'overrideBackgroundColor': boolean;
    /**
     * Overridden background color of the text 
     * @type {string}
     * @memberof LandingPageColorWithOverrideResponse
     */
    'backgroundColor'?: string;
}
/**
 * 
 * @export
 * @interface LandingPageConfigResponse
 */
export interface LandingPageConfigResponse {
    /**
     * Whether the landing page is enabled or not
     * @type {boolean}
     * @memberof LandingPageConfigResponse
     */
    'enabled': boolean;
    /**
     * Config of the Landing Page Header
     * @type {LandingPageHeaderConfigResponse}
     * @memberof LandingPageConfigResponse
     */
    'header': LandingPageHeaderConfigResponse;
    /**
     * Config of the Landing Page Content
     * @type {Array<LandingPageContentConfigImageResponse | LandingPageContentConfigQuickOrderResponse | LandingPageContentConfigBannersSliderResponse | LandingPageContentConfigProductGalleryResponse | LandingPageContentConfigInstagramFeedResponse>}
     * @memberof LandingPageConfigResponse
     */
    'content': Array<LandingPageContentConfigImageResponse | LandingPageContentConfigQuickOrderResponse | LandingPageContentConfigBannersSliderResponse | LandingPageContentConfigProductGalleryResponse | LandingPageContentConfigInstagramFeedResponse>;
}
/**
 * 
 * @export
 * @interface LandingPageContentConfigBannersSliderResponse
 */
export interface LandingPageContentConfigBannersSliderResponse {
    /**
     * 
     * @type {LandingPageContentOption}
     * @memberof LandingPageContentConfigBannersSliderResponse
     */
    'option': LandingPageContentOption;
    /**
     * Title config
     * @type {LandingPageLocalizedColorTextResponse}
     * @memberof LandingPageContentConfigBannersSliderResponse
     */
    'title': LandingPageLocalizedColorTextResponse;
    /**
     * Subtitle config
     * @type {LandingPageLocalizedColorTextResponse}
     * @memberof LandingPageContentConfigBannersSliderResponse
     */
    'subtitle': LandingPageLocalizedColorTextResponse;
}
/**
 * 
 * @export
 * @interface LandingPageContentConfigImageResponse
 */
export interface LandingPageContentConfigImageResponse {
    /**
     * 
     * @type {LandingPageContentOption}
     * @memberof LandingPageContentConfigImageResponse
     */
    'option': LandingPageContentOption;
    /**
     * Desktop Image
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageContentConfigImageResponse
     */
    'desktop': LocalizedFieldResponse;
    /**
     * Mobile Image
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageContentConfigImageResponse
     */
    'mobile': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface LandingPageContentConfigInstagramFeedResponse
 */
export interface LandingPageContentConfigInstagramFeedResponse {
    /**
     * 
     * @type {LandingPageContentOption}
     * @memberof LandingPageContentConfigInstagramFeedResponse
     */
    'option': LandingPageContentOption;
    /**
     * CTA config of the InstagramFeed
     * @type {LandingPageContentInstagramCtaConfigResponse}
     * @memberof LandingPageContentConfigInstagramFeedResponse
     */
    'cta': LandingPageContentInstagramCtaConfigResponse;
}
/**
 * 
 * @export
 * @interface LandingPageContentConfigProductGalleryResponse
 */
export interface LandingPageContentConfigProductGalleryResponse {
    /**
     * 
     * @type {LandingPageContentOption}
     * @memberof LandingPageContentConfigProductGalleryResponse
     */
    'option': LandingPageContentOption;
    /**
     * Title of the Product Gallery
     * @type {LandingPageLocalizedColorTextResponse}
     * @memberof LandingPageContentConfigProductGalleryResponse
     */
    'title': LandingPageLocalizedColorTextResponse;
    /**
     * Subtitle of the Product Gallery
     * @type {LandingPageLocalizedColorTextResponse}
     * @memberof LandingPageContentConfigProductGalleryResponse
     */
    'subtitle': LandingPageLocalizedColorTextResponse;
    /**
     * Config of the ProductGallery background
     * @type {LandingPageBackgroundConfigImageResponse | LandingPageBackgroundConfigColorResponse}
     * @memberof LandingPageContentConfigProductGalleryResponse
     */
    'background': LandingPageBackgroundConfigImageResponse | LandingPageBackgroundConfigColorResponse;
    /**
     * CTA of the Product Gallery
     * @type {LandingPageContentProductGalleryCtaConfigResponse}
     * @memberof LandingPageContentConfigProductGalleryResponse
     */
    'cta': LandingPageContentProductGalleryCtaConfigResponse;
    /**
     * Layout of the Landing Page Content Item
     * @type {LandingPageContentProductGalleryLayoutConfigResponse}
     * @memberof LandingPageContentConfigProductGalleryResponse
     */
    'layout': LandingPageContentProductGalleryLayoutConfigResponse;
}
/**
 * 
 * @export
 * @interface LandingPageContentConfigQuickOrderResponse
 */
export interface LandingPageContentConfigQuickOrderResponse {
    /**
     * 
     * @type {LandingPageContentOption}
     * @memberof LandingPageContentConfigQuickOrderResponse
     */
    'option': LandingPageContentOption;
    /**
     * Title config
     * @type {LandingPageLocalizedColorTextResponse}
     * @memberof LandingPageContentConfigQuickOrderResponse
     */
    'title': LandingPageLocalizedColorTextResponse;
    /**
     * Subtitle config
     * @type {LandingPageLocalizedColorTextResponse}
     * @memberof LandingPageContentConfigQuickOrderResponse
     */
    'subtitle': LandingPageLocalizedColorTextResponse;
    /**
     * Config of the QuickOrder background
     * @type {LandingPageBackgroundConfigImageResponse | LandingPageBackgroundConfigColorResponse}
     * @memberof LandingPageContentConfigQuickOrderResponse
     */
    'background': LandingPageBackgroundConfigImageResponse | LandingPageBackgroundConfigColorResponse;
    /**
     * Colors of the Delivery block of the QuickOrder
     * @type {LandingPageColorWithOverrideResponse}
     * @memberof LandingPageContentConfigQuickOrderResponse
     */
    'deliveryButtonColors': LandingPageColorWithOverrideResponse;
    /**
     * Colors of the Pickup block of the QuickOrder
     * @type {LandingPageColorWithOverrideResponse}
     * @memberof LandingPageContentConfigQuickOrderResponse
     */
    'pickupButtonColors': LandingPageColorWithOverrideResponse;
}
/**
 * 
 * @export
 * @interface LandingPageContentInstagramCtaConfigResponse
 */
export interface LandingPageContentInstagramCtaConfigResponse {
    /**
     * Whether the color should be overridden
     * @type {boolean}
     * @memberof LandingPageContentInstagramCtaConfigResponse
     */
    'overrideColor': boolean;
    /**
     * Color of the text
     * @type {string}
     * @memberof LandingPageContentInstagramCtaConfigResponse
     */
    'color'?: string;
    /**
     * Whether the background color should be overridden
     * @type {boolean}
     * @memberof LandingPageContentInstagramCtaConfigResponse
     */
    'overrideBackgroundColor': boolean;
    /**
     * Background color
     * @type {string}
     * @memberof LandingPageContentInstagramCtaConfigResponse
     */
    'backgroundColor'?: string;
    /**
     * Title of the link
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageContentInstagramCtaConfigResponse
     */
    'text': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LandingPageContentOption = {
    QuickOrder: 'quickOrder',
    BannersSlider: 'bannersSlider',
    Image: 'image',
    ProductGallery: 'productGallery',
    InstagramFeed: 'instagramFeed'
} as const;

export type LandingPageContentOption = typeof LandingPageContentOption[keyof typeof LandingPageContentOption];


/**
 * 
 * @export
 * @interface LandingPageContentProductGalleryCtaConfigResponse
 */
export interface LandingPageContentProductGalleryCtaConfigResponse {
    /**
     * Whether the color should be overridden
     * @type {boolean}
     * @memberof LandingPageContentProductGalleryCtaConfigResponse
     */
    'overrideColor': boolean;
    /**
     * Color of the text
     * @type {string}
     * @memberof LandingPageContentProductGalleryCtaConfigResponse
     */
    'color'?: string;
    /**
     * Whether the background color should be overridden
     * @type {boolean}
     * @memberof LandingPageContentProductGalleryCtaConfigResponse
     */
    'overrideBackgroundColor': boolean;
    /**
     * Background color
     * @type {string}
     * @memberof LandingPageContentProductGalleryCtaConfigResponse
     */
    'backgroundColor'?: string;
    /**
     * URL of the cta
     * @type {string}
     * @memberof LandingPageContentProductGalleryCtaConfigResponse
     */
    'url': string;
    /**
     * Title of the link
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageContentProductGalleryCtaConfigResponse
     */
    'text': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LandingPageContentProductGalleryLayout = {
    Picture4Products: 'picture4Products',
    Picture2Products: 'picture2Products',
    NoPicture4Products: 'noPicture4Products'
} as const;

export type LandingPageContentProductGalleryLayout = typeof LandingPageContentProductGalleryLayout[keyof typeof LandingPageContentProductGalleryLayout];


/**
 * 
 * @export
 * @interface LandingPageContentProductGalleryLayoutConfigResponse
 */
export interface LandingPageContentProductGalleryLayoutConfigResponse {
    /**
     * 
     * @type {LandingPageContentProductGalleryLayout}
     * @memberof LandingPageContentProductGalleryLayoutConfigResponse
     */
    'option': LandingPageContentProductGalleryLayout;
    /**
     * Type of Product Gallery
     * @type {Array<string>}
     * @memberof LandingPageContentProductGalleryLayoutConfigResponse
     */
    'itemIds': Array<string>;
    /**
     * Image of the Product Gallery
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageContentProductGalleryLayoutConfigResponse
     */
    'picture'?: LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface LandingPageHeaderBarColorsConfigResponse
 */
export interface LandingPageHeaderBarColorsConfigResponse {
    /**
     * Whether the Header bar background color is overridden
     * @type {boolean}
     * @memberof LandingPageHeaderBarColorsConfigResponse
     */
    'overrideBackgroundColor': boolean;
    /**
     * Header bar Background color
     * @type {string}
     * @memberof LandingPageHeaderBarColorsConfigResponse
     */
    'backgroundColor'?: string;
    /**
     * Whether the Header bar color is overridden
     * @type {boolean}
     * @memberof LandingPageHeaderBarColorsConfigResponse
     */
    'overrideColor': boolean;
    /**
     * Header bar color
     * @type {string}
     * @memberof LandingPageHeaderBarColorsConfigResponse
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface LandingPageHeaderBarConfigResponse
 */
export interface LandingPageHeaderBarConfigResponse {
    /**
     * Header base bar config
     * @type {LandingPageHeaderBarColorsConfigResponse}
     * @memberof LandingPageHeaderBarConfigResponse
     */
    'base': LandingPageHeaderBarColorsConfigResponse;
    /**
     * Header navigation button config
     * @type {LandingPageHeaderBarColorsConfigResponse}
     * @memberof LandingPageHeaderBarConfigResponse
     */
    'navigationButton': LandingPageHeaderBarColorsConfigResponse;
}
/**
 * 
 * @export
 * @interface LandingPageHeaderConfigResponse
 */
export interface LandingPageHeaderConfigResponse {
    /**
     * Landing Page Header link\"s config
     * @type {Array<LandingPageHeaderLinkConfigResponse>}
     * @memberof LandingPageHeaderConfigResponse
     */
    'links': Array<LandingPageHeaderLinkConfigResponse>;
    /**
     * Landing Page Header base config
     * @type {LandingPageHeaderBarConfigResponse}
     * @memberof LandingPageHeaderConfigResponse
     */
    'fixed': LandingPageHeaderBarConfigResponse;
    /**
     * Landing Page Header sticky config
     * @type {LandingPageHeaderBarConfigResponse}
     * @memberof LandingPageHeaderConfigResponse
     */
    'sticky': LandingPageHeaderBarConfigResponse;
    /**
     * Landing Page Header top config
     * @type {LandingPageHeaderTopConfigResponse}
     * @memberof LandingPageHeaderConfigResponse
     */
    'top': LandingPageHeaderTopConfigResponse;
}
/**
 * 
 * @export
 * @interface LandingPageHeaderLinkConfigResponse
 */
export interface LandingPageHeaderLinkConfigResponse {
    /**
     * URL of the link
     * @type {string}
     * @memberof LandingPageHeaderLinkConfigResponse
     */
    'url': string;
    /**
     * Title of the link
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageHeaderLinkConfigResponse
     */
    'text': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface LandingPageHeaderTopBaseConfigResponse
 */
export interface LandingPageHeaderTopBaseConfigResponse {
    /**
     * Whether the Header top background color is overridden
     * @type {boolean}
     * @memberof LandingPageHeaderTopBaseConfigResponse
     */
    'overrideBackgroundColor': boolean;
    /**
     * Header top Background color
     * @type {string}
     * @memberof LandingPageHeaderTopBaseConfigResponse
     */
    'backgroundColor'?: string;
    /**
     * Whether the Header top color is overridden
     * @type {boolean}
     * @memberof LandingPageHeaderTopBaseConfigResponse
     */
    'overrideColor': boolean;
    /**
     * Header top color
     * @type {string}
     * @memberof LandingPageHeaderTopBaseConfigResponse
     */
    'color'?: string;
    /**
     * Header top text
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageHeaderTopBaseConfigResponse
     */
    'text': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface LandingPageHeaderTopCTAConfigResponse
 */
export interface LandingPageHeaderTopCTAConfigResponse {
    /**
     * Whether the Header top cta background color is overridden
     * @type {boolean}
     * @memberof LandingPageHeaderTopCTAConfigResponse
     */
    'overrideBackgroundColor': boolean;
    /**
     * Header top cta Background color
     * @type {string}
     * @memberof LandingPageHeaderTopCTAConfigResponse
     */
    'backgroundColor'?: string;
    /**
     * Whether the Header top cta color is overridden
     * @type {boolean}
     * @memberof LandingPageHeaderTopCTAConfigResponse
     */
    'overrideColor': boolean;
    /**
     * Header top cta color
     * @type {string}
     * @memberof LandingPageHeaderTopCTAConfigResponse
     */
    'color'?: string;
    /**
     * Header top cta text
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageHeaderTopCTAConfigResponse
     */
    'text': LocalizedFieldResponse;
    /**
     * Header top cta URL
     * @type {string}
     * @memberof LandingPageHeaderTopCTAConfigResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface LandingPageHeaderTopConfigResponse
 */
export interface LandingPageHeaderTopConfigResponse {
    /**
     * Whether the top header is enabled
     * @type {boolean}
     * @memberof LandingPageHeaderTopConfigResponse
     */
    'enabled': boolean;
    /**
     * Top header base config
     * @type {LandingPageHeaderTopBaseConfigResponse}
     * @memberof LandingPageHeaderTopConfigResponse
     */
    'base'?: LandingPageHeaderTopBaseConfigResponse;
    /**
     * Top header cta config
     * @type {LandingPageHeaderTopCTAConfigResponse}
     * @memberof LandingPageHeaderTopConfigResponse
     */
    'cta'?: LandingPageHeaderTopCTAConfigResponse;
}
/**
 * 
 * @export
 * @interface LandingPageItemResponse
 */
export interface LandingPageItemResponse {
    /**
     * ID of the item
     * @type {string}
     * @memberof LandingPageItemResponse
     */
    'id': string;
    /**
     * Name of the item
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageItemResponse
     */
    'name': LocalizedFieldResponse;
    /**
     * Price of the item in cents
     * @type {number}
     * @memberof LandingPageItemResponse
     */
    'price': number;
    /**
     * URL of the image
     * @type {string}
     * @memberof LandingPageItemResponse
     */
    'image': string;
}
/**
 * 
 * @export
 * @interface LandingPageLocalizedColorTextResponse
 */
export interface LandingPageLocalizedColorTextResponse {
    /**
     * Text
     * @type {LocalizedFieldResponse}
     * @memberof LandingPageLocalizedColorTextResponse
     */
    'text': LocalizedFieldResponse;
    /**
     * Whether the color should be overridden
     * @type {boolean}
     * @memberof LandingPageLocalizedColorTextResponse
     */
    'overrideColor': boolean;
    /**
     * Color of the text
     * @type {string}
     * @memberof LandingPageLocalizedColorTextResponse
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface LandingPageRegionConfigResponse
 */
export interface LandingPageRegionConfigResponse {
    /**
     * The Company Id
     * @type {string}
     * @memberof LandingPageRegionConfigResponse
     */
    'companyId': string;
    /**
     * The Company Region Id
     * @type {string}
     * @memberof LandingPageRegionConfigResponse
     */
    'companyRegionId': string;
    /**
     * Config of the Landing Page
     * @type {LandingPageConfigResponse}
     * @memberof LandingPageRegionConfigResponse
     */
    'landingPageConfig'?: LandingPageConfigResponse;
    /**
     * Items of the Landing Page
     * @type {Array<LandingPageItemResponse>}
     * @memberof LandingPageRegionConfigResponse
     */
    'items': Array<LandingPageItemResponse>;
    /**
     * Posts of the Instagram Feed
     * @type {Array<CompanyRegionInstagramPostResponse>}
     * @memberof LandingPageRegionConfigResponse
     */
    'instagramFeedPosts': Array<CompanyRegionInstagramPostResponse>;
    /**
     * Instagram username
     * @type {string}
     * @memberof LandingPageRegionConfigResponse
     */
    'instagramUsername': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Language = {
    Fr: 'fr',
    En: 'en',
    Es: 'es',
    Pt: 'pt',
    Ca: 'ca',
    Nl: 'nl'
} as const;

export type Language = typeof Language[keyof typeof Language];


/**
 * 
 * @export
 * @interface LocalizedFieldResponse
 */
export interface LocalizedFieldResponse {
    /**
     * Default value
     * @type {string}
     * @memberof LocalizedFieldResponse
     */
    'default'?: string;
    /**
     * Field value in french
     * @type {string}
     * @memberof LocalizedFieldResponse
     */
    'fr'?: string;
    /**
     * Field value in english
     * @type {string}
     * @memberof LocalizedFieldResponse
     */
    'en'?: string;
    /**
     * Field value in spanish
     * @type {string}
     * @memberof LocalizedFieldResponse
     */
    'es'?: string;
    /**
     * Field value in portuguese
     * @type {string}
     * @memberof LocalizedFieldResponse
     */
    'pt'?: string;
    /**
     * Field value in catalan
     * @type {string}
     * @memberof LocalizedFieldResponse
     */
    'ca'?: string;
    /**
     * Field value in dutch
     * @type {string}
     * @memberof LocalizedFieldResponse
     */
    'nl'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * The username
     * @type {string}
     * @memberof LoginRequest
     */
    'username': string;
    /**
     * The password
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface MealPaymentBalanceRequest
 */
export interface MealPaymentBalanceRequest {
    /**
     * Whether the meal payment has enough balance to make this payment
     * @type {boolean}
     * @memberof MealPaymentBalanceRequest
     */
    'needsSecondPayment': boolean;
    /**
     * Amount that needs to be payed with the meal payment
     * @type {number}
     * @memberof MealPaymentBalanceRequest
     */
    'mealPaymentAmount'?: number;
    /**
     * Amount that needs to be payed with other meal payment 
     * @type {number}
     * @memberof MealPaymentBalanceRequest
     */
    'secondPaymentAmount'?: number;
}
/**
 * 
 * @export
 * @interface MealPaymentBalanceResponse
 */
export interface MealPaymentBalanceResponse {
    /**
     * Whether the meal payment has enough balance to make this payment
     * @type {boolean}
     * @memberof MealPaymentBalanceResponse
     */
    'needsSecondPayment': boolean;
    /**
     * Amount that needs to be payed with the meal payment
     * @type {number}
     * @memberof MealPaymentBalanceResponse
     */
    'mealPaymentAmount'?: number;
    /**
     * Amount that needs to be payed with other meal payment 
     * @type {number}
     * @memberof MealPaymentBalanceResponse
     */
    'secondPaymentAmount'?: number;
}
/**
 * 
 * @export
 * @interface OrderByTraceDataResponse
 */
export interface OrderByTraceDataResponse {
    /**
     * 
     * @type {OrderTraceStatus}
     * @memberof OrderByTraceDataResponse
     */
    'status': OrderTraceStatus;
    /**
     * Order attached to the order trace
     * @type {OrderPublicResponse}
     * @memberof OrderByTraceDataResponse
     */
    'order'?: OrderPublicResponse;
}
/**
 * 
 * @export
 * @interface OrderConditionalNumberValueResponse
 */
export interface OrderConditionalNumberValueResponse {
    /**
     * 
     * @type {OrderType}
     * @memberof OrderConditionalNumberValueResponse
     */
    'orderType'?: OrderType;
    /**
     * 
     * @type {DeliveryCompany}
     * @memberof OrderConditionalNumberValueResponse
     */
    'deliveryCompany'?: DeliveryCompany;
    /**
     * 
     * @type {OrderPlatform}
     * @memberof OrderConditionalNumberValueResponse
     */
    'platform'?: OrderPlatform;
    /**
     * Value to use when the conditions are met
     * @type {number}
     * @memberof OrderConditionalNumberValueResponse
     */
    'value': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderPlatform = {
    Kiosk: 'kiosk',
    Qr: 'qr',
    App: 'app',
    AppiOs: 'app:iOS',
    AppAndroid: 'app:Android',
    Web: 'web',
    Pos: 'pos',
    External: 'external'
} as const;

export type OrderPlatform = typeof OrderPlatform[keyof typeof OrderPlatform];


/**
 * 
 * @export
 * @interface OrderPublicResponse
 */
export interface OrderPublicResponse {
    /**
     * ID of order
     * @type {string}
     * @memberof OrderPublicResponse
     */
    'id': string;
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrderPublicResponse
     */
    'restaurantId': string;
    /**
     * 
     * @type {GuestResponse}
     * @memberof OrderPublicResponse
     */
    'guest'?: GuestResponse;
    /**
     * 
     * @type {UserResponse}
     * @memberof OrderPublicResponse
     */
    'user'?: UserResponse;
    /**
     * ID of the table, in case of eat in
     * @type {string}
     * @memberof OrderPublicResponse
     */
    'tableId'?: string;
    /**
     * Note from the user
     * @type {string}
     * @memberof OrderPublicResponse
     */
    'note': string;
    /**
     * Whether cutlery is needed 
     * @type {boolean}
     * @memberof OrderPublicResponse
     */
    'cutlery': boolean;
    /**
     * Fidelity points accumulated with the order
     * @type {number}
     * @memberof OrderPublicResponse
     */
    'fidelityPoints': number;
    /**
     * Order anonymous token
     * @type {string}
     * @memberof OrderPublicResponse
     */
    'anonymousToken': string;
    /**
     * Number of the order from the POS
     * @type {string}
     * @memberof OrderPublicResponse
     */
    'posOrderNumber': string;
    /**
     * ID of the order from the POS
     * @type {string}
     * @memberof OrderPublicResponse
     */
    'posOrderId': string;
    /**
     * Date when the order needs to be prepared
     * @type {string}
     * @memberof OrderPublicResponse
     */
    'dueDate': string;
    /**
     * Items that compose the order
     * @type {Array<CartItemResponse>}
     * @memberof OrderPublicResponse
     */
    'items': Array<CartItemResponse>;
    /**
     * Populated item
     * @type {Array<ItemResponse>}
     * @memberof OrderPublicResponse
     */
    'populatedItems'?: Array<ItemResponse>;
    /**
     * Populated item modifier
     * @type {Array<ItemModifierResponse>}
     * @memberof OrderPublicResponse
     */
    'populatedModifiers'?: Array<ItemModifierResponse>;
    /**
     * 
     * @type {OrderType}
     * @memberof OrderPublicResponse
     */
    'type': OrderType;
    /**
     * 
     * @type {DeliveryCompany}
     * @memberof OrderPublicResponse
     */
    'deliveryCompany'?: DeliveryCompany;
    /**
     * Data for the current order delivery if exists
     * @type {DeliveryOrderDataResponse}
     * @memberof OrderPublicResponse
     */
    'deliveryOrderData'?: DeliveryOrderDataResponse;
    /**
     * Address used for the delivery
     * @type {AddressResponse}
     * @memberof OrderPublicResponse
     */
    'deliveryAddress'?: AddressResponse;
    /**
     * Delivery quote for the order
     * @type {DeliveryQuoteResponse}
     * @memberof OrderPublicResponse
     */
    'deliveryQuote'?: DeliveryQuoteResponse;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderPublicResponse
     */
    'status': OrderStatus;
    /**
     * 
     * @type {CartTotalsResponse}
     * @memberof OrderPublicResponse
     */
    'totals': CartTotalsResponse;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof OrderPublicResponse
     */
    'paymentMethod': PaymentMethod;
    /**
     * Date when it was created
     * @type {string}
     * @memberof OrderPublicResponse
     */
    'created': string;
    /**
     * 
     * @type {Array<TransactionPublicResponse>}
     * @memberof OrderPublicResponse
     */
    'transactions': Array<TransactionPublicResponse>;
    /**
     * 
     * @type {RestaurantPublicResponse}
     * @memberof OrderPublicResponse
     */
    'restaurant': RestaurantPublicResponse;
}
/**
 * 
 * @export
 * @interface OrderResultActionResponse
 */
export interface OrderResultActionResponse {
    /**
     * 
     * @type {OrderResultTypeAdyenActionRequired}
     * @memberof OrderResultActionResponse
     */
    'type': OrderResultTypeAdyenActionRequired;
    /**
     * Order trace data
     * @type {OrderTraceDataResponse}
     * @memberof OrderResultActionResponse
     */
    'trace': OrderTraceDataResponse;
    /**
     * Adyen action
     * @type {object}
     * @memberof OrderResultActionResponse
     */
    'action': object;
}
/**
 * 
 * @export
 * @interface OrderResultOrderResponse
 */
export interface OrderResultOrderResponse {
    /**
     * 
     * @type {OrderResultTypeOrder}
     * @memberof OrderResultOrderResponse
     */
    'type': OrderResultTypeOrder;
    /**
     * Order
     * @type {OrderPublicResponse}
     * @memberof OrderResultOrderResponse
     */
    'order': OrderPublicResponse;
}
/**
 * 
 * @export
 * @interface OrderResultResponse
 */
export interface OrderResultResponse {
    /**
     * 
     * @type {OrderResultOrderResponse | OrderResultActionResponse}
     * @memberof OrderResultResponse
     */
    'result': OrderResultOrderResponse | OrderResultActionResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderResultTypeAdyenActionRequired = {
    AdyenActionRequired: 'adyenActionRequired'
} as const;

export type OrderResultTypeAdyenActionRequired = typeof OrderResultTypeAdyenActionRequired[keyof typeof OrderResultTypeAdyenActionRequired];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderResultTypeOrder = {
    Order: 'order'
} as const;

export type OrderResultTypeOrder = typeof OrderResultTypeOrder[keyof typeof OrderResultTypeOrder];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatus = {
    Waiting: 'waiting',
    Preorder: 'preorder',
    Rejected: 'rejected',
    Unpaid: 'unpaid',
    New: 'new',
    InProgress: 'inProgress',
    Delivering: 'delivering',
    Prepared: 'prepared',
    Finished: 'finished',
    Cancelled: 'Cancelled',
    Failed: 'failed',
    Refunded: 'refunded',
    PartiallyRefunded: 'partially-refunded'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 
 * @export
 * @interface OrderTraceDataResponse
 */
export interface OrderTraceDataResponse {
    /**
     * ID of the trace
     * @type {string}
     * @memberof OrderTraceDataResponse
     */
    'id': string;
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrderTraceDataResponse
     */
    'restaurantId': string;
    /**
     * Token to identify an order trace
     * @type {string}
     * @memberof OrderTraceDataResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderTraceStatus = {
    Processing: 'processing',
    Finished: 'finished',
    Failed: 'failed',
    Refunded: 'refunded',
    RefundPending: 'refundPending'
} as const;

export type OrderTraceStatus = typeof OrderTraceStatus[keyof typeof OrderTraceStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderType = {
    PickUp: 'pickUp',
    Delivery: 'delivery',
    EatIn: 'eatIn',
    Table: 'table'
} as const;

export type OrderType = typeof OrderType[keyof typeof OrderType];


/**
 * 
 * @export
 * @interface OrderTypeOptionsResponse
 */
export interface OrderTypeOptionsResponse {
    /**
     * Whether the mode is enabled or not.
     * @type {boolean}
     * @memberof OrderTypeOptionsResponse
     */
    'isEnabled': boolean;
    /**
     * Fee in cents for using the mode
     * @type {number}
     * @memberof OrderTypeOptionsResponse
     */
    'fee': number;
    /**
     * 
     * @type {DailyActiveHoursResponse}
     * @memberof OrderTypeOptionsResponse
     */
    'hours': DailyActiveHoursResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const POS = {
    Zelty: 'zelty',
    Belorder: 'belorder',
    Zeshop: 'zeshop',
    Deliverect: 'deliverect'
} as const;

export type POS = typeof POS[keyof typeof POS];


/**
 * 
 * @export
 * @interface POSDataResponse
 */
export interface POSDataResponse {
    /**
     * Data for zelty
     * @type {ZeltyDataResponse}
     * @memberof POSDataResponse
     */
    'zelty'?: ZeltyDataResponse;
    /**
     * Data for zelty
     * @type {ZeshopDataResponse}
     * @memberof POSDataResponse
     */
    'zeshop'?: ZeshopDataResponse;
    /**
     * Data for deliverect
     * @type {DeliverectDataResponse}
     * @memberof POSDataResponse
     */
    'deliverect'?: DeliverectDataResponse;
}
/**
 * 
 * @export
 * @interface PaymentLinkOrderResponse
 */
export interface PaymentLinkOrderResponse {
    /**
     * ID of the link, used to check if it was successful.
     * @type {string}
     * @memberof PaymentLinkOrderResponse
     */
    'id': string;
    /**
     * URL for the customer to make the payment.
     * @type {string}
     * @memberof PaymentLinkOrderResponse
     */
    'url': string;
    /**
     * Order trace id, check trace instead
     * @type {string}
     * @memberof PaymentLinkOrderResponse
     * @deprecated
     */
    'traceId': string;
    /**
     * Order trace data
     * @type {OrderTraceDataResponse}
     * @memberof PaymentLinkOrderResponse
     */
    'trace': OrderTraceDataResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMethod = {
    Cash: 'cash',
    Card: 'Card',
    ApplePay: 'ApplePay',
    GooglePay: 'GooglePay',
    PayPal: 'PayPal',
    AliPay: 'AliPay',
    Terminal: 'Terminal',
    Link: 'link',
    Natixis: 'Natixis',
    Sodexo: 'Sodexo',
    Groupeup: 'Groupeup',
    BelorderWallet: 'BelorderWallet',
    BelorderCash: 'BelorderCash',
    BelorderCoin: 'BelorderCoin',
    Cb: 'CB',
    Trd: 'TRD',
    Credit: 'Credit',
    Edenred: 'Edenred',
    Lunchr: 'LUNCHR',
    Restoflash: 'RESTOFLASH',
    Swile: 'Swile',
    Cashback: 'Cashback',
    FidelitySilver: 'FidelitySilver',
    FidelityGold: 'FidelityGold',
    FidelityDiamond: 'FidelityDiamond',
    FidelityProgram: 'FidelityProgram',
    Deliveroo: 'deliveroo',
    UberEats: 'UberEats',
    Zelty: 'Zelty'
} as const;

export type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];


/**
 * 
 * @export
 * @interface PaymentMethodsDataResponse
 */
export interface PaymentMethodsDataResponse {
    /**
     * Accepted payment methods
     * @type {Array<PaymentMethod>}
     * @memberof PaymentMethodsDataResponse
     */
    'paymentMethods': Array<PaymentMethod>;
    /**
     * Accepted card brands, e.g. visa
     * @type {Array<string>}
     * @memberof PaymentMethodsDataResponse
     * @deprecated
     */
    'cardBrands': Array<string>;
    /**
     * Data from the provider
     * @type {{ [key: string]: object; }}
     * @memberof PaymentMethodsDataResponse
     * @deprecated
     */
    'data': { [key: string]: object; };
    /**
     * Data for each strategy
     * @type {{ [key: string]: object; }}
     * @memberof PaymentMethodsDataResponse
     */
    'strategiesData': { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface PaymentRequest
 */
export interface PaymentRequest {
    /**
     * 
     * @type {AcceptedPaymentMethod}
     * @memberof PaymentRequest
     */
    'method': AcceptedPaymentMethod;
    /**
     * Data needed to make the payment
     * @type {object}
     * @memberof PaymentRequest
     */
    'data'?: object;
    /**
     * Browser info, for web requests
     * @type {object}
     * @memberof PaymentRequest
     */
    'browserInfo'?: object;
    /**
     * Origin URL for web requests
     * @type {string}
     * @memberof PaymentRequest
     */
    'origin'?: string;
    /**
     * URL to return with the 3Ds mechanism
     * @type {string}
     * @memberof PaymentRequest
     */
    'returnUrl'?: string;
    /**
     * Terminal Id
     * @type {string}
     * @memberof PaymentRequest
     */
    'terminalId'?: string;
}
/**
 * 
 * @export
 * @interface ProcessPaymentActionResultRequest
 */
export interface ProcessPaymentActionResultRequest {
    /**
     * Order trace id from the operation that originated the payment
     * @type {string}
     * @memberof ProcessPaymentActionResultRequest
     */
    'traceId': string;
    /**
     * Result data from the action
     * @type {object}
     * @memberof ProcessPaymentActionResultRequest
     */
    'data': object;
}
/**
 * 
 * @export
 * @interface RecoverPasswordRequest
 */
export interface RecoverPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof RecoverPasswordRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * Recover token, sent via email
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'token': string;
    /**
     * Email of the staff user that wants to recover password
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'email': string;
    /**
     * New password for the user
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface RestaurantDeliveryQuoteResponse
 */
export interface RestaurantDeliveryQuoteResponse {
    /**
     * 
     * @type {RestaurantPublicResponse}
     * @memberof RestaurantDeliveryQuoteResponse
     */
    'restaurant': RestaurantPublicResponse;
    /**
     * 
     * @type {DeliveryQuoteResponse}
     * @memberof RestaurantDeliveryQuoteResponse
     */
    'quote': DeliveryQuoteResponse;
}
/**
 * 
 * @export
 * @interface RestaurantExternalOrderSitesResponse
 */
export interface RestaurantExternalOrderSitesResponse {
    /**
     * Uber eats store url
     * @type {string}
     * @memberof RestaurantExternalOrderSitesResponse
     */
    'uber'?: string;
    /**
     * Deliveroo store url
     * @type {string}
     * @memberof RestaurantExternalOrderSitesResponse
     */
    'deliveroo'?: string;
    /**
     * Belorder store url
     * @type {string}
     * @memberof RestaurantExternalOrderSitesResponse
     */
    'belorder'?: string;
}
/**
 * 
 * @export
 * @interface RestaurantPickUpDataResponse
 */
export interface RestaurantPickUpDataResponse {
    /**
     * ID of the entity
     * @type {string}
     * @memberof RestaurantPickUpDataResponse
     */
    'id': string;
    /**
     * Restaurant company id
     * @type {string}
     * @memberof RestaurantPickUpDataResponse
     */
    'companyId': string;
    /**
     * ID of the region to which belongs
     * @type {string}
     * @memberof RestaurantPickUpDataResponse
     */
    'regionId'?: string;
    /**
     * Restaurant name
     * @type {string}
     * @memberof RestaurantPickUpDataResponse
     */
    'name': string;
    /**
     * unique Restaurant slug for seo
     * @type {string}
     * @memberof RestaurantPickUpDataResponse
     */
    'slug': string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof RestaurantPickUpDataResponse
     */
    'address': AddressResponse;
    /**
     * Time needed to prepare orders in minutes
     * @type {number}
     * @memberof RestaurantPickUpDataResponse
     */
    'preparationDuration': number;
    /**
     * 
     * @type {OrderTypeOptionsResponse}
     * @memberof RestaurantPickUpDataResponse
     */
    'pickUp': OrderTypeOptionsResponse;
}
/**
 * 
 * @export
 * @interface RestaurantPromotionResponse
 */
export interface RestaurantPromotionResponse {
    /**
     * Title of the promotion.
     * @type {string}
     * @memberof RestaurantPromotionResponse
     */
    'title': string;
    /**
     * Subtitle of the promotion
     * @type {string}
     * @memberof RestaurantPromotionResponse
     */
    'subtitle'?: string;
    /**
     * ID of the item that is in promotion
     * @type {string}
     * @memberof RestaurantPromotionResponse
     */
    'itemId'?: string;
    /**
     * Image of the promotion
     * @type {string}
     * @memberof RestaurantPromotionResponse
     */
    'image': string;
}
/**
 * 
 * @export
 * @interface RestaurantPublicResponse
 */
export interface RestaurantPublicResponse {
    /**
     * ID of the entity
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'id': string;
    /**
     * Date when it was created
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'updated': string;
    /**
     * ID of the region to which belongs
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'regionId'?: string;
    /**
     * Languages available in the restaurant, taken from company or region
     * @type {Array<Language>}
     * @memberof RestaurantPublicResponse
     */
    'languages'?: Array<Language>;
    /**
     * 
     * @type {Language}
     * @memberof RestaurantPublicResponse
     */
    'mainLanguage'?: Language;
    /**
     * Restaurant name
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'name': string;
    /**
     * unique Restaurant slug for seo
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'slug': string;
    /**
     * Description of the restaurant
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'description': string;
    /**
     * 
     * @type {RestaurantStatus}
     * @memberof RestaurantPublicResponse
     */
    'status': RestaurantStatus;
    /**
     * 
     * @type {BusinessDetailsResponse}
     * @memberof RestaurantPublicResponse
     */
    'businessDetails': BusinessDetailsResponse;
    /**
     * 
     * @type {AddressResponse}
     * @memberof RestaurantPublicResponse
     */
    'address': AddressResponse;
    /**
     * IDs of the banners that are published in the restaurant
     * @type {Array<string>}
     * @memberof RestaurantPublicResponse
     */
    'bannerIds': Array<string>;
    /**
     * 
     * @type {Array<BannerResponse>}
     * @memberof RestaurantPublicResponse
     */
    'banners'?: Array<BannerResponse>;
    /**
     * 
     * @type {Array<RestaurantPromotionResponse>}
     * @memberof RestaurantPublicResponse
     */
    'promotions': Array<RestaurantPromotionResponse>;
    /**
     * Url of the restaurant
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'url': string;
    /**
     * Restaurant timezone
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'timezone': string;
    /**
     * 
     * @type {Currency}
     * @memberof RestaurantPublicResponse
     */
    'currency': Currency;
    /**
     * Url of banner file
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'banner': string;
    /**
     * Url of logo file
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'logo': string;
    /**
     * Url of favicon file
     * @type {string}
     * @memberof RestaurantPublicResponse
     */
    'favicon': string;
    /**
     * Urls of shop pictures file
     * @type {Array<string>}
     * @memberof RestaurantPublicResponse
     */
    'shopPictures': Array<string>;
    /**
     * Discounts published in the restaurant
     * @type {Array<DiscountResponse>}
     * @memberof RestaurantPublicResponse
     */
    'discounts': Array<DiscountResponse>;
    /**
     * Whether the restaurant is busy (unable to accept orders)
     * @type {boolean}
     * @memberof RestaurantPublicResponse
     */
    'isBusy': boolean;
    /**
     * Time needed to prepare orders in minutes
     * @type {number}
     * @memberof RestaurantPublicResponse
     */
    'preparationDuration': number;
    /**
     * 
     * @type {DeliveryOptionsResponse}
     * @memberof RestaurantPublicResponse
     */
    'delivery': DeliveryOptionsResponse;
    /**
     * 
     * @type {OrderTypeOptionsResponse}
     * @memberof RestaurantPublicResponse
     */
    'pickUp': OrderTypeOptionsResponse;
    /**
     * 
     * @type {EatInOptionsResponse}
     * @memberof RestaurantPublicResponse
     */
    'eatIn': EatInOptionsResponse;
    /**
     * IDs of the items that are disabled in this restaurant (out of stock)
     * @type {Array<string>}
     * @memberof RestaurantPublicResponse
     */
    'disabledItemIds': Array<string>;
    /**
     * Urls of external ordering sites
     * @type {RestaurantExternalOrderSitesResponse}
     * @memberof RestaurantPublicResponse
     */
    'externalOrderSites': RestaurantExternalOrderSitesResponse;
    /**
     * Restaurant zones
     * @type {Array<RestaurantTableZoneResponse>}
     * @memberof RestaurantPublicResponse
     */
    'tableZones': Array<RestaurantTableZoneResponse>;
    /**
     * Restaurant tables
     * @type {Array<RestaurantTableResponse>}
     * @memberof RestaurantPublicResponse
     */
    'tables': Array<RestaurantTableResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RestaurantStatus = {
    Online: 'online',
    Offline: 'offline',
    Inactive: 'inactive',
    Register: 'register'
} as const;

export type RestaurantStatus = typeof RestaurantStatus[keyof typeof RestaurantStatus];


/**
 * 
 * @export
 * @interface RestaurantTableResponse
 */
export interface RestaurantTableResponse {
    /**
     * ID of the entity
     * @type {string}
     * @memberof RestaurantTableResponse
     */
    'id': string;
    /**
     * Zone to which the table belongs
     * @type {string}
     * @memberof RestaurantTableResponse
     */
    'zoneId': string;
    /**
     * Name of the table
     * @type {string}
     * @memberof RestaurantTableResponse
     */
    'name': string;
    /**
     * Index of the table in the restaurant
     * @type {number}
     * @memberof RestaurantTableResponse
     */
    'index': number;
    /**
     * Number of seats
     * @type {number}
     * @memberof RestaurantTableResponse
     */
    'seats'?: number;
}
/**
 * 
 * @export
 * @interface RestaurantTableZoneResponse
 */
export interface RestaurantTableZoneResponse {
    /**
     * Date when it was created
     * @type {string}
     * @memberof RestaurantTableZoneResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof RestaurantTableZoneResponse
     */
    'updated': string;
    /**
     * ID of the restaurant table zone
     * @type {string}
     * @memberof RestaurantTableZoneResponse
     */
    'id': string;
    /**
     * Name of the restaurant table zone
     * @type {string}
     * @memberof RestaurantTableZoneResponse
     */
    'name': string;
    /**
     * Description of the restaurant table zone
     * @type {string}
     * @memberof RestaurantTableZoneResponse
     */
    'description': string;
    /**
     * Prefix for the names of the tables
     * @type {string}
     * @memberof RestaurantTableZoneResponse
     */
    'tablePrefix': string;
}
/**
 * 
 * @export
 * @interface SendOrderInvoiceRequest
 */
export interface SendOrderInvoiceRequest {
    /**
     * Email to receive the invoice
     * @type {string}
     * @memberof SendOrderInvoiceRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface SetupKioskRequest
 */
export interface SetupKioskRequest {
    /**
     * Token of the kiosk
     * @type {string}
     * @memberof SetupKioskRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface TemplateAppDataResponse
 */
export interface TemplateAppDataResponse {
    /**
     * App icons
     * @type {TemplateAppIconsResponse}
     * @memberof TemplateAppDataResponse
     */
    'icons': TemplateAppIconsResponse;
    /**
     * App images
     * @type {TemplateAppImagesResponse}
     * @memberof TemplateAppDataResponse
     */
    'images': TemplateAppImagesResponse;
    /**
     * Texts used in the app
     * @type {TemplateAppTextsResponse}
     * @memberof TemplateAppDataResponse
     */
    'texts': TemplateAppTextsResponse;
    /**
     * App tutorial
     * @type {TemplateAppTutorialResponse}
     * @memberof TemplateAppDataResponse
     */
    'tutorial': TemplateAppTutorialResponse;
}
/**
 * 
 * @export
 * @interface TemplateAppIconsResponse
 */
export interface TemplateAppIconsResponse {
    /**
     * Logo to show in the loading screen
     * @type {string}
     * @memberof TemplateAppIconsResponse
     */
    'logoLoading'?: string;
    /**
     * Logo to show in the welcome screen
     * @type {string}
     * @memberof TemplateAppIconsResponse
     */
    'logoWelcome'?: string;
    /**
     * Logo to show in the header of the home screen
     * @type {string}
     * @memberof TemplateAppIconsResponse
     */
    'logoHeader'?: string;
    /**
     * Icon to show in for the fidelity program
     * @type {string}
     * @memberof TemplateAppIconsResponse
     */
    'fidelityProgram'?: string;
    /**
     * Home button in the main menu
     * @type {string}
     * @memberof TemplateAppIconsResponse
     */
    'menuHome'?: string;
    /**
     * Order button in the main menu
     * @type {string}
     * @memberof TemplateAppIconsResponse
     */
    'menuOrder'?: string;
    /**
     * User orders button in the main menu
     * @type {string}
     * @memberof TemplateAppIconsResponse
     */
    'menuUserOrders'?: string;
    /**
     * User button in the main menu
     * @type {string}
     * @memberof TemplateAppIconsResponse
     */
    'menuUser'?: string;
}
/**
 * 
 * @export
 * @interface TemplateAppImagesResponse
 */
export interface TemplateAppImagesResponse {
    /**
     * Image to show in the background of the welcome screen
     * @type {LocalizedFieldResponse}
     * @memberof TemplateAppImagesResponse
     */
    'welcome'?: LocalizedFieldResponse;
    /**
     * Image to show in the header of the home page
     * @type {string}
     * @memberof TemplateAppImagesResponse
     */
    'homeHeader'?: string;
}
/**
 * 
 * @export
 * @interface TemplateAppTextsResponse
 */
export interface TemplateAppTextsResponse {
    /**
     * Text to show in the welcome screen
     * @type {LocalizedFieldResponse}
     * @memberof TemplateAppTextsResponse
     */
    'welcome': LocalizedFieldResponse;
    /**
     * Prefix before the user name in home
     * @type {LocalizedFieldResponse}
     * @memberof TemplateAppTextsResponse
     */
    'homePrefix': LocalizedFieldResponse;
    /**
     * Suffix after the user name in home
     * @type {LocalizedFieldResponse}
     * @memberof TemplateAppTextsResponse
     */
    'homeSuffix': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface TemplateAppTutorialResponse
 */
export interface TemplateAppTutorialResponse {
    /**
     * Slides of the tutorial
     * @type {Array<TemplateAppTutorialSlideResponse>}
     * @memberof TemplateAppTutorialResponse
     */
    'slides': Array<TemplateAppTutorialSlideResponse>;
}
/**
 * 
 * @export
 * @interface TemplateAppTutorialSlideResponse
 */
export interface TemplateAppTutorialSlideResponse {
    /**
     * Image for the tutorial slide
     * @type {LocalizedFieldResponse}
     * @memberof TemplateAppTutorialSlideResponse
     */
    'image'?: LocalizedFieldResponse;
    /**
     * Color for the background
     * @type {string}
     * @memberof TemplateAppTutorialSlideResponse
     */
    'backgroundColor'?: string;
    /**
     * Title to show
     * @type {LocalizedFieldResponse}
     * @memberof TemplateAppTutorialSlideResponse
     */
    'title'?: LocalizedFieldResponse;
    /**
     * Subtitle to show
     * @type {LocalizedFieldResponse}
     * @memberof TemplateAppTutorialSlideResponse
     */
    'subtitle'?: LocalizedFieldResponse;
    /**
     * Whether the slide is disabled
     * @type {boolean}
     * @memberof TemplateAppTutorialSlideResponse
     */
    'disabled'?: boolean;
}
/**
 * 
 * @export
 * @interface TemplateColorCoupleResponse
 */
export interface TemplateColorCoupleResponse {
    /**
     * Background color of the couple
     * @type {string}
     * @memberof TemplateColorCoupleResponse
     */
    'background': string;
    /**
     * Primary color
     * @type {string}
     * @memberof TemplateColorCoupleResponse
     */
    'primary': string;
    /**
     * Secondary color
     * @type {string}
     * @memberof TemplateColorCoupleResponse
     */
    'secondary': string;
}
/**
 * 
 * @export
 * @interface TemplateColorCouplesResponse
 */
export interface TemplateColorCouplesResponse {
    /**
     * C1 template color couple
     * @type {TemplateColorCoupleResponse}
     * @memberof TemplateColorCouplesResponse
     */
    'c1': TemplateColorCoupleResponse;
    /**
     * C2 template color couple
     * @type {TemplateColorCoupleResponse}
     * @memberof TemplateColorCouplesResponse
     */
    'c2': TemplateColorCoupleResponse;
    /**
     * C3 template color couple
     * @type {TemplateColorCoupleResponse}
     * @memberof TemplateColorCouplesResponse
     */
    'c3': TemplateColorCoupleResponse;
    /**
     * C4 template color couple
     * @type {TemplateColorCoupleResponse}
     * @memberof TemplateColorCouplesResponse
     */
    'c4': TemplateColorCoupleResponse;
    /**
     * C5 template color couple
     * @type {TemplateColorCoupleResponse}
     * @memberof TemplateColorCouplesResponse
     */
    'c5': TemplateColorCoupleResponse;
    /**
     * Footer template color couple
     * @type {TemplateColorCoupleResponse}
     * @memberof TemplateColorCouplesResponse
     */
    'footer': TemplateColorCoupleResponse;
}
/**
 * 
 * @export
 * @interface TemplateColorGradientResponse
 */
export interface TemplateColorGradientResponse {
    /**
     * Colors for the gradient
     * @type {Array<string>}
     * @memberof TemplateColorGradientResponse
     */
    'colors': Array<string>;
    /**
     * DEG angle for the gradient
     * @type {number}
     * @memberof TemplateColorGradientResponse
     */
    'angle': number;
}
/**
 * 
 * @export
 * @interface TemplateColorsResponse
 */
export interface TemplateColorsResponse {
    /**
     * System colors
     * @type {TemplateSystemColorsResponse}
     * @memberof TemplateColorsResponse
     */
    'system': TemplateSystemColorsResponse;
    /**
     * Color couples
     * @type {TemplateColorCouplesResponse}
     * @memberof TemplateColorsResponse
     */
    'couples': TemplateColorCouplesResponse;
    /**
     * Gradient color
     * @type {TemplateColorGradientResponse}
     * @memberof TemplateColorsResponse
     */
    'gradient'?: TemplateColorGradientResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TemplateFontFamily = {
    Eveleth: 'Eveleth',
    Livvic: 'Livvic',
    Montserrat: 'Montserrat',
    Poppins: 'Poppins',
    Obviously: 'Obviously'
} as const;

export type TemplateFontFamily = typeof TemplateFontFamily[keyof typeof TemplateFontFamily];


/**
 * 
 * @export
 * @enum {string}
 */

export const TemplateFontStyle = {
    Hero: 'hero',
    Title: 'title',
    Body: 'body'
} as const;

export type TemplateFontStyle = typeof TemplateFontStyle[keyof typeof TemplateFontStyle];


/**
 * 
 * @export
 * @interface TemplateFontStyleContainer
 */
export interface TemplateFontStyleContainer {
    /**
     * 
     * @type {TemplateFontStyle}
     * @memberof TemplateFontStyleContainer
     */
    'style': TemplateFontStyle;
}
/**
 * 
 * @export
 * @interface TemplateFontsResponse
 */
export interface TemplateFontsResponse {
    /**
     * 
     * @type {TemplateFontFamily}
     * @memberof TemplateFontsResponse
     */
    'hero': TemplateFontFamily;
    /**
     * 
     * @type {TemplateFontFamily}
     * @memberof TemplateFontsResponse
     */
    'title': TemplateFontFamily;
    /**
     * 
     * @type {TemplateFontFamily}
     * @memberof TemplateFontsResponse
     */
    'body': TemplateFontFamily;
}
/**
 * 
 * @export
 * @interface TemplateKioskDataResponse
 */
export interface TemplateKioskDataResponse {
    /**
     * 
     * @type {TemplateKioskWelcomeVideoResponse | TemplateKioskWelcomeImagesResponse}
     * @memberof TemplateKioskDataResponse
     */
    'welcomeMedia': TemplateKioskWelcomeVideoResponse | TemplateKioskWelcomeImagesResponse;
}
/**
 * 
 * @export
 * @interface TemplateKioskWelcomeImagesResponse
 */
export interface TemplateKioskWelcomeImagesResponse {
    /**
     * 
     * @type {TemplateKioskWelcomeTypeImages}
     * @memberof TemplateKioskWelcomeImagesResponse
     */
    'type': TemplateKioskWelcomeTypeImages;
    /**
     * Interval for the slider in milliseconds
     * @type {number}
     * @memberof TemplateKioskWelcomeImagesResponse
     */
    'autoplayInterval': number;
    /**
     * Images to show in a slider
     * @type {Array<LocalizedFieldResponse>}
     * @memberof TemplateKioskWelcomeImagesResponse
     */
    'images': Array<LocalizedFieldResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TemplateKioskWelcomeTypeImages = {
    Images: 'images'
} as const;

export type TemplateKioskWelcomeTypeImages = typeof TemplateKioskWelcomeTypeImages[keyof typeof TemplateKioskWelcomeTypeImages];


/**
 * 
 * @export
 * @enum {string}
 */

export const TemplateKioskWelcomeTypeVideo = {
    Video: 'video'
} as const;

export type TemplateKioskWelcomeTypeVideo = typeof TemplateKioskWelcomeTypeVideo[keyof typeof TemplateKioskWelcomeTypeVideo];


/**
 * 
 * @export
 * @interface TemplateKioskWelcomeVideoResponse
 */
export interface TemplateKioskWelcomeVideoResponse {
    /**
     * 
     * @type {TemplateKioskWelcomeTypeVideo}
     * @memberof TemplateKioskWelcomeVideoResponse
     */
    'type': TemplateKioskWelcomeTypeVideo;
    /**
     * Video to show in the welcome page
     * @type {LocalizedFieldResponse}
     * @memberof TemplateKioskWelcomeVideoResponse
     */
    'video': LocalizedFieldResponse;
}
/**
 * 
 * @export
 * @interface TemplateResponse
 */
export interface TemplateResponse {
    /**
     * Colors of the template
     * @type {TemplateColorsResponse}
     * @memberof TemplateResponse
     */
    'colors': TemplateColorsResponse;
    /**
     * Typography to use in web applications
     * @type {TemplateTypographyResponse}
     * @memberof TemplateResponse
     */
    'typography': TemplateTypographyResponse;
    /**
     * Font family used for each font style
     * @type {TemplateFontsResponse}
     * @memberof TemplateResponse
     */
    'fonts': TemplateFontsResponse;
    /**
     * Template data for the app
     * @type {TemplateAppDataResponse}
     * @memberof TemplateResponse
     */
    'app'?: TemplateAppDataResponse;
    /**
     * Template data for the kiosk
     * @type {TemplateKioskDataResponse}
     * @memberof TemplateResponse
     */
    'kiosk'?: TemplateKioskDataResponse;
}
/**
 * 
 * @export
 * @interface TemplateSystemColorsResponse
 */
export interface TemplateSystemColorsResponse {
    /**
     * Black system color
     * @type {string}
     * @memberof TemplateSystemColorsResponse
     */
    'black': string;
    /**
     * White system color
     * @type {string}
     * @memberof TemplateSystemColorsResponse
     */
    'white': string;
    /**
     * LightGrey system color
     * @type {string}
     * @memberof TemplateSystemColorsResponse
     */
    'lightGrey': string;
    /**
     * MediumGrey system color
     * @type {string}
     * @memberof TemplateSystemColorsResponse
     */
    'mediumGrey': string;
    /**
     * DarkGrey system color
     * @type {string}
     * @memberof TemplateSystemColorsResponse
     */
    'darkGrey': string;
    /**
     * Green system color
     * @type {string}
     * @memberof TemplateSystemColorsResponse
     */
    'green': string;
    /**
     * Red system color
     * @type {string}
     * @memberof TemplateSystemColorsResponse
     */
    'red': string;
    /**
     * Orange system color
     * @type {string}
     * @memberof TemplateSystemColorsResponse
     */
    'orange': string;
}
/**
 * 
 * @export
 * @interface TemplateTypographyResponse
 */
export interface TemplateTypographyResponse {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'h1': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'h2': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'h3': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'bodyBold': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'body': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'link': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'button': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'categoryWithPicture': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'categoryWithoutPicture': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'label': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'placeholder': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'input': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'productTag': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'priceTag': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'priceTagDiscount': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'alert': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'alertBold': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TemplateTypographyResponse
     */
    'copyright': { [key: string]: string; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TextAlignment = {
    Left: 'left',
    Center: 'center',
    Right: 'right'
} as const;

export type TextAlignment = typeof TextAlignment[keyof typeof TextAlignment];


/**
 * 
 * @export
 * @interface TransactionPublicResponse
 */
export interface TransactionPublicResponse {
    /**
     * Amount in cent that was charged
     * @type {number}
     * @memberof TransactionPublicResponse
     */
    'price': number;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof TransactionPublicResponse
     */
    'paymentMethod': PaymentMethod;
    /**
     * 
     * @type {TransactionReceiptResponse}
     * @memberof TransactionPublicResponse
     */
    'receipt'?: TransactionReceiptResponse;
}
/**
 * 
 * @export
 * @interface TransactionReceiptLineResponse
 */
export interface TransactionReceiptLineResponse {
    /**
     * 
     * @type {TextAlignment}
     * @memberof TransactionReceiptLineResponse
     */
    'alignment'?: TextAlignment;
    /**
     * Whether the text should be bold
     * @type {boolean}
     * @memberof TransactionReceiptLineResponse
     */
    'bold'?: boolean;
    /**
     * Whether the text should be italic
     * @type {boolean}
     * @memberof TransactionReceiptLineResponse
     */
    'italic'?: boolean;
    /**
     * Whether there should be an underline
     * @type {boolean}
     * @memberof TransactionReceiptLineResponse
     */
    'underline'?: boolean;
    /**
     * Title of the line
     * @type {string}
     * @memberof TransactionReceiptLineResponse
     */
    'title'?: string;
    /**
     * Value for the line
     * @type {string}
     * @memberof TransactionReceiptLineResponse
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface TransactionReceiptResponse
 */
export interface TransactionReceiptResponse {
    /**
     * Receipt lines
     * @type {Array<TransactionReceiptLineResponse>}
     * @memberof TransactionReceiptResponse
     */
    'lines'?: Array<TransactionReceiptLineResponse>;
    /**
     * Masked credit card number
     * @type {string}
     * @memberof TransactionReceiptResponse
     */
    'maskedCardNumber'?: string;
}
/**
 * 
 * @export
 * @interface UberDirectDataResponse
 */
export interface UberDirectDataResponse {
    /**
     * Uber Direct customerId for Direct API
     * @type {string}
     * @memberof UberDirectDataResponse
     */
    'customerId': string;
}
/**
 * 
 * @export
 * @interface UnlockKioskRequest
 */
export interface UnlockKioskRequest {
    /**
     * Number used to unlock the kiosk
     * @type {number}
     * @memberof UnlockKioskRequest
     */
    'code': number;
}
/**
 * 
 * @export
 * @interface UpdateKioskDeviceInfoRequest
 */
export interface UpdateKioskDeviceInfoRequest {
    /**
     * Brand of the device
     * @type {string}
     * @memberof UpdateKioskDeviceInfoRequest
     */
    'brand': string;
    /**
     * Manufacturer of the device
     * @type {string}
     * @memberof UpdateKioskDeviceInfoRequest
     */
    'manufacturer': string;
    /**
     * Device model
     * @type {string}
     * @memberof UpdateKioskDeviceInfoRequest
     */
    'model': string;
    /**
     * Product
     * @type {string}
     * @memberof UpdateKioskDeviceInfoRequest
     */
    'product': string;
    /**
     * OS version
     * @type {string}
     * @memberof UpdateKioskDeviceInfoRequest
     */
    'os': string;
    /**
     * App version name
     * @type {string}
     * @memberof UpdateKioskDeviceInfoRequest
     */
    'versionName': string;
    /**
     * App version code
     * @type {string}
     * @memberof UpdateKioskDeviceInfoRequest
     */
    'versionCode': string;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * First name of the user
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'firstName'?: string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {CreateUserPhoneRequest}
     * @memberof UpdateUserRequest
     */
    'phone'?: CreateUserPhoneRequest;
    /**
     * Whether the user wants to receive offers via email
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'offersViaEmail'?: boolean;
    /**
     * Whether the user wants to receive offers via SMS
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'offersViaSMS'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserErrorCode = {
    AuthEmailExists: 'AUTH_EMAIL_EXISTS',
    AuthPhoneExists: 'AUTH_PHONE_EXISTS',
    AuthPhoneVerificationInvalid: 'AUTH_PHONE_VERIFICATION_INVALID',
    OrderInvalid: 'ORDER_INVALID',
    OrderRestaurantOffline: 'ORDER_RESTAURANT_OFFLINE',
    OrderRestaurantBusy: 'ORDER_RESTAURANT_BUSY',
    OrderDateInPast: 'ORDER_DATE_IN_PAST',
    OrderRestaurantClosed: 'ORDER_RESTAURANT_CLOSED',
    OrderNoRestaurantAvailable: 'ORDER_NO_RESTAURANT_AVAILABLE',
    OrderDeliveryQuoteExpired: 'ORDER_DELIVERY_QUOTE_EXPIRED',
    OrderItemsDontExist: 'ORDER_ITEMS_DONT_EXIST',
    OrderModifiersDontExist: 'ORDER_MODIFIERS_DONT_EXIST',
    OrderInvalidItems: 'ORDER_INVALID_ITEMS',
    OrderInvalidModifiers: 'ORDER_INVALID_MODIFIERS',
    OrderInvalidPriceItems: 'ORDER_INVALID_PRICE_ITEMS',
    OrderItemsDisabled: 'ORDER_ITEMS_DISABLED',
    OrderFidelityInvalidItems: 'ORDER_FIDELITY_INVALID_ITEMS',
    OrderPaymentRejected: 'ORDER_PAYMENT_REJECTED',
    OrderPaymentError: 'ORDER_PAYMENT_ERROR',
    OrderPaymentNoBalance: 'ORDER_PAYMENT_NO_BALANCE',
    OrderPaymentNotSuitableMeal: 'ORDER_PAYMENT_NOT_SUITABLE_MEAL',
    ItemSkuExists: 'ITEM_SKU_EXISTS',
    OrderFidelityUserBalanceInsufficient: 'ORDER_FIDELITY_USER_BALANCE_INSUFFICIENT',
    OrderFidelityUnknownUser: 'ORDER_FIDELITY_UNKNOWN_USER',
    OrderFidelityInactive: 'ORDER_FIDELITY_INACTIVE'
} as const;

export type UserErrorCode = typeof UserErrorCode[keyof typeof UserErrorCode];


/**
 * 
 * @export
 * @interface UserErrorResponse
 */
export interface UserErrorResponse {
    /**
     * 
     * @type {UserErrorCode}
     * @memberof UserErrorResponse
     */
    'code': UserErrorCode;
}
/**
 * 
 * @export
 * @interface UserLoginResponse
 */
export interface UserLoginResponse {
    /**
     * The access token to be used for accessing the services
     * @type {string}
     * @memberof UserLoginResponse
     */
    'token': string;
    /**
     * 
     * @type {UserResponse}
     * @memberof UserLoginResponse
     */
    'user': UserResponse;
}
/**
 * 
 * @export
 * @interface UserMetaResponse
 */
export interface UserMetaResponse {
    /**
     * Number of orders the user has made
     * @type {number}
     * @memberof UserMetaResponse
     */
    'numOrders': number;
    /**
     * Total amount in cents spent by the user
     * @type {{ [key: string]: number; }}
     * @memberof UserMetaResponse
     */
    'totalSpent': { [key: string]: number; };
    /**
     * Total reductions obtained in cents for each currency
     * @type {{ [key: string]: number; }}
     * @memberof UserMetaResponse
     */
    'totalReductions': { [key: string]: number; };
    /**
     * Average spent in cents for each currency
     * @type {{ [key: string]: number; }}
     * @memberof UserMetaResponse
     */
    'averageSpentPerOrder': { [key: string]: number; };
    /**
     * Number of fidelity points used in all the orders
     * @type {number}
     * @memberof UserMetaResponse
     */
    'fidelityPointsUsed': number;
    /**
     * Date of the last order
     * @type {string}
     * @memberof UserMetaResponse
     */
    'lastOrderAt'?: string;
}
/**
 * 
 * @export
 * @interface UserPhoneResponse
 */
export interface UserPhoneResponse {
    /**
     * Country code
     * @type {string}
     * @memberof UserPhoneResponse
     */
    'countryCode': string;
    /**
     * Phone number
     * @type {string}
     * @memberof UserPhoneResponse
     */
    'number': string;
    /**
     * Full phone number
     * @type {string}
     * @memberof UserPhoneResponse
     */
    'fullNumber': string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * ID of the user
     * @type {string}
     * @memberof UserResponse
     */
    'id': string;
    /**
     * First name of the user
     * @type {string}
     * @memberof UserResponse
     */
    'firstName': string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof UserResponse
     */
    'lastName': string;
    /**
     * Email of the user
     * @type {string}
     * @memberof UserResponse
     */
    'email': string;
    /**
     * Language of the guest user
     * @type {string}
     * @memberof UserResponse
     */
    'language': string;
    /**
     * Date when it was created
     * @type {string}
     * @memberof UserResponse
     */
    'created': string;
    /**
     * Date when it was updated
     * @type {string}
     * @memberof UserResponse
     */
    'updated': string;
    /**
     * Company to which the user belongs
     * @type {string}
     * @memberof UserResponse
     */
    'companyId': string;
    /**
     * 
     * @type {UserType}
     * @memberof UserResponse
     */
    'type': UserType;
    /**
     * Cumulated fidelity points
     * @type {number}
     * @memberof UserResponse
     */
    'fidelityPoints': number;
    /**
     * URL of the avatar
     * @type {string}
     * @memberof UserResponse
     */
    'avatarURL': string;
    /**
     * 
     * @type {UserPhoneResponse}
     * @memberof UserResponse
     */
    'phone': UserPhoneResponse;
    /**
     * Whether the email was verified
     * @type {boolean}
     * @memberof UserResponse
     */
    'emailVerified': boolean;
    /**
     * Whether the phone number was verified
     * @type {boolean}
     * @memberof UserResponse
     */
    'phoneVerified': boolean;
    /**
     * Whether the user wants to receive offers via email
     * @type {boolean}
     * @memberof UserResponse
     */
    'offersViaEmail': boolean;
    /**
     * Whether the user wants to receive offers via SMS
     * @type {boolean}
     * @memberof UserResponse
     */
    'offersViaSMS': boolean;
    /**
     * Date when it was last connected
     * @type {string}
     * @memberof UserResponse
     */
    'lastConnectedAt': string;
    /**
     * 
     * @type {UserMetaResponse}
     * @memberof UserResponse
     */
    'meta': UserMetaResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserType = {
    User: 'user',
    Staff: 'staff'
} as const;

export type UserType = typeof UserType[keyof typeof UserType];


/**
 * 
 * @export
 * @interface ValidatePhoneCodeRequest
 */
export interface ValidatePhoneCodeRequest {
    /**
     * Validation code
     * @type {string}
     * @memberof ValidatePhoneCodeRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface ZeltyDataResponse
 */
export interface ZeltyDataResponse {
    /**
     * ID of the restaurant in zelty
     * @type {string}
     * @memberof ZeltyDataResponse
     */
    'restaurantId'?: string;
    /**
     * API key to access the restaurant in zelty
     * @type {Array<string>}
     * @memberof ZeltyDataResponse
     */
    'apiKeys': Array<string>;
    /**
     * Zelty payment ID
     * @type {number}
     * @memberof ZeltyDataResponse
     */
    'paymentId'?: number;
}
/**
 * 
 * @export
 * @interface ZeshopDataResponse
 */
export interface ZeshopDataResponse {
    /**
     * Base URL of the server where the POS is installed
     * @type {string}
     * @memberof ZeshopDataResponse
     */
    'serverBaseURL': string;
    /**
     * Local Base URL of the server where the POS is installed, used to fallback when there is no connection
     * @type {string}
     * @memberof ZeshopDataResponse
     */
    'localBaseURL'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archives the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the email is in use used
         * @param {CheckUserEmailRequest} checkUserEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserEmail: async (checkUserEmailRequest: CheckUserEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkUserEmailRequest' is not null or undefined
            assertParamExists('checkUserEmail', 'checkUserEmailRequest', checkUserEmailRequest)
            const localVarPath = `/auth/check-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkUserEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the current user from thw JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log in the system using the user credentials
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('loginUser', 'loginRequest', loginRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends email to recover the password of a user
         * @param {RecoverPasswordRequest} recoverPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverUserPassword: async (recoverPasswordRequest: RecoverPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recoverPasswordRequest' is not null or undefined
            assertParamExists('recoverUserPassword', 'recoverPasswordRequest', recoverPasswordRequest)
            const localVarPath = `/auth/recover-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recoverPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers a new user
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('registerUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Re-sends the phone verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserPhoneVerificationCode: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/phone-code/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets the password of a staff user
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword: async (resetPasswordRequest: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetUserPassword', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the current user
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser: async (updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateCurrentUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates the code sent to the user phone
         * @param {ValidatePhoneCodeRequest} validatePhoneCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUserPhone: async (validatePhoneCodeRequest: ValidatePhoneCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validatePhoneCodeRequest' is not null or undefined
            assertParamExists('validateUserPhone', 'validatePhoneCodeRequest', validatePhoneCodeRequest)
            const localVarPath = `/auth/phone-code/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validatePhoneCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archives the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks if the email is in use used
         * @param {CheckUserEmailRequest} checkUserEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUserEmail(checkUserEmailRequest: CheckUserEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUserEmail(checkUserEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the current user from thw JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log in the system using the user credentials
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends email to recover the password of a user
         * @param {RecoverPasswordRequest} recoverPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverUserPassword(recoverPasswordRequest: RecoverPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverUserPassword(recoverPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers a new user
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Re-sends the phone verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendUserPhoneVerificationCode(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendUserPhoneVerificationCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resets the password of a staff user
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPassword(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPassword(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the current user
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentUser(updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCurrentUser(updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validates the code sent to the user phone
         * @param {ValidatePhoneCodeRequest} validatePhoneCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateUserPhone(validatePhoneCodeRequest: ValidatePhoneCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateUserPhone(validatePhoneCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Archives the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCurrentUser(options?: any): AxiosPromise<void> {
            return localVarFp.archiveCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks if the email is in use used
         * @param {CheckUserEmailRequest} checkUserEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUserEmail(checkUserEmailRequest: CheckUserEmailRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkUserEmail(checkUserEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the current user from thw JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log in the system using the user credentials
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(loginRequest: LoginRequest, options?: any): AxiosPromise<UserLoginResponse> {
            return localVarFp.loginUser(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends email to recover the password of a user
         * @param {RecoverPasswordRequest} recoverPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverUserPassword(recoverPasswordRequest: RecoverPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.recoverUserPassword(recoverPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers a new user
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<UserLoginResponse> {
            return localVarFp.registerUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Re-sends the phone verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserPhoneVerificationCode(options?: any): AxiosPromise<UserResponse> {
            return localVarFp.resendUserPhoneVerificationCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets the password of a staff user
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<UserLoginResponse> {
            return localVarFp.resetUserPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the current user
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser(updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateCurrentUser(updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates the code sent to the user phone
         * @param {ValidatePhoneCodeRequest} validatePhoneCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUserPhone(validatePhoneCodeRequest: ValidatePhoneCodeRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.validateUserPhone(validatePhoneCodeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkUserEmail operation in AuthApi.
 * @export
 * @interface AuthApiCheckUserEmailRequest
 */
export interface AuthApiCheckUserEmailRequest {
    /**
     * 
     * @type {CheckUserEmailRequest}
     * @memberof AuthApiCheckUserEmail
     */
    readonly checkUserEmailRequest: CheckUserEmailRequest
}

/**
 * Request parameters for loginUser operation in AuthApi.
 * @export
 * @interface AuthApiLoginUserRequest
 */
export interface AuthApiLoginUserRequest {
    /**
     * 
     * @type {LoginRequest}
     * @memberof AuthApiLoginUser
     */
    readonly loginRequest: LoginRequest
}

/**
 * Request parameters for recoverUserPassword operation in AuthApi.
 * @export
 * @interface AuthApiRecoverUserPasswordRequest
 */
export interface AuthApiRecoverUserPasswordRequest {
    /**
     * 
     * @type {RecoverPasswordRequest}
     * @memberof AuthApiRecoverUserPassword
     */
    readonly recoverPasswordRequest: RecoverPasswordRequest
}

/**
 * Request parameters for registerUser operation in AuthApi.
 * @export
 * @interface AuthApiRegisterUserRequest
 */
export interface AuthApiRegisterUserRequest {
    /**
     * 
     * @type {CreateUserRequest}
     * @memberof AuthApiRegisterUser
     */
    readonly createUserRequest: CreateUserRequest
}

/**
 * Request parameters for resetUserPassword operation in AuthApi.
 * @export
 * @interface AuthApiResetUserPasswordRequest
 */
export interface AuthApiResetUserPasswordRequest {
    /**
     * 
     * @type {ResetPasswordRequest}
     * @memberof AuthApiResetUserPassword
     */
    readonly resetPasswordRequest: ResetPasswordRequest
}

/**
 * Request parameters for updateCurrentUser operation in AuthApi.
 * @export
 * @interface AuthApiUpdateCurrentUserRequest
 */
export interface AuthApiUpdateCurrentUserRequest {
    /**
     * 
     * @type {UpdateUserRequest}
     * @memberof AuthApiUpdateCurrentUser
     */
    readonly updateUserRequest: UpdateUserRequest
}

/**
 * Request parameters for validateUserPhone operation in AuthApi.
 * @export
 * @interface AuthApiValidateUserPhoneRequest
 */
export interface AuthApiValidateUserPhoneRequest {
    /**
     * 
     * @type {ValidatePhoneCodeRequest}
     * @memberof AuthApiValidateUserPhone
     */
    readonly validatePhoneCodeRequest: ValidatePhoneCodeRequest
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Archives the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public archiveCurrentUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).archiveCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks if the email is in use used
     * @param {AuthApiCheckUserEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public checkUserEmail(requestParameters: AuthApiCheckUserEmailRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).checkUserEmail(requestParameters.checkUserEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the current user from thw JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getCurrentUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log in the system using the user credentials
     * @param {AuthApiLoginUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginUser(requestParameters: AuthApiLoginUserRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginUser(requestParameters.loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends email to recover the password of a user
     * @param {AuthApiRecoverUserPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public recoverUserPassword(requestParameters: AuthApiRecoverUserPasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).recoverUserPassword(requestParameters.recoverPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers a new user
     * @param {AuthApiRegisterUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public registerUser(requestParameters: AuthApiRegisterUserRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).registerUser(requestParameters.createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Re-sends the phone verification code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resendUserPhoneVerificationCode(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resendUserPhoneVerificationCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets the password of a staff user
     * @param {AuthApiResetUserPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetUserPassword(requestParameters: AuthApiResetUserPasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resetUserPassword(requestParameters.resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the current user
     * @param {AuthApiUpdateCurrentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateCurrentUser(requestParameters: AuthApiUpdateCurrentUserRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).updateCurrentUser(requestParameters.updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validates the code sent to the user phone
     * @param {AuthApiValidateUserPhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public validateUserPhone(requestParameters: AuthApiValidateUserPhoneRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).validateUserPhone(requestParameters.validatePhoneCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CartsApi - axios parameter creator
 * @export
 */
export const CartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculates the totals for a given cart
         * @param {string} restaurantId ID of the restaurant
         * @param {CalculateCartTotalsRequest} calculateCartTotalsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateCartTotals: async (restaurantId: string, calculateCartTotalsRequest: CalculateCartTotalsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('calculateCartTotals', 'restaurantId', restaurantId)
            // verify required parameter 'calculateCartTotalsRequest' is not null or undefined
            assertParamExists('calculateCartTotals', 'calculateCartTotalsRequest', calculateCartTotalsRequest)
            const localVarPath = `/api/v1/carts/restaurants/{restaurantId}/calculate`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateCartTotalsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartsApi - functional programming interface
 * @export
 */
export const CartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculates the totals for a given cart
         * @param {string} restaurantId ID of the restaurant
         * @param {CalculateCartTotalsRequest} calculateCartTotalsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateCartTotals(restaurantId: string, calculateCartTotalsRequest: CalculateCartTotalsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartCalculatedTotalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateCartTotals(restaurantId, calculateCartTotalsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CartsApi - factory interface
 * @export
 */
export const CartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartsApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculates the totals for a given cart
         * @param {string} restaurantId ID of the restaurant
         * @param {CalculateCartTotalsRequest} calculateCartTotalsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateCartTotals(restaurantId: string, calculateCartTotalsRequest: CalculateCartTotalsRequest, options?: any): AxiosPromise<CartCalculatedTotalsResponse> {
            return localVarFp.calculateCartTotals(restaurantId, calculateCartTotalsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calculateCartTotals operation in CartsApi.
 * @export
 * @interface CartsApiCalculateCartTotalsRequest
 */
export interface CartsApiCalculateCartTotalsRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof CartsApiCalculateCartTotals
     */
    readonly restaurantId: string

    /**
     * 
     * @type {CalculateCartTotalsRequest}
     * @memberof CartsApiCalculateCartTotals
     */
    readonly calculateCartTotalsRequest: CalculateCartTotalsRequest
}

/**
 * CartsApi - object-oriented interface
 * @export
 * @class CartsApi
 * @extends {BaseAPI}
 */
export class CartsApi extends BaseAPI {
    /**
     * 
     * @summary Calculates the totals for a given cart
     * @param {CartsApiCalculateCartTotalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public calculateCartTotals(requestParameters: CartsApiCalculateCartTotalsRequest, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).calculateCartTotals(requestParameters.restaurantId, requestParameters.calculateCartTotalsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the current company from the token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the current company from the token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the current company from the token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCompany(options?: any): AxiosPromise<CompanyPublicResponse> {
            return localVarFp.getCurrentCompany(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @summary Gets the current company from the token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCurrentCompany(options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCurrentCompany(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyRegionsApi - axios parameter creator
 * @export
 */
export const CompanyRegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the landing page configs by region in the current company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLandingPageRegionsConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/company-regions/landing-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyRegionsApi - functional programming interface
 * @export
 */
export const CompanyRegionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyRegionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the landing page configs by region in the current company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyLandingPageRegionsConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LandingPageRegionConfigResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyLandingPageRegionsConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyRegionsApi - factory interface
 * @export
 */
export const CompanyRegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyRegionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the landing page configs by region in the current company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLandingPageRegionsConfig(options?: any): AxiosPromise<Array<LandingPageRegionConfigResponse>> {
            return localVarFp.getCompanyLandingPageRegionsConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyRegionsApi - object-oriented interface
 * @export
 * @class CompanyRegionsApi
 * @extends {BaseAPI}
 */
export class CompanyRegionsApi extends BaseAPI {
    /**
     * 
     * @summary Gets the landing page configs by region in the current company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRegionsApi
     */
    public getCompanyLandingPageRegionsConfig(options?: AxiosRequestConfig) {
        return CompanyRegionsApiFp(this.configuration).getCompanyLandingPageRegionsConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConnectApi - axios parameter creator
 * @export
 */
export const ConnectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Connects apple user from the authorization code.
         * @param {AppleConnectRequest} appleConnectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleConnect: async (appleConnectRequest: AppleConnectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appleConnectRequest' is not null or undefined
            assertParamExists('appleConnect', 'appleConnectRequest', appleConnectRequest)
            const localVarPath = `/auth/connect/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appleConnectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the status of a facebook user in a company
         * @param {string} accessToken Facebook access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFacebookUserAccessToken: async (accessToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessToken' is not null or undefined
            assertParamExists('checkFacebookUserAccessToken', 'accessToken', accessToken)
            const localVarPath = `/auth/connect/facebook/token/{accessToken}`
                .replace(`{${"accessToken"}}`, encodeURIComponent(String(accessToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a new user using apple data
         * @param {AppleRegisterRequest} appleRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserFromApple: async (appleRegisterRequest: AppleRegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appleRegisterRequest' is not null or undefined
            assertParamExists('registerUserFromApple', 'appleRegisterRequest', appleRegisterRequest)
            const localVarPath = `/auth/connect/apple/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appleRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers a new user using facebook details
         * @param {FacebookRegisterRequest} facebookRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserFromFacebook: async (facebookRegisterRequest: FacebookRegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facebookRegisterRequest' is not null or undefined
            assertParamExists('registerUserFromFacebook', 'facebookRegisterRequest', facebookRegisterRequest)
            const localVarPath = `/auth/connect/facebook/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facebookRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectApi - functional programming interface
 * @export
 */
export const ConnectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectApiAxiosParamCreator(configuration)
    return {
        /**
         * Connects apple user from the authorization code.
         * @param {AppleConnectRequest} appleConnectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appleConnect(appleConnectRequest: AppleConnectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppleProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appleConnect(appleConnectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the status of a facebook user in a company
         * @param {string} accessToken Facebook access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkFacebookUserAccessToken(accessToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthProviderOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkFacebookUserAccessToken(accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers a new user using apple data
         * @param {AppleRegisterRequest} appleRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUserFromApple(appleRegisterRequest: AppleRegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUserFromApple(appleRegisterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registers a new user using facebook details
         * @param {FacebookRegisterRequest} facebookRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUserFromFacebook(facebookRegisterRequest: FacebookRegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUserFromFacebook(facebookRegisterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectApi - factory interface
 * @export
 */
export const ConnectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectApiFp(configuration)
    return {
        /**
         * Connects apple user from the authorization code.
         * @param {AppleConnectRequest} appleConnectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appleConnect(appleConnectRequest: AppleConnectRequest, options?: any): AxiosPromise<AppleProviderResponse> {
            return localVarFp.appleConnect(appleConnectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the status of a facebook user in a company
         * @param {string} accessToken Facebook access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFacebookUserAccessToken(accessToken: string, options?: any): AxiosPromise<AuthProviderOutputResponse> {
            return localVarFp.checkFacebookUserAccessToken(accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a new user using apple data
         * @param {AppleRegisterRequest} appleRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserFromApple(appleRegisterRequest: AppleRegisterRequest, options?: any): AxiosPromise<UserLoginResponse> {
            return localVarFp.registerUserFromApple(appleRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers a new user using facebook details
         * @param {FacebookRegisterRequest} facebookRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserFromFacebook(facebookRegisterRequest: FacebookRegisterRequest, options?: any): AxiosPromise<UserLoginResponse> {
            return localVarFp.registerUserFromFacebook(facebookRegisterRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appleConnect operation in ConnectApi.
 * @export
 * @interface ConnectApiAppleConnectRequest
 */
export interface ConnectApiAppleConnectRequest {
    /**
     * 
     * @type {AppleConnectRequest}
     * @memberof ConnectApiAppleConnect
     */
    readonly appleConnectRequest: AppleConnectRequest
}

/**
 * Request parameters for checkFacebookUserAccessToken operation in ConnectApi.
 * @export
 * @interface ConnectApiCheckFacebookUserAccessTokenRequest
 */
export interface ConnectApiCheckFacebookUserAccessTokenRequest {
    /**
     * Facebook access token
     * @type {string}
     * @memberof ConnectApiCheckFacebookUserAccessToken
     */
    readonly accessToken: string
}

/**
 * Request parameters for registerUserFromApple operation in ConnectApi.
 * @export
 * @interface ConnectApiRegisterUserFromAppleRequest
 */
export interface ConnectApiRegisterUserFromAppleRequest {
    /**
     * 
     * @type {AppleRegisterRequest}
     * @memberof ConnectApiRegisterUserFromApple
     */
    readonly appleRegisterRequest: AppleRegisterRequest
}

/**
 * Request parameters for registerUserFromFacebook operation in ConnectApi.
 * @export
 * @interface ConnectApiRegisterUserFromFacebookRequest
 */
export interface ConnectApiRegisterUserFromFacebookRequest {
    /**
     * 
     * @type {FacebookRegisterRequest}
     * @memberof ConnectApiRegisterUserFromFacebook
     */
    readonly facebookRegisterRequest: FacebookRegisterRequest
}

/**
 * ConnectApi - object-oriented interface
 * @export
 * @class ConnectApi
 * @extends {BaseAPI}
 */
export class ConnectApi extends BaseAPI {
    /**
     * Connects apple user from the authorization code.
     * @param {ConnectApiAppleConnectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public appleConnect(requestParameters: ConnectApiAppleConnectRequest, options?: AxiosRequestConfig) {
        return ConnectApiFp(this.configuration).appleConnect(requestParameters.appleConnectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the status of a facebook user in a company
     * @param {ConnectApiCheckFacebookUserAccessTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public checkFacebookUserAccessToken(requestParameters: ConnectApiCheckFacebookUserAccessTokenRequest, options?: AxiosRequestConfig) {
        return ConnectApiFp(this.configuration).checkFacebookUserAccessToken(requestParameters.accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers a new user using apple data
     * @param {ConnectApiRegisterUserFromAppleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public registerUserFromApple(requestParameters: ConnectApiRegisterUserFromAppleRequest, options?: AxiosRequestConfig) {
        return ConnectApiFp(this.configuration).registerUserFromApple(requestParameters.appleRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers a new user using facebook details
     * @param {ConnectApiRegisterUserFromFacebookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public registerUserFromFacebook(requestParameters: ConnectApiRegisterUserFromFacebookRequest, options?: AxiosRequestConfig) {
        return ConnectApiFp(this.configuration).registerUserFromFacebook(requestParameters.facebookRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Callback from payment via URL
         * @param {string} restaurantId ID of the restaurant
         * @param {string} cartId ID of the cart
         * @param {string} token Token of the cart
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paygreenPaymentURLCallback: async (restaurantId: string, cartId: string, token: string, pid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('paygreenPaymentURLCallback', 'restaurantId', restaurantId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('paygreenPaymentURLCallback', 'cartId', cartId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('paygreenPaymentURLCallback', 'token', token)
            // verify required parameter 'pid' is not null or undefined
            assertParamExists('paygreenPaymentURLCallback', 'pid', pid)
            const localVarPath = `/api/v1/paygreen/{restaurantId}/callback/{cartId}`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)))
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (pid !== undefined) {
                localVarQueryParameter['pid'] = pid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async check(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.check(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Callback from payment via URL
         * @param {string} restaurantId ID of the restaurant
         * @param {string} cartId ID of the cart
         * @param {string} token Token of the cart
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paygreenPaymentURLCallback(restaurantId: string, cartId: string, token: string, pid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paygreenPaymentURLCallback(restaurantId, cartId, token, pid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.check(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Callback from payment via URL
         * @param {string} restaurantId ID of the restaurant
         * @param {string} cartId ID of the cart
         * @param {string} token Token of the cart
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paygreenPaymentURLCallback(restaurantId: string, cartId: string, token: string, pid: string, options?: any): AxiosPromise<void> {
            return localVarFp.paygreenPaymentURLCallback(restaurantId, cartId, token, pid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for paygreenPaymentURLCallback operation in DefaultApi.
 * @export
 * @interface DefaultApiPaygreenPaymentURLCallbackRequest
 */
export interface DefaultApiPaygreenPaymentURLCallbackRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof DefaultApiPaygreenPaymentURLCallback
     */
    readonly restaurantId: string

    /**
     * ID of the cart
     * @type {string}
     * @memberof DefaultApiPaygreenPaymentURLCallback
     */
    readonly cartId: string

    /**
     * Token of the cart
     * @type {string}
     * @memberof DefaultApiPaygreenPaymentURLCallback
     */
    readonly token: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaygreenPaymentURLCallback
     */
    readonly pid: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public check(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).check(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Callback from payment via URL
     * @param {DefaultApiPaygreenPaymentURLCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paygreenPaymentURLCallback(requestParameters: DefaultApiPaygreenPaymentURLCallbackRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paygreenPaymentURLCallback(requestParameters.restaurantId, requestParameters.cartId, requestParameters.token, requestParameters.pid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountsApi - axios parameter creator
 * @export
 */
export const DiscountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a discount in the restaurant with the given code
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {string} [code] Code of the discount
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDiscountInRestaurantFromCode: async (restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getDiscountInRestaurantFromCode', 'restaurantId', restaurantId)
            // verify required parameter 'orderTypes' is not null or undefined
            assertParamExists('getDiscountInRestaurantFromCode', 'orderTypes', orderTypes)
            // verify required parameter 'platforms' is not null or undefined
            assertParamExists('getDiscountInRestaurantFromCode', 'platforms', platforms)
            const localVarPath = `/api/v1/restaurants/{restaurantId}/discounts/code`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderTypes) {
                localVarQueryParameter['orderTypes'] = orderTypes;
            }

            if (platforms) {
                localVarQueryParameter['platforms'] = platforms;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the discounts that ara available in a given restaurant.
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscountsAvailableInRestaurant: async (restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getDiscountsAvailableInRestaurant', 'restaurantId', restaurantId)
            // verify required parameter 'orderTypes' is not null or undefined
            assertParamExists('getDiscountsAvailableInRestaurant', 'orderTypes', orderTypes)
            // verify required parameter 'platforms' is not null or undefined
            assertParamExists('getDiscountsAvailableInRestaurant', 'platforms', platforms)
            const localVarPath = `/api/v1/restaurants/{restaurantId}/discounts`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderTypes) {
                localVarQueryParameter['orderTypes'] = orderTypes;
            }

            if (platforms) {
                localVarQueryParameter['platforms'] = platforms;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountsApi - functional programming interface
 * @export
 */
export const DiscountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a discount in the restaurant with the given code
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {string} [code] Code of the discount
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDiscountInRestaurantFromCode(restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscountInRestaurantFromCode(restaurantId, orderTypes, platforms, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the discounts that ara available in a given restaurant.
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiscountsAvailableInRestaurant(restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiscountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscountsAvailableInRestaurant(restaurantId, orderTypes, platforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountsApi - factory interface
 * @export
 */
export const DiscountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a discount in the restaurant with the given code
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {string} [code] Code of the discount
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDiscountInRestaurantFromCode(restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, code?: string, options?: any): AxiosPromise<DiscountResponse> {
            return localVarFp.getDiscountInRestaurantFromCode(restaurantId, orderTypes, platforms, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the discounts that ara available in a given restaurant.
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscountsAvailableInRestaurant(restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, options?: any): AxiosPromise<Array<DiscountResponse>> {
            return localVarFp.getDiscountsAvailableInRestaurant(restaurantId, orderTypes, platforms, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDiscountInRestaurantFromCode operation in DiscountsApi.
 * @export
 * @interface DiscountsApiGetDiscountInRestaurantFromCodeRequest
 */
export interface DiscountsApiGetDiscountInRestaurantFromCodeRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof DiscountsApiGetDiscountInRestaurantFromCode
     */
    readonly restaurantId: string

    /**
     * Order types where this discount can be applied
     * @type {Array<OrderType>}
     * @memberof DiscountsApiGetDiscountInRestaurantFromCode
     */
    readonly orderTypes: Array<OrderType>

    /**
     * Platforms where this discount can be applied
     * @type {Array<OrderPlatform>}
     * @memberof DiscountsApiGetDiscountInRestaurantFromCode
     */
    readonly platforms: Array<OrderPlatform>

    /**
     * Code of the discount
     * @type {string}
     * @memberof DiscountsApiGetDiscountInRestaurantFromCode
     */
    readonly code?: string
}

/**
 * Request parameters for getDiscountsAvailableInRestaurant operation in DiscountsApi.
 * @export
 * @interface DiscountsApiGetDiscountsAvailableInRestaurantRequest
 */
export interface DiscountsApiGetDiscountsAvailableInRestaurantRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof DiscountsApiGetDiscountsAvailableInRestaurant
     */
    readonly restaurantId: string

    /**
     * Order types where this discount can be applied
     * @type {Array<OrderType>}
     * @memberof DiscountsApiGetDiscountsAvailableInRestaurant
     */
    readonly orderTypes: Array<OrderType>

    /**
     * Platforms where this discount can be applied
     * @type {Array<OrderPlatform>}
     * @memberof DiscountsApiGetDiscountsAvailableInRestaurant
     */
    readonly platforms: Array<OrderPlatform>
}

/**
 * DiscountsApi - object-oriented interface
 * @export
 * @class DiscountsApi
 * @extends {BaseAPI}
 */
export class DiscountsApi extends BaseAPI {
    /**
     * 
     * @summary Gets a discount in the restaurant with the given code
     * @param {DiscountsApiGetDiscountInRestaurantFromCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public getDiscountInRestaurantFromCode(requestParameters: DiscountsApiGetDiscountInRestaurantFromCodeRequest, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).getDiscountInRestaurantFromCode(requestParameters.restaurantId, requestParameters.orderTypes, requestParameters.platforms, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the discounts that ara available in a given restaurant.
     * @param {DiscountsApiGetDiscountsAvailableInRestaurantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public getDiscountsAvailableInRestaurant(requestParameters: DiscountsApiGetDiscountsAvailableInRestaurantRequest, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).getDiscountsAvailableInRestaurant(requestParameters.restaurantId, requestParameters.orderTypes, requestParameters.platforms, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EdenredApi - axios parameter creator
 * @export
 */
export const EdenredApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the url to get a token from edenred
         * @param {string} restaurantId ID of the restaurant
         * @param {Language} language Language for the edenred login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEdenredTokenUrl: async (restaurantId: string, language: Language, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getEdenredTokenUrl', 'restaurantId', restaurantId)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('getEdenredTokenUrl', 'language', language)
            const localVarPath = `/api/v1/edenred/restaurants/{restaurantId}/token`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EdenredApi - functional programming interface
 * @export
 */
export const EdenredApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EdenredApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the url to get a token from edenred
         * @param {string} restaurantId ID of the restaurant
         * @param {Language} language Language for the edenred login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEdenredTokenUrl(restaurantId: string, language: Language, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEdenredTokenUrl(restaurantId, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EdenredApi - factory interface
 * @export
 */
export const EdenredApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EdenredApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the url to get a token from edenred
         * @param {string} restaurantId ID of the restaurant
         * @param {Language} language Language for the edenred login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEdenredTokenUrl(restaurantId: string, language: Language, options?: any): AxiosPromise<string> {
            return localVarFp.getEdenredTokenUrl(restaurantId, language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEdenredTokenUrl operation in EdenredApi.
 * @export
 * @interface EdenredApiGetEdenredTokenUrlRequest
 */
export interface EdenredApiGetEdenredTokenUrlRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof EdenredApiGetEdenredTokenUrl
     */
    readonly restaurantId: string

    /**
     * Language for the edenred login page
     * @type {Language}
     * @memberof EdenredApiGetEdenredTokenUrl
     */
    readonly language: Language
}

/**
 * EdenredApi - object-oriented interface
 * @export
 * @class EdenredApi
 * @extends {BaseAPI}
 */
export class EdenredApi extends BaseAPI {
    /**
     * 
     * @summary Gets the url to get a token from edenred
     * @param {EdenredApiGetEdenredTokenUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EdenredApi
     */
    public getEdenredTokenUrl(requestParameters: EdenredApiGetEdenredTokenUrlRequest, options?: AxiosRequestConfig) {
        return EdenredApiFp(this.configuration).getEdenredTokenUrl(requestParameters.restaurantId, requestParameters.language, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FidelityProgramApi - axios parameter creator
 * @export
 */
export const FidelityProgramApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get an active company fidelity program
         * @param {string} companyId ID of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFidelityProgram: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyFidelityProgram', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}/fidelity-program`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an active company fidelity program
         * @param {string} regionId ID of the region
         * @param {any} companyId ID of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyRegionFidelityProgram: async (regionId: string, companyId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getCompanyRegionFidelityProgram', 'regionId', regionId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyRegionFidelityProgram', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}/company-regions/{regionId}/fidelity-program`
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an active restaurant\'s fidelity program
         * @param {string} restaurantId ID of the restaurant
         * @param {any} companyId ID of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantFidelityProgram: async (restaurantId: string, companyId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getRestaurantFidelityProgram', 'restaurantId', restaurantId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getRestaurantFidelityProgram', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}/fidelity-program/restaurants/{restaurantId}/fidelity-program`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FidelityProgramApi - functional programming interface
 * @export
 */
export const FidelityProgramApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FidelityProgramApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get an active company fidelity program
         * @param {string} companyId ID of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyFidelityProgram(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FidelityProgramResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyFidelityProgram(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an active company fidelity program
         * @param {string} regionId ID of the region
         * @param {any} companyId ID of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyRegionFidelityProgram(regionId: string, companyId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FidelityProgramResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyRegionFidelityProgram(regionId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an active restaurant\'s fidelity program
         * @param {string} restaurantId ID of the restaurant
         * @param {any} companyId ID of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurantFidelityProgram(restaurantId: string, companyId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FidelityProgramResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurantFidelityProgram(restaurantId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FidelityProgramApi - factory interface
 * @export
 */
export const FidelityProgramApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FidelityProgramApiFp(configuration)
    return {
        /**
         * 
         * @summary Get an active company fidelity program
         * @param {string} companyId ID of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFidelityProgram(companyId: string, options?: any): AxiosPromise<FidelityProgramResponse> {
            return localVarFp.getCompanyFidelityProgram(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an active company fidelity program
         * @param {string} regionId ID of the region
         * @param {any} companyId ID of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyRegionFidelityProgram(regionId: string, companyId: any, options?: any): AxiosPromise<FidelityProgramResponse> {
            return localVarFp.getCompanyRegionFidelityProgram(regionId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an active restaurant\'s fidelity program
         * @param {string} restaurantId ID of the restaurant
         * @param {any} companyId ID of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantFidelityProgram(restaurantId: string, companyId: any, options?: any): AxiosPromise<FidelityProgramResponse> {
            return localVarFp.getRestaurantFidelityProgram(restaurantId, companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCompanyFidelityProgram operation in FidelityProgramApi.
 * @export
 * @interface FidelityProgramApiGetCompanyFidelityProgramRequest
 */
export interface FidelityProgramApiGetCompanyFidelityProgramRequest {
    /**
     * ID of the company
     * @type {string}
     * @memberof FidelityProgramApiGetCompanyFidelityProgram
     */
    readonly companyId: string
}

/**
 * Request parameters for getCompanyRegionFidelityProgram operation in FidelityProgramApi.
 * @export
 * @interface FidelityProgramApiGetCompanyRegionFidelityProgramRequest
 */
export interface FidelityProgramApiGetCompanyRegionFidelityProgramRequest {
    /**
     * ID of the region
     * @type {string}
     * @memberof FidelityProgramApiGetCompanyRegionFidelityProgram
     */
    readonly regionId: string

    /**
     * ID of the company
     * @type {any}
     * @memberof FidelityProgramApiGetCompanyRegionFidelityProgram
     */
    readonly companyId: any
}

/**
 * Request parameters for getRestaurantFidelityProgram operation in FidelityProgramApi.
 * @export
 * @interface FidelityProgramApiGetRestaurantFidelityProgramRequest
 */
export interface FidelityProgramApiGetRestaurantFidelityProgramRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof FidelityProgramApiGetRestaurantFidelityProgram
     */
    readonly restaurantId: string

    /**
     * ID of the company
     * @type {any}
     * @memberof FidelityProgramApiGetRestaurantFidelityProgram
     */
    readonly companyId: any
}

/**
 * FidelityProgramApi - object-oriented interface
 * @export
 * @class FidelityProgramApi
 * @extends {BaseAPI}
 */
export class FidelityProgramApi extends BaseAPI {
    /**
     * 
     * @summary Get an active company fidelity program
     * @param {FidelityProgramApiGetCompanyFidelityProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityProgramApi
     */
    public getCompanyFidelityProgram(requestParameters: FidelityProgramApiGetCompanyFidelityProgramRequest, options?: AxiosRequestConfig) {
        return FidelityProgramApiFp(this.configuration).getCompanyFidelityProgram(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an active company fidelity program
     * @param {FidelityProgramApiGetCompanyRegionFidelityProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityProgramApi
     */
    public getCompanyRegionFidelityProgram(requestParameters: FidelityProgramApiGetCompanyRegionFidelityProgramRequest, options?: AxiosRequestConfig) {
        return FidelityProgramApiFp(this.configuration).getCompanyRegionFidelityProgram(requestParameters.regionId, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an active restaurant\'s fidelity program
     * @param {FidelityProgramApiGetRestaurantFidelityProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityProgramApi
     */
    public getRestaurantFidelityProgram(requestParameters: FidelityProgramApiGetRestaurantFidelityProgramRequest, options?: AxiosRequestConfig) {
        return FidelityProgramApiFp(this.configuration).getRestaurantFidelityProgram(requestParameters.restaurantId, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KiosksApi - axios parameter creator
 * @export
 */
export const KiosksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a kiosk by the device id
         * @param {string} deviceId ID of the device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskForDeviceId: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getKioskForDeviceId', 'deviceId', deviceId)
            const localVarPath = `/api/v1/kiosks/device/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the kiosk latest version to download
         * @param {string} deviceId ID of the device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskLatestVersion: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getKioskLatestVersion', 'deviceId', deviceId)
            const localVarPath = `/api/v1/kiosks/device/{deviceId}/download`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the kiosk latest version to download
         * @param {string} deviceId ID of the device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskLatestVersionInfo: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getKioskLatestVersionInfo', 'deviceId', deviceId)
            const localVarPath = `/api/v1/kiosks/device/{deviceId}/latest`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setups a kiosk by the device id
         * @param {string} deviceId ID of the device
         * @param {SetupKioskRequest} setupKioskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupKiosk: async (deviceId: string, setupKioskRequest: SetupKioskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('setupKiosk', 'deviceId', deviceId)
            // verify required parameter 'setupKioskRequest' is not null or undefined
            assertParamExists('setupKiosk', 'setupKioskRequest', setupKioskRequest)
            const localVarPath = `/api/v1/kiosks/device/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setupKioskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setups a kiosk by the device id
         * @param {string} deviceId ID of the device
         * @param {SetupKioskRequest} setupKioskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupKioskUpdater: async (deviceId: string, setupKioskRequest: SetupKioskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('setupKioskUpdater', 'deviceId', deviceId)
            // verify required parameter 'setupKioskRequest' is not null or undefined
            assertParamExists('setupKioskUpdater', 'setupKioskRequest', setupKioskRequest)
            const localVarPath = `/api/v1/kiosks/updater/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setupKioskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks whether the unlock code is valid for the kiosk
         * @param {string} deviceId ID of the device
         * @param {UnlockKioskRequest} unlockKioskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockKiosk: async (deviceId: string, unlockKioskRequest: UnlockKioskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('unlockKiosk', 'deviceId', deviceId)
            // verify required parameter 'unlockKioskRequest' is not null or undefined
            assertParamExists('unlockKiosk', 'unlockKioskRequest', unlockKioskRequest)
            const localVarPath = `/api/v1/kiosks/device/{deviceId}/unlock`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unlockKioskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the device info of a kiosk
         * @param {string} deviceId ID of the device
         * @param {UpdateKioskDeviceInfoRequest} updateKioskDeviceInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKioskDeviceInfo: async (deviceId: string, updateKioskDeviceInfoRequest: UpdateKioskDeviceInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateKioskDeviceInfo', 'deviceId', deviceId)
            // verify required parameter 'updateKioskDeviceInfoRequest' is not null or undefined
            assertParamExists('updateKioskDeviceInfo', 'updateKioskDeviceInfoRequest', updateKioskDeviceInfoRequest)
            const localVarPath = `/api/v1/kiosks/device/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKioskDeviceInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KiosksApi - functional programming interface
 * @export
 */
export const KiosksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KiosksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a kiosk by the device id
         * @param {string} deviceId ID of the device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKioskForDeviceId(deviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskForDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKioskForDeviceId(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the kiosk latest version to download
         * @param {string} deviceId ID of the device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKioskLatestVersion(deviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKioskLatestVersion(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the kiosk latest version to download
         * @param {string} deviceId ID of the device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKioskLatestVersionInfo(deviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskLatestVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKioskLatestVersionInfo(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setups a kiosk by the device id
         * @param {string} deviceId ID of the device
         * @param {SetupKioskRequest} setupKioskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setupKiosk(deviceId: string, setupKioskRequest: SetupKioskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskForDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setupKiosk(deviceId, setupKioskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setups a kiosk by the device id
         * @param {string} deviceId ID of the device
         * @param {SetupKioskRequest} setupKioskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setupKioskUpdater(deviceId: string, setupKioskRequest: SetupKioskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskForDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setupKioskUpdater(deviceId, setupKioskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks whether the unlock code is valid for the kiosk
         * @param {string} deviceId ID of the device
         * @param {UnlockKioskRequest} unlockKioskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockKiosk(deviceId: string, unlockKioskRequest: UnlockKioskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockKiosk(deviceId, unlockKioskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the device info of a kiosk
         * @param {string} deviceId ID of the device
         * @param {UpdateKioskDeviceInfoRequest} updateKioskDeviceInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateKioskDeviceInfo(deviceId: string, updateKioskDeviceInfoRequest: UpdateKioskDeviceInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateKioskDeviceInfo(deviceId, updateKioskDeviceInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KiosksApi - factory interface
 * @export
 */
export const KiosksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KiosksApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a kiosk by the device id
         * @param {string} deviceId ID of the device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskForDeviceId(deviceId: string, options?: any): AxiosPromise<KioskForDeviceResponse> {
            return localVarFp.getKioskForDeviceId(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the kiosk latest version to download
         * @param {string} deviceId ID of the device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskLatestVersion(deviceId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getKioskLatestVersion(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the kiosk latest version to download
         * @param {string} deviceId ID of the device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKioskLatestVersionInfo(deviceId: string, options?: any): AxiosPromise<KioskLatestVersionResponse> {
            return localVarFp.getKioskLatestVersionInfo(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setups a kiosk by the device id
         * @param {string} deviceId ID of the device
         * @param {SetupKioskRequest} setupKioskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupKiosk(deviceId: string, setupKioskRequest: SetupKioskRequest, options?: any): AxiosPromise<KioskForDeviceResponse> {
            return localVarFp.setupKiosk(deviceId, setupKioskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setups a kiosk by the device id
         * @param {string} deviceId ID of the device
         * @param {SetupKioskRequest} setupKioskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupKioskUpdater(deviceId: string, setupKioskRequest: SetupKioskRequest, options?: any): AxiosPromise<KioskForDeviceResponse> {
            return localVarFp.setupKioskUpdater(deviceId, setupKioskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks whether the unlock code is valid for the kiosk
         * @param {string} deviceId ID of the device
         * @param {UnlockKioskRequest} unlockKioskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockKiosk(deviceId: string, unlockKioskRequest: UnlockKioskRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.unlockKiosk(deviceId, unlockKioskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the device info of a kiosk
         * @param {string} deviceId ID of the device
         * @param {UpdateKioskDeviceInfoRequest} updateKioskDeviceInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKioskDeviceInfo(deviceId: string, updateKioskDeviceInfoRequest: UpdateKioskDeviceInfoRequest, options?: any): AxiosPromise<KioskResponse> {
            return localVarFp.updateKioskDeviceInfo(deviceId, updateKioskDeviceInfoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getKioskForDeviceId operation in KiosksApi.
 * @export
 * @interface KiosksApiGetKioskForDeviceIdRequest
 */
export interface KiosksApiGetKioskForDeviceIdRequest {
    /**
     * ID of the device
     * @type {string}
     * @memberof KiosksApiGetKioskForDeviceId
     */
    readonly deviceId: string
}

/**
 * Request parameters for getKioskLatestVersion operation in KiosksApi.
 * @export
 * @interface KiosksApiGetKioskLatestVersionRequest
 */
export interface KiosksApiGetKioskLatestVersionRequest {
    /**
     * ID of the device
     * @type {string}
     * @memberof KiosksApiGetKioskLatestVersion
     */
    readonly deviceId: string
}

/**
 * Request parameters for getKioskLatestVersionInfo operation in KiosksApi.
 * @export
 * @interface KiosksApiGetKioskLatestVersionInfoRequest
 */
export interface KiosksApiGetKioskLatestVersionInfoRequest {
    /**
     * ID of the device
     * @type {string}
     * @memberof KiosksApiGetKioskLatestVersionInfo
     */
    readonly deviceId: string
}

/**
 * Request parameters for setupKiosk operation in KiosksApi.
 * @export
 * @interface KiosksApiSetupKioskRequest
 */
export interface KiosksApiSetupKioskRequest {
    /**
     * ID of the device
     * @type {string}
     * @memberof KiosksApiSetupKiosk
     */
    readonly deviceId: string

    /**
     * 
     * @type {SetupKioskRequest}
     * @memberof KiosksApiSetupKiosk
     */
    readonly setupKioskRequest: SetupKioskRequest
}

/**
 * Request parameters for setupKioskUpdater operation in KiosksApi.
 * @export
 * @interface KiosksApiSetupKioskUpdaterRequest
 */
export interface KiosksApiSetupKioskUpdaterRequest {
    /**
     * ID of the device
     * @type {string}
     * @memberof KiosksApiSetupKioskUpdater
     */
    readonly deviceId: string

    /**
     * 
     * @type {SetupKioskRequest}
     * @memberof KiosksApiSetupKioskUpdater
     */
    readonly setupKioskRequest: SetupKioskRequest
}

/**
 * Request parameters for unlockKiosk operation in KiosksApi.
 * @export
 * @interface KiosksApiUnlockKioskRequest
 */
export interface KiosksApiUnlockKioskRequest {
    /**
     * ID of the device
     * @type {string}
     * @memberof KiosksApiUnlockKiosk
     */
    readonly deviceId: string

    /**
     * 
     * @type {UnlockKioskRequest}
     * @memberof KiosksApiUnlockKiosk
     */
    readonly unlockKioskRequest: UnlockKioskRequest
}

/**
 * Request parameters for updateKioskDeviceInfo operation in KiosksApi.
 * @export
 * @interface KiosksApiUpdateKioskDeviceInfoRequest
 */
export interface KiosksApiUpdateKioskDeviceInfoRequest {
    /**
     * ID of the device
     * @type {string}
     * @memberof KiosksApiUpdateKioskDeviceInfo
     */
    readonly deviceId: string

    /**
     * 
     * @type {UpdateKioskDeviceInfoRequest}
     * @memberof KiosksApiUpdateKioskDeviceInfo
     */
    readonly updateKioskDeviceInfoRequest: UpdateKioskDeviceInfoRequest
}

/**
 * KiosksApi - object-oriented interface
 * @export
 * @class KiosksApi
 * @extends {BaseAPI}
 */
export class KiosksApi extends BaseAPI {
    /**
     * 
     * @summary Gets a kiosk by the device id
     * @param {KiosksApiGetKioskForDeviceIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KiosksApi
     */
    public getKioskForDeviceId(requestParameters: KiosksApiGetKioskForDeviceIdRequest, options?: AxiosRequestConfig) {
        return KiosksApiFp(this.configuration).getKioskForDeviceId(requestParameters.deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the kiosk latest version to download
     * @param {KiosksApiGetKioskLatestVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KiosksApi
     */
    public getKioskLatestVersion(requestParameters: KiosksApiGetKioskLatestVersionRequest, options?: AxiosRequestConfig) {
        return KiosksApiFp(this.configuration).getKioskLatestVersion(requestParameters.deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the kiosk latest version to download
     * @param {KiosksApiGetKioskLatestVersionInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KiosksApi
     */
    public getKioskLatestVersionInfo(requestParameters: KiosksApiGetKioskLatestVersionInfoRequest, options?: AxiosRequestConfig) {
        return KiosksApiFp(this.configuration).getKioskLatestVersionInfo(requestParameters.deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setups a kiosk by the device id
     * @param {KiosksApiSetupKioskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KiosksApi
     */
    public setupKiosk(requestParameters: KiosksApiSetupKioskRequest, options?: AxiosRequestConfig) {
        return KiosksApiFp(this.configuration).setupKiosk(requestParameters.deviceId, requestParameters.setupKioskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setups a kiosk by the device id
     * @param {KiosksApiSetupKioskUpdaterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KiosksApi
     */
    public setupKioskUpdater(requestParameters: KiosksApiSetupKioskUpdaterRequest, options?: AxiosRequestConfig) {
        return KiosksApiFp(this.configuration).setupKioskUpdater(requestParameters.deviceId, requestParameters.setupKioskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks whether the unlock code is valid for the kiosk
     * @param {KiosksApiUnlockKioskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KiosksApi
     */
    public unlockKiosk(requestParameters: KiosksApiUnlockKioskRequest, options?: AxiosRequestConfig) {
        return KiosksApiFp(this.configuration).unlockKiosk(requestParameters.deviceId, requestParameters.unlockKioskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the device info of a kiosk
     * @param {KiosksApiUpdateKioskDeviceInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KiosksApi
     */
    public updateKioskDeviceInfo(requestParameters: KiosksApiUpdateKioskDeviceInfoRequest, options?: AxiosRequestConfig) {
        return KiosksApiFp(this.configuration).updateKioskDeviceInfo(requestParameters.deviceId, requestParameters.updateKioskDeviceInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checks the balance of a meal payment method
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderUsingPayment} createOrderUsingPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMealPaymentBalance: async (restaurantId: string, createOrderUsingPayment: CreateOrderUsingPayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('checkMealPaymentBalance', 'restaurantId', restaurantId)
            // verify required parameter 'createOrderUsingPayment' is not null or undefined
            assertParamExists('checkMealPaymentBalance', 'createOrderUsingPayment', createOrderUsingPayment)
            const localVarPath = `/api/v1/orders/{restaurantId}/payment/check-balance`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderUsingPayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks the result of an order payed using a payment link
         * @param {string} restaurantId ID of the restaurant
         * @param {string} linkId ID of the payment link
         * @param {CheckPaymentLinkOrderRequest} checkPaymentLinkOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPaymentLink: async (restaurantId: string, linkId: string, checkPaymentLinkOrderRequest: CheckPaymentLinkOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('checkPaymentLink', 'restaurantId', restaurantId)
            // verify required parameter 'linkId' is not null or undefined
            assertParamExists('checkPaymentLink', 'linkId', linkId)
            // verify required parameter 'checkPaymentLinkOrderRequest' is not null or undefined
            assertParamExists('checkPaymentLink', 'checkPaymentLinkOrderRequest', checkPaymentLinkOrderRequest)
            const localVarPath = `/api/v1/orders/{restaurantId}/link/{linkId}`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)))
                .replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkPaymentLinkOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an order that will be payed using cash later
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderUsingCashRequest} createOrderUsingCashRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderUsingCash: async (restaurantId: string, createOrderUsingCashRequest: CreateOrderUsingCashRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('createOrderUsingCash', 'restaurantId', restaurantId)
            // verify required parameter 'createOrderUsingCashRequest' is not null or undefined
            assertParamExists('createOrderUsingCash', 'createOrderUsingCashRequest', createOrderUsingCashRequest)
            const localVarPath = `/api/v1/orders/{restaurantId}/cash`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderUsingCashRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an order using some payment information
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderUsingPayment} createOrderUsingPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderUsingPayment: async (restaurantId: string, createOrderUsingPayment: CreateOrderUsingPayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('createOrderUsingPayment', 'restaurantId', restaurantId)
            // verify required parameter 'createOrderUsingPayment' is not null or undefined
            assertParamExists('createOrderUsingPayment', 'createOrderUsingPayment', createOrderUsingPayment)
            const localVarPath = `/api/v1/orders/{restaurantId}/payment`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderUsingPayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an order that will be payed using a third-party service (through an URL sent to the client.)
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderViaLinkRequest} createOrderViaLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderViaPaymentLink: async (restaurantId: string, createOrderViaLinkRequest: CreateOrderViaLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('createOrderViaPaymentLink', 'restaurantId', restaurantId)
            // verify required parameter 'createOrderViaLinkRequest' is not null or undefined
            assertParamExists('createOrderViaPaymentLink', 'createOrderViaLinkRequest', createOrderViaLinkRequest)
            const localVarPath = `/api/v1/orders/{restaurantId}/link`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderViaLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an order that will be payed using a third-party service (through an URL sent to the client.)
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderViaURLRequest} createOrderViaURLRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderViaPaymentURL: async (restaurantId: string, createOrderViaURLRequest: CreateOrderViaURLRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('createOrderViaPaymentURL', 'restaurantId', restaurantId)
            // verify required parameter 'createOrderViaURLRequest' is not null or undefined
            assertParamExists('createOrderViaPaymentURL', 'createOrderViaURLRequest', createOrderViaURLRequest)
            const localVarPath = `/api/v1/orders/{restaurantId}/url`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderViaURLRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets one order by its trace data
         * @param {string} id ID of the trace
         * @param {string} restaurantId ID of the restaurant
         * @param {string} token Token to identify an order trace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderByTraceData: async (id: string, restaurantId: string, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderByTraceData', 'id', id)
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getOrderByTraceData', 'restaurantId', restaurantId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getOrderByTraceData', 'token', token)
            const localVarPath = `/api/v1/orders/trace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (restaurantId !== undefined) {
                localVarQueryParameter['restaurantId'] = restaurantId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an order from its anonymous token
         * @param {string} anonymousToken Anonymous token from the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderFromAnonymousToken: async (anonymousToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'anonymousToken' is not null or undefined
            assertParamExists('getOrderFromAnonymousToken', 'anonymousToken', anonymousToken)
            const localVarPath = `/api/v1/orders/token/{anonymousToken}`
                .replace(`{${"anonymousToken"}}`, encodeURIComponent(String(anonymousToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the orders for the current user
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOrderById: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getPublicOrderById', 'orderId', orderId)
            const localVarPath = `/api/v1/orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the orders for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Processes the result from a payment action
         * @param {string} restaurantId ID of the restaurant
         * @param {ProcessPaymentActionResultRequest} processPaymentActionResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPaymentActionResult: async (restaurantId: string, processPaymentActionResultRequest: ProcessPaymentActionResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('processPaymentActionResult', 'restaurantId', restaurantId)
            // verify required parameter 'processPaymentActionResultRequest' is not null or undefined
            assertParamExists('processPaymentActionResult', 'processPaymentActionResultRequest', processPaymentActionResultRequest)
            const localVarPath = `/api/v1/orders/{restaurantId}/payment/action-result`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processPaymentActionResultRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends the invoice of the order id by email
         * @param {string} orderId ID of the order
         * @param {SendOrderInvoiceRequest} sendOrderInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOrderInvoice: async (orderId: string, sendOrderInvoiceRequest: SendOrderInvoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('sendOrderInvoice', 'orderId', orderId)
            // verify required parameter 'sendOrderInvoiceRequest' is not null or undefined
            assertParamExists('sendOrderInvoice', 'sendOrderInvoiceRequest', sendOrderInvoiceRequest)
            const localVarPath = `/api/v1/orders/{orderId}/invoice`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendOrderInvoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Checks the balance of a meal payment method
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderUsingPayment} createOrderUsingPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkMealPaymentBalance(restaurantId: string, createOrderUsingPayment: CreateOrderUsingPayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPaymentBalanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkMealPaymentBalance(restaurantId, createOrderUsingPayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks the result of an order payed using a payment link
         * @param {string} restaurantId ID of the restaurant
         * @param {string} linkId ID of the payment link
         * @param {CheckPaymentLinkOrderRequest} checkPaymentLinkOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPaymentLink(restaurantId: string, linkId: string, checkPaymentLinkOrderRequest: CheckPaymentLinkOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPaymentLink(restaurantId, linkId, checkPaymentLinkOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an order that will be payed using cash later
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderUsingCashRequest} createOrderUsingCashRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderUsingCash(restaurantId: string, createOrderUsingCashRequest: CreateOrderUsingCashRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderUsingCash(restaurantId, createOrderUsingCashRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an order using some payment information
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderUsingPayment} createOrderUsingPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderUsingPayment(restaurantId: string, createOrderUsingPayment: CreateOrderUsingPayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderUsingPayment(restaurantId, createOrderUsingPayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an order that will be payed using a third-party service (through an URL sent to the client.)
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderViaLinkRequest} createOrderViaLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderViaPaymentLink(restaurantId: string, createOrderViaLinkRequest: CreateOrderViaLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentLinkOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderViaPaymentLink(restaurantId, createOrderViaLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an order that will be payed using a third-party service (through an URL sent to the client.)
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderViaURLRequest} createOrderViaURLRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderViaPaymentURL(restaurantId: string, createOrderViaURLRequest: CreateOrderViaURLRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderViaPaymentURL(restaurantId, createOrderViaURLRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets one order by its trace data
         * @param {string} id ID of the trace
         * @param {string} restaurantId ID of the restaurant
         * @param {string} token Token to identify an order trace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderByTraceData(id: string, restaurantId: string, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderByTraceDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderByTraceData(id, restaurantId, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an order from its anonymous token
         * @param {string} anonymousToken Anonymous token from the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderFromAnonymousToken(anonymousToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderFromAnonymousToken(anonymousToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the orders for the current user
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicOrderById(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicOrderById(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the orders for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderPublicResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Processes the result from a payment action
         * @param {string} restaurantId ID of the restaurant
         * @param {ProcessPaymentActionResultRequest} processPaymentActionResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processPaymentActionResult(restaurantId: string, processPaymentActionResultRequest: ProcessPaymentActionResultRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processPaymentActionResult(restaurantId, processPaymentActionResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends the invoice of the order id by email
         * @param {string} orderId ID of the order
         * @param {SendOrderInvoiceRequest} sendOrderInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOrderInvoice(orderId: string, sendOrderInvoiceRequest: SendOrderInvoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendOrderInvoice(orderId, sendOrderInvoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @summary Checks the balance of a meal payment method
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderUsingPayment} createOrderUsingPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMealPaymentBalance(restaurantId: string, createOrderUsingPayment: CreateOrderUsingPayment, options?: any): AxiosPromise<MealPaymentBalanceResponse> {
            return localVarFp.checkMealPaymentBalance(restaurantId, createOrderUsingPayment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks the result of an order payed using a payment link
         * @param {string} restaurantId ID of the restaurant
         * @param {string} linkId ID of the payment link
         * @param {CheckPaymentLinkOrderRequest} checkPaymentLinkOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPaymentLink(restaurantId: string, linkId: string, checkPaymentLinkOrderRequest: CheckPaymentLinkOrderRequest, options?: any): AxiosPromise<OrderPublicResponse> {
            return localVarFp.checkPaymentLink(restaurantId, linkId, checkPaymentLinkOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an order that will be payed using cash later
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderUsingCashRequest} createOrderUsingCashRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderUsingCash(restaurantId: string, createOrderUsingCashRequest: CreateOrderUsingCashRequest, options?: any): AxiosPromise<OrderPublicResponse> {
            return localVarFp.createOrderUsingCash(restaurantId, createOrderUsingCashRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an order using some payment information
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderUsingPayment} createOrderUsingPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderUsingPayment(restaurantId: string, createOrderUsingPayment: CreateOrderUsingPayment, options?: any): AxiosPromise<OrderResultResponse> {
            return localVarFp.createOrderUsingPayment(restaurantId, createOrderUsingPayment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an order that will be payed using a third-party service (through an URL sent to the client.)
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderViaLinkRequest} createOrderViaLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderViaPaymentLink(restaurantId: string, createOrderViaLinkRequest: CreateOrderViaLinkRequest, options?: any): AxiosPromise<PaymentLinkOrderResponse> {
            return localVarFp.createOrderViaPaymentLink(restaurantId, createOrderViaLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an order that will be payed using a third-party service (through an URL sent to the client.)
         * @param {string} restaurantId ID of the restaurant
         * @param {CreateOrderViaURLRequest} createOrderViaURLRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderViaPaymentURL(restaurantId: string, createOrderViaURLRequest: CreateOrderViaURLRequest, options?: any): AxiosPromise<string> {
            return localVarFp.createOrderViaPaymentURL(restaurantId, createOrderViaURLRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets one order by its trace data
         * @param {string} id ID of the trace
         * @param {string} restaurantId ID of the restaurant
         * @param {string} token Token to identify an order trace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderByTraceData(id: string, restaurantId: string, token: string, options?: any): AxiosPromise<OrderByTraceDataResponse> {
            return localVarFp.getOrderByTraceData(id, restaurantId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an order from its anonymous token
         * @param {string} anonymousToken Anonymous token from the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderFromAnonymousToken(anonymousToken: string, options?: any): AxiosPromise<OrderPublicResponse> {
            return localVarFp.getOrderFromAnonymousToken(anonymousToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the orders for the current user
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOrderById(orderId: string, options?: any): AxiosPromise<OrderPublicResponse> {
            return localVarFp.getPublicOrderById(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the orders for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrders(options?: any): AxiosPromise<Array<OrderPublicResponse>> {
            return localVarFp.getUserOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Processes the result from a payment action
         * @param {string} restaurantId ID of the restaurant
         * @param {ProcessPaymentActionResultRequest} processPaymentActionResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPaymentActionResult(restaurantId: string, processPaymentActionResultRequest: ProcessPaymentActionResultRequest, options?: any): AxiosPromise<OrderPublicResponse> {
            return localVarFp.processPaymentActionResult(restaurantId, processPaymentActionResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends the invoice of the order id by email
         * @param {string} orderId ID of the order
         * @param {SendOrderInvoiceRequest} sendOrderInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOrderInvoice(orderId: string, sendOrderInvoiceRequest: SendOrderInvoiceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendOrderInvoice(orderId, sendOrderInvoiceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkMealPaymentBalance operation in OrdersApi.
 * @export
 * @interface OrdersApiCheckMealPaymentBalanceRequest
 */
export interface OrdersApiCheckMealPaymentBalanceRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrdersApiCheckMealPaymentBalance
     */
    readonly restaurantId: string

    /**
     * 
     * @type {CreateOrderUsingPayment}
     * @memberof OrdersApiCheckMealPaymentBalance
     */
    readonly createOrderUsingPayment: CreateOrderUsingPayment
}

/**
 * Request parameters for checkPaymentLink operation in OrdersApi.
 * @export
 * @interface OrdersApiCheckPaymentLinkRequest
 */
export interface OrdersApiCheckPaymentLinkRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrdersApiCheckPaymentLink
     */
    readonly restaurantId: string

    /**
     * ID of the payment link
     * @type {string}
     * @memberof OrdersApiCheckPaymentLink
     */
    readonly linkId: string

    /**
     * 
     * @type {CheckPaymentLinkOrderRequest}
     * @memberof OrdersApiCheckPaymentLink
     */
    readonly checkPaymentLinkOrderRequest: CheckPaymentLinkOrderRequest
}

/**
 * Request parameters for createOrderUsingCash operation in OrdersApi.
 * @export
 * @interface OrdersApiCreateOrderUsingCashRequest
 */
export interface OrdersApiCreateOrderUsingCashRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrdersApiCreateOrderUsingCash
     */
    readonly restaurantId: string

    /**
     * 
     * @type {CreateOrderUsingCashRequest}
     * @memberof OrdersApiCreateOrderUsingCash
     */
    readonly createOrderUsingCashRequest: CreateOrderUsingCashRequest
}

/**
 * Request parameters for createOrderUsingPayment operation in OrdersApi.
 * @export
 * @interface OrdersApiCreateOrderUsingPaymentRequest
 */
export interface OrdersApiCreateOrderUsingPaymentRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrdersApiCreateOrderUsingPayment
     */
    readonly restaurantId: string

    /**
     * 
     * @type {CreateOrderUsingPayment}
     * @memberof OrdersApiCreateOrderUsingPayment
     */
    readonly createOrderUsingPayment: CreateOrderUsingPayment
}

/**
 * Request parameters for createOrderViaPaymentLink operation in OrdersApi.
 * @export
 * @interface OrdersApiCreateOrderViaPaymentLinkRequest
 */
export interface OrdersApiCreateOrderViaPaymentLinkRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrdersApiCreateOrderViaPaymentLink
     */
    readonly restaurantId: string

    /**
     * 
     * @type {CreateOrderViaLinkRequest}
     * @memberof OrdersApiCreateOrderViaPaymentLink
     */
    readonly createOrderViaLinkRequest: CreateOrderViaLinkRequest
}

/**
 * Request parameters for createOrderViaPaymentURL operation in OrdersApi.
 * @export
 * @interface OrdersApiCreateOrderViaPaymentURLRequest
 */
export interface OrdersApiCreateOrderViaPaymentURLRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrdersApiCreateOrderViaPaymentURL
     */
    readonly restaurantId: string

    /**
     * 
     * @type {CreateOrderViaURLRequest}
     * @memberof OrdersApiCreateOrderViaPaymentURL
     */
    readonly createOrderViaURLRequest: CreateOrderViaURLRequest
}

/**
 * Request parameters for getOrderByTraceData operation in OrdersApi.
 * @export
 * @interface OrdersApiGetOrderByTraceDataRequest
 */
export interface OrdersApiGetOrderByTraceDataRequest {
    /**
     * ID of the trace
     * @type {string}
     * @memberof OrdersApiGetOrderByTraceData
     */
    readonly id: string

    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrdersApiGetOrderByTraceData
     */
    readonly restaurantId: string

    /**
     * Token to identify an order trace
     * @type {string}
     * @memberof OrdersApiGetOrderByTraceData
     */
    readonly token: string
}

/**
 * Request parameters for getOrderFromAnonymousToken operation in OrdersApi.
 * @export
 * @interface OrdersApiGetOrderFromAnonymousTokenRequest
 */
export interface OrdersApiGetOrderFromAnonymousTokenRequest {
    /**
     * Anonymous token from the order
     * @type {string}
     * @memberof OrdersApiGetOrderFromAnonymousToken
     */
    readonly anonymousToken: string
}

/**
 * Request parameters for getPublicOrderById operation in OrdersApi.
 * @export
 * @interface OrdersApiGetPublicOrderByIdRequest
 */
export interface OrdersApiGetPublicOrderByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof OrdersApiGetPublicOrderById
     */
    readonly orderId: string
}

/**
 * Request parameters for processPaymentActionResult operation in OrdersApi.
 * @export
 * @interface OrdersApiProcessPaymentActionResultRequest
 */
export interface OrdersApiProcessPaymentActionResultRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof OrdersApiProcessPaymentActionResult
     */
    readonly restaurantId: string

    /**
     * 
     * @type {ProcessPaymentActionResultRequest}
     * @memberof OrdersApiProcessPaymentActionResult
     */
    readonly processPaymentActionResultRequest: ProcessPaymentActionResultRequest
}

/**
 * Request parameters for sendOrderInvoice operation in OrdersApi.
 * @export
 * @interface OrdersApiSendOrderInvoiceRequest
 */
export interface OrdersApiSendOrderInvoiceRequest {
    /**
     * ID of the order
     * @type {string}
     * @memberof OrdersApiSendOrderInvoice
     */
    readonly orderId: string

    /**
     * 
     * @type {SendOrderInvoiceRequest}
     * @memberof OrdersApiSendOrderInvoice
     */
    readonly sendOrderInvoiceRequest: SendOrderInvoiceRequest
}

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @summary Checks the balance of a meal payment method
     * @param {OrdersApiCheckMealPaymentBalanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public checkMealPaymentBalance(requestParameters: OrdersApiCheckMealPaymentBalanceRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).checkMealPaymentBalance(requestParameters.restaurantId, requestParameters.createOrderUsingPayment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks the result of an order payed using a payment link
     * @param {OrdersApiCheckPaymentLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public checkPaymentLink(requestParameters: OrdersApiCheckPaymentLinkRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).checkPaymentLink(requestParameters.restaurantId, requestParameters.linkId, requestParameters.checkPaymentLinkOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an order that will be payed using cash later
     * @param {OrdersApiCreateOrderUsingCashRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderUsingCash(requestParameters: OrdersApiCreateOrderUsingCashRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderUsingCash(requestParameters.restaurantId, requestParameters.createOrderUsingCashRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an order using some payment information
     * @param {OrdersApiCreateOrderUsingPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderUsingPayment(requestParameters: OrdersApiCreateOrderUsingPaymentRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderUsingPayment(requestParameters.restaurantId, requestParameters.createOrderUsingPayment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an order that will be payed using a third-party service (through an URL sent to the client.)
     * @param {OrdersApiCreateOrderViaPaymentLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderViaPaymentLink(requestParameters: OrdersApiCreateOrderViaPaymentLinkRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderViaPaymentLink(requestParameters.restaurantId, requestParameters.createOrderViaLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an order that will be payed using a third-party service (through an URL sent to the client.)
     * @param {OrdersApiCreateOrderViaPaymentURLRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderViaPaymentURL(requestParameters: OrdersApiCreateOrderViaPaymentURLRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderViaPaymentURL(requestParameters.restaurantId, requestParameters.createOrderViaURLRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets one order by its trace data
     * @param {OrdersApiGetOrderByTraceDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrderByTraceData(requestParameters: OrdersApiGetOrderByTraceDataRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOrderByTraceData(requestParameters.id, requestParameters.restaurantId, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an order from its anonymous token
     * @param {OrdersApiGetOrderFromAnonymousTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrderFromAnonymousToken(requestParameters: OrdersApiGetOrderFromAnonymousTokenRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOrderFromAnonymousToken(requestParameters.anonymousToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the orders for the current user
     * @param {OrdersApiGetPublicOrderByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getPublicOrderById(requestParameters: OrdersApiGetPublicOrderByIdRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getPublicOrderById(requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the orders for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getUserOrders(options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getUserOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Processes the result from a payment action
     * @param {OrdersApiProcessPaymentActionResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public processPaymentActionResult(requestParameters: OrdersApiProcessPaymentActionResultRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).processPaymentActionResult(requestParameters.restaurantId, requestParameters.processPaymentActionResultRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends the invoice of the order id by email
     * @param {OrdersApiSendOrderInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public sendOrderInvoice(requestParameters: OrdersApiSendOrderInvoiceRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).sendOrderInvoice(requestParameters.orderId, requestParameters.sendOrderInvoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods: async (restaurantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getPaymentMethods', 'restaurantId', restaurantId)
            const localVarPath = `/api/v1/restaurants/{restaurantId}/payments/methods`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethods(restaurantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethods(restaurantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods(restaurantId: string, options?: any): AxiosPromise<PaymentMethodsDataResponse> {
            return localVarFp.getPaymentMethods(restaurantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPaymentMethods operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetPaymentMethodsRequest
 */
export interface PaymentsApiGetPaymentMethodsRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetPaymentMethods
     */
    readonly restaurantId: string
}

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {PaymentsApiGetPaymentMethodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPaymentMethods(requestParameters: PaymentsApiGetPaymentMethodsRequest, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).getPaymentMethods(requestParameters.restaurantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RestaurantsApi - axios parameter creator
 * @export
 */
export const RestaurantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a discount in the restaurant with the given code
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {string} [code] Code of the discount
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDiscountFromCode: async (restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getDiscountFromCode', 'restaurantId', restaurantId)
            // verify required parameter 'orderTypes' is not null or undefined
            assertParamExists('getDiscountFromCode', 'orderTypes', orderTypes)
            // verify required parameter 'platforms' is not null or undefined
            assertParamExists('getDiscountFromCode', 'platforms', platforms)
            const localVarPath = `/api/v1/restaurants/restaurants/{restaurantId}/discounts/code`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderTypes) {
                localVarQueryParameter['orderTypes'] = orderTypes;
            }

            if (platforms) {
                localVarQueryParameter['platforms'] = platforms;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the discounts for a restaurant.
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDiscountsForRestaurant: async (restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getDiscountsForRestaurant', 'restaurantId', restaurantId)
            // verify required parameter 'orderTypes' is not null or undefined
            assertParamExists('getDiscountsForRestaurant', 'orderTypes', orderTypes)
            // verify required parameter 'platforms' is not null or undefined
            assertParamExists('getDiscountsForRestaurant', 'platforms', platforms)
            const localVarPath = `/api/v1/restaurants/restaurants/{restaurantId}/discounts`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderTypes) {
                localVarQueryParameter['orderTypes'] = orderTypes;
            }

            if (platforms) {
                localVarQueryParameter['platforms'] = platforms;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the restaurant with the given id.
         * @param {string} restaurantId ID of the restaurant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurant: async (restaurantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getRestaurant', 'restaurantId', restaurantId)
            const localVarPath = `/api/v1/restaurants/{restaurantId}`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the restaurant with the given slug.
         * @param {string} restaurantSlug Slug of the restaurant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantBySlug: async (restaurantSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantSlug' is not null or undefined
            assertParamExists('getRestaurantBySlug', 'restaurantSlug', restaurantSlug)
            const localVarPath = `/api/v1/restaurants/slug/{restaurantSlug}`
                .replace(`{${"restaurantSlug"}}`, encodeURIComponent(String(restaurantSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the catalog restaurant with the given id.
         * @param {string} restaurantId ID of the restaurant
         * @param {OrderType} [orderType] Type of order
         * @param {DeliveryCompany} [deliveryCompany] Company that will deliver the order
         * @param {OrderPlatform} [platform] Platform where the order was done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantCatalog: async (restaurantId: string, orderType?: OrderType, deliveryCompany?: DeliveryCompany, platform?: OrderPlatform, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('getRestaurantCatalog', 'restaurantId', restaurantId)
            const localVarPath = `/api/v1/restaurants/{restaurantId}/catalog`
                .replace(`{${"restaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (deliveryCompany !== undefined) {
                localVarQueryParameter['deliveryCompany'] = deliveryCompany;
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the delivery restaurant to a given location.
         * @param {string} countryCode Country Code
         * @param {string} city City.
         * @param {string} street Street name.
         * @param {string} zipCode Zip code.
         * @param {Array<number>} coordinates Lat, Lng coordinates
         * @param {string} [apartment] Apartment number.
         * @param {string} [instructions] Instructions to access to this address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantForAddress: async (countryCode: string, city: string, street: string, zipCode: string, coordinates: Array<number>, apartment?: string, instructions?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('getRestaurantForAddress', 'countryCode', countryCode)
            // verify required parameter 'city' is not null or undefined
            assertParamExists('getRestaurantForAddress', 'city', city)
            // verify required parameter 'street' is not null or undefined
            assertParamExists('getRestaurantForAddress', 'street', street)
            // verify required parameter 'zipCode' is not null or undefined
            assertParamExists('getRestaurantForAddress', 'zipCode', zipCode)
            // verify required parameter 'coordinates' is not null or undefined
            assertParamExists('getRestaurantForAddress', 'coordinates', coordinates)
            const localVarPath = `/api/v1/restaurants/delivery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (street !== undefined) {
                localVarQueryParameter['street'] = street;
            }

            if (apartment !== undefined) {
                localVarQueryParameter['apartment'] = apartment;
            }

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (instructions !== undefined) {
                localVarQueryParameter['instructions'] = instructions;
            }

            if (coordinates) {
                localVarQueryParameter['coordinates'] = coordinates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the restaurants that are online.
         * @param {Array<string>} [regionIds] IDs of the regions
         * @param {boolean} [includeRegister] Includes registered restaurants.
         * @param {Array<'web' | 'app' | 'kiosk'>} [platforms] Order platforms for which the restaurant is available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurants: async (regionIds?: Array<string>, includeRegister?: boolean, platforms?: Array<'web' | 'app' | 'kiosk'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/restaurants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (includeRegister !== undefined) {
                localVarQueryParameter['includeRegister'] = includeRegister;
            }

            if (platforms) {
                localVarQueryParameter['platforms'] = platforms;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the restaurants that are online.
         * @param {Array<string>} [regionIds] IDs of the regions
         * @param {boolean} [includeRegister] Includes registered restaurants.
         * @param {Array<'web' | 'app' | 'kiosk'>} [platforms] Order platforms for which the restaurant is available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantsPickUpData: async (regionIds?: Array<string>, includeRegister?: boolean, platforms?: Array<'web' | 'app' | 'kiosk'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/restaurants/pick-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-COMPANY-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-COMPANY-TOKEN", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (regionIds) {
                localVarQueryParameter['regionIds'] = regionIds;
            }

            if (includeRegister !== undefined) {
                localVarQueryParameter['includeRegister'] = includeRegister;
            }

            if (platforms) {
                localVarQueryParameter['platforms'] = platforms;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestaurantsApi - functional programming interface
 * @export
 */
export const RestaurantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RestaurantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a discount in the restaurant with the given code
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {string} [code] Code of the discount
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDiscountFromCode(restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscountFromCode(restaurantId, orderTypes, platforms, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the discounts for a restaurant.
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDiscountsForRestaurant(restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiscountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscountsForRestaurant(restaurantId, orderTypes, platforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the restaurant with the given id.
         * @param {string} restaurantId ID of the restaurant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurant(restaurantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestaurantPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurant(restaurantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the restaurant with the given slug.
         * @param {string} restaurantSlug Slug of the restaurant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurantBySlug(restaurantSlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestaurantPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurantBySlug(restaurantSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the catalog restaurant with the given id.
         * @param {string} restaurantId ID of the restaurant
         * @param {OrderType} [orderType] Type of order
         * @param {DeliveryCompany} [deliveryCompany] Company that will deliver the order
         * @param {OrderPlatform} [platform] Platform where the order was done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurantCatalog(restaurantId: string, orderType?: OrderType, deliveryCompany?: DeliveryCompany, platform?: OrderPlatform, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurantCatalog(restaurantId, orderType, deliveryCompany, platform, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the delivery restaurant to a given location.
         * @param {string} countryCode Country Code
         * @param {string} city City.
         * @param {string} street Street name.
         * @param {string} zipCode Zip code.
         * @param {Array<number>} coordinates Lat, Lng coordinates
         * @param {string} [apartment] Apartment number.
         * @param {string} [instructions] Instructions to access to this address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurantForAddress(countryCode: string, city: string, street: string, zipCode: string, coordinates: Array<number>, apartment?: string, instructions?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestaurantDeliveryQuoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurantForAddress(countryCode, city, street, zipCode, coordinates, apartment, instructions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the restaurants that are online.
         * @param {Array<string>} [regionIds] IDs of the regions
         * @param {boolean} [includeRegister] Includes registered restaurants.
         * @param {Array<'web' | 'app' | 'kiosk'>} [platforms] Order platforms for which the restaurant is available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurants(regionIds?: Array<string>, includeRegister?: boolean, platforms?: Array<'web' | 'app' | 'kiosk'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RestaurantPublicResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurants(regionIds, includeRegister, platforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the restaurants that are online.
         * @param {Array<string>} [regionIds] IDs of the regions
         * @param {boolean} [includeRegister] Includes registered restaurants.
         * @param {Array<'web' | 'app' | 'kiosk'>} [platforms] Order platforms for which the restaurant is available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurantsPickUpData(regionIds?: Array<string>, includeRegister?: boolean, platforms?: Array<'web' | 'app' | 'kiosk'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RestaurantPickUpDataResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurantsPickUpData(regionIds, includeRegister, platforms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RestaurantsApi - factory interface
 * @export
 */
export const RestaurantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RestaurantsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a discount in the restaurant with the given code
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {string} [code] Code of the discount
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDiscountFromCode(restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, code?: string, options?: any): AxiosPromise<DiscountResponse> {
            return localVarFp.getDiscountFromCode(restaurantId, orderTypes, platforms, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the discounts for a restaurant.
         * @param {string} restaurantId ID of the restaurant
         * @param {Array<OrderType>} orderTypes Order types where this discount can be applied
         * @param {Array<OrderPlatform>} platforms Platforms where this discount can be applied
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDiscountsForRestaurant(restaurantId: string, orderTypes: Array<OrderType>, platforms: Array<OrderPlatform>, options?: any): AxiosPromise<Array<DiscountResponse>> {
            return localVarFp.getDiscountsForRestaurant(restaurantId, orderTypes, platforms, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the restaurant with the given id.
         * @param {string} restaurantId ID of the restaurant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurant(restaurantId: string, options?: any): AxiosPromise<RestaurantPublicResponse> {
            return localVarFp.getRestaurant(restaurantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the restaurant with the given slug.
         * @param {string} restaurantSlug Slug of the restaurant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantBySlug(restaurantSlug: string, options?: any): AxiosPromise<RestaurantPublicResponse> {
            return localVarFp.getRestaurantBySlug(restaurantSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the catalog restaurant with the given id.
         * @param {string} restaurantId ID of the restaurant
         * @param {OrderType} [orderType] Type of order
         * @param {DeliveryCompany} [deliveryCompany] Company that will deliver the order
         * @param {OrderPlatform} [platform] Platform where the order was done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantCatalog(restaurantId: string, orderType?: OrderType, deliveryCompany?: DeliveryCompany, platform?: OrderPlatform, options?: any): AxiosPromise<CatalogResponse> {
            return localVarFp.getRestaurantCatalog(restaurantId, orderType, deliveryCompany, platform, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the delivery restaurant to a given location.
         * @param {string} countryCode Country Code
         * @param {string} city City.
         * @param {string} street Street name.
         * @param {string} zipCode Zip code.
         * @param {Array<number>} coordinates Lat, Lng coordinates
         * @param {string} [apartment] Apartment number.
         * @param {string} [instructions] Instructions to access to this address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantForAddress(countryCode: string, city: string, street: string, zipCode: string, coordinates: Array<number>, apartment?: string, instructions?: string, options?: any): AxiosPromise<RestaurantDeliveryQuoteResponse> {
            return localVarFp.getRestaurantForAddress(countryCode, city, street, zipCode, coordinates, apartment, instructions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the restaurants that are online.
         * @param {Array<string>} [regionIds] IDs of the regions
         * @param {boolean} [includeRegister] Includes registered restaurants.
         * @param {Array<'web' | 'app' | 'kiosk'>} [platforms] Order platforms for which the restaurant is available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurants(regionIds?: Array<string>, includeRegister?: boolean, platforms?: Array<'web' | 'app' | 'kiosk'>, options?: any): AxiosPromise<Array<RestaurantPublicResponse>> {
            return localVarFp.getRestaurants(regionIds, includeRegister, platforms, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the restaurants that are online.
         * @param {Array<string>} [regionIds] IDs of the regions
         * @param {boolean} [includeRegister] Includes registered restaurants.
         * @param {Array<'web' | 'app' | 'kiosk'>} [platforms] Order platforms for which the restaurant is available
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantsPickUpData(regionIds?: Array<string>, includeRegister?: boolean, platforms?: Array<'web' | 'app' | 'kiosk'>, options?: any): AxiosPromise<Array<RestaurantPickUpDataResponse>> {
            return localVarFp.getRestaurantsPickUpData(regionIds, includeRegister, platforms, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDiscountFromCode operation in RestaurantsApi.
 * @export
 * @interface RestaurantsApiGetDiscountFromCodeRequest
 */
export interface RestaurantsApiGetDiscountFromCodeRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof RestaurantsApiGetDiscountFromCode
     */
    readonly restaurantId: string

    /**
     * Order types where this discount can be applied
     * @type {Array<OrderType>}
     * @memberof RestaurantsApiGetDiscountFromCode
     */
    readonly orderTypes: Array<OrderType>

    /**
     * Platforms where this discount can be applied
     * @type {Array<OrderPlatform>}
     * @memberof RestaurantsApiGetDiscountFromCode
     */
    readonly platforms: Array<OrderPlatform>

    /**
     * Code of the discount
     * @type {string}
     * @memberof RestaurantsApiGetDiscountFromCode
     */
    readonly code?: string
}

/**
 * Request parameters for getDiscountsForRestaurant operation in RestaurantsApi.
 * @export
 * @interface RestaurantsApiGetDiscountsForRestaurantRequest
 */
export interface RestaurantsApiGetDiscountsForRestaurantRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof RestaurantsApiGetDiscountsForRestaurant
     */
    readonly restaurantId: string

    /**
     * Order types where this discount can be applied
     * @type {Array<OrderType>}
     * @memberof RestaurantsApiGetDiscountsForRestaurant
     */
    readonly orderTypes: Array<OrderType>

    /**
     * Platforms where this discount can be applied
     * @type {Array<OrderPlatform>}
     * @memberof RestaurantsApiGetDiscountsForRestaurant
     */
    readonly platforms: Array<OrderPlatform>
}

/**
 * Request parameters for getRestaurant operation in RestaurantsApi.
 * @export
 * @interface RestaurantsApiGetRestaurantRequest
 */
export interface RestaurantsApiGetRestaurantRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof RestaurantsApiGetRestaurant
     */
    readonly restaurantId: string
}

/**
 * Request parameters for getRestaurantBySlug operation in RestaurantsApi.
 * @export
 * @interface RestaurantsApiGetRestaurantBySlugRequest
 */
export interface RestaurantsApiGetRestaurantBySlugRequest {
    /**
     * Slug of the restaurant
     * @type {string}
     * @memberof RestaurantsApiGetRestaurantBySlug
     */
    readonly restaurantSlug: string
}

/**
 * Request parameters for getRestaurantCatalog operation in RestaurantsApi.
 * @export
 * @interface RestaurantsApiGetRestaurantCatalogRequest
 */
export interface RestaurantsApiGetRestaurantCatalogRequest {
    /**
     * ID of the restaurant
     * @type {string}
     * @memberof RestaurantsApiGetRestaurantCatalog
     */
    readonly restaurantId: string

    /**
     * Type of order
     * @type {OrderType}
     * @memberof RestaurantsApiGetRestaurantCatalog
     */
    readonly orderType?: OrderType

    /**
     * Company that will deliver the order
     * @type {DeliveryCompany}
     * @memberof RestaurantsApiGetRestaurantCatalog
     */
    readonly deliveryCompany?: DeliveryCompany

    /**
     * Platform where the order was done
     * @type {OrderPlatform}
     * @memberof RestaurantsApiGetRestaurantCatalog
     */
    readonly platform?: OrderPlatform
}

/**
 * Request parameters for getRestaurantForAddress operation in RestaurantsApi.
 * @export
 * @interface RestaurantsApiGetRestaurantForAddressRequest
 */
export interface RestaurantsApiGetRestaurantForAddressRequest {
    /**
     * Country Code
     * @type {string}
     * @memberof RestaurantsApiGetRestaurantForAddress
     */
    readonly countryCode: string

    /**
     * City.
     * @type {string}
     * @memberof RestaurantsApiGetRestaurantForAddress
     */
    readonly city: string

    /**
     * Street name.
     * @type {string}
     * @memberof RestaurantsApiGetRestaurantForAddress
     */
    readonly street: string

    /**
     * Zip code.
     * @type {string}
     * @memberof RestaurantsApiGetRestaurantForAddress
     */
    readonly zipCode: string

    /**
     * Lat, Lng coordinates
     * @type {Array<number>}
     * @memberof RestaurantsApiGetRestaurantForAddress
     */
    readonly coordinates: Array<number>

    /**
     * Apartment number.
     * @type {string}
     * @memberof RestaurantsApiGetRestaurantForAddress
     */
    readonly apartment?: string

    /**
     * Instructions to access to this address
     * @type {string}
     * @memberof RestaurantsApiGetRestaurantForAddress
     */
    readonly instructions?: string
}

/**
 * Request parameters for getRestaurants operation in RestaurantsApi.
 * @export
 * @interface RestaurantsApiGetRestaurantsRequest
 */
export interface RestaurantsApiGetRestaurantsRequest {
    /**
     * IDs of the regions
     * @type {Array<string>}
     * @memberof RestaurantsApiGetRestaurants
     */
    readonly regionIds?: Array<string>

    /**
     * Includes registered restaurants.
     * @type {boolean}
     * @memberof RestaurantsApiGetRestaurants
     */
    readonly includeRegister?: boolean

    /**
     * Order platforms for which the restaurant is available
     * @type {Array<'web' | 'app' | 'kiosk'>}
     * @memberof RestaurantsApiGetRestaurants
     */
    readonly platforms?: Array<'web' | 'app' | 'kiosk'>
}

/**
 * Request parameters for getRestaurantsPickUpData operation in RestaurantsApi.
 * @export
 * @interface RestaurantsApiGetRestaurantsPickUpDataRequest
 */
export interface RestaurantsApiGetRestaurantsPickUpDataRequest {
    /**
     * IDs of the regions
     * @type {Array<string>}
     * @memberof RestaurantsApiGetRestaurantsPickUpData
     */
    readonly regionIds?: Array<string>

    /**
     * Includes registered restaurants.
     * @type {boolean}
     * @memberof RestaurantsApiGetRestaurantsPickUpData
     */
    readonly includeRegister?: boolean

    /**
     * Order platforms for which the restaurant is available
     * @type {Array<'web' | 'app' | 'kiosk'>}
     * @memberof RestaurantsApiGetRestaurantsPickUpData
     */
    readonly platforms?: Array<'web' | 'app' | 'kiosk'>
}

/**
 * RestaurantsApi - object-oriented interface
 * @export
 * @class RestaurantsApi
 * @extends {BaseAPI}
 */
export class RestaurantsApi extends BaseAPI {
    /**
     * 
     * @summary Gets a discount in the restaurant with the given code
     * @param {RestaurantsApiGetDiscountFromCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RestaurantsApi
     */
    public getDiscountFromCode(requestParameters: RestaurantsApiGetDiscountFromCodeRequest, options?: AxiosRequestConfig) {
        return RestaurantsApiFp(this.configuration).getDiscountFromCode(requestParameters.restaurantId, requestParameters.orderTypes, requestParameters.platforms, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the discounts for a restaurant.
     * @param {RestaurantsApiGetDiscountsForRestaurantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RestaurantsApi
     */
    public getDiscountsForRestaurant(requestParameters: RestaurantsApiGetDiscountsForRestaurantRequest, options?: AxiosRequestConfig) {
        return RestaurantsApiFp(this.configuration).getDiscountsForRestaurant(requestParameters.restaurantId, requestParameters.orderTypes, requestParameters.platforms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the restaurant with the given id.
     * @param {RestaurantsApiGetRestaurantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantsApi
     */
    public getRestaurant(requestParameters: RestaurantsApiGetRestaurantRequest, options?: AxiosRequestConfig) {
        return RestaurantsApiFp(this.configuration).getRestaurant(requestParameters.restaurantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the restaurant with the given slug.
     * @param {RestaurantsApiGetRestaurantBySlugRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantsApi
     */
    public getRestaurantBySlug(requestParameters: RestaurantsApiGetRestaurantBySlugRequest, options?: AxiosRequestConfig) {
        return RestaurantsApiFp(this.configuration).getRestaurantBySlug(requestParameters.restaurantSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the catalog restaurant with the given id.
     * @param {RestaurantsApiGetRestaurantCatalogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantsApi
     */
    public getRestaurantCatalog(requestParameters: RestaurantsApiGetRestaurantCatalogRequest, options?: AxiosRequestConfig) {
        return RestaurantsApiFp(this.configuration).getRestaurantCatalog(requestParameters.restaurantId, requestParameters.orderType, requestParameters.deliveryCompany, requestParameters.platform, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the delivery restaurant to a given location.
     * @param {RestaurantsApiGetRestaurantForAddressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantsApi
     */
    public getRestaurantForAddress(requestParameters: RestaurantsApiGetRestaurantForAddressRequest, options?: AxiosRequestConfig) {
        return RestaurantsApiFp(this.configuration).getRestaurantForAddress(requestParameters.countryCode, requestParameters.city, requestParameters.street, requestParameters.zipCode, requestParameters.coordinates, requestParameters.apartment, requestParameters.instructions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the restaurants that are online.
     * @param {RestaurantsApiGetRestaurantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantsApi
     */
    public getRestaurants(requestParameters: RestaurantsApiGetRestaurantsRequest = {}, options?: AxiosRequestConfig) {
        return RestaurantsApiFp(this.configuration).getRestaurants(requestParameters.regionIds, requestParameters.includeRegister, requestParameters.platforms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the restaurants that are online.
     * @param {RestaurantsApiGetRestaurantsPickUpDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantsApi
     */
    public getRestaurantsPickUpData(requestParameters: RestaurantsApiGetRestaurantsPickUpDataRequest = {}, options?: AxiosRequestConfig) {
        return RestaurantsApiFp(this.configuration).getRestaurantsPickUpData(requestParameters.regionIds, requestParameters.includeRegister, requestParameters.platforms, options).then((request) => request(this.axios, this.basePath));
    }
}


