import { DiscountTarget, Language, OrderStatus, OrderType, UserErrorCode } from '../../client'
import { PurchaseStatus } from '../../modules/cart/cart.types'
import frTranslations from '../fr'
// Modules
import kiosk from './kiosk'
import { ptLandingPageTranslations } from './landingPage'

const errors: Record<UserErrorCode | string, string> = {
  // Order
  // - Cart details
  [UserErrorCode.OrderRestaurantOffline]:
    'O restaurante está offline, tente novamente em outro momento.',
  [UserErrorCode.OrderRestaurantBusy]:
    'Le restaurant est occupé, veuillez réessayer à un autre moment.',
  [UserErrorCode.OrderDateInPast]:
    'A data do pedido está no passado, atualize a web e verifique se o horário selecionado está no futuro.',
  [UserErrorCode.OrderRestaurantClosed]:
    'O restaurante está offline, tente novamente em outro momento.',
  // - Items
  [UserErrorCode.OrderItemsDontExist]:
    'Seu pedido contém produtos que não estão mais disponíveis. Atualize sua página para que você possa refazê-lo',
  [UserErrorCode.OrderModifiersDontExist]:
    'Seu pedido contém produtos que não estão mais disponíveis. Atualize sua página para que você possa refazê-lo',
  [UserErrorCode.OrderInvalidItems]:
    'Seu pedido contém produtos que não estão mais disponíveis. Atualize sua página para que você possa refazê-lo',
  [UserErrorCode.OrderInvalidModifiers]:
    'Seu pedido contém produtos que não estão mais disponíveis. Atualize sua página para que você possa refazê-lo',
  [UserErrorCode.OrderItemsDisabled]:
    'Seu pedido contém produtos que não estão mais disponíveis. Atualize sua página para que você possa refazê-lo',
  // - Payment
  [UserErrorCode.OrderPaymentRejected]: 'O pagamento foi rejeitado, tente novamente mais tarde.',
  [UserErrorCode.OrderPaymentError]:
    'Ocorreu um problema ao processar seu pagamento, tente novamente mais tarde.',

  403: 'Você não tem acesso a esta seção',
  404: 'A seção que você está procurando não está disponível',
  500: 'Oh não, ocorreu um erro inesperado',
}

// TODO - remove & any and ensure the types are correct
export const ptTranslations: typeof frTranslations & any = {
  unknownError: 'Algo deu errado, tente novamente mais tarde',
  geolocationUnavailable: 'A geolocalização não está disponível',

  add: 'Adicionar',
  create: 'Criar',
  modify: 'Modificar',
  delete: 'Excluir',
  remove: 'Remover',

  showMore: 'Mostre mais',
  select: 'Selecionar',
  change: 'Mudar',
  previous: 'Anterior',
  next: 'Próximo',
  goBack: 'Voltar',
  reload: 'Recarregar',
  continue: 'Continuar',
  confirm: 'Confirmar',
  retry: 'Tente novamente',
  close: 'Fechado',
  cancel: 'Cancelar',
  quit: 'Desistir',
  abandon: 'Abandonar',
  pass: 'Passar',
  yes: 'Sim',
  no: 'Não',
  or: 'Ou',
  without: 'Without',

  help: 'Ajuda',
  free: 'Grátis',

  languageCode: {
    [Language.Fr]: 'fr',
  },
  language: {
    [Language.Fr]: 'Francês',
    [Language.En]: 'Inglês',
    [Language.Es]: 'Espanhol',
    [Language.Pt]: 'Português',
    [Language.Ca]: 'Catalão',
    [Language.Nl]: 'Holandês',
  },

  form: {
    region: 'País',
    language: 'Linguagem',
    firstName: {
      label: 'Primeiro nome',
      placeholder: 'Primeiro nome...',
      error: 'O primeiro nome é necessário',
    },
    lastName: {
      label: 'Último nome',
      placeholder: 'Último nome...',
      error: 'O último nome é obrigatório',
    },

    email: {
      label: 'Endereço de email',
      error: 'Este email é inválido',
    },

    phone: {
      label: 'Número de telefone',
      error: 'Por favor insira um número válido.',
    },

    instructions: {
      label: 'Instrução ao entregador',
      placeholder: 'Código do portão, indicações específicas...',
    },
  },

  // Pages

  tutorial: {
    button: 'redação cta',
    slides: [
      {
        title: 'pedido em click & collect e delivery',
        subtitle:
          'Seja recompensado desde o primeiro pedido com nosso programa de fidelidade exclusivo projetado para você.',
      },
      {
        title: 'Benefit of the cheapest prices',
        subtitle:
          'Seja recompensado desde o primeiro pedido com nosso programa de fidelidade exclusivo projetado para você.',
      },
      {
        title: 'Enjoy our exclusive offers',
        subtitle:
          'Seja recompensado desde o primeiro pedido com nosso programa de fidelidade exclusivo projetado para você.',
      },
      {
        title: 'Your loyalty is rewarded!',
        subtitle:
          'Seja recompensado desde o primeiro pedido com nosso programa de fidelidade exclusivo projetado para você.',
      },
    ],
  },

  enter: {
    home: {
      title: 'Peça seu prato favorito',
      loginButton: 'entrar / registrar',
      guestButton: 'continuar como convidado',
    },
  },

  user: {
    guest: 'Modo convidado',
    myOrders: 'Todos os meus pedidos',

    orders: {
      title: 'Todos os meus pedidos',

      activeTitle: 'Pedidos atuais ({{quantidade}})',
    },

    links: {
      myOrders: 'Minhas ordens',
      help: 'Preciso de ajuda ?',
      cguv: 'CGUV',
      terms: 'CGUV',
      confidentiality: 'Política de Privacidade',
      legal: 'Notícia legal',
    },
  },

  setup: {
    title: 'Olá <1>{{nome}},</1>',

    address: {
      title: 'Selecione um endereço',
      title_delivery: 'Selecione um endereço de entrega',
      subtitle: 'ou localize-se',
      placeholder: 'Adicione seu endereço',
      error: {
        title: 'Atenção',
        description: 'Não foi possível encontrar seu endereço, adicione-o novamente',
      },
    },

    info: {
      instructions: {
        label: 'Instrução ao entregador',
        placeholder: 'Exemplo: número do apartamento, andar...',
      },
      phone: {
        label: 'Número de telefone',
        placeholder: '6 00 00 00 00',
        error: {
          invalid: 'Por favor insira um número válido.',
        },
        tip: 'Esta informação será utilizada para identificar a sua encomenda e contactá-lo se necessário.',
      },

      submit: 'Use este endereço.',

      error: {
        title: 'Importante',
        description: 'Ainda não entregamos neste endereço.',
      },
    },

    error: {
      title: 'Importante',
      description: 'O ponto de venda selecionado não está disponível.',
    },
  },

  restaurant: {
    openUntil: 'Aberto até {{horas}}h{{minutos}}',
    openUntilTime: 'Aberto até {{horas}}',
    closed: 'Fechados',
    findRestaurant: 'Encontre o seu restaurante',
    searchRestaurant: 'Pesquise seu restaurante',
    upcoming: 'Abertura em breve',
    availableSoon: 'Disponível em breve',
    orderHere: 'Peça aqui',
    moreInfo: 'Mais Informações',
    openHours: 'Hora de abertura',
    clickAndCollect: 'Click & collect',
    atRestaurant: 'At {{restauranteNome}}',
    theRestaurants: 'Os restaurantes',
    findAll: 'Encontre todos os nossos restaurantes {{Nome da empresa}} !',
    allRestaurants: 'Todos os restaurantes',
    informations: 'Informações',
  },

  order: {
    type: {
      [OrderType.Delivery]: 'Entregar',
      [OrderType.PickUp]: 'Buscar',
      [OrderType.EatIn]: 'Coma em',
    },

    status: {
      [OrderStatus.Waiting]: 'Aguardando',
      [OrderStatus.Preorder]: 'Pedido antecipado',
      [OrderStatus.Unpaid]: 'Não pago',
      [OrderStatus.New]: 'Novo',
      [OrderStatus.InProgress]: 'Em andamento',
      [OrderStatus.Prepared]: 'Pronto',
      [OrderStatus.Finished]: 'Finalizado',
      [OrderStatus.Refunded]: 'Devolveu',
    },

    banners: 'Ofertas atuais',

    summary: {
      title: 'Seu carrinho',
    },

    change: {
      title: 'Importante',
      description: 'Ao alterar o método de pedido, seu carrinho será esvaziado.',
    },

    settings: {
      type: {
        [OrderType.Delivery]: 'Entrega',
        [OrderType.PickUp]: 'Buscar',
        [OrderType.EatIn]: 'Comer em',
        [OrderType.Table]: 'Serviço de mesa',
      },

      slots: {
        now: 'Agora ({{ tempo }})',
        asap: 'O mais breve possível',
        asapTable: 'O mais breve possível',
        expectedFor: 'Esperado para',
      },
    },

    card: {
      number: 'Pedido\nN°{{ número }}',
      quantity: '{{ quantidade }} produtos',
    },

    details: {
      header: {
        title: 'Pedido\nN°{{ número }}',
        date: 'O {{- data}} no {{hora}}',

        restaurant: 'Encomende em',

        mode: {
          [OrderType.Delivery]: 'Entrega em',
          [OrderType.PickUp]: 'Pegue em',
          [OrderType.Table]: 'Serviço de mesa',
        },

        payment: 'Pago por',
      },

      products: {
        title: 'Produtos ({{ quantidade }})',
      },

      sendEmail: {
        button: 'Baixar recibo',
        title: 'Obter um recibo',

        email: 'E-mail',
        quantity: 'Número de talheres',
        submit: 'Enviar',
      },
    },

    upsell: {
      title: 'Um pouco mais para levar',
      dismiss: 'Não, obrigado !',
      finish: 'Terminado',
    },

    actionBar: {
      activeOrder: {
        title: 'Pedido em andamento',

        time: {
          [OrderType.Delivery]: 'Entrega em',
          [OrderType.PickUp]: 'Pegue em',
          [OrderType.EatIn]: 'Encomende em',
          [OrderType.Table]: 'Encomende em',
        },
      },
    },
  },

  checkout: {
    basket: 'Carrinho',
    title: 'Confira seu pedido em {{ nome do restaurante }}',
    button: 'Veja meu pedido',
    addProducts: 'Adicionar produto',
    restaurantAddress: 'Endereço do restaurantev',
    finish: 'Terminar',
    discount: {
      notDelivery: {
        pending: 'Adicionar {{pendente}} e aproveitar <1>{{recompensa}} desconto</1>',
        completed: 'Parabéns ! Você se beneficia de <1>{{recompensa}} desconto</1>',
      },
      delivery: {
        pending: 'Adicionar {{pendente}} para desfrutar da <1>entrega gratuita</1>',
        completed: 'Parabéns ! Você se beneficia da entrega gratuita',
      },
    },

    products: {
      empty: {
        title: 'Seu carrinho está vazio',
        subtitle: 'Adicione produtos ao seu carrinho',
      },
      mandatory: 'Obrigatório',
    },

    summary: {
      title: 'Seu <1>pedido ({{quantidade}})</1>',

      total: {
        products: 'Produtos',
        deliveryFee: {
          title: 'Taxa de entrega',
          description:
            'Quanto mais próximo o restaurante estiver de você, menores serão os custos de entrega.',
        },
        discount: 'Desconto',
        service: {
          title: 'Taxa de serviço',
          description:
            'Estas taxas permitem-nos otimizar a sua experiência, nomeadamente através de novas funcionalidades e de um ótimo serviço ao cliente.',
        },
        additionalFees: {
          title: 'Taxas adicionais',
          description:
            'Se o valor do seu pedido for inferior ao valor mínimo definido pelo restaurante no momento da entrega, esses custos serão adicionados ao seu carrinho.',
        },
        total: 'Total',
        withTaxes: 'TTC',
      },
    },

    priceInfo: {
      title: 'Quais são as taxas aplicadas?',
    },

    promotions: {
      title: 'Promoções',
      error: 'O código promocional utilizado não é válido',
      add: {
        heading: 'Um código promocional?',
        title: 'Adicionar um código promocional',
        label: 'Adicione seu código promocional',
        placeholder: 'Código promocional',
        cta: 'Aplicar',
        error: 'O código promocional utilizado não é válido.',
      },

      details: {
        [DiscountTarget.Total]: '-{{ desconto }} nesta ordem',
        [DiscountTarget.Delivery]: {
          discount: '-{{ desconto }} na sua entrega',
          free: 'Entrega grátis !',
        },
      },
    },

    payments: {
      title: 'Forma de pagamento',
      card: 'cartão do banco',
      paywithgoogle: 'Google Pay',
      applepay: 'Apple Pay',
      paypal: 'PayPal',
    },

    settings: {
      title: 'Informações do pedido',

      timeSlot: {
        title: {
          [OrderType.Delivery]: 'Tempo de entrega',
          [OrderType.PickUp]: 'Hora de pegar',
          [OrderType.EatIn]: 'Coma em',
          [OrderType.Table]: 'Servido em',
        },
        asap: '{{ tempo }}',
        asapTable: 'O mais breve possível',
        expectedFor: 'Esperado para {{ tempo }}',
      },

      telephone: {
        title: 'Número de telefone',
        info: 'Essas informações serão usadas para identificar seu pedido e entrar em contato com você, se necessário.',
      },

      guestInfo: {
        info: 'Essas informações serão usadas apenas para rastrear seu pedido.',
      },

      cutlery: {
        title: 'Talheres',
        info: 'Peça talheres apenas se precisar.',
        infoEnabled: 'Adicionaremos talheres ao seu pedido dentro dos limites de disponibilidade.',
      },

      comments: {
        title: 'Comentário',
        placeholder: 'Você tem um pedido específico no seu pedido?',
      },
      tos: 'Ao continuar, aceito os <1>Termos e condições</1> de {{ cliente }}.',
      info: 'Ao continuar, aceito a <1>CGUV</1> e a <2>política de privacidade</2> de {{ cliente }}. O pagamento aparecerá no seu extrato com a referência Belorder.',
      cta: 'Pagar ({{ total }})',
    },

    purchaseStatusModal: {
      title: {
        [PurchaseStatus.Inactive]: 'Pedido sendo aceito...',
        [PurchaseStatus.PrePush]: 'Pedido sendo aceito...',
        [PurchaseStatus.Ready]: 'Pedido sendo aceito...',
        [PurchaseStatus.Purchasing]: 'Pedido sendo aceito...',
        [PurchaseStatus.CheckoutActionResult]: 'Pedido sendo aceito...',
        [PurchaseStatus.Failed]: 'Ocorreu um problema...',
        [PurchaseStatus.Finished]: 'O pedido foi criado com sucesso...',
      },
      cancel: 'Cancelar meu pedido',
      retry: 'Repetir',
    },
  },

  confirmation: {
    title: 'Pedido\nN°{{ nu+umero }}',
    failed: 'Seu pagamento falhou...',
    goBack: 'Pedir novamente',

    status: {
      [OrderType.Delivery]: {
        inProgress: 'Previsão de chegada em {{ tempo }}',
        finish: 'Pedido entregue',
      },
      [OrderType.PickUp]: {
        inProgress: 'Retirada prevista em {{ tempo }}',
        finish: 'Pedido recuperado',
      },
      [OrderType.EatIn]: {
        inProgress: 'Em preparação...',
        finish: 'Pedido atendido',
      },
      [OrderType.Table]: {
        inProgress: 'Em preparação...',
        finish: 'Pedido atendido',
      },
    },

    details: {
      products: 'Produtos',
      cta: 'Veja detalhes',
      total: 'Total',

      checkItinerary: 'Veja o itinerário',
      trackDelivery: 'Rastrear entrega',
      help: 'Um problema com o pedido?',
    },
  },

  product: {
    composed: {
      modifiers: {
        title: 'Personalize seu produto',
        option: {
          placeholder: 'Selecionar...',
        },
      },
    },

    menu: {
      selectProduct: 'Selecione um produto',
    },

    labels: {
      discount: '{{desconto}} desconto',
      new: 'Novo',
      outOfOrder: 'Fora de serviço',
    },
    required: 'Requeridos',
  },
  footer: {
    usefulLinks: {
      title: 'Links Úteis',
      customerService: 'Atendimento ao Cliente',
      accessToWebsite: 'Acesso ao site',
      seeOurRestaurants: 'Veja nossos restaurantes',
      winAPrize: 'Ganhar um prêmio',
    },
    legal: {
      title: 'Jurídico',
      cgv: 'Termos de vendas',
      cgu: 'Termos de serviço',
      cookies: 'Política de cookies',
      data: 'Política de Privacidade',
      mentions: 'Notícia Legal',
    },
    bestOffers: {
      title: 'Aproveite as melhores ofertas',
      download: 'Baixe nosso aplicativo móvel !',
    },
  },
  print: {
    note: 'COMENTE / NOTA',
    total: 'Total',
    section: {
      user: {
        title: 'CLIENTE',
        firstName: 'Primeiro Nome',
        lastName: 'Último Nome',
        phone: 'Número de telefone',
      },
      note: {
        title: 'COMENTE / NOTA',
      },
    },
    type: {
      delivery: 'Entrega',
      pickUp: 'C&C',
      eatIn: 'Coma em',
      table: 'No local',
    },
    error: {
      notFound: 'A impressora não foi encontrada',
      init: 'Parece haver um problema com o seu Bluetooth. Certifique-se de que o Bluetooth está ligado.',
      bluetooth: {
        title: 'Atenção',
        message: 'Bluetooth não está ativado. Nenhuma impressora está conectada no momento.',
      },
    },
  },

  credits: {
    poweredBy: 'Distribuído por',
    name: 'Belorder v2.0',
  },

  errors,

  kiosk,
  landingPage: ptLandingPageTranslations,
}

export default ptTranslations
