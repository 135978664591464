import { frLandingPageTranslations } from '../fr/landingPage'

export const esLandingPageTranslations: typeof frLandingPageTranslations = {
  header: {
    menu: 'MENÚ',
  },
  content: {
    quickOrder: {
      delivery: 'Envío',
      clickAndCollect: 'Recogida',
      address: {
        placeholder: 'Elija su dirección...',
        placeholder_delivery: 'Elija una dirección de envío...',
      },
    },
  },
}
