import { useEffect, useState } from 'react'

import { StorageRepository } from '../interfaces/storage-repository.interface'
import { useLoadingStore } from './loading.store'
import { parseBoolean, parseNumber, parseObject, parseString } from './parser'

export function useStoredString(
  key: string,
  defaultValue: string | undefined,
  storage?: StorageRepository,
) {
  return useStoredField<string | undefined>(
    key,
    defaultValue,
    (value) => value ?? '',
    (value) => {
      const strValue = parseString(value)
      if (strValue === 'undefined') {
        return
      }

      return strValue
    },
    storage,
  )
}

export function useStoredBoolean(key: string, defaultValue: boolean, storage?: StorageRepository) {
  return useStoredField<boolean>(
    key,
    defaultValue,
    (value) => value?.toString(),
    (value) => parseBoolean(value) ?? false,
    storage,
  )
}

export function useStoredNumber(
  key: string,
  defaultValue: number | undefined,
  storage?: StorageRepository,
) {
  return useStoredField<number | undefined>(
    key,
    defaultValue,
    (value) => value?.toString(),
    parseNumber,
    storage,
  )
}

export function useStoredObject<T extends Record<string, any>>(
  key: string,
  defaultValue: T | undefined,
  storage?: StorageRepository,
) {
  return useStoredField<T | undefined>(
    key,
    defaultValue,
    (value) => (value ? JSON.stringify(value) : undefined),
    (value: unknown) => parseObject(value) as T | undefined,
    storage,
  )
}

export function useStoredField<T>(
  key: string,
  defaultValue: T,
  serialize: (value: T) => string | undefined,
  deserialize: (raw: unknown) => T,
  storage?: StorageRepository,
) {
  const { isLoading, operation } = useLoadingStore()

  const [loaded, setLoaded] = useState(false)
  const [value, setValue] = useState<T>(defaultValue)

  const load = operation(async () => {
    if (storage) {
      const newValue = deserialize(await storage.get(key))
      if (newValue) {
        setValue((value) => newValue ?? value ?? defaultValue)
      }
    }

    setLoaded(true)
  })

  const save = operation(async (newValue: T) => {
    setValue(newValue)
    await storage?.set(key, serialize(newValue))
  })

  const remove = operation(async () => {
    setValue(defaultValue)
    await storage?.set(key, null)
  })

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    setValue((value) => value ?? defaultValue)
  }, [defaultValue])

  return {
    isLoading,
    value,
    loaded,

    load,
    save,
    remove,
  }
}
