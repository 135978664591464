import { frLandingPageTranslations } from '../fr/landingPage'

export const caLandingPageTranslations: typeof frLandingPageTranslations = {
  header: {
    menu: 'MENU',
  },
  content: {
    quickOrder: {
      delivery: 'Entrega',
      clickAndCollect: 'Feu clic i recopileu',
      address: {
        placeholder: 'Trieu la vostra adreça ...',
        placeholder_delivery: 'Trieu una adreça de lliurament ...',
      },
    },
  },
}
