import { CartItemRequest, ItemResponse } from '../../client'

export enum ItemType {
  // Simple item without modifiers
  Simple = 'simple',

  // Composed item with modifiers but only for simple item
  Composed = 'composed',

  // Complex item that contains simple and composed items in its modifiers
  Menu = 'menu',
}

export interface ItemMeta {
  /** Number of sub-items */
  readonly depth: number

  /** Type of item */
  readonly type: ItemType
}

export type ItemWithPrice = CartItemRequest &
  ItemResponse & {
    readonly price: number
  }
