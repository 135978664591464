import { frKioskTranslations } from '../fr/kiosk'

export const enKioskTranslations: typeof frKioskTranslations = {
  welcome: {
    cta: 'Touch to order',
  },

  setupKiosk: {
    title: 'Install',
    subtitle: 'Insert Dashboard Token',

    token: { placeholder: 'Insert the token...' },

    unlockPad: {
      incorrectCode: 'The code is incorrect',
    },

    home: {
      title: 'Terminal parameters',
      subtitle: 'Manage order terminal settings',

      general: {
        title: 'General',
        restaurant: 'Restaurant',
        deviceId: 'ID device',
        terminal: 'Payment terminal',
        refresh: 'Refresh',
      },

      printer: {
        title: 'Printer',
        usb: 'USB Port',
        type: 'Type of printer',
      },

      tests: {
        title: 'Tests',
        printer: 'Test the printer',
        crash: 'Test a crash',
      },

      kiosk: {
        title: 'Kiosk',
        isAdmin: 'Admin mode',
        enable: 'Enable kiosk mode',
        disable: 'Disable kiosk mode',
        makeAdmin: 'Activate kiosk mode',

        removeAdmin: 'Disable administrator mode',
      },

      version: {
        title: 'Version',
        downloadLatest: 'Download the latest version',
      },
    },

    error: {
      title: 'Error',
      subtitle: 'A problem has occurred. Please try again or contact customer support.',
    },
  },

  setup: {
    accessibility: 'Change the display',

    connect: {
      title: 'Login',
      description: 'Benefit from the advantages with your customer account',
      retryTip:
        'Press this button if the kiosk was connected before and there was some network problem.',

      yes: {
        title: 'Login',
        description: 'Log in with your customer loyalty account',
      },
      no: {
        title: 'Order',
        description: 'Continue as guest',
      },
    },

    guest: {
      title: 'Your first name',
      description: 'To identify your order',
      placeholder: 'Enter your first name here...',
    },

    login: {
      title: 'Login',
      description: 'Enter your username',
      placeholder: 'ABC123',
    },

    order: {
      title: 'Order',
      description: 'Choose your order mode',
    },

    eatIn: {
      title: 'Table service',
      description: 'Enter the table number below',
      placeholder: '',
      pass: 'Recover my order at the counter',
      confirm: 'Confirm',
    },
  },

  catalog: {
    title: 'Welcome {{name}},',
    home: 'Home',

    cancel: {
      title: 'Your basket will be emptied. Would you like to continue ?',
    },
    inactivity: {
      title: 'Are you still here ?',
      description: 'Would you like to continue with your order?',
      cta: 'Continue',
    },

    header: {
      allergens: 'Allergen list',
      fidelity: 'My loyalty',
      banners: 'Right now',
      categories: 'Our categories',
    },

    footer: {
      title: 'YOUR ORDER',
      total: 'TOTAL {{total}}',
      empty: 'Your cart is empty',

      buttons: {
        cancel: 'Cancel the order',
        validate: 'Validate',
      },
    },

    baseItems: {
      title: 'Do you want to remove an ingredient?',
    },
    upsell: {
      title: 'Make a choice',
    },
    crossSell: {
      title: 'A little extra for the road?',
    },

    cartProductDetails: {
      quantity: 'Quantity',
      personalize: 'Personalize',
    },
  },

  catalogProduct: {
    selection: 'Your selection :',
    noThanks: 'No thanks',
    addProduct: 'Add to cart',

    emptyItem: 'Selection',

    extras: {
      minMax: '{{ min }} min. / {{ max }} max.',
      max: '{{ current }}/{{ max }} selected',
      multiMin: '{{ current }}/{{ min }} min.',
      optional: '{{ current }}/{{ max }} max.',

      skip: 'No thanks',
    },
  },

  checkout: {
    title: 'Is your order correct ?',
    total: 'Total to pay',

    promotion: {
      cta: 'Promo code',
      title: 'Promo code',
      subtitle: 'Enter your promo code',
      placeholder: 'Promo',
    },

    footerButtons: {
      cancel: 'Continue order',
      validate: 'Proceed to payment',
    },

    payment: {
      home: {
        title: 'Payment',
        subtitle: 'Choose your payment method',

        creditCard: 'Cards',
        trd: 'Titres restaurant',
        cash: 'Counter',
      },

      loading: {
        pos: {
          title: 'Follow the payment instruction.',
          subtitle: 'Please follow the payment instructions displayed on the payment terminal.',
        },
        trd: {
          title: 'Ordering in progress...',
          subtitle: 'We send the order to the cashier, please wait a moment.',
        },
        cash: {
          title: 'Ordering in progress...',
          subtitle: 'We send the order to the cashier, please wait a moment.',
        },
      },

      finished: {
        title: 'We will start preparing your order',
        title_cash: 'Head to the cashier to make the payment',
        title_eatIn: 'Head to your table, you will be served there',

        pos: {
          subtitle: 'Your payment has been accepted',
        },
        trd: {
          subtitle: 'Please go to the cashier for making the payment.',
        },
        cash: {
          subtitle: 'Please go to the cashier for making the payment.',
        },

        ticketType: {
          title: 'Collect your receipt',
          print: 'Print ticket',
          email: 'By email',
          emailTip: 'A gesture for the planet !',
        },

        details: {
          title: 'Recover your order receipt',
          subtitle: 'Present your ticket to collect your order',
          subtitle_cash: 'Present your ticket to the counter to pay your order',
          subtitle_eathIn: 'Present your ticket to collect your order',
        },

        sendEmail: {
          title: 'Received',
          subtitle: 'Enter your email address',
          input: 'Enter your email here...',
        },
      },
    },

    footer: 'YOUR ORDER : {{price}}',
  },

  update: {
    title: 'Checking for updates...',
  },
}

export default enKioskTranslations
