import { frLandingPageTranslations } from '../fr/landingPage'

export const nlLandingPageTranslations: typeof frLandingPageTranslations = {
  header: {
    menu: 'MENU',
  },
  content: {
    quickOrder: {
      delivery: 'Verzending',
      clickAndCollect: 'Verzameling',
      address: {
        placeholder: 'Kies uw adres...',
        placeholder_delivery: 'Kies een afleveradres...',
      },
    },
  },
}
