import { frKioskTranslations } from '../fr/kiosk'

export const esKioskTranslations: typeof frKioskTranslations = {
  welcome: {
    cta: 'Haga click para comenzar',
  },

  setupKiosk: {
    title: 'Instalar',
    subtitle: 'Insertar token de la tablet',

    token: { placeholder: 'Inserte el token...' },

    unlockPad: {
      incorrectCode: 'El código es incorrecto',
    },

    home: {
      title: 'Parámetros del terminal',
      subtitle: 'Administrar la configuración de la terminal de pedidos',

      general: {
        title: 'General',
        restaurant: 'Restaurante',
        deviceId: 'ID del dispositivo',
        terminal: 'Terminal dep ago',
        refresh: 'Recargar',
      },

      printer: {
        title: 'Printer',
        usb: 'USB Port',
        type: 'Type of printer',
      },

      tests: {
        title: 'Tests',
        printer: 'Prueba la impresora',
        crash: 'Prueba un crash',
      },

      kiosk: {
        title: 'Quiosco',
        isAdmin: 'Modo de administración',
        enable: 'Habilitar el modo quiosco',
        disable: 'Deshabilitar el modo quiosco',
        makeAdmin: 'Activar el modo quiosco',

        removeAdmin: 'Deshabilitar el modo administrador',
      },

      version: {
        title: 'Versión',
        downloadLatest: 'Descargar la última versión',
      },
    },

    error: {
      title: 'Error',
      subtitle:
        'Se ha producido un problema.Vuelva a intentarlo o comuníquese con la atención al cliente.',
    },
  },

  setup: {
    accessibility: 'Cambia la visualización',

    connect: {
      title: 'Entrar',
      description: 'Benefíciese de las ventajas con su cuenta de clientes',
      retryTip:
        'Press this button if the kiosk was connected before and there was some network problem.',

      yes: {
        title: 'Entrar',
        description: 'Inicie sesión con la cuenta de fidelización de su cliente',
      },
      no: {
        title: 'Pedir',
        description: 'Continuar como invitado',
      },
    },

    guest: {
      title: 'Su nombre',
      description: 'Para identificar su pedido',
      placeholder: 'Ingrese su primer nombre aquí...',
    },

    login: {
      title: 'Acceso',
      description: 'Ingrese su nombre de usuario',
      placeholder: 'ABC123',
    },

    order: {
      title: 'Pedido',
      description: 'Elige el tipo de pedido',
    },

    eatIn: {
      title: 'Comer aquí',
      description: 'Introduce el número de la mesa',
      placeholder: '',
      pass: 'Recoger mi pedido en la caja',
      confirm: 'Confirmar',
    },
  },

  catalog: {
    title: 'Bienvenido {{name}},',
    home: 'Home',

    cancel: {
      title: 'Tu carrito se vaciará. ¿Seguro que quieres continuar?',
    },
    inactivity: {
      title: '¿Sigues aquí?',
      description: '¿Le gustaría continuar con su pedido?',
      cta: 'Continuar',
    },

    header: {
      allergens: 'Lista de alérgenos',
      fidelity: 'Mi loyalty',
      banners: 'En este momento',
      categories: 'Nuestras categorías',
    },

    footer: {
      title: 'SU PEDIDO',
      total: 'TOTAL {{total}}',
      empty: 'Tu carrito esta vacío',

      buttons: {
        cancel: 'Cancelar el pedido',
        validate: 'Validar',
      },
    },

    baseItems: {
      title: '¿Quieres quitar un ingrediente?',
    },
    upsell: {
      title: 'Elige un producto',
    },
    crossSell: {
      title: '¿Un extra para el camino?',
    },

    cartProductDetails: {
      quantity: 'Cantidad',
      personalize: 'Personalizar',
    },
  },

  catalogProduct: {
    selection: 'Tu selección :',
    noThanks: 'No, gracias',
    addProduct: 'Añadir a la cesta',

    emptyItem: 'Selección',

    extras: {
      minMax: '{{ min }} min. / {{ max }} máx.',
      max: '{{ current }}/{{ max }} seleccionados',
      multiMin: '{{ current }}/{{ min }} min.',
      optional: '{{ current }}/{{ max }} máx.',

      skip: 'No gracias',
    },
  },

  checkout: {
    title: '¿Es tu pedido correcto?',
    total: 'Total a pagar',

    promotion: {
      cta: 'Código promocional',
      title: 'Código promocional',
      subtitle: 'Introduce tu código de promoción',
      placeholder: 'Promo',
    },

    footerButtons: {
      cancel: 'Continuar el pedido',
      validate: 'Proceder al pago',
    },

    payment: {
      home: {
        title: 'Pago',
        subtitle: 'Escoja su método de pago',

        creditCard: 'Tarjeta',
        trd: 'Titres restaurant',
        cash: 'Efectivo',
      },

      loading: {
        pos: {
          title: 'Siga las instrucciones de pago.',
          subtitle: 'Siga las instrucciones que se muestran en la terminal de pago.',
        },
        trd: {
          title: 'Procesando la comanda',
          subtitle: 'Enviamos el pedido al cajero, espere un momento.',
        },
        cash: {
          title: 'Procesando la comanda',
          subtitle: 'Enviamos el pedido al cajero, espere un momento.',
        },
      },

      finished: {
        title: 'Comenzaremos a preparar su pedido',
        title_cash: 'Dirígete al cajero para realizar el pago',
        title_eatIn: 'Dirígete a su mesa, te servirán allí',

        pos: {
          subtitle: 'Su pago ha sido aceptado',
        },
        trd: {
          subtitle: 'Diríjase al cajero para realizar el pago.',
        },
        cash: {
          subtitle: 'Diríjase al cajero para realizar el pago.',
        },

        ticketType: {
          title: 'Recoja su recibo',
          print: 'Imprimir recibo',
          email: 'Por correo electrónico',
          emailTip: '¡Un gesto para el planeta!',
        },

        details: {
          title: 'Recupere su recibo de pedido',
          subtitle: 'Presente su ticket para recoger su pedido',
          subtitle_cash: 'Presente su ticket al mostrador para pagar su pedido',
          subtitle_eathIn: 'Presente su ticket para recoger su pedido',
        },

        sendEmail: {
          title: 'Recibo',
          subtitle: 'Introduzca su dirección de correo electrónico',
          input: 'Introduzca su correo electrónico aquí...',
        },
      },
    },

    footer: 'SU PEDIDO : {{price}}',
  },

  update: {
    title: 'Comprobando actualizaciones...',
  },
}

export default esKioskTranslations
