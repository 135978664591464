export const frLandingPageTranslations = {
  header: {
    menu: 'MENU',
  },
  content: {
    quickOrder: {
      delivery: 'Livraison',
      clickAndCollect: 'Click & collect',
      address: {
        placeholder: 'Saisissez votre adresse...',
        placeholder_delivery: 'Saisissez une adresse de livraison...',
      },
    },
  },
}
