// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
// import { BrowserTracing } from '@sentry/tracing'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0,
  // integrations: [new BrowserTracing()],
  release: process.env.NEXT_PUBLIC_RELEASE,
  environment: process.env.NEXT_PUBLIC_ENV || 'local',
})
