import { Template, TemplateData } from '../interfaces/template.interface'
import { bagelCornerTemplateData } from './bagel-corner.template'
import { belorderTemplateData } from './belorder.template'
import { berlinerTemplateData } from './berliner.template'
import { cmdTemplateData } from './cmd.template'
import { cuveeNoireTemplateData } from './cuvee-noire.template'
import { fivePizzaTemplateData } from './five-pizza.template'
import { jourTemplateData } from './jour.template'
import { mamasCornerTemplateData } from './mamas-corner.template'
import { mifanTemplateData } from './mifan.template'
import { newTokyoTemplateData } from './new-tokyo.template'
import { phoodTemplateData } from './phood.template'
import { pokawaTemplateData } from './pokawa.template'
import { saladandCoTemplateData } from './saladandco.template'

export const templateData: Record<Template, TemplateData> = {
  [Template.Belorder]: belorderTemplateData,
  [Template.Pokawa]: pokawaTemplateData,
  [Template.Mifan]: mifanTemplateData,
  [Template.FivePizza]: fivePizzaTemplateData,
  [Template.Jour]: jourTemplateData,
  [Template.Berliner]: berlinerTemplateData,
  [Template.BagelCorner]: bagelCornerTemplateData,
  [Template.MamasCorner]: mamasCornerTemplateData,
  [Template.Phood]: phoodTemplateData,
  [Template.CuveeNoire]: cuveeNoireTemplateData,
  [Template.Cmd]: cmdTemplateData,
  [Template.NewTokyo]: newTokyoTemplateData,
  [Template.SaladandCo]: saladandCoTemplateData,
}

/**
 * Gets the data for the given template.
 * @param template
 * @returns template data
 */
export function getTemplateData(template: Template): TemplateData {
  return templateData[template]
}
