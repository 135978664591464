import { DeliveryQuoteResponse, DiscountResponse, GuestRequest, OrderType } from '../../client'
import { Coordinates, TimeSlot } from '../restaurant/restaurant.interface'

export interface CartAddress {
  readonly countryCode: string
  readonly city: string
  readonly postalCode: string
  readonly street: string
  readonly streetNumber: string
  readonly description: string
  readonly coords: Coordinates
}

export interface GuestPhone {
  readonly country?: string
  readonly countryCode: string
  readonly number: string
  readonly fullNumber: string
}

export interface Guest extends Omit<GuestRequest, 'phone'> {
  /** User hone */
  readonly phone: GuestPhone
}

export interface CartDeliveryInfo {
  /** Address description. */
  readonly address: CartAddress

  /** Apartment number */
  readonly apartment?: string

  /** Delivery instructions (apartment, pass-code) */
  readonly instructions?: string
}

export interface Cart {
  /** Type of order */
  readonly type: OrderType

  /** Eat in table id */
  readonly tableId?: string

  /** ID of the selected restaurant. */
  readonly restaurantId: string

  /** Time slot selected */
  readonly slot?: TimeSlot

  /** Delivery information */
  readonly delivery?: CartDeliveryInfo

  /** Delivery quote */
  readonly deliveryQuote?: DeliveryQuoteResponse

  /** Whether the order should include cutlery */
  readonly cutlery: boolean

  /** Comments from the client */
  readonly note: string

  /** Saved promotion */
  readonly promotion?: DiscountResponse

  /** Whether the language has been changed */
  readonly hasChangedLanguage?: boolean

  /** ID of the trace, when the order is waiting for user action */
  readonly traceId?: string
}

export enum PurchaseStatus {
  // Before the user has clicked on pay button
  Inactive = 'inactive',

  // Before sending the request, to give the user some time to cancel
  PrePush = 'prePush',

  // Pre-push has finished, it's ready to make the purchase
  Ready = 'ready',

  // Needs another payment method to make the order
  NeedsSecondPayment = 'needsSecondPayment',

  // Sending request to purchase
  Purchasing = 'purchasing',

  // Checking the action result
  CheckoutActionResult = 'actionResult',

  // Order finished
  Finished = 'finished',

  // Request has failed
  Failed = 'failed',
}

export enum PurchaseAction {
  Start = 'start',
  Cancel = 'cancel',
  SetReady = 'ready',
  StartImmediately = 'startImmediately',
  SetNeedsSecondsPayment = 'setNeedsSecondPayment',
  SetPurchasing = 'setPurchasing',
  SetProcessingActionResult = 'setProcessingActionResult',
  SetFinished = 'setFinished',
  SetFailed = 'setFailed',
  Reset = 'reset',
}
