import {
  Template,
  TemplateData,
  TemplateLinks,
  Theme,
  ThemeColor,
  ThemeComponents,
  ThemeTypography,
} from '../interfaces/template.interface'

const theme: Theme = {
  colors: {
    // Theme
    [ThemeColor.Primary]: '#333333',
    [ThemeColor.LightPrimary]: '#FDE354',
    [ThemeColor.Secondary]: '#FDE354',
    [ThemeColor.Tertiary]: '#444444',
    // System
    [ThemeColor.Black]: '#000000',
    [ThemeColor.White]: '#FFFFFF',
    [ThemeColor.LightGrey]: '#F4F4F5',
    [ThemeColor.MediumGrey]: '#F3F5F5',
    [ThemeColor.Grey]: '#707070',
    [ThemeColor.DarkGrey]: '#B9BDC0',

    [ThemeColor.New]: '#2D9CDB',
    [ThemeColor.Prices]: '#FF9E34',
    [ThemeColor.Discounts]: '#EC6C53',
    [ThemeColor.OutOfStock]: '#BDBDBD',
    [ThemeColor.Success]: '#008305',
    [ThemeColor.Alert]: '#EB5757',
    // Form
    [ThemeColor.InputContrast]: '#EBB826',
    [ThemeColor.ContrastThreshold]: '#E8E8EB',
    [ThemeColor.SwitchChecked]: '#2CD889',
    [ThemeColor.SwitchUnchecked]: '#E0E0E0',
    // Typography:
    [ThemeColor.Text]: '#333333',
    [ThemeColor.DarkText]: '#333333',
    [ThemeColor.LightText]: '#FFF',
    [ThemeColor.TextContrast]: '#333333',
    [ThemeColor.Icon]: '#333333',
  },

  typography: {
    [ThemeTypography.H1]: {
      fontFamily: 'Obviously',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '35px',
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
    },
    [ThemeTypography.H2]: {
      fontFamily: 'Obviously',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '37px',
      letterSpacing: '0.01em',
    },
    [ThemeTypography.H3]: {
      fontFamily: 'Obviously',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.BodyBold]: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.Body]: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.Link]: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
      textDecorationLine: 'underline',
    },
    [ThemeTypography.Button]: {
      fontFamily: 'Obviously',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.02em',
    },
    [ThemeTypography.CategoryWithPicture]: {
      fontFamily: 'Obviously-narrow',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '14px',
    },
    [ThemeTypography.CategoryWithoutPicture]: {
      fontFamily: 'Obviously-narrow',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
    },
    [ThemeTypography.Label]: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    [ThemeTypography.Placeholder]: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: 300,
      lineHeight: '18px',
    },
    [ThemeTypography.Input]: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '18px',
    },
    [ThemeTypography.ProductTag]: {
      fontFamily: 'Obviously',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: '0em',
      textAlign: 'left',
    },
    [ThemeTypography.PriceTag]: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.PriceTagDiscount]: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textDecorationLine: ' line-through',
    },
    [ThemeTypography.Alert]: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: '0em',
    },
    [ThemeTypography.AlertBold]: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    [ThemeTypography.Copyright]: {
      fontFamily: 'Montserrat',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
  },

  nativeComponents: {},

  mobileBreakpoint: 750,

  background: '#FFF',
  cardsBackground: '#FFF',
}

const components: ThemeComponents = {
  navBar: {
    backgroundColor: theme.colors.primary,
    dividerColor: theme.colors.white,
    textColor: theme.colors.white,
    iconColor: theme.colors.secondary,
  },

  orderSettings: {
    backgroundColor: '#444444',
    dividerColor: theme.colors.white,
    arrowColor: theme.colors.secondary,
    labelColor: theme.colors.white,
    valueColor: theme.colors.secondary,
  },

  products: {
    cardWidth: 165,
    embeddedCardWidth: 286,

    labelColors: {
      discount: theme.colors.secondary,
      new: theme.colors.secondary,
      outOfOrder: theme.colors.white,
    },
  },
}

const links: TemplateLinks = {
  usefulLinks: {
    customerService: 'https://fivepizzaoriginal.com/service-client/',
    accessToWebsite: 'https://www.fivepizzaoriginal.com/',
    seeOurRestaurants: 'https://stores.fivepizzaoriginal.com/',
  },
  legal: {
    cgv: 'https://fivepizzaoriginal.com/conditions-generales/',
    cgu: 'https://fivepizzaoriginal.com/conditions-generales/',
    cookies: 'https://fivepizzaoriginal.com/politique-de-donnees/',
    data: 'https://fivepizzaoriginal.com/politique-de-donnees/',
    mentions: 'https://fivepizzaoriginal.com/mentions-legales/',
  },
  applications: {
    appStore: 'https://fivepizzaoriginal.com/pre-inscription-app/',
    googlePlay: 'https://fivepizzaoriginal.com/pre-inscription-app/',
  },
}

export const fivePizzaTemplateData: TemplateData = {
  name: Template.FivePizza,
  storeLink: 'https://stores.fivepizzaoriginal.com',
  webappLink: 'https://order.fivepizzaoriginal.com',
  websiteLink: 'https://fivepizzaoriginal.com/',
  instagramLink: 'https://www.instagram.com/fivepizzaoriginal/',
  fontLinks: [
    'https://use.typekit.net/ivy4hye.css', // "Obviously" font
    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,300&display=swap',
  ],
  links,
  theme,
  components,
}
