import { frKioskTranslations } from '../fr/kiosk'

export const ptKioskTranslations: typeof frKioskTranslations = {
  welcome: {
    cta: 'Toque para Pedir',
  },

  setupKiosk: {
    title: 'Instalar',
    subtitle: 'Inserir Token do Painel',

    token: { placeholder: 'Insira o Token...' },

    unlockPad: {
      incorrectCode: 'O Código está Incorreto',
    },

    home: {
      title: 'Parâmetros do Terminal',
      subtitle: 'Gerenciar configurações do terminal de pedidos',

      general: {
        title: 'Em geral',
        restaurant: 'Restaurante',
        deviceId: 'Dispositivo de Identificação',
        terminal: 'Terminal de Pagamento',
        refresh: 'Atualizar',
      },

      printer: {
        title: 'Printer',
        usb: 'USB Port',
        type: 'Type of printer',
      },

      tests: {
        title: 'Testes',
        printer: 'Teste a Impressora',
        crash: 'Testar uma Falha',
      },

      kiosk: {
        title: 'Quiosque',
        isAdmin: 'Modo Administrador',
        enable: 'Ativar o Modo Quiosque',
        disable: 'Desabilitar o Modo Quiosque',
        makeAdmin: 'Ativar Modo Quiosque',

        removeAdmin: 'Desabilitar Modo Administrador',
      },

      version: {
        title: 'Versão',
        downloadLatest: 'Baixe a Versão mais Recente',
      },
    },

    error: {
      title: 'Erro',
      subtitle:
        'Ocorreu um problema. Tente novamente ou entre em contato com o suporte ao cliente.',
    },
  },

  setup: {
    accessibility: 'Change the display',

    connect: {
      title: 'Login',
      description: 'Beneficie das vantagens com a sua conta de cliente',
      retryTip:
        'Press this button if the kiosk was connected before and there was some network problem.',

      yes: {
        title: 'Login',
        description: 'Faça login com sua conta de fidelidade do cliente',
      },
      no: {
        title: 'Pedido',
        description: 'Continuar como convidado',
      },
    },

    guest: {
      title: 'Seu primeiro nome',
      description: 'Para identificar seu pedido',
      placeholder: 'Digite seu primeiro nome aqui...',
    },

    login: {
      title: 'Login',
      description: 'Entre com seu nome de usuário',
      placeholder: 'ABC123',
    },

    order: {
      title: 'Pedido',
      description: 'Escolha o seu modo de pedido',
    },

    eatIn: {
      title: 'Table service',
      description: 'Enter the table number below',
      placeholder: '',
      pass: 'Recover my order at the counter',
      confirm: 'Confirm',
    },
  },

  catalog: {
    title: 'Bem-vindo {{nome}},',
    home: 'Início',

    cancel: {
      title: 'Seu carrinho será esvaziado. Você gostaria de continuar ?',
    },
    inactivity: {
      title: 'Você ainda está aqui ?',
      description: 'Deseja continuar com seu pedido?',
      cta: 'Continuar',
    },

    header: {
      allergens: 'Lista de alérgenos',
      fidelity: 'Minha fidelidade',
      banners: 'Agora mesmo',
      categories: 'Nossas categorias',
    },

    footer: {
      title: 'SEU PEDIDO',
      total: 'TOTAL {{total}}',
      empty: 'Seu carrinho está vazio',

      buttons: {
        cancel: 'Cancelar o pedido',
        validate: 'Validar',
      },
    },

    baseItems: {
      title: 'Do you want to remove an ingredient?',
    },
    upsell: {
      title: 'Make a choice',
    },
    crossSell: {
      title: 'A little extra for the road?',
    },

    cartProductDetails: {
      quantity: 'Quantidade',
      personalize: 'Personalizar',
    },
  },

  catalogProduct: {
    selection: 'Sua escolha :',
    noThanks: 'Não, obrigado',
    addProduct: 'Adicionar ao carrinho',

    emptyItem: 'Escolha',

    extras: {
      minMax: '{{ min }} min. / {{ max }} max.',
      max: '{{ atual }}/{{ max }} escolha',
      multiMin: '{{ atual }}/{{ min }} min.',
      optional: '{{ atual }}/{{ max }} max.',

      skip: 'Não, obrigado',
    },
  },

  checkout: {
    title: 'Seu pedido está correto?',
    total: 'Total a pagar',

    promotion: {
      cta: 'Código promocional',
      title: 'Código promocional',
      subtitle: 'Insira seu código promocional',
      placeholder: 'Promoção',
    },

    footerButtons: {
      cancel: 'Continuar pedido',
      validate: 'Prossiga para o pagamento',
    },

    payment: {
      home: {
        title: 'Pagamento',
        subtitle: 'Escolha o seu método de pagamento',

        creditCard: 'Cartão',
        trd: 'Titres restaurant',
        cash: 'Caixa',
      },

      loading: {
        pos: {
          title: 'Siga a instrução de pagamento.',
          subtitle: 'Please follow the payment instructions displayed on the payment terminal.',
        },
        trd: {
          title: 'Ordering in progress...',
          subtitle: 'We send the order to the cashier, please wait a moment.',
        },
        cash: {
          title: 'Ordering in progress...',
          subtitle: 'We send the order to the cashier, please wait a moment.',
        },
      },

      finished: {
        title: 'Vamos começar a preparar seu pedido',
        title_cash: 'Vá para o caixa para fazer o pagamento',
        title_eatIn: 'Vá para a sua mesa, você será servido lá',

        pos: {
          subtitle: 'Your payment has been accepted',
        },
        trd: {
          subtitle: 'Please go to the cashier for making the payment.',
        },
        cash: {
          subtitle: 'Please go to the cashier for making the payment.',
        },

        ticketType: {
          title: 'Pegue seu recibo',
          print: 'Imprimir bilhete',
          email: 'Por email',
          emailTip: 'Um gesto para o planeta !',
        },

        details: {
          title: 'Recupere o recibo do seu pedido',
          subtitle: 'Apresente seu ingresso para coletar seu pedido',
          subtitle_cash: 'Apresente seu ingresso para o balcão para pagar seu pedido',
          subtitle_eathIn: 'Apresente seu ingresso para coletar seu pedido',
        },

        sendEmail: {
          title: 'Recebido',
          subtitle: 'Insira o seu endereço de email',
          input: 'Digite seu email aqui...',
        },
      },
    },

    footer: 'SEU PEDIDO : {{preço}}',
  },

  update: {
    title: 'Verificando atualizações...',
  },
}

export default ptKioskTranslations
