import {
  Template,
  TemplateData,
  TemplateLinks,
  Theme,
  ThemeColor,
  ThemeComponents,
  ThemeTypography,
} from '../interfaces/template.interface'

const theme: Theme = {
  colors: {
    // Theme
    [ThemeColor.Primary]: '#FFCC40',
    [ThemeColor.LightPrimary]: '#F0F5F9',
    [ThemeColor.Secondary]: '#1C97D2',
    [ThemeColor.Tertiary]: '#FFCC40',
    // System
    [ThemeColor.Black]: '#333333',
    [ThemeColor.White]: '#FFFFFF',
    [ThemeColor.LightGrey]: '#F8FAFA',
    [ThemeColor.MediumGrey]: '#F3F5F5',
    [ThemeColor.Grey]: '#707070',
    [ThemeColor.DarkGrey]: '#B9BDC0',

    [ThemeColor.New]: '#2D9CDB',
    [ThemeColor.Prices]: '#F2994A',
    [ThemeColor.Discounts]: '#F2994A',
    [ThemeColor.OutOfStock]: '#BDBDBD',
    [ThemeColor.Success]: '#27AE60',
    [ThemeColor.Alert]: '#EB5757',
    // Form
    [ThemeColor.InputContrast]: '#FFCC40',
    [ThemeColor.ContrastThreshold]: '#E8E8EB',
    [ThemeColor.SwitchChecked]: '#2CD889',
    [ThemeColor.SwitchUnchecked]: '#E0E0E0',
    // Typography
    [ThemeColor.Text]: '#333333',
    [ThemeColor.DarkText]: '#333333',
    [ThemeColor.LightText]: '#FFF',
    [ThemeColor.TextContrast]: '#333333',
    [ThemeColor.Icon]: '#333333',
  },

  typography: {
    [ThemeTypography.H1]: {
      fontFamily: 'Poppins',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '35px',
      letterSpacing: '0.03em',
    },
    [ThemeTypography.H2]: {
      fontFamily: 'Poppins',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '37px',
      letterSpacing: '0.01em',
    },
    [ThemeTypography.H3]: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.BodyBold]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.Body]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.Link]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
      textDecorationLine: ' underline',
    },
    [ThemeTypography.Button]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.02em',
    },
    [ThemeTypography.CategoryWithPicture]: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0em',
    },
    [ThemeTypography.CategoryWithoutPicture]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.Label]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    [ThemeTypography.Placeholder]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: 300,
      lineHeight: '18px',
    },
    [ThemeTypography.Input]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '18px',
    },
    [ThemeTypography.ProductTag]: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    [ThemeTypography.PriceTag]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
    },
    [ThemeTypography.PriceTagDiscount]: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textDecorationLine: ' line-through',
    },
    [ThemeTypography.Alert]: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: '0em',
    },
    [ThemeTypography.AlertBold]: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    [ThemeTypography.Copyright]: {
      fontFamily: 'Poppins',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
  },

  mobileBreakpoint: 750,
}

const components: ThemeComponents = {
  navBar: {
    backgroundColor: theme.colors.white,
    dividerColor: theme.colors.white,
    iconColor: theme.colors.text,
    textColor: theme.colors.text,
  },

  orderSettings: {
    backgroundColor: '#444444',
    dividerColor: theme.colors.white,
    arrowColor: theme.colors.primary,
    labelColor: theme.colors.white,
    valueColor: theme.colors.primary,
  },

  products: {
    cardWidth: 165,
    embeddedCardWidth: 286,

    labelColors: {
      discount: '#F2994A',
      new: '#2D9CDB',
      outOfOrder: theme.colors.white,
    },
  },
}

const links: TemplateLinks = {
  usefulLinks: {
    customerService: 'mailto:contact@belorder.com',
    accessToWebsite: 'https://belorder.com/',
    seeOurRestaurants: 'https://stores.belorder.com',
    winAPrize: 'https://stores.belorder.com',
  },
  legal: {
    cgv: 'https://belorder.com/cgu/',
    cgu: 'https://belorder.com/cgu/',
    cookies: 'https://belorder.com/cgu/',
    data: 'https://belorder.com/cgu/',
    mentions: 'https://belorder.com/mentions-legales/',
  },
  applications: {
    appStore: 'https://belorder.com',
    googlePlay: 'https://belorder.com',
  },
}

export const belorderTemplateData: TemplateData = {
  name: Template.Belorder,
  storeLink: 'https://stores.belorder.com',
  webappLink: 'https://order.belorder.com',
  links,
  theme,
  components,
}
