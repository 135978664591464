import caTranslations from './ca'
import enTranslations from './en'
import esTranslations from './es'
import frTranslations from './fr'
import nlTranslations from './nl'
import ptTranslations from './pt'

export const resources = {
  en: { translation: enTranslations },
  fr: { translation: frTranslations },
  es: { translation: esTranslations },
  ca: { translation: caTranslations },
  pt: { translation: ptTranslations },
  nl: { translation: nlTranslations },
} as const
