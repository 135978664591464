import { CSSProperties } from 'react'

export enum ThemeColor {
  // Theme
  Primary = 'primary',
  LightPrimary = 'lightPrimary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  // System
  Black = 'black',
  White = 'white',
  LightGrey = 'lightGrey',
  Grey = 'grey',
  MediumGrey = 'mediumGrey',
  DarkGrey = 'darkGrey',

  New = 'new',
  Prices = 'prices',
  Discounts = 'discounts',
  OutOfStock = 'outOfStock',
  Success = 'success',
  Alert = 'alert',
  // Form
  InputContrast = 'inputContrast',
  ContrastThreshold = 'contrastThreshold',
  SwitchChecked = 'switchChecked',
  SwitchUnchecked = 'switchUnchecked',
  // Typography
  Text = 'text',
  DarkText = 'darkText',
  LightText = 'lightText',
  TextContrast = 'textContrast',
  Icon = 'icon',
}

export enum ThemeTypography {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  BodyBold = 'bodyBold',
  Body = 'body',
  Link = 'link',
  Button = 'button',
  CategoryWithPicture = 'categoryWithPicture',
  CategoryWithoutPicture = 'categoryWithoutPicture',
  Label = 'label',
  Placeholder = 'placeholder',
  Input = 'input',
  ProductTag = 'productTag',
  PriceTag = 'priceTag',
  PriceTagDiscount = 'priceTagDiscount',
  Alert = 'alert',
  AlertBold = 'alertBold',
  Copyright = 'copyright',
}

export interface ThemeNativeComponents {
  readonly Button?: any
  readonly IconButton?: any
  readonly Text?: any
  readonly Heading?: any
  readonly Input?: any
  readonly TextArea?: any
  readonly Link?: any
  readonly FormControlLabel?: any
}

export interface TypographyStyle {
  readonly fontFamily: string
  readonly fontWeight: string
}

export interface UsefulLinks {
  readonly customerService?: string
  readonly accessToWebsite?: string
  readonly seeOurRestaurants?: string
  readonly winAPrize?: string
}
export interface LegalLinks {
  readonly cgv?: string
  readonly cgu?: string
  readonly cookies?: string
  readonly data?: string
  readonly mentions?: string
}

export interface ApplicationsLinks {
  readonly appStore?: string
  readonly googlePlay?: string
}

export interface TemplateLinks {
  readonly usefulLinks?: UsefulLinks
  readonly legal?: LegalLinks
  readonly applications?: ApplicationsLinks
}

export interface Theme {
  /** Colors for the theme */
  readonly colors: Record<ThemeColor, string>

  /** Style for all the typographies */
  readonly typography: Record<ThemeTypography, CSSProperties>

  /** Breakpoint before which the screen is considered a mobile phone. */
  readonly mobileBreakpoint: number

  /** The of native base components */
  readonly nativeComponents?: ThemeNativeComponents

  /** App background */
  readonly background?: string

  /** Cards background */
  readonly cardsBackground?: string
}

export interface ThemeComponents {
  /** Navbar styling */
  readonly navBar: {
    readonly dividerColor: string
    readonly backgroundColor: string
    readonly textColor: string
    readonly iconColor: string
  }

  readonly orderSettings: {
    readonly backgroundColor: string
    readonly dividerColor: string
    readonly arrowColor: string
    readonly labelColor: string
    readonly valueColor: string
  }

  /** Product cards */
  readonly products: {
    /** Width of the cards (in pixels) */
    readonly cardWidth: number
    /** Width of the cards on embedded pages (in pixels) */
    readonly embeddedCardWidth: number

    /** Colors of the labels */
    readonly labelColors: {
      readonly discount: string
      readonly new: string
      readonly outOfOrder: string
    }
  }
}

/**
 * A template contains all the details (theme, config) for a given company.
 */
export interface TemplateData {
  /** Name of the template */
  readonly name: Template

  /** Link to the webapp */
  readonly webappLink: string

  /** Link to the store */
  readonly storeLink: string

  /** Link to client's website */
  readonly websiteLink?: string

  /** Link to client's instagram profile */
  readonly instagramLink?: string

  /** Links to the fonts used by the theme */
  readonly fontLinks?: string[]

  /** Links to be shown in the user drawer */
  readonly links: TemplateLinks

  /** Theme of the template */
  readonly theme: Theme

  /** Specific styling for certain components */
  readonly components: ThemeComponents
}

/** Available templates */
export enum Template {
  Belorder = 'belorder',
  Berliner = 'Berliner',
  Phood = 'Phood',
  Cmd = 'Cmd',
  CuveeNoire = 'CuveeNoire',
  Pokawa = 'pokawa',
  FivePizza = 'FivePizza',
  BagelCorner = 'BagelCorner',
  MamasCorner = 'MamasCorner',
  Mifan = 'Mifan',
  NewTokyo = 'NewTokyo',
  Jour = 'Jour',
  SaladandCo = 'SaladandCo',
}
