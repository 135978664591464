import { DiscountTarget, Language, OrderStatus, OrderType, UserErrorCode } from '../../client'
import { PurchaseStatus } from '../../modules/cart/cart.types'
import frTranslations from '../fr'
// Modules
import kiosk from './kiosk'
import { esLandingPageTranslations } from './landingPage'

const errors: Record<UserErrorCode | string, string> = {
  // Order
  // - Cart details
  [UserErrorCode.OrderRestaurantOffline]:
    'El restaurante no está disponible en estos momentos, por favor inténtalo de nuevo más tarde.',
  [UserErrorCode.OrderRestaurantBusy]:
    'El restaurante no está disponible en estos momentos, por favor inténtalo de nuevo más tarde.',
  [UserErrorCode.OrderDateInPast]:
    'La hora del pedido está en el pasado, por favor recarga la página y asegúrate de elegir una hora que esté en el futuro.',
  [UserErrorCode.OrderRestaurantClosed]:
    'El restaurante no está disponible en estos momentos, por favor inténtalo de nuevo más tarde.',
  // - Items
  [UserErrorCode.OrderItemsDontExist]:
    'Tu pedido contiene productos que ya no están disponibles. Por favor recarga la página e inténtalo de nuevo.',
  [UserErrorCode.OrderModifiersDontExist]:
    'Tu pedido contiene productos que ya no están disponibles. Por favor recarga la página e inténtalo de nuevo.',
  [UserErrorCode.OrderInvalidItems]:
    'Tu pedido contiene productos que ya no están disponibles. Por favor recarga la página e inténtalo de nuevo.',
  [UserErrorCode.OrderInvalidModifiers]:
    'Tu pedido contiene productos que ya no están disponibles. Por favor recarga la página e inténtalo de nuevo.',
  [UserErrorCode.OrderItemsDisabled]:
    'Tu pedido contiene productos que ya no están disponibles. Por favor recarga la página e inténtalo de nuevo.',
  // - Payment
  [UserErrorCode.OrderPaymentRejected]: 'El pago ha sido rechazado. Por favor inténtalo más tarde.',
  [UserErrorCode.OrderPaymentError]:
    'Ha habido un problema procesando el pago. Por favor inténtalo más tarde.',

  403: 'No tienes acceso a esta sección',
  404: 'Esta sección no está disponible.',
  500: 'Un error inesperado ha ocurrido.',
}

// TODO - remove & any and ensure the types are correct
export const esTranslations: typeof frTranslations & any = {
  unknownError: 'Algo ha salido mal, por favor inténtalo más tarde.',
  geolocationUnavailable: 'La localización GPS no está disponible.',

  add: 'Añadir',
  create: 'Crear',
  modify: 'Modificar',
  delete: 'Borrar',
  remove: 'Quitar',

  showMore: 'Ver más',
  select: 'Seleccionar',
  change: 'Cambiar',
  previous: 'Anterior',
  next: 'Siguiente',
  goBack: 'Atrás',
  reload: 'Recargar',
  continue: 'Continuar',
  confirm: 'Confirmar',
  retry: 'Intentar de nuevo',
  close: 'Cerrar',
  cancel: 'Cancelar',
  quit: 'Quitar',
  abandon: 'Abandonar',
  pass: 'Pasar',
  yes: 'Sí',
  no: 'No',
  or: 'O',
  without: 'Sin',

  help: 'Ayuda',
  free: 'Gratis',

  languageCode: {
    [Language.Fr]: 'fr',
  },
  language: {
    [Language.Fr]: 'Francés',
    [Language.En]: 'Inglés',
    [Language.Es]: 'Castellano',
    [Language.Pt]: 'Portugués',
    [Language.Ca]: 'Catalán',
    [Language.Nl]: 'Holandés',
  },

  form: {
    region: 'País',
    language: 'Idioma',
    user: {
      firstName: {
        label: 'Nombre',
        placeholder: 'Nombre...',
        error: 'El nombre es obligatorio.',
      },
      lastName: {
        label: 'Apellidos',
        placeholder: 'Apellidos...',
        error: 'Los apellidos son obligatorios.',
      },
      email: {
        label: 'E-mail',
        placeholder: 'johndoe@email.com',
        error: 'El e-mail es incorrecto',
      },
      phone: {
        label: 'Teléfono',
        placeholder: '6 00 00 00 00',
        error: 'Por favor introduce un teléfono válido.',
      },
    },

    instructions: {
      label: 'Instrucciones para el repartidor.',
      placeholder: 'Puerta, instrucciones especificas...',
    },
  },

  // Pages

  tutorial: {
    button: 'wording cta',
    slides: [
      {
        title: 'order in click & collect and delivery',
        subtitle:
          'Be rewarded from the first order with our exclusive loyalty program designed for you.',
      },
      {
        title: 'Benefit of the cheapest prices',
        subtitle:
          'Be rewarded from the first order with our exclusive loyalty program designed for you.',
      },
      {
        title: 'Enjoy our exclusive offers',
        subtitle:
          'Be rewarded from the first order with our exclusive loyalty program designed for you.',
      },
      {
        title: 'Your loyalty is rewarded!',
        subtitle:
          'Be rewarded from the first order with our exclusive loyalty program designed for you.',
      },
    ],
  },

  enter: {
    home: {
      title: 'Pide tu plato favorito',
      loginButton: 'Entrar',
      guestButton: 'Continuar como invitado',
    },
  },

  user: {
    guest: 'Modo invitado',
    myOrders: 'Todos mis pedidos',

    orders: {
      title: 'Todos mis pedidos',

      activeTitle: 'Pedidos activos ({{quantity}})',
    },

    links: {
      myOrders: 'Mis pedidos',
      help: '¿Necesitas ayuda?',
      cguv: 'Términos y condiciones',
      terms: 'Términos y condiciones',
      confidentiality: 'Política de privacidad',
      legal: 'Nota legal',
    },
  },

  setup: {
    title: 'Hola <1>{{name}},</1>',

    address: {
      title: 'Elige una dirección',
      title_delivery: 'Elige la dirección de envío',
      subtitle: 'o usa la localización GPS',
      placeholder: 'Añade tu dirección',
      error: {
        title: 'Atención',
        description: 'No se pudo encontrar su dirección, agréguela nuevamente',
      },
    },

    info: {
      instructions: {
        label: 'Instrucciones para el repartidor',
        placeholder: 'Ejemplo: Número de apartamento, piso...',
      },
      phone: {
        label: 'Teléfono',
        placeholder: '622 12 12 12',
        error: {
          invalid: 'Por favor introduce un teléfono válido.',
        },
        tip: 'Esta información se utilizará para identificar tu pedido y contactar contigo si fuese necesario.',
      },

      submit: 'Usar esta dirección',

      error: {
        title: 'Importante',
        description: 'No podemos entregar en esta dirección.',
      },
    },

    error: {
      title: 'Importante',
      description: 'El restaurant que has elegido no está disponible.',
    },
  },

  restaurant: {
    openUntil: 'Abierto hasta las {{hours}}h{{minutes}}',
    openUntilTime: 'Abierto hasta las {{time}}',
    closed: 'Cerrado',
    findRestaurant: 'Encuentra tu restaurante',
    searchRestaurant: 'Busca tu restaurante',
    upcoming: 'Próxima apertura',
    availableSoon: 'Disponible en breve',
    orderHere: 'Pide aquí',
    moreInfo: 'Más información',
    openHours: 'Hora de apertura',
    clickAndCollect: 'Click & collect',
    atRestaurant: 'En {{restaurantName}}',
    theRestaurants: 'Los restaurantes',
    findAll: '¡Encuentra todos los restaurantes {{companyName}}!',
    allRestaurants: 'Todos los restaurantes',
    informations: 'Información',
  },

  order: {
    type: {
      [OrderType.Delivery]: 'Envío',
      [OrderType.PickUp]: 'Recoger',
      [OrderType.EatIn]: 'Comer aquí',
    },

    status: {
      [OrderStatus.Waiting]: 'En espera',
      [OrderStatus.Preorder]: 'Pedido previo',
      [OrderStatus.Unpaid]: 'Sin pagar',
      [OrderStatus.New]: 'Nuevo',
      [OrderStatus.InProgress]: 'En preparación',
      [OrderStatus.Prepared]: 'Preparado',
      [OrderStatus.Finished]: 'Terminado',
      [OrderStatus.Refunded]: 'Reembolsado',
    },

    banners: 'Ofertas actuales',

    summary: {
      title: 'Tu carrito',
    },

    change: {
      title: 'Importante',
      description: 'Al cambiar el tipo de pedido, tu carrito será vaciado.',
    },

    settings: {
      type: {
        [OrderType.Delivery]: 'Envío',
        [OrderType.PickUp]: 'Recoger',
        [OrderType.EatIn]: 'Comer aquí',
        [OrderType.Table]: 'Comer aquí',
      },

      slots: {
        now: 'Ahora ({{ time }})',
        asap: 'Lo antes posible',
        asapTable: 'Lo antes posible',
        expectedFor: 'A las',
      },
    },

    card: {
      number: 'Pedido\nN°{{ number }}',
      quantity: '{{ quantity }} productos',
    },

    details: {
      header: {
        title: 'Pedido\nN°{{ number }}',
        date: 'El día {{- date}} a las {{time}}',

        restaurant: 'Pide en',

        mode: {
          [OrderType.Delivery]: 'Entrega a las',
          [OrderType.PickUp]: 'Recoger a las',
          [OrderType.Table]: 'Comer aquí',
        },

        payment: 'Pagado por',
      },

      products: {
        title: 'Productos ({{ quantity }})',
      },

      sendEmail: {
        button: 'Descargar recibo',
        title: 'Obtener el recibo',

        email: 'E-mail',
        quantity: 'Número de comensales',
        submit: 'Enviar',
      },
    },

    upsell: {
      title: 'Un poco más para el camino',
      dismiss: '¡No gracias!',
      finish: 'Terminar',
    },

    actionBar: {
      activeOrder: {
        title: 'Pedido en marcha',

        time: {
          [OrderType.Delivery]: 'Entrega a las',
          [OrderType.PickUp]: 'Recoger a las',
          [OrderType.EatIn]: 'Comer aquí',
          [OrderType.Table]: 'Comer aquí',
        },
      },
    },
  },

  checkout: {
    basket: 'Carrito',
    title: 'Comprueba tu carrito en {{ restaurantName }}',
    button: 'Ver mi pedido',
    addProducts: 'Añadir producto',
    restaurantAddress: 'Dirección del restaurante',
    finish: 'Terminar',
    discount: {
      notDelivery: {
        pending: 'Añade {{pending}} más y disfruta de <1>{{reward}} de descuento</1>',
        completed: '¡Enhorabuena! Ya puedes disfrutar de <1>{{reward}} de descuento</1>',
      },
      delivery: {
        pending: 'Añade {{pending}} más y disfruta de <1>envío gratuito</1>',
        completed: '¡Enhorabuena! Puedes disfrutar de envío gratuito',
      },
    },

    products: {
      empty: {
        title: 'Tu carrito está vacío',
        subtitle: 'Añade productos a tu carrito',
      },
      mandatory: 'Obligatorio',
    },

    summary: {
      title: 'Tu <1>pedido ({{quantity}})</1>',

      total: {
        products: 'Productos',
        deliveryFee: {
          title: 'Coste de envío',
          description: 'Cuanto más cerca esté el restaurante, más bajo será el coste de envío.',
        },
        discount: 'Descuento',
        service: {
          title: 'Coste de servicio',
          description:
            'Estas tarifas nos permiten optimizar su experiencia, en particular a través de nuevas características y un servicio al cliente óptimo.',
        },
        additionalFees: {
          title: 'Costes adicionales',
          description:
            'Si el total de su pedido es menor que la cantidad mínima establecida por el restaurante tras la entrega, estos costos se agregarán a su carrito.',
        },
        total: 'Total',
        withTaxes: 'Total + IVA',
      },
    },

    priceInfo: {
      title: '¿Cuáles son los costes aplicados?',
    },

    promotions: {
      title: 'Descuentos',
      error: 'El código promocional que has usado es inválido.',
      add: {
        heading: '¿Un código promocional?',
        title: 'Añade un código promocional',
        label: 'Añade tu código promocional',
        placeholder: 'Código promocional',
        cta: 'Aplicar',
        error: 'El código promocional que has usado es inválido.',
      },

      details: {
        [DiscountTarget.Total]: '-{{ discount }} en el pedido',
        [DiscountTarget.Delivery]: {
          discount: '-{{ discount }} en los gastos de envío',
          free: '¡Envío gratis!',
        },
      },
    },

    payments: {
      title: 'Método de pago',
      card: 'Tarjeta bancaria',
      paywithgoogle: 'Google Pay',
      applepay: 'Apple Pay',
      paypal: 'PayPal',
    },

    settings: {
      title: 'Información del pedido',

      timeSlot: {
        title: {
          [OrderType.Delivery]: 'Tiempo de entrega',
          [OrderType.PickUp]: 'Tiempo para recoger',
          [OrderType.EatIn]: 'Servido a las',
          [OrderType.Table]: 'Servido a las',
        },
        asap: '{{ time }}',
        asapTable: 'Lo antes posible',
        expectedFor: 'Se espera a las {{ time }}',
      },

      telephone: {
        title: 'Número de teléfono',
        info: 'Esta información se utilizará para identificar su pedido y contactarlo si es necesario.',
      },

      guestInfo: {
        info: 'Esta información solo se utilizará para rastrear su pedido..',
      },

      cutlery: {
        title: 'Cubiertos',
        info: 'Solicite cubiertos solo si lo necesita.',
        infoEnabled: 'Añadiremos cubiertos a su pedido dentro de los límites de disponibilidad.',
      },

      comments: {
        title: 'Cometarios',
        placeholder: '¿Tiene una solicitud específica en su pedido?',
      },
      tos: 'Al continuar, acepto los <1>Términos y condiciones</1> de {{ client }}.',
      info: 'Al continuar, acepto las <1>Condiciones generales de uso</1> y la <2>política de privacidad</2> de {{ client }}. El pago aparecerá en su estado de cuenta bajo la referencia Belorder.',
      cta: 'Pagar ({{ total }})',
    },

    purchaseStatusModal: {
      title: {
        [PurchaseStatus.Inactive]: 'El pedido está siendo tramitado...',
        [PurchaseStatus.PrePush]: 'El pedido está siendo tramitado...',
        [PurchaseStatus.Ready]: 'El pedido está siendo tramitado...',
        [PurchaseStatus.Purchasing]: 'El pedido está siendo tramitado...',
        [PurchaseStatus.CheckoutActionResult]: 'El pedido está siendo tramitado...',
        [PurchaseStatus.Failed]: 'Ha habido un problema...',
        [PurchaseStatus.Finished]: 'El pedido se ha creado satisfactoriamente...',
      },
      cancel: 'Cancelar mi pedido',
      retry: 'Intentar de nuevo',
    },
  },

  confirmation: {
    title: 'Pedido\nN°{{ number }}',
    failed: 'Su pago ha fallado...',
    goBack: 'Pedir de nuevo',

    status: {
      [OrderType.Delivery]: {
        inProgress: 'Llegada esperada a las {{ time }}',
        finish: 'Pedido enviado',
      },
      [OrderType.PickUp]: {
        inProgress: 'Recogida esperada a las {{ time }}',
        finish: 'Pedido recogido',
      },
      [OrderType.EatIn]: {
        inProgress: 'En preparación...',
        finish: 'Pedido servido',
      },
      [OrderType.Table]: {
        inProgress: 'En preparación...',
        finish: 'Pedido servido',
      },
    },

    details: {
      products: 'Productos',
      cta: 'Ver detalles',
      total: 'Total',

      checkItinerary: 'Ver el itinerario',
      trackDelivery: 'Seguimiento de la entrega',
      help: '¿Un problema con el pedido?',
    },
  },

  product: {
    composed: {
      modifiers: {
        title: 'Personalice su producto',
        option: {
          placeholder: 'Seleccione...',
        },
      },
    },

    menu: {
      selectProduct: 'Seleccione un producto',
    },

    labels: {
      discount: '{{discount}} descuento',
      new: 'New',
      outOfOrder: 'No disponible',
    },
    required: 'Obligatorio',
  },
  footer: {
    usefulLinks: {
      title: 'Enlaces útiles',
      customerService: 'Servicio al Cliente',
      accessToWebsite: 'Acceso al sitio web',
      seeOurRestaurants: 'Ver nuestros restaurantes',
      winAPrize: 'Ganar un premio',
    },
    legal: {
      title: 'Legal',
      cgv: 'Términos de ventas',
      cgu: 'Términos de servicio',
      cookies: 'Política de cookies',
      data: 'Política de privacidad',
      mentions: 'Aviso Legal',
    },
    bestOffers: {
      title: 'Aproveche las mejores ofertas',
      download: '¡Descargue nuestra aplicación móvil!',
    },
  },
  print: {
    note: 'Comentario / Nota',
    total: 'Total',
    section: {
      user: {
        title: 'Cliente',
        firstName: 'Nombre',
        lastName: 'Apellidos',
        phone: 'Teléfono',
      },
      note: {
        title: 'Comentario / Nota',
      },
    },
    type: {
      delivery: 'Envío',
      pickUp: 'Recoger',
      eatIn: 'Comer aquí',
      table: 'Comer aquí',
    },
    error: {
      notFound: 'La impresora no fue encontrada',
      init: 'Parece haber un problema con su Bluetooth. Asegúrese de que Bluetooth esté encendido.',
      bluetooth: {
        title: 'Atención',
        message: 'Bluetooth no está activado.Ninguna impresora está actualmente conectada.',
      },
    },
  },

  credits: {
    poweredBy: 'ofrecido por',
    name: 'Belorder v2.0',
  },

  errors,

  kiosk,
  landingPage: esLandingPageTranslations,
}

export default esTranslations
