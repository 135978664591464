/** Lat, Lng coordinates */
export type Coordinates = [number, number]

// export interface Address {
//   /** City. */
//   readonly city: string

//   /** Street name. */
//   readonly street: string

//   /** Zip code. */
//   readonly zipCode: string

//   /** Phone number */
//   readonly phone: string

//   /** Lat, Lng coordinates */
//   readonly coords: Coordinates
// }

// export interface RestaurantPromotion {
//   /** Title of the promotion. */
//   readonly title: string

//   /** Sub title of the promotion. */
//   readonly subtitle: string

//   /** Image of the promotion. */
//   readonly imageUrl: string
// }

export enum DayOfWeek {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export interface TimeSlot {
  /** TimeSlot identifier */
  readonly id: number

  /** Soonest date. */
  readonly from: Date

  /** Latest. */
  readonly to: Date

  /** Whether this slot represents the current time. */
  readonly isNow?: boolean
}

// export interface DeliverySlot {
//   /** Title to show for the slot. */
//   readonly title: string

//   /** Seconds from 00:00 when it can be delivered. */
//   readonly seconds: number
// }

// export enum DiscountType {
//   Amount = 'amount',
//   Percent = 'percent',
// }

// export enum DiscountTarget {
//   Price = 'price',

//   // Applies to the order type (like delivery) fee
//   OrderTypeFee = 'fee',
// }

// export interface Discount {
//   /** Min price needed to apply the discount. */
//   readonly minPrice: number

//   /** Type of discount */
//   readonly type: DiscountType

//   /** Target of the discount */
//   readonly target: DiscountTarget

//   /** Quantity to discount. */
//   readonly value: number

//   /** Max amount that can be discounted. */
//   readonly maxAmount?: number
// }

// export interface OpenClosedHours {
//   /** Seconds from 00:00 when it's open. */
//   readonly open: number

//   /** Seconds from 00:00 when it's closed.  */
//   readonly close: number
// }

// export interface OrderModeOptions {
//   /** Whether the mode is enabled or not. */
//   readonly isEnabled: boolean

//   /** Fee in cents for using the mode */
//   readonly fee: number

//   /** Hours when the mode is active for every day of the week. */
//   readonly hours: Record<DayOfWeek, OpenClosedHours[]>
// }

// export enum DeliveryCompany {
//   ZitiCity = 'ziticity',
// }

// export interface DeliveryOptions extends OrderModeOptions {
//   /** Company responsible for delivering the order. */
//   readonly company?: DeliveryCompany
// }

// export type PickUpOptions = OrderModeOptions

// export type EatInOptions = OrderModeOptions

// export interface Restaurant {
//   /** ID of the restaurant in the database. */
//   readonly id: string

//   /** Restaurant name */
//   readonly name: string

//   /** Restaurant address */
//   readonly address: Address

//   /** URL of the banner. */
//   readonly bannerURL: string

//   /** Minutes needed to prepare an order. */
//   readonly preparationDuration: number

//   /** Restaurant promotions */
//   readonly promotions: RestaurantPromotion[]

//   /** Restaurant discounts. */
//   readonly discounts: Discount[]

//   /** Whether the restaurant is currently busy */
//   readonly isBusy: boolean

//   /** Service fee */
//   readonly fee: number

//   /** Delivery options. */
//   readonly delivery: DeliveryOptions

//   /** Pick up options. */
//   readonly pickUp: PickUpOptions

//   /** Eat in options. */
//   readonly eatIn: EatInOptions
// }

export enum PrinterType {
  '_80mm' = '80mm',
  '_58mm' = '58mm',
}
