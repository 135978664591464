import {
  DiscountItemsCampaign,
  DiscountTarget,
  ItemDetail,
  ItemTag,
  KioskTRDBrand,
  Language,
  OrderStatus,
  OrderType,
  PaymentMethod,
  UserErrorCode,
} from '../../client'
import { PurchaseStatus } from '../../modules/cart/cart.types'
import { ItemType } from '../../modules/items/item.types'
import { DayOfWeek } from '../../modules/restaurant/restaurant.interface'
// Modules
import { frKioskTranslations } from './kiosk'
import { frLandingPageTranslations } from './landingPage'
import mobile from './mobile'

const errors: Record<UserErrorCode | string, string> = {
  // Auth
  [UserErrorCode.AuthEmailExists]: 'Cette adresse e-mail est déjà utilisée',
  [UserErrorCode.AuthPhoneExists]: 'Ce numéro de téléphone est déjà pris',
  [UserErrorCode.AuthPhoneVerificationInvalid]:
    "Le code n'est pas valide, veuillez réessayer plus tard pour demander un nouveau code.",

  // Order
  // - Cart details
  [UserErrorCode.OrderInvalid]: 'La commande est invalide.',
  [UserErrorCode.OrderNoRestaurantAvailable]:
    'Pas de restaurant disponible pour livrer en ce moment.',
  [UserErrorCode.OrderRestaurantOffline]:
    'Le restaurant est hors ligne, veuillez réessayer à un autre moment.',
  [UserErrorCode.OrderRestaurantBusy]:
    'Le restaurant est occupé, veuillez réessayer à un autre moment.',
  [UserErrorCode.OrderDateInPast]:
    "La date de commande est dans le passé, veuillez actualiser le Web et vous assurer que le créneau horaire sélectionné est à l'avenir.",
  [UserErrorCode.OrderRestaurantClosed]:
    'Le restaurant est hors ligne, veuillez réessayer à un autre moment.',
  // - Items
  [UserErrorCode.OrderItemsDontExist]:
    'Votre commande contient des produits plus disponible. Veuillez rafraîchir votre page afin de pouvoir recommander.',
  [UserErrorCode.OrderModifiersDontExist]:
    'Votre commande contient des produits plus disponible. Veuillez rafraîchir votre page afin de pouvoir recommander.',
  [UserErrorCode.OrderInvalidItems]:
    'Votre commande contient des produits plus disponible. Veuillez rafraîchir votre page afin de pouvoir recommander.',
  [UserErrorCode.OrderInvalidModifiers]:
    'Votre commande contient des produits plus disponible. Veuillez rafraîchir votre page afin de pouvoir recommander.',
  [UserErrorCode.OrderItemsDisabled]:
    'Votre commande contient des produits plus disponible. Veuillez rafraîchir votre page afin de pouvoir recommander.',
  // - Payment
  [UserErrorCode.OrderPaymentRejected]: 'Le paiement a été rejeté, veuillez réessayer plus tard.',
  [UserErrorCode.OrderPaymentError]:
    'Il y a eu un problème lors du traitement de votre paiement, veuillez réessayer plus tard.',
  [UserErrorCode.OrderPaymentNoBalance]: "Le paiement a été rejeté, la carte n'a pas de fonds",
  [UserErrorCode.OrderPaymentNotSuitableMeal]:
    '{{ method }} ne prend pas en compte les produits de votre panier. Veuillez choisir un autre moyen de paiement.',

  403: "Vous n'avez pas accès à cette section",
  404: "La section que vous recherchez n'est pas disponible",
  500: "Oh non, une erreur inattendue s'est produite",
}

export const caTranslations = {
  success: 'Opération terminée avec succès',
  unknownError: 'Un problème est survenu, veuillez réessayer plus tard',
  geolocationUnavailable: "La géolocalisation n'est pas disponible",

  add: 'Ajouter',
  create: 'Ajouter',
  modify: 'Modifier',
  delete: 'Supprimer',
  remove: 'Retirer',

  showMore: 'Afficher plus',
  select: 'Sélectionner',
  change: 'Changer',
  previous: 'Précédent',
  next: 'Suivant',
  goBack: 'Retour',
  reload: 'Rafraîchir',
  continue: 'Continuer',
  confirm: 'Confirmer',
  retry: 'Réessayer',
  close: 'Fermer',
  cancel: 'Annuler',
  quit: 'Quitter',
  abandon: 'Abandonner',
  pass: 'Passer',
  yes: 'Oui',
  no: 'Non',
  or: 'Ou',
  important: 'Important',
  required: 'Requis',
  mandatory: 'Obligatoire',
  optional: 'Optionel',
  without: 'Sans',

  help: 'Aide',
  free: 'Offert',

  weekDays: {
    [DayOfWeek.Monday]: 'Lundi',
    [DayOfWeek.Tuesday]: 'Mardi',
    [DayOfWeek.Wednesday]: 'Mercredi',
    [DayOfWeek.Thursday]: 'Jeudi',
    [DayOfWeek.Friday]: 'Vendredi',
    [DayOfWeek.Saturday]: 'Samedi',
    [DayOfWeek.Sunday]: 'Dimanche',
  },

  languageCode: {
    [Language.Fr]: 'fr',
  },
  language: {
    [Language.Fr]: 'Français',
    [Language.En]: 'English',
    [Language.Es]: 'Espagnol',
    [Language.Pt]: 'Portugais',
    [Language.Ca]: 'Catalan',
    [Language.Nl]: 'Néerlandaise',
  },

  trdBrand: {
    type: {
      [KioskTRDBrand.Sodexo]: 'Sodexo',
      [KioskTRDBrand.Updejeuner]: 'Up',
      [KioskTRDBrand.Bimpli]: 'Bimpli',
      [KioskTRDBrand.Edenred]: 'Edenred',
      [KioskTRDBrand.Swile]: 'Swile',
    },
  },

  form: {
    region: 'Pays',
    language: 'Langue',
    user: {
      firstName: {
        label: 'Prénom',
        placeholder: 'Prénom...',
        error: 'Le prénom est requis',
      },
      lastName: {
        label: 'Nom',
        placeholder: 'Nom...',
        error: 'Le nom est requis',
      },
      email: {
        label: 'Adresse e-mail',
        placeholder: 'johndoe@email.com',
        error: 'Cet e-mail est invalide',
      },
      phone: {
        label: 'Numéro de téléphone',
        placeholder: '6 00 00 00 00',
        error: 'Veuillez entrer un numéro valide.',
      },
      password: {
        label: 'Mot de passe',
        placeholder: 'Choisissez un mot de passe',
        error: 'Le mot de passe doit comporter au moins 5 caractères.',
      },

      passwordConfirmation: {
        label: 'Confirmer mot de passe',
        placeholder: 'Choisissez un mot de passe',
        error: 'La confirmation du mot de passe ne correspond pas au mot de passe.',
      },

      options: 'Mes préférences marketing',
      generalConditions: {
        label: 'Conditions Générales',
        forCompany: 'J’accepte les Conditions Générales de {{ company }}',
        forBelorder: 'J’accepte les Conditions Générales de Belorder',
        consent:
          'Les données recueillies via ce formulaire sont traitées par {{ company }} afin de gérer votre inscription. Pour en savoir plus sur la gestion de vos données et vos droits, vous pouvez consulter notre politique de confidentialité.',
      },

      offersViaEmail: {
        label: "J'accepte de recevoir des offres et actualité par email",
      },
      offersViaSMS: {
        label: "J'accepte de recevoir des offres et actualité par SMS",
      },
    },

    address: {
      name: {
        label: 'Nom de l’adresse',
        placeholder: 'exemple : base secrète, chez moi',
      },
      apartment: {
        label: 'Appartement ou n° de porte',
        placeholder: 'Code portail, numéro d’appartement...',
        error: "Le numéro d'appartement est requis",
      },
      instructions: {
        label: 'Instruction au livreur',
        placeholder: 'Laisser devant la porte...',
      },
    },

    instructions: {
      label: 'Instruction au livreur',
      placeholder: 'Code portail, indications spécifiques...',
    },

    addressInput: {
      label: 'Séléctionnez une adresse de livraison',
      sublabel: 'ou géolocalisez-vous',
      placeholder: 'Ajoutez votre adresse',
    },

    restaurant: {
      placeholder: 'Recherchez...',
    },
  },

  orderTypes: {
    pickup: 'Emporter',
    delivery: 'Livraison',
    eatin: 'Sur place',
  },

  auth: {
    invalidCredentials: "Il n'y a pas de compte avec cet identifiant ou cette adresse e-mail.",

    resetPassword: {
      title: 'Mot de passe oublié',
      subtitle: 'Entrez un nouveau mot de passe pour votre compte.',

      submit: 'Modifier mon mot de passe',
    },
  },

  // Pages

  tutorial: {
    button: 'wording cta',
    slides: [
      {
        title: 'commande en click & collect et livraison',
        subtitle: '',
      },
      {
        title: 'Bénéficie des prix les moins cher',
        subtitle: '',
      },
      {
        title: 'Profite de nos offres exclusives',
        subtitle: '',
      },
      {
        title: 'Ta fidélité est récompensée !',
        subtitle: '',
      },
    ],
  },

  user: {
    guest: 'Mode invité',
    myOrders: 'Toutes mes commandes',

    orders: {
      title: 'Toutes mes commandes',

      activeTitle: 'Commandes en cours ({{quantity}})',
    },

    links: {
      myOrders: 'Mes commandes',
      help: 'Besoin d’aide ?',
      terms: 'CGUV',
      cguv: 'Conditions Générales',
      confidentiality: 'Politique de Données',
      legal: 'Mentions légales',
    },

    login: 'S’inscrire / Se connecter',
  },

  setup: {
    title: 'Bonjour <1>{{name}}</1>',

    restaurant: {
      title: 'Sélectionnez un restaurant',
      subtitle: 'ou géolocalisez-vous',
      placeholder: 'Ajoutez votre adresse',
      button: 'Valider',
    },

    address: {
      title: 'Tapez votre adresse',
      title_delivery: 'Sélectionnez une adresse de livraison',
      subtitle: 'ou géolocalisez-vous',
      placeholder: 'Ajoutez votre adresse',
      button: 'Utiliser cette adresse',
      error: {
        title: 'Attention',
        description: 'Impossible de trouver votre adresse, veuillez l’ajouter à nouveau',
      },
    },

    info: {
      instructions: {
        label: 'Instruction au livreur',
        placeholder: 'Exemple : Numéro d’appartement, étage...',
      },
      phone: {
        label: 'Téléphone',
        placeholder: '6 00 00 00 00',
        error: {
          invalid: 'Veuillez entrer un numéro valide.',
        },
        tip: 'Cette information sera utilisée pour identifier votre commande et vous contacter si nécessaire.',
      },

      submit: 'Utiliser cette adresse',

      error: {
        title: 'Important',
        description: 'Nous ne livrons pas encore à cette adresse.',
      },
    },

    error: {
      title: 'Important',
      description: 'Le point de vente sélectionné n’est pas disponible.',
    },
  },

  restaurant: {
    openUntil: 'Ouvert jusqu’à {{hours}}h{{minutes}}',
    openUntilTime: 'Ouvert jusqu’à {{time}}',
    closed: 'Fermé',
    findRestaurant: 'Trouvez votre restaurant',
    searchRestaurant: 'Rechercher un restaurant',
    upcoming: 'Ouverture prochaine',
    availableSoon: 'Bientôt disponible',
    orderHere: 'Commander ici',
    moreInfo: 'Plus d’informations',
    openHours: 'Horaires d’ouverture',
    clickAndCollect: 'Click & collect',
    atRestaurant: 'Chez {{restaurantName}}',
    theRestaurants: 'Les restaurants',
    findAll: 'Retrouvez tous vos restaurants {{companyName}} !',
    allRestaurants: 'Tous les restaurants',
    informations: 'Informations',
  },

  order: {
    type: {
      [OrderType.Delivery]: 'Livraison',
      [OrderType.PickUp]: 'Emporter',
      [OrderType.EatIn]: 'Sur place',
    },

    status: {
      [OrderStatus.Waiting]: 'En attente',
      [OrderStatus.Preorder]: 'Pré-commander',
      [OrderStatus.Unpaid]: 'Non payé',
      [OrderStatus.New]: 'Accepté',
      [OrderStatus.InProgress]: 'En cours',
      [OrderStatus.Prepared]: 'Prêt',
      [OrderStatus.Finished]: 'Terminé',
      [OrderStatus.Refunded]: 'Remboursé',
    },

    banners: 'Offres du moment',

    discounts: {
      minPrice: 'Commandez pour {{ min }} et',
      maxUsesPerOrder: 'Valable {{ max }} fois.',
      campaign: {
        [DiscountItemsCampaign.FlashOffer]: {
          title: 'OFFRE ÉCLAIR',
          description:
            'Profitez de {{ reduction }} de réduction sur une sélection de produits. Hors suppléments et extras.',
        },
        [DiscountItemsCampaign.FreeProduct]: {
          title: 'PRODUITS OFFERTS',
          description: 'Recevez un produit offert sur une sélection de produits. ',
        },
        [DiscountItemsCampaign.Custom]: {
          title: 'RÉDUCTIONS EXCLUSIVES',
          description: 'Profitez de {{ reduction }} de réduction sur une sélection de produits.',
        },
        [DiscountItemsCampaign.Buy1Get1]: {
          title: '1 ACHETÉ = 1 OFFERT',
          description: '1 produit acheté, le même produit est offert.',
        },
        [DiscountItemsCampaign.GoodDeals]: {
          full: {
            title: 'BONS PLANS (100% reduction)',
            description:
              '{{ triggerQuantity }} produit(s) acheté(s) parmi notre sélection et profite d’une reduction de 100%.',
          },
          partial: {
            title: 'BONS PLANS ({{ percentage }}% reduction)',
            description:
              '{{ triggerQuantity }} produit(s) acheté(s) parmis notre sélection, le {{ triggerQuantityPlusOne }} ème à {{ reduction }}',
          },
        },
      },
    },

    summary: {
      title: 'Votre panier',
    },

    change: {
      title: 'Important',
      description: 'En changeant de mode de commande, votre panier sera vidé.',
    },

    settings: {
      type: {
        [OrderType.Delivery]: 'Livraison',
        [OrderType.PickUp]: 'À emporter',
        [OrderType.EatIn]: 'Sur place',
        [OrderType.Table]: 'Service à table',
      },

      slots: {
        now: 'Au plus tôt ({{ time }})',
        asap: 'Au plus tôt',
        asapTable: 'Dès que possible',
        expectedFor: 'Prévu pour',
      },
    },

    card: {
      number: 'Commande\nN°{{ number }}',
      expectedFor: 'Prévu pour {{ time }}',
      deliveredAt: 'Récupérée à {{ time }}',
      quantity: '{{ quantity }} produits',
    },

    details: {
      header: {
        title: 'Commande N°{{ number }}',
        date: 'Le {{- date}} à {{time}}',

        restaurant: 'Commandé à',

        mode: {
          [OrderType.Delivery]: 'Livraison à',
          [OrderType.PickUp]: 'à Récupérer',
          [OrderType.EatIn]: 'Service à table',
          [OrderType.Table]: 'Service à table',
        },

        payment: 'Payé par',
      },

      products: {
        title: 'Produits ({{ quantity }})',
      },

      sendEmail: {
        button: 'Télécharger le reçu',
        title: 'Obtenir un reçu',

        email: 'E-mail',
        quantity: 'Nombre de couverts',
        submit: 'Envoyer',
      },
    },

    upsell: {
      title: 'Un petit extra pour la route ?',
      dismiss: 'Non merci !',
      finish: 'Finaliser',
    },

    actionBar: {
      activeOrder: {
        title: 'Commande en cours...',

        time: {
          [OrderType.Delivery]: 'Arrivée prévue',
          [OrderType.PickUp]: 'Retrait prévu',
          [OrderType.EatIn]: 'Commandé à',
          [OrderType.Table]: 'Commandé à',
        },
      },
    },
  },

  embeddedOrder: {
    order: 'Commander',
    selectDeliveryType: 'Sélectionnez un mode commande',
    tags: {
      [ItemTag.New]: 'Nouveautés',
      [ItemTag.Popular]: 'Best-sellers',
      [ItemTag.SpecialEdition]: 'Exclusivités',
    },
  },

  checkout: {
    basket: 'Panier',
    title: 'Vérifiez votre commande chez {{ restaurantName }}',
    button: 'Voir ma commande',
    addProducts: 'Ajouter produit',
    restaurantAddress: 'Adresse du restaurant',
    finish: 'Finaliser',
    discount: {
      notDelivery: {
        pending: 'Ajoutez {{pending}} et profitez de <1>{{reward}} de réduction</1>',
        completed: 'Bravo ! Vous bénéficiez de <1>{{reward}} de réduction</1>',
      },
      delivery: {
        pending: 'Ajoutez {{pending}} pour profiter des <1>frais de livraison offert</1>',
        completed: 'Bravo ! Vous bénéficiez des frais de livraison offert',
      },
    },

    products: {
      empty: {
        title: 'Votre panier est vide',
        subtitle: 'Ajoutez des plats dans votre panier',
      },
      mandatory: 'Obligatoire',
    },

    summary: {
      title: 'Votre <1>commande ({{quantity}})</1>',

      total: {
        products: 'Sous-total',
        deliveryFee: {
          title: 'Frais de livraison',
          description:
            'Plus le restaurant est proche de chez vous, plus les frais de livraison sont bas.',
        },
        discount: 'Réduction',
        service: {
          title: 'Frais de service',
          description:
            'Ces frais nous permettent d’optimiser votre expérience, notamment via de nouvelles fonctionnalités et un service client optimal.',
        },
        additionalFees: {
          title: 'Frais additionnels',
          description:
            'Si le montant de votre commande est inférieur au montant minimum fixé par le restaurant lors de la livraison, ces frais s’ajouteront à votre panier.',
        },
        total: 'Total',
        withTaxes: 'TTC',
      },
    },

    secondPayment: {
      title: 'Complétez votre paiement',
      message:
        'Seul les produits alimentaires (hors boissons alcoolisées) peuvent être réglés avec <strong>{{ method }}</strong> avec un montant total de <strong>{{ max }}</strong>.\nChoisissez un deuxième moyen de paiement pour compléter votre paiement.\nLe montant restant à régler est de <strong>{{ remaining }}</strong>',
    },

    priceInfo: {
      title: 'Quels sont les frais appliqués ?',
    },

    promotions: {
      title: 'Promotions',
      error: 'Le code promotionnel utilisé n’est pas valide',
      add: {
        heading: 'Un code promo ?',
        title: 'Ajoutez un code promo',
        label: 'Ajoutez votre code promo',
        placeholder: 'Code promo',
        cta: 'Appliquer',
        error: 'Le code promotionnel utilisé n’est pas valide.',
      },

      details: {
        notUsed: 'Code invalide',
        [DiscountTarget.Item]: {
          discount: '-{{ discount }} sur ton produit {{- items }}',
          discount_many: '-{{ discount }} sur tes produits {{- items }}',
        },
        [DiscountTarget.Total]: '-{{ discount }} sur votre commande',

        [DiscountTarget.Delivery]: {
          discount: '-{{ discount }} sur ta livraison',
          free: 'Livraison offerte !',
        },
        [DiscountTarget.Items]: '-{{ discount }} sur une séléction de produit',
      },
    },

    payments: {
      title: 'Mode de paiement',
      card: 'Carte Bancaire',
      paywithgoogle: 'Google Pay',
      applepay: 'Apple Pay',
      paypal: 'PayPal',
      mealVoucher_FR_natixis: 'Natixis',
      mealVoucher_FR_sodexo: 'Sodexo',
      mealVoucher_FR_groupeup: 'Groupe Up',
      [PaymentMethod.Edenred]: 'Edenred',
    },

    settings: {
      title: 'Informations de commande',

      timeSlot: {
        title: {
          [OrderType.Delivery]: 'Heure de livraison',
          [OrderType.PickUp]: 'Heure de retrait',
          [OrderType.EatIn]: 'Servi à',
          [OrderType.Table]: 'Servi à',
        },
        asap: '{{ time }}',
        asapTable: 'Dès que possible',
        expectedFor: 'Prévu à {{ time }}',
      },

      telephone: {
        title: 'Téléphone',
        info: 'Cette information sera utilisée pour identifier votre commande et vous contacter si nécessaire.',
      },

      guestInfo: {
        info: 'Ces informations seront utilisées uniquement pour le suivi de votre commande.',
      },

      cutlery: {
        title: 'Couverts',
        info: 'Demandez des couverts seulement si vous en avez besoin.',
        infoEnabled:
          'Nous ajouterons des couverts à votre commande dans la limite des disponibilités.',
      },

      comments: {
        title: 'Commentaire',
        placeholder: 'Avez vous une demande spécifique sur la commande ?',
      },
      tos: 'En continuant, j’accepte les <1>Conditions Générales</1> de {{ client }}.',
      info: 'En continant, j’accepte les <1>CGUV</1> et la <2>politique de confidentialité</2> de {{ client }}. Le paiement apparaitra sur votre relevé sous la référence Belorder.',
      cta: 'Payer ({{ total }})',
    },

    purchaseStatusModal: {
      title: {
        [PurchaseStatus.Inactive]: 'Traitement de la commande...',
        [PurchaseStatus.PrePush]: 'Traitement de la commande...',
        [PurchaseStatus.Ready]: 'Traitement de la commande...',
        [PurchaseStatus.Purchasing]: 'Traitement de la commande...',
        [PurchaseStatus.NeedsSecondPayment]: 'Complétez votre paiement...',
        [PurchaseStatus.CheckoutActionResult]: 'Traitement de la commande...',
        [PurchaseStatus.Failed]: 'Un problème est survenu…',
        [PurchaseStatus.Finished]: 'La commande a été créée avec succès…',
      },
      cancel: 'Annuler ma commande',
      retry: 'Réessayer',
    },
  },

  checkoutInfo: {
    collectInfo: {
      title: 'Heure de collecte',
    },
    restaurantInfo: {
      title: 'Adresse du restaurant',
    },
    deliveryInfo: {
      address: 'Adresse de livraison',
      instructions: 'Instruction de livraison',
    },
    guestForm: {
      footer: 'Ces informations seront utilisées uniquement pour le suivi de votre commande.',
    },
    cutlery: {
      title: 'Couverts',
      subtitle: 'Réduisez les déchets : demandez des couverts seulement si vous en avez besoin.',
    },
    note: {
      label: 'Commentaire',
      placeholder: 'Avez vous une demande spécifique sur la commande ?',
    },
    pay: 'Payer ({{ total }})',
    payWithEdenred: 'Payer avec Edenred',
  },

  confirmation: {
    title: 'Commande\nN°{{ number }}',
    failed: 'Votre paiement a échoué...',
    goBack: 'Commander à nouveau',
    products: 'Produit',
    products_plural: 'Produits',
    seeDetails: 'Voir details',
    total: 'TOTAL (TTC)',
    ttc: '(TTC)',
    giveFeedback: 'Donner un avis',
    help: 'Un problème sur la commande ?',

    type: {
      [OrderType.Delivery]: {
        title: 'Votre livreur',
        cta: 'Voir le suivi',
      },
      [OrderType.PickUp]: {
        title: 'À Récupérer',
        cta: 'Voir l’itinéraire',
      },
      [OrderType.EatIn]: {
        title: 'Au comptoir',
        cta: 'Retourner au menu',
      },
      [OrderType.Table]: {
        title: 'Service à table',
        cta: 'Retourner au menu',
      },
    },

    status: {
      [OrderStatus.Waiting]: 'En attente',
      [OrderStatus.InProgress]: 'En cours',
      [OrderStatus.Refunded]: 'Remboursé',
      [OrderStatus.Rejected]: 'Annulé',
      [OrderStatus.Delivering]: 'En chemin',
      [OrderStatus.Prepared]: 'Prête',
      [OrderStatus.Finished]: 'Terminé',
      [OrderStatus.Cancelled]: 'Annulé',

      [OrderType.Delivery]: {
        [OrderStatus.InProgress]: 'Arrivée prévue à {{ time }}',
        [OrderStatus.Delivering]: 'Livraison en cours\nArrivée prévue à {{ time }}',
        [OrderStatus.Finished]: 'Commande livrée',
      },
      [OrderType.PickUp]: {
        [OrderStatus.InProgress]: 'Retrait prévu à {{ time }}',
        [OrderStatus.Finished]: 'Commande récupérée',
      },
      [OrderType.EatIn]: {
        [OrderStatus.InProgress]: 'En cours de préparation...',
        [OrderStatus.Finished]: 'Commande servie',
      },
      [OrderType.Table]: {
        [OrderStatus.InProgress]: 'En cours de préparation...',
        [OrderStatus.Finished]: 'Commande servie',
      },
    },

    details: {
      products: 'Produits',
      cta: 'Voir détails',
      total: 'Total',

      checkItinerary: 'Voir l’itinéraire',
      trackDelivery: 'Suivre la livraison',
      help: 'Un problème sur la commande ?',
    },
  },

  product: {
    [ItemType.Composed]: {
      modifiers: {
        title: 'Personnalisez votre produit',
        option: {
          placeholder: 'Sélectionnez...',
        },
      },
    },

    [ItemType.Menu]: {
      selectProduct: 'Sélectionner un produit',
      modifiers: {
        title: 'Personnalisez votre formule',
        option: {
          placeholder: 'Sélectionnez...',
        },
      },
    },

    labels: {
      discount: '{{discount}} de réduction',
      new: 'Nouveauté',
      outOfOrder: 'Rupture',
    },
    required: 'Requis',

    detail: {
      [ItemDetail.Halal]: 'Halal',
      [ItemDetail.Spicy]: 'Épicé',
      [ItemDetail.Vegetarian]: 'Végétarien',
      [ItemDetail.DoNotFreeze]: 'Ne pas congeler',
      [ItemDetail.Sulfite]: 'Sulfite',
      [ItemDetail.Gluten]: 'Gluten',
      [ItemDetail.GlutenFree]: 'Sans gluten',
      [ItemDetail.Milk]: 'Lait',
      [ItemDetail.Egg]: 'Oeufs',
      [ItemDetail.Sesame]: 'Sésame',
      [ItemDetail.Shellfish]: 'Crustacés',
      [ItemDetail.Nuts]: 'Fruits à coque',
      [ItemDetail.Peanuts]: 'Arachides',
      [ItemDetail.Fish]: 'Poisson',
      [ItemDetail.Soybean]: 'Soja',
    },
  },
  footer: {
    usefulLinks: {
      title: 'Liens utiles',
      customerService: 'Service client',
      accessToWebsite: 'Accéder au site web',
      seeOurRestaurants: 'Voir nos restaurants',
      winAPrize: 'Gagner un cadeau !',
    },
    legal: {
      title: 'Légal',
      cgv: 'Conditions Générales de vente',
      cgu: 'Conditions Générales d’utilisation',
      cookies: 'Politique de cookies',
      data: 'Politique de confidentialité',
      mentions: 'Mentions légales',
    },
    bestOffers: {
      title: 'Profitez des meilleures offres',
      download: 'Téléchargez notre application mobile !',
    },
  },
  print: {
    payment: {
      unpaid: ' À RÉGLER EN CAISSE ',
      paid: '   PAYÉ   ',
    },
    note: 'COMMENTAIRE / NOTE',
    total: 'Total',
    section: {
      user: {
        title: 'CLIENT',
        firstName: 'prenom',
        lastName: 'nom',
        phone: 'telephone',
      },
      note: {
        title: 'COMMENTAIRE / NOTE',
      },
    },
    items: {
      quantity: 'QTE',
      item: 'PRODUIT',
      price: 'Montant',
    },
    totals: {
      productsTotalWithoutDiscount: 'SOUS-TOTAL:',
      priceReduction: 'REDUCTION:',
      serviceFee: 'FRAIS DE SERVICE:',
      total: 'TOTAL:',
      toPay: 'RESTANT A PAYER:',
    },
    type: {
      delivery: 'Livraison',
      pickUp: 'C&C',
      eatIn: 'Sur place',
      table: 'Sur place',
    },
    error: {
      notFound: "L'imprimante n'a pas été trouvée",
      init: 'Il semble y avoir un problème avec votre Bluetooth. Veuillez vous assurer que Bluetooth est activé.',
      bluetooth: {
        title: 'Attention',
        message: "Le Bluethooth n'est pas activé. Aucune imprimante n'est actuellement connectée.",
      },
    },
  },

  credits: {
    poweredBy: 'Propulsé par',
    name: 'Belorder v2.0',
  },

  catalog: {
    info: {
      [OrderType.PickUp]: 'Retrait à {{slot}}',
      [OrderType.Delivery]: 'Livré dans {{slot}}',
      [OrderType.EatIn]: 'Prêt dans {{slot}}',
      [OrderType.Table]: 'Servi dans {{slot}}',
    },
    details: {
      [ItemDetail.Halal]: 'Halal',
      [ItemDetail.Vegetarian]: 'Végétarien',
      [ItemDetail.Spicy]: 'Épicé',
      [ItemDetail.GlutenFree]: 'Sans gluten',
    },
    tags: {
      [ItemTag.New]: 'Nouveauté',
      [ItemTag.SpecialEdition]: 'Édition limité',
      [ItemTag.Popular]: 'Populaire',
      out_of_stock: 'Rupture',
    },
    add: 'Ajouter',
    customize: 'Personnalisez votre produit',
    required: 'Requis',
    optional: 'Optionel',
  },

  helpOrder: {
    form: {
      problemType: {
        label: 'Quel est votre problème ?',
        placeholder: 'Selectionnez un sujet',
        types: {
          refund_request: 'Demande de remboursement',
          order_problem: 'Signalez un problème sur la commande',
          other: 'Autre',
        },
      },
      photos: {
        label: 'Ajouter des photos',
        button: 'Ajouter une pièce jointe ?',
        gallery: 'phototèque',
        camera: 'Prendre une photo',
      },
      description: {
        label: 'Ajoutez une précision',
        placeholder: 'Boisson manquante...',
      },
      submit: 'Soumettre',
    },
    submitted: {
      title: 'DEMANDE PRISE EN COMPTE.',
      subtitle: 'Nous reviendrons vers vous dans les plus brefs délais.',
    },
  },

  errors,

  kiosk: frKioskTranslations,
  landingPage: frLandingPageTranslations,
  mobile,
}

export default caTranslations
