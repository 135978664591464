import { DiscountTarget, Language, OrderStatus, OrderType, UserErrorCode } from '../../client'
import { PurchaseStatus } from '../../modules/cart/cart.types'
import frTranslations from '../fr'
// Modules
import kiosk from './kiosk'
import { nlLandingPageTranslations } from './landingPage'

const errors: Record<UserErrorCode | string, string> = {
  // Order
  // - Cart details
  [UserErrorCode.OrderRestaurantOffline]:
    'Het restaurant is offline, probeer het later eens opnieuw.',
  [UserErrorCode.OrderRestaurantBusy]: 'Het restaurant is offline, probeer het later eens opnieuw.',
  [UserErrorCode.OrderDateInPast]:
    'De besteldatum is voorbij, vernieuw je webpagina en kies een tijdslot in de toekomst.',
  [UserErrorCode.OrderRestaurantClosed]:
    'Het restaurant is offline, probeer het later eens opnieuw.',
  // - Items
  [UserErrorCode.OrderItemsDontExist]:
    'Uw bestelling bevat producten die niet meer leverbaar zijn. Vernieuw jouw webpagina zodat je opnieuw kan bestellen',
  [UserErrorCode.OrderModifiersDontExist]:
    'Uw bestelling bevat producten die niet meer leverbaar zijn. Vernieuw jouw webpagina zodat je opnieuw kan bestellen',
  [UserErrorCode.OrderInvalidItems]:
    'Uw bestelling bevat producten die niet meer leverbaar zijn. Ververs uw pagina zodat u opnieuw kunt bestellen',
  [UserErrorCode.OrderInvalidModifiers]:
    'Uw bestelling bevat producten die niet meer leverbaar zijn. Vernieuw jouw webpagina zodat je opnieuw kan bestellen',
  [UserErrorCode.OrderItemsDisabled]:
    'Uw bestelling bevat producten die niet meer leverbaar zijn. Vernieuw jouw webpagina zodat je opnieuw kan bestellen',
  // - Payment
  [UserErrorCode.OrderPaymentRejected]:
    'Jouw betaling is geweigerd, probeer het later eens opnieuw.',
  [UserErrorCode.OrderPaymentError]:
    'Er is een probleem opgetreden bij het verwerken van uw betaling. Probeer het later eens opnieuw.',

  403: 'U heeft geen toegang tot deze sectie',
  404: 'De sectie die u zoekt is niet beschikbaar',
  500: 'Oh nee, er is een onverwachte fout opgetreden',
}

// TODO - remove & any and ensure the types are correct
export const nlTranslations: typeof frTranslations & any = {
  unknownError: 'Er is iets misgegaan, probeer het later eens opnieuw',
  geolocationUnavailable: 'Geolocalisatie is niet beschikbaar',

  add: 'Toevoegen',
  create: 'Creëren',
  modify: 'Bewerken',
  delete: 'Verwijderen',
  remove: 'Verwijderen',

  showMore: 'Toon meer',
  select: 'Kiezen',
  change: 'Wijzigen',
  previous: 'Vorige',
  next: 'Volgende',
  goBack: 'Terug',
  reload: 'Herladen',
  continue: 'Doorgaan',
  confirm: 'Bevestigen',
  retry: 'Probeer het nog eens',
  close: 'Afsluiten',
  cancel: 'Annuleren',
  quit: 'Afsluiten',
  abandon: 'Verlaten',
  pass: 'Doorgaan',
  yes: 'Ja',
  no: 'Nee',
  or: 'Of',
  without: 'Without',

  help: 'Hulp',
  free: 'Gratis',

  languageCode: {
    [Language.Fr]: 'fr',
  },
  language: {
    [Language.Fr]: 'Français',
    [Language.En]: 'English',
    [Language.Es]: 'Espagnol',
    [Language.Pt]: 'Portugais',
    [Language.Ca]: 'Catalan',
    [Language.Nl]: 'Nederlands',
  },

  form: {
    region: 'Land',
    language: 'Taal',
    firstName: {
      label: 'Voornaam',
      placeholder: 'Voornaam...',
      error: 'Voornaam is verplicht',
    },
    lastName: {
      label: 'Achternaam',
      placeholder: 'Achternaam...',
      error: 'Achternaam is verplicht',
    },

    email: {
      label: 'E-mailadres',
      error: 'Ongeldig e-mailadres',
    },

    phone: {
      label: 'Telefoonnummer',
      error: 'Voer alstublieft een geldig nummer in.',
    },

    instructions: {
      label: 'Instructies voor de bezorger',
      placeholder: 'Specifieke info...',
    },
  },

  // Pages

  tutorial: {
    button: 'bewoording cta',
    slides: [
      {
        title: 'Bestellen in Click & Collect of thuis laten bezorgen',
        subtitle:
          'Word beloond vanaf de eerste bestelling met ons voor jouw ontworpen exclusieve getrouwheidsprogramma.',
      },
      {
        title: 'De BESTE prijzen',
        subtitle:
          ' Word beloond vanaf de eerste bestelling met ons voor jouw ontworpen exclusieve getrouwheidsprogramma.',
      },
      {
        title: 'Geniet van onze exclusieve aanbiedingen',
        subtitle:
          ' Word beloond vanaf de eerste bestelling met ons voor jouw ontworpen exclusieve getrouwheidsprogramma.',
      },
      {
        title: 'Jouw getrouwheid wordt beloond!',
        subtitle:
          ' Word beloond vanaf de eerste bestelling met ons voor jouw ontworpen exclusieve getrouwheidsprogramma.',
      },
    ],
  },

  enter: {
    home: {
      title: 'Bestel je lievelingsgerecht',
      loginButton: 'inloggen / registreren',
      guestButton: 'ga door als gast',
    },
  },

  user: {
    guest: 'Gastmodus',
    myOrders: 'Mijn bestellingen',

    orders: {
      title: 'Mijn bestellingen',

      activeTitle: 'Lopende bestellingen ({{quantity}})',
    },

    links: {
      myOrders: 'Mijn bestellingen',
      help: 'Hulp nodig ?',
      cguv: 'AVV',
      terms: 'AVV',
      confidentiality: 'Privacybeleid',
      legal: 'Juridische kennisgeving',
    },
  },

  setup: {
    title: 'Hallo <1>{{name}},</1>',

    address: {
      title: 'Selecteer een adres',
      title_delivery: 'Kies een leveringsadres',
      subtitle: 'of geolokaliseer jezelf',
      placeholder: 'Voeg je adres toe',
      error: {
        title: 'Aandacht',
        description: 'Kan uw adres niet vinden, voeg het opnieuw toe',
      },
    },

    info: {
      instructions: {
        label: 'Instructies voor de bezorger',
        placeholder: 'Voorbeeld: Appartementnummer, verdieping...',
      },
      phone: {
        label: 'Telefoonnummer',
        placeholder: '6 00 00 00 00',
        error: {
          invalid: 'Voer alstublieft een geldig nummer in.',
        },
        tip: 'Deze informatie wordt gebruikt om jouw bestelling te identificeren en indien nodig contact met je op te nemen.',
      },

      submit: 'Gebruik dit adres',

      error: {
        title: 'Belangrijk',
        description: 'Spijtig genoeg leveren we nog niet op dit adres.',
      },
    },

    error: {
      title: 'Opgelet',
      description: 'Het gekozen verkooppunt is niet beschikbaar.',
    },
  },

  restaurant: {
    openUntil: 'Open tot {{hours}}u{{minutes}}',
    openUntilTime: 'Open tot {{time}}',
    closed: 'Gesloten',
    findRestaurant: 'Vind je restaurant',
    searchRestaurant: 'Zoek je restaurant',
    orderHere: 'Bestel hier',
    moreInfo: 'Meer informatie',
    openHours: 'Openingsuren',
    clickAndCollect: 'Click & Collect',
    atRestaurant: 'Bij {{restaurantName}}',
    theRestaurants: 'De restauranten',
    findAll: 'Vind al onze restauranten {{companyName}} !',
    allRestaurants: 'Alle restauranten',
    informations: 'Informatie',
  },

  order: {
    type: {
      [OrderType.Delivery]: 'Levering',
      [OrderType.PickUp]: 'Ophalen',
      [OrderType.EatIn]: 'Ter plaatse',
    },

    status: {
      [OrderStatus.Waiting]: 'Wachtend ',
      [OrderStatus.Preorder]: 'Voorafgaande bestelling',
      [OrderStatus.Unpaid]: 'onbetaald',
      [OrderStatus.New]: 'Nieuw',
      [OrderStatus.InProgress]: 'Bezig',
      [OrderStatus.Prepared]: 'Klaar',
      [OrderStatus.Finished]: 'Afgerond',
      [OrderStatus.Refunded]: 'Terugbetaald',
    },

    banners: 'Huidige aanbiedingen',

    summary: {
      title: 'Jouw winkelwagen',
    },

    change: {
      title: 'Opgelet',
      description: 'Door de bestelmethode te wijzigen, wordt jouw winkelwagen geledigd.',
    },

    settings: {
      type: {
        [OrderType.Delivery]: 'Leveren',
        [OrderType.PickUp]: 'Ophalen',
        [OrderType.EatIn]: 'Ter plaatse',
        [OrderType.Table]: 'Bedienen aan tafel',
      },

      slots: {
        now: 'Nu ({{time }})',
        asap: 'Zo snel mogelijk',
        asapTable: 'Zo snel mogelijk',
        expectedFor: 'verwacht om',
      },
    },

    card: {
      number: 'Bestel\nN°{{ number }}',
      quantity: '{{ quantity }} producten',
    },

    details: {
      header: {
        title: 'Bestel\nN°{{ number }}',
        date: 'De {{- date}} om {{time}}',

        restaurant: 'Bestellen bij',

        mode: {
          [OrderType.Delivery]: 'Bezorgen bij',
          [OrderType.PickUp]: 'Ophalen bij',
          [OrderType.Table]: 'Bediend aan tafel',
        },

        payment: 'betaald door',
      },

      products: {
        title: 'Producten ({{ qunatity }})',
      },

      sendEmail: {
        button: 'Ontvangstbewijs downloaden',
        title: 'Ontvang jouw ontvangstbewijs',

        email: 'E-mail',
        quantity: 'Aantal bestek',
        submit: 'Bevestigen',
      },
    },

    upsell: {
      title: 'Een extraatje voor onderweg ?',
      dismiss: 'Nee, dank u wel !',
      finish: 'Afsluiten',
    },

    actionBar: {
      activeOrder: {
        title: 'Bestelling in uitvoering',

        time: {
          [OrderType.Delivery]: 'Bezorgen bij',
          [OrderType.PickUp]: 'Ophalen bij',
          [OrderType.EatIn]: 'Bestellen bij',
          [OrderType.Table]: 'Bestellen bij',
        },
      },
    },
  },

  checkout: {
    basket: 'Winkelwagen',
    title: 'Controleer uw bestelling bij {{ restaurantName }}',
    button: 'Zie mijn bestelling',
    addProducts: 'Product toevoegen',
    restaurantAddress: 'Restaurantadres',
    finish: 'Afsluiten',
    discount: {
      notDelivery: {
        pending: 'Voeg {{pending}} toe en geniet van <1>{{reward}} korting</1>',
        completed: 'Proficiat! Je geniet van <1>{{reward}} korting</1>',
      },
      delivery: {
        pending: 'Voeg {{pending}} toe om te genieten van <1>gratis bezorging</1>',
        completed: 'Proficiat! Je geniet van gratis bezorging',
      },
    },

    products: {
      empty: {
        title: 'Uw winkelwagen is leeg',
        subtitle: 'Voeg producten toe aan uw winkelwagen',
      },
      mandatory: 'Verplicht',
    },

    summary: {
      title: 'Uw <1>bestelling ({{quantity}})</1>',

      total: {
        products: 'Producten',
        deliveryFee: {
          title: 'Leveringskosten',
          description:
            'Hoe dichter het restaurant bij u in de buurt is, hoe goedkoper de levering.',
        },
        discount: 'Korting',
        service: {
          title: 'Servicekosten',
          description:
            'Deze kosten optimaliseren jouw ervaring, nl.door nieuwe functies en een optimale klantenservice.',
        },
        additionalFees: {
          title: 'Extra kosten',
          description:
            'Als het bedrag van je bestelling lager is dan het minimumbedrag dat door het restaurant voor de levering is vastgesteld, worden deze kosten automatisch aan je winkelwagen toegevoegd.',
        },
        total: 'Totaal',
        withTaxes: 'BTW inclusief',
      },
    },

    priceInfo: {
      title: 'Welke kosten worden er bijgerekend?',
    },

    promotions: {
      title: 'Promoties',
      error: 'De gebruikte promotiecode is niet geldig',
      add: {
        heading: 'Een actiecode ?',
        title: 'Voeg een promotiecode toe',
        label: 'Voeg jouw promotiecode toe',
        placeholder: 'Promotiecode',
        cta: 'Van toepassing zijn',
        error: 'De gebruikte promotiecode is niet geldig.',
      },

      details: {
        [DiscountTarget.Total]: '-{{ discount }} op deze bestelling',
        [DiscountTarget.Delivery]: {
          discount: '-{{ discount }} op je levering',
          free: 'Gratis levering !',
        },
      },
    },

    payments: {
      title: 'Betaalmethode',
      card: 'Bankkaart',
      paywithgoogle: 'Google Pay',
      applepay: 'Apple Pay',
      paypal: 'PayPal',
    },

    settings: {
      title: 'Bestelinformatie',

      timeSlot: {
        title: {
          [OrderType.Delivery]: 'Leveringstijd',
          [OrderType.PickUp]: 'Ophaaltijd',
          [OrderType.EatIn]: 'Ter plaatse',
          [OrderType.Table]: 'Bediend bij',
        },
        asap: '{{ time }}',
        asapTable: 'Zo snel mogelijk',
        expectedFor: 'Verwacht om {{ time }}',
      },

      telephone: {
        title: 'Telefoonnummer',
        info: 'Deze informatie wordt gebruikt om jouw bestelling te identificeren en indien nodig contact met jou op te nemen.',
      },

      guestInfo: {
        info: 'Deze informatie wordt enkel gebruikt om jouw bestelling te volgen.',
      },

      cutlery: {
        title: 'Bestek',
        info: 'Vraag enkel om bestek indien echt nodig.',
        infoEnabled:
          'We zullen bestek aan jouw bestelling toevoegen binnen de grenzen van de beschikbaarheid.',
      },

      comments: {
        title: 'Commentaar',
        placeholder: 'Heb je een speciaal verzoek bij uw bestelling?',
      },
      tos: 'Door verder te gaan, aanvaard ik de <1>Algemene voorwaarden</1> van {{ client }}.',
      info: 'Door verder te gaan, aanvaard ik de <1>CGUV</1> en het <2>privacybeleid</2> van {{ client }}. De betaling verschijnt op jouw bankafschrift onder de referentie Belorder.',
      cta: 'Betalen ({{ total }})',
    },

    purchaseStatusModal: {
      title: {
        [PurchaseStatus.Inactive]: 'Bestelling wordt aanvaard...',
        [PurchaseStatus.PrePush]: 'Bestelling wordt aanvaard...',
        [PurchaseStatus.Ready]: 'Bestelling wordt aanvaard...',
        [PurchaseStatus.Purchasing]: 'Bestelling wordt aanvaard...',
        [PurchaseStatus.CheckoutActionResult]: 'Bestelling wordt aanvaard...',
        [PurchaseStatus.Failed]: 'Er is een probleem opgetreden...',
        [PurchaseStatus.Finished]: 'De bestelling is succesvol aangemaakt...',
      },
      cancel: 'Annuleer mijn bestelling',
      retry: 'Opnieuw proberen',
    },
  },

  confirmation: {
    title: 'Bestelling\nN°{{ number }}',
    failed: 'Jouw betaling is mislukt...',
    goBack: 'Bestel opnieuw',

    status: {
      [OrderType.Delivery]: {
        inProgress: 'Verwachte aankomst om {{ time }}',
        finish: 'Bestelling geleverd',
      },
      [OrderType.PickUp]: {
        inProgress: 'Verwachte ophaling om {{ time }}',
        finish: 'Bestelling opgehaald',
      },
      [OrderType.EatIn]: {
        inProgress: 'In voorbereiding...',
        finish: 'Bestelling bediend',
      },
      [OrderType.Table]: {
        inProgress: 'In voorbereiding...',
        finish: 'Bestelling bediend',
      },
    },

    details: {
      products: 'Producten',
      cta: 'Zie de details',
      total: 'Totaal',

      checkItinerary: 'Bekijk de route',
      trackDelivery: 'Levering volgen',
      help: 'Een probleem met je bestelling?',
    },
  },

  product: {
    composed: {
      modifiers: {
        title: 'Personaliseer je product',
        option: {
          placeholder: 'Kies...',
        },
      },
    },

    menu: {
      selectProduct: 'Kies een product',
    },

    labels: {
      discount: '{{discount}} korting',
      new: 'Nieuw',
      outOfOrder: 'Buiten gebruik',
    },
    required: 'Verplicht',
  },
  footer: {
    usefulLinks: {
      title: 'Handige links',
      customerService: 'Klantenservice',
      accessToWebsite: 'Toegang tot de website',
      seeOurRestaurants: 'Bekijk onze restauranten',
      winAPrize: 'Win een prijs',
    },
    legal: {
      title: 'legaal',
      cgv: 'Verkoopvoorwaarden',
      cgu: 'Servicevoorwaarden',
      cookies: 'Cookiebeleid',
      data: 'Privacybeleid',
      mentions: 'Juridische kennisgeving',
    },
    bestOffers: {
      title: 'Geniet van de beste aanbiedingen',
      download: 'Download onze mobiele app!',
    },
  },
  print: {
    note: 'OPMERKING / OPMERKING',
    total: 'Totaal',
    section: {
      user: {
        title: 'KLANT',
        firstName: 'Voornaam',
        lastName: 'achternaam',
        phone: 'telefoonnummer',
      },
      note: {
        title: 'OPMERKING / OPMERKING',
      },
    },
    type: {
      delivery: 'Levering',
      pickUp: 'C&C',
      eatIn: 'Ter plaatse',
      table: 'Ter plaatse',
    },
    error: {
      notFound: 'De printer is niet gevonden',
      init: 'Er lijkt een probleem te zijn met uw Bluetooth. Zorg ervoor dat Bluetooth is ingeschakeld.',
      bluetooth: {
        title: 'Opgelet!',
        message:
          'Bluetooth is blijkbaar niet ingeschakeld. Er is momenteel geen printer aangesloten.',
      },
    },
  },

  credits: {
    poweredBy: 'aangedreven door',
    name: 'Belorder v2.0',
  },

  errors,

  kiosk,
  landingPage: nlLandingPageTranslations,
}

export default nlTranslations
