import { frLandingPageTranslations } from '../fr/landingPage'

export const enLandingPageTranslations: typeof frLandingPageTranslations = {
  header: {
    menu: 'MENU',
  },
  content: {
    quickOrder: {
      delivery: 'Delivery',
      clickAndCollect: 'Click & Collect',
      address: {
        placeholder: 'Pick your address...',
        placeholder_delivery: 'Pick a delivery address...',
      },
    },
  },
}
